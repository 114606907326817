import { Extension } from "@tiptap/core";

const Shortcuts = Extension.create({
  name: "chatShortcuts",

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        this.editor.view.dom.closest("form").querySelector("button").click();
        setTimeout(() => {
          this.editor.commands.clearContent(true);
        }, 50);
      },
    };
  },
});

export default Shortcuts;
