import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState, useMemo } from "react";
import { collectionById } from "../../features/collectionsSlice";
import Popover from "../Popover";
import Menu from "../Menu";
import { buttonReset } from "../../css-mixins/button";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const StyledFocusButton = styled.div`
  ${buttonReset}
  border-radius: 3rem;
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  padding: 0.5rem 1rem;
  max-width: 35rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hoverBackgroundColor};
  }
`;

const StyledFormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
`;

export default function ContextSelector({
  document,
  focusedName,
  isDocumentFocused,
  setFocusedDocumentId,
}) {
  const { collectionId } = useCurrentCollectionId();
  const collection = useSelector(collectionById(collectionId));
  const [isFocusMenuOpen, setIsFocusMenuOpen] = useState(false);

  const items = useMemo(() => {
    return [
      {
        id: "collection",
        name: collection.name,
        isSelected: !isDocumentFocused,
        onClick: () => {
          setFocusedDocumentId(false);
        },
      },
      {
        id: "document",
        name: document?.name || "Untitled",
        isSelected: isDocumentFocused,
        onClick: () => {
          setFocusedDocumentId(document?.id);
        },
      },
    ];
  }, [collection, document, isDocumentFocused, setFocusedDocumentId]);

  return (
    <StyledFormRow>
      <Popover
        placement="top-start"
        isOpen={isFocusMenuOpen}
        setIsOpen={setIsFocusMenuOpen}
        closePopover={() => setIsFocusMenuOpen(false)}
        activator={
          <StyledFocusButton
            className="fs-mask"
            onClick={() => setIsFocusMenuOpen(true)}
          >
            {focusedName}
          </StyledFocusButton>
        }
      >
        <Menu items={items} withSelectedIcon isOpen={isFocusMenuOpen} />
      </Popover>
    </StyledFormRow>
  );
}
