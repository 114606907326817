import { useCallback, useContext } from "react";
import FrameContext from "../../contexts/FrameContext";
import PlaceholderButton from "../Page/PlaceholderButton";

export default function NewWorkspaceButton() {
  const { openNewWorkspaceModal } = useContext(FrameContext);
  const handleClick = useCallback(
    () => openNewWorkspaceModal("workspace-home"),
    []
  );

  return (
    <PlaceholderButton onClick={handleClick}>New workspace</PlaceholderButton>
  );
}
