import {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import styled from "styled-components";
import { listItemMixin } from "../../../css-mixins/menu";
import COLORS from "../../../theme/colors";
import Item from "./Item";

const StyledMentionList = styled.div`
  border-radius: 1.5rem;
  padding: 0.5rem;
  min-width: 28rem;
  display: flex;
  flex-direction: column;
  max-height: 22.5rem;
  background-color: ${({ theme }) => theme.body.secondaryBackgroundColor};
  overflow: hidden;
  box-shadow: 0 4px 8px ${COLORS.BLACK100T}, 0 8px 24px ${COLORS.BLACK100T},
    0 0 0 1px ${({ theme }) => theme.border.primary};
`;

const StyledListWrapper = styled.ul`
  padding: 0;
  margin: 0;
  overflow: auto;
`;

const StyledNoResults = styled.div`
  ${listItemMixin}
  width: 100%;
  text-align: left;
  :hover {
    background-color: unset;
  }
`;

const MentionList = forwardRef(({ command, query, items }, ref) => {
  const menuRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const filteredItems = useMemo(() => {
    return items.filter((item) =>
      item.name.toLowerCase().startsWith(query.toLowerCase())
    );
  }, [items]);

  const numberOfItems = items.length;

  const selectItem = (index) => {
    const item = filteredItems[index];

    if (item) {
      command({
        id: item.id,
        "data-mention-type": item.type,
        label: item.name,
      });
    }
  };

  useEffect(() => setSelectedIndex(0), [filteredItems]);

  const upHandler = () => {
    setSelectedIndex((selectedIndex + numberOfItems - 1) % numberOfItems);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % numberOfItems);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        event.preventDefault();
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <StyledMentionList ref={menuRef}>
      <StyledListWrapper>
        {filteredItems.length ? (
          filteredItems.map((item, index) => {
            return (
              <Item
                menuRef={menuRef}
                className={`${index === selectedIndex ? "is-selected" : ""}`}
                key={index}
                onClick={() => selectItem(index)}
                isHighlighted={index === selectedIndex}
              >
                {item.name}
              </Item>
            );
          })
        ) : (
          <StyledNoResults>No result</StyledNoResults>
        )}
      </StyledListWrapper>
    </StyledMentionList>
  );
});

MentionList.displayName = "MentionList";
export default MentionList;
