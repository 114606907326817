import { useFlags } from "launchdarkly-react-client-sdk";
import styled from "styled-components";
import { Routes, Route, useParams } from "react-router-dom";
import { lazy, useContext, Suspense } from "react";
import { useSelector } from "react-redux";
import { device } from "../css-mixins/media-queries";
import { gridMixin } from "../css-mixins/main-grid";
import FrameContext from "../contexts/FrameContext";
import SidebarLeft from "./SidebarLeft";
import IconButton from "./IconButton";
import AccountButton from "./AccountButton";
import BillingModal from "./BillingModal";
import { SidebarLeft16 } from "./Icon";
import BrandVoices from "./BrandVoices";
import BrandVoice from "./BrandVoice";
import Home from "./Home";
import NewBrandVoiceModal from "./NewBrandVoiceModal";
import NewWorkspaceModal from "./NewWorkspace/NewWorkspaceModal";
import UploaderModal from "./UploaderModal";
import Tooltip from "./Tooltip";
import DndContext from "./DndContext";
import { getIsTyping } from "../features/uiSlice";
import CollectionRoute from "./CollectionRoute";
import MacOSButtons from "./MacOSButtons";

const Announcement = lazy(() => import("./Announcement"));
const StyledIconButton = styled(IconButton)`
  margin-right: 1rem;
  position: relative;
  z-index: 2;
  opacity: ${({ isSidebarLeftOpen, isEditorTyping }) =>
    !isSidebarLeftOpen && isEditorTyping ? 0 : 1};
  transition: opacity 0.5s ease-in-out;
  -webkit-app-region: no-drag;
`;

const StyledFrame = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.body.backgroundColor};

  @media (${device.desktop}) {
    ${gridMixin}
  }

  @media (${device.mobile}) {
    display: flex;
  }
`;

const StyledHeader = styled.div`
  position: absolute;
  padding: 2.5rem 0 2.5rem 2.5rem;
  width: 28rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-app-region: drag;

  @media (${device.mobile}) {
    z-index: 4;
  }

  html.todesktop-platform-darwin & {
    padding-left: 28px;
  }
`;

const StyledOverlaylayer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

export default function Frame() {
  const { isCheckoutEnabled, isUploaderEnabled } = useFlags();
  const params = useParams();
  const isEditorTyping = useSelector(getIsTyping);

  const {
    endDrag,
    frameRef,
    isBillingModalOpen,
    isBrandVoiceModalOpen,
    isDragging,
    isNewWorkspaceModalOpen,
    isUploaderModalOpen,
    isSidebarLeftOpen,
    onDrag,
    overlayLayer,
    toggleSidebarLeft,
    rightColWidth,
    isSidebarRightOpen,
  } = useContext(FrameContext);

  return (
    <>
      <Suspense fallback={null}>
        <Announcement />
      </Suspense>
      <StyledOverlaylayer id="overlay-layer" ref={overlayLayer} />
      <StyledFrame
        $isSidebarRightOpen={isSidebarRightOpen && !(params["*"] === "new")} // chat doesn't exist on home page
        $isDragging={isDragging}
        $isSidebarLeftOpen={isSidebarLeftOpen}
        $rightColWidth={rightColWidth}
        onMouseMove={onDrag}
        onMouseUp={endDrag}
        ref={frameRef}
      >
        <DndContext>
          <StyledHeader>
            <MacOSButtons />
            <Tooltip
              content={isSidebarLeftOpen ? "Open Sidebar" : "Close Sidebar"}
              shortcuts={["CTRL", "`"]}
            >
              <StyledIconButton
                isEditorTyping={isEditorTyping}
                isSidebarLeftOpen={isSidebarLeftOpen}
                onClick={toggleSidebarLeft}
                icon={<SidebarLeft16 />}
              />
            </Tooltip>
            {isSidebarLeftOpen && (
              <>
                <AccountButton />
              </>
            )}
          </StyledHeader>
          <SidebarLeft onClose={toggleSidebarLeft} isOpen={isSidebarLeftOpen} />
          {isNewWorkspaceModalOpen && <NewWorkspaceModal />}
          {isBrandVoiceModalOpen && <NewBrandVoiceModal />}
          {isCheckoutEnabled && isBillingModalOpen && <BillingModal />}
          {isUploaderEnabled && isUploaderModalOpen && <UploaderModal />}
          <Routes>
            <Route path="/new" element={<Home />} />
            <Route
              path="/inbox/*"
              element={
                <CollectionRoute isSidebarLeftOpen={isSidebarLeftOpen} />
              }
            />
            <Route path="/brand" element={<BrandVoices />} />
            <Route path="/brand/:brandVoiceId" element={<BrandVoice />} />
            <Route
              path="/c/:collectionId/*"
              element={
                <CollectionRoute isSidebarLeftOpen={isSidebarLeftOpen} />
              }
            />
          </Routes>
        </DndContext>
      </StyledFrame>
    </>
  );
}
