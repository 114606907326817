import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { remove } from "../features/collectionsSlice";
import { DELETE_COLLECTION, GET_COLLECTIONS } from "../graphql";

export default function useDeleteCollection({ collectionId }) {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const closeDeleteModal = useCallback(() => setIsDeleteModalOpen(false), []);
  const openDeleteModal = useCallback(() => setIsDeleteModalOpen(true), []);
  const dispatch = useDispatch();
  const [deleteCollectionMutation] = useMutation(DELETE_COLLECTION);
  const deleteCollection = useCallback(async () => {
    closeDeleteModal();
    deleteCollectionMutation({
      variables: { id: collectionId },
      refetchQueries: [GET_COLLECTIONS],
      onCompleted: (data) => {
        dispatch(remove({ id: data.deleteCollection.id }));
      },
    });

    navigate("/new");
  }, [
    deleteCollectionMutation,
    collectionId,
    navigate,
    dispatch,
    closeDeleteModal,
  ]);

  return {
    deleteCollection,
    isDeleteModalOpen,
    closeDeleteModal,
    openDeleteModal,
  };
}
