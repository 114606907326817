import { useCallback, useContext } from "react";
import FrameContext from "../contexts/FrameContext";

import { ModalForm, Button, CTAButton, ButtonGroup } from "./ModalForm";

export default function UploaderModal() {
  const { closeUploaderModal, triggerUploader, uploaderOptions } =
    useContext(FrameContext);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      triggerUploader(uploaderOptions);
      closeUploaderModal();
    },
    [uploaderOptions, triggerUploader, closeUploaderModal]
  );

  return (
    <ModalForm
      onClose={closeUploaderModal}
      header="Import from Google"
      description={
        <span>
          Strut only imports content from Google Docs you select and cannot view
          or access any other documents in your Google Drive. We strictly adhere
          to{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            target="_blank"
            rel="noreferrer"
          >
            Google’s API Services User Data Policy
          </a>
          .
        </span>
      }
      onSubmit={handleSubmit}
    >
      <ButtonGroup>
        <Button onClick={closeUploaderModal}>Cancel</Button>
        <CTAButton onClick={handleSubmit}>Continue</CTAButton>
      </ButtonGroup>
    </ModalForm>
  );
}
