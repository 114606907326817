import { forwardRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useDndContext } from "@dnd-kit/core";
import { buttonReset } from "../../../css-mixins/button";
import {
  getSelectedDocuments,
  selectIsCommandPaletteOpen,
  setSelected,
  unselect,
} from "../../../features/multiSelectSlice";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../../lib/cmd-palette-helpers";
import Tag from "../../Tag";
import { BodyMedium } from "../../Typography";
import Checkbox, { documentHoverStyles } from "../Checkbox";
import DocumentButtons from "./DocumentButtons";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";
import { device } from "../../../css-mixins/media-queries";

const StyledButton = styled.button`
  ${buttonReset}
  width: 100%;
  text-align: left;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  outline: none;
  color: ${({ theme }) => theme.body.foregroundColor};
`;

const focusStyles = css`
  background-color: ${({ theme }) =>
    theme.button.secondary.hoverBackgroundColor};
  border-color: transparent;
`;

const StyledDocument = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 5rem;

  @media (${device.mobile}) {
    margin-left: 3rem;
  }
`;

const hoverStyles = css`
  &:hover {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
    box-shadow: 0 1px 0 0 ${({ theme }) => theme.border.secondary},
      0 1px 0 0 ${({ theme }) => theme.border.secondary};

    ${StyledCheckbox} {
      ${documentHoverStyles}
    }
  }
`;

const StyledWrapper = styled.li`
  position: relative;
  margin: 0;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.border.secondary};
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ $isButtonFocused, $isDragging }) =>
    !$isDragging && $isButtonFocused && focusStyles}
  ${({ $isDragging }) => !$isDragging && hoverStyles}
`;

const Document = forwardRef(({ document, ...rest }, ref) => {
  const { active } = useDndContext();
  const dispatch = useDispatch();
  const { isInbox } = useCurrentCollectionId();
  const { name, id, tags = [] } = document;
  const navigate = useNavigate();

  const selectedDocumentIds = useSelector(getSelectedDocuments);
  const isCommandPaletteOpen = useSelector(selectIsCommandPaletteOpen);
  const isChecked = selectedDocumentIds.includes(id);

  const [isButtonFocused, setIsButtonFocused] = useState(false);

  const selectOrOpenModal = useCallback(() => {
    if (isCommandPaletteOpen) {
      dispatch(isChecked ? unselect(id) : setSelected(id));
    } else {
      navigate(id);
    }
  }, [
    dispatch,
    id,
    isChecked,
    isCommandPaletteOpen,
    navigate,
    setSelected,
    unselect,
  ]);

  const toggleButtonFocus = useCallback((focus) => {
    setIsButtonFocused(focus);
  }, []);

  return (
    <StyledWrapper
      $isChecked={isChecked}
      $isButtonFocused={isButtonFocused}
      className={CMDK_SKIP_TARGET_CLASSNAME} // used for outside click with cmd palette
      $isDragging={Boolean(active)}
      ref={ref}
      {...rest}
    >
      <StyledCheckbox documentId={id} isChecked={isChecked} viewType="list" />
      <StyledDocument onClick={selectOrOpenModal}>
        <StyledButton
          onFocus={() => toggleButtonFocus(true)}
          onBlur={() => toggleButtonFocus(false)}
        >
          <BodyMedium className="fs-mask">{name || "Untitled"}</BodyMedium>
          {tags?.map(({ id, name }) => (
            <Tag key={id} name={name} />
          ))}
        </StyledButton>
        <DocumentButtons document={document} isInbox={isInbox} />
      </StyledDocument>
    </StyledWrapper>
  );
});

Document.displayName = "Document";

export default Document;
