import styled, { css } from "styled-components";
import { COLORS } from "../../../theme";
import MenuItem from "./MenuItem";

const StyledWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateX(100%) translateY(50%);
`;

const openStyles = css`
  padding: 0.5rem;
  pointer-events: "auto";
  opacity: 1;
  box-shadow: ${({ theme }) => theme.menu.borderColor},
    0 8px 24px 0 ${COLORS.BLACK100T};
`;

const StyledSubmenu = styled.ul`
  margin: 0;
  background-color: ${({ theme }) => theme.menu.backgroundColor};
  border-radius: 1.5rem;
  padding: 0;
  margin-left: 1rem;
  opacity: 0;
  pointer-events: "none";
  min-width: 28rem;

  ${({ isOpen }) => isOpen && openStyles}
`;

export default function Submenu({ items, isOpen, editor }) {
  return (
    <StyledWrapper>
      <StyledSubmenu isOpen={isOpen}>
        {isOpen &&
          items.map((item) => (
            <MenuItem key={item.id} item={item} editor={editor} />
          ))}
      </StyledSubmenu>
    </StyledWrapper>
  );
}
