import { Command } from "cmdk";
import styled from "styled-components";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../../../lib/cmd-palette-helpers";

const StyledList = styled(Command.List)`
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-height: 31rem;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export default function CommandList({ children }) {
  return (
    <StyledList className={CMDK_SKIP_TARGET_CLASSNAME}>{children}</StyledList>
  );
}
