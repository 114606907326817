import { useSelector } from "react-redux";
import { forwardRef } from "react";
import { mentionsByCollectionId } from "../../../features/documentsSlice";
import MentionList from "./MentionList";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const MentionListWrapper = forwardRef(({ command, query }, ref) => {
  const { collectionId } = useCurrentCollectionId();
  const items = useSelector(mentionsByCollectionId(collectionId));

  return (
    <MentionList ref={ref} items={items} command={command} query={query} />
  );
});

MentionListWrapper.displayName = "MentionListWrapper";
export default MentionListWrapper;
