import styled from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import DocumentContext from "../../contexts/DocumentContext";
import Button from "../Button";

const DebugOutput = styled.textarea`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: scroll;
  background-color: #ffffff;
  width: 40%;
`;

const StyledAdminButton = styled(Button)`
  width: auto;
`;

const StyledAdminButtons = styled.div`
  position: absolute;
  top: 2.5rem;
  margin-left: 1.5rem;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

export default function AdminTools() {
  const query = useQuery();
  const jsonDebug = query.get("json");
  const { editor } = useContext(DocumentContext);
  const [editorContent, setEditorContent] = useState(null);

  useEffect(() => {
    if (jsonDebug) {
      editor?.on("update", ({ editor }) => {
        setEditorContent(editor.getJSON());
      });
    }
  }, [editor, jsonDebug]);

  const addImage = useCallback(() => {
    const url = window.prompt("URL");

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  const addYoutubeVideo = () => {
    const url = prompt("Enter YouTube URL");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: 480,
        height: 270,
      });
    }
  };

  return (
    jsonDebug &&
    editorContent && (
      <>
        <StyledAdminButtons>
          <StyledAdminButton onClick={addYoutubeVideo}>
            Set youtube video
          </StyledAdminButton>
          <StyledAdminButton onClick={addImage}>Set image</StyledAdminButton>
        </StyledAdminButtons>
        <DebugOutput value={JSON.stringify(editorContent, null, 2)} readOnly />
      </>
    )
  );
}
