import styled, { css } from "styled-components";
import { buttonReset } from "../../../css-mixins/button";
import COLORS from "../../../theme/colors";

const selectedStyles = css`
  background-color: ${COLORS.SUNRISE500};
  color: ${COLORS.BLACK800T};
  border-radius: 1rem;

  &:hover,
  &:focus {
    background-color: ${COLORS.SUNRISE500};
    border-color: ${COLORS.SUNRISE500};
    fill: ${COLORS.BLACK800T};
    & svg * {
      fill: ${COLORS.BLACK800T};
    }
  }

  &:active,
  &:focus {
    background-color: ${COLORS.SUNRISE500};
    border-color: ${COLORS.SUNRISE500};
    & svg * {
      fill: ${COLORS.BLACK800T};
    }
  }
`;

const ToolbarButton = styled.button`
  ${buttonReset}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  height: 3.5rem;
  padding: 0.5rem 0.75rem;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  white-space: nowrap;
  & svg * {
    fill: ${({ isSelected, theme }) =>
      isSelected ? COLORS.BLACK800T : theme.button.secondary.foregroundColor};
  }

  ${({ isSelected }) => isSelected && selectedStyles}

  &:hover, &:focus {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
    border-radius: 1rem;
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};

    & svg * {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }

    ${({ isSelected }) => isSelected && selectedStyles}
  }
`;

export default ToolbarButton;
