import { Auth0Provider } from "@auth0/auth0-react";

function StruttAuth0Provider({ children }) {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;

  const redirect_uri = window.todesktop
    ? window.location.origin + "/desktop"
    : window.location.origin + "/inbox";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri,
        audience,
      }}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}

export default StruttAuth0Provider;
