import styled from "styled-components";
import { listItemMixin } from "../../css-mixins/menu";
import { BodyMedium } from "../Typography";

const StyledEmptyState = styled.div`
  ${listItemMixin}
  margin: 0.5rem;
  :hover {
    background-color: unset;
  }
`;

export default function EmptyState({ children }) {
  return (
    <StyledEmptyState>
      <BodyMedium>{children}</BodyMedium>
    </StyledEmptyState>
  );
}
