import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import FrameContext from "../../contexts/FrameContext";
import {
  reset,
  selectIsCommandPaletteOpen,
  setSelected,
  toggleIsOpenState,
  toggleShouldUseDocuments,
} from "../../features/multiSelectSlice";
import {
  CMDK_SKIP_TARGET_CLASSNAME,
  COMMAND_LIST_PAGE,
} from "../../lib/cmd-palette-helpers";
import useCurrentCollectionId from "../useCurrentCollectionId";

export default function useCommandPalette() {
  const { frameRef, isDragging } = useContext(FrameContext);
  const [selectedCommand, setSelectedCommand] = useState(null);

  const [page, setPage] = useState(COMMAND_LIST_PAGE);
  const isCommandPaletteOpen = useSelector(selectIsCommandPaletteOpen);
  const dispatch = useDispatch();

  const { collectionId } = useCurrentCollectionId();

  const match = useMatch("/c/:collectionId/:documentId");
  const inboxMatch = useMatch("/inbox/:documentId");

  const documentId = match?.params.documentId || inboxMatch?.params.documentId;

  const setCommandListPage = useCallback(() => {
    setPage(COMMAND_LIST_PAGE);

    !documentId && dispatch(toggleShouldUseDocuments(false));
  }, [dispatch, setPage, documentId]);

  const openCommandPalette = useCallback(() => {
    if (documentId) {
      dispatch(setSelected(documentId));
    }

    dispatch(toggleIsOpenState(true));
  }, [dispatch, documentId]);

  const closeAndResetMenu = useCallback(() => {
    dispatch(reset());
    dispatch(toggleIsOpenState(false));
    setPage(COMMAND_LIST_PAGE);
    setSelectedCommand(null);
  }, [dispatch]);

  useEffect(() => {
    // reset multi select when collection changes
    closeAndResetMenu();
  }, [collectionId, closeAndResetMenu]);

  const shortcuts = useCallback(
    (e) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        return closeAndResetMenu();
      }
      // cmd k
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        return openCommandPalette();
      }
    },
    [closeAndResetMenu, openCommandPalette]
  );

  useEffect(() => {
    window.addEventListener("keydown", shortcuts);
    return () => {
      window.removeEventListener("keydown", shortcuts);
    };
  }, [shortcuts]);

  useEffect(() => {
    const frameElement = frameRef.current;
    // handle closing multi select menu when clicking outside or not on a document
    function handleOutsideClick(e) {
      if (isCommandPaletteOpen && !isDragging) {
        const shouldSkipElement = e.target.closest(
          `.${CMDK_SKIP_TARGET_CLASSNAME}`
        );

        if (!shouldSkipElement) {
          return closeAndResetMenu();
        }
      }
      return;
    }

    frameElement.addEventListener("mouseup", handleOutsideClick);
    return () => {
      frameElement.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [closeAndResetMenu, isCommandPaletteOpen, isDragging]);

  return {
    closeAndResetMenu,
    openCommandPalette,
    page,
    selectedCommand,
    setCommandListPage,
    setPage,
    setSelectedCommand,
  };
}
