import styled from "styled-components";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { userMetadata } from "../../features/userMetadataSlice";
import { BodyMedium } from "../Typography";
import { Upgrade12 } from "../Icon";
import Button from "../Button";
import FrameContext from "../../contexts/FrameContext";

const DEFAULT_MESSAGE_LIMIT = 25;

const StyledContent = styled.div`
  padding: 4rem 8rem 4rem 8rem;
  border-top: 1px solid ${({ theme }) => theme.border.primary};
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.body.subtleBackgroundColor} 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const StyledHeading = styled(BodyMedium)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  margin: 0 0 0.5rem -4rem;
`;

const StyledUsage = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.foregroundColor};
`;

const StyledPlan = styled.div`
  user-select: none;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  white-space: nowrap;
  margin-top: 1.5rem;
`;

const StyledFill = styled.div`
  position: absolute;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.plan.foregroundColor};
  width: ${({ $width }) => $width * 100}%;
  max-width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
`;

const StyledMeter = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.plan.backgroundColor};
  height: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
`;

const StyledUpgradeButton = styled(Button)`
  margin-top: 1.5rem;
  width: fit-content;
`;

const StyledIcon = styled(Upgrade12)`
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.body.foregroundColor};
  }
`;

export default function UpgradeChat() {
  const metadata = useSelector(userMetadata);
  const usage = metadata?.usage.aiMessageCount;
  const limitAllotment = metadata.allotments?.find(
    (allotment) => allotment.allotmentType.code === "ai_messages"
  );
  const limit = limitAllotment?.allotmentType.amount || DEFAULT_MESSAGE_LIMIT;

  const { openBillingModal } = useContext(FrameContext);
  const handleUpgradeButton = function () {
    openBillingModal();
  };
  // Default end of month for usage reset
  const today = new Date();
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const options = { month: "short", day: "numeric" };
  const lastDayofMonth = new Intl.DateTimeFormat(
    navigator.language,
    options
  ).format(lastDay);

  return (
    <StyledContent>
      <StyledHeader>
        <StyledIcon />
        <StyledHeading>Upgrade to continue using AI</StyledHeading>
      </StyledHeader>
      <BodyMedium>
        You’ve reached the maximum number of monthly AI Messages on your current
        plan. Your usage resets on {lastDayofMonth}.
      </BodyMedium>
      <StyledPlan>
        <StyledUsage>
          {usage.toLocaleString()}/{limit.toLocaleString()} Tokens
        </StyledUsage>
        <StyledMeter>
          <StyledFill $width={usage / limit} />
        </StyledMeter>
        <StyledUpgradeButton onClick={handleUpgradeButton}>
          Upgrade
        </StyledUpgradeButton>
      </StyledPlan>
    </StyledContent>
  );
}
