import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectIsCommandPaletteOpen } from "../../features/multiSelectSlice";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../lib/cmd-palette-helpers";
import COLORS from "../../theme/colors";
import CommandPaletteLauncher from "./CommandPaletteLauncher";
import Page from "./Page";
import cmdPaletteContainerStyles from "./cmd-palette-container";

const StyledWrapper = styled.div`
  ${cmdPaletteContainerStyles}
  bottom: 0;
  justify-content: center;
  left: 0;
  padding: 0 2rem 5rem;
  right: 0;
  max-width: 656px;
  margin: 0 auto;
`;

const StyledCommandDialog = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.cmdk.backgroundColor};
  backdrop-filter: blur(1.5rem);
  border-radius: 2.5rem;
  box-shadow: 0 2rem 5rem 0 ${COLORS.BLACK100T};
  border: 1px solid ${({ theme }) => theme.cmdk.borderColor};
  overflow: hidden;
`;

export default function CommandPalette() {
  const isCommandPaletteOpen = useSelector(selectIsCommandPaletteOpen);

  if (!isCommandPaletteOpen) {
    return <CommandPaletteLauncher />;
  }

  return (
    <StyledWrapper>
      <StyledCommandDialog className={CMDK_SKIP_TARGET_CLASSNAME}>
        <Page />
      </StyledCommandDialog>
    </StyledWrapper>
  );
}
