import styled from "styled-components";
import { BodySmall } from "./Typography";

const StyledTag = styled.span`
  display: flex;
  background-color: ${({ theme }) => theme.tag.backgroundColor};
  padding: 0.25rem 1rem;
  border-radius: 2.5rem;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.tag.foregroundColor};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function Tag({ name }) {
  return (
    <StyledTag>
      <StyledBodySmall className="fs-mask">{name}</StyledBodySmall>
    </StyledTag>
  );
}
