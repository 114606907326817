import { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { ModalForm, Input, Button, CTAButton, ButtonGroup } from "../ModalForm";
import { GENERATE_BRAND_VOICE_FROM_WEBSITE } from "../../graphql";

function isUrlValid(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export default function GenerateFromWebsiteModal({ onClose, brandVoiceId }) {
  const [fieldValue, setFieldValue] = useState("");
  const [urlValue, setUrlValue] = useState(false);
  const [generateBrandVoiceFromWebsite] = useMutation(
    GENERATE_BRAND_VOICE_FROM_WEBSITE
  );

  const setInputValue = useCallback(
    (e) => {
      setFieldValue(e.target.value);
      if (e.target.value && isUrlValid(e.target.value)) {
        setUrlValue(e.target.value);
      } else if (e.target.value && isUrlValid("https://" + e.target.value)) {
        setUrlValue("https://" + e.target.value);
      } else {
        setUrlValue(false);
      }
    },
    [setFieldValue, setUrlValue]
  );

  const submitForm = useCallback(
    (e) => {
      e.preventDefault();
      generateBrandVoiceFromWebsite({
        variables: { brandVoiceId, website: urlValue },
      });
      onClose();
    },
    [urlValue, onClose]
  );

  return (
    <ModalForm
      onClose={onClose}
      header="Generate from Website"
      description="We’ll automatically scan and generate a Brand Voice based on your website link."
      onSubmit={submitForm}
    >
      <Input
        name="url"
        id="generate-url"
        label="Link"
        value={fieldValue}
        onChange={setInputValue}
        placeholder="https://www.example.com"
      />
      <ButtonGroup>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
        <CTAButton type="submit" disabled={!urlValue}>
          Create
        </CTAButton>
      </ButtonGroup>
    </ModalForm>
  );
}
