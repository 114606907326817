import { useMutation } from "@apollo/client";
import throttle from "lodash/throttle";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultStageByCollectionId } from "../features/collectionsSlice";
import { addDocument } from "../features/documentsSlice";
import { CREATE_DOCUMENT, GET_COLLECTION } from "../graphql";
import useCurrentCollectionId from "./useCurrentCollectionId";

export default function useCreateDocument() {
  const [createDocumentMutation] = useMutation(CREATE_DOCUMENT);
  const { collectionId } = useCurrentCollectionId();
  const dispatch = useDispatch();
  const defaultStage = useSelector(defaultStageByCollectionId(collectionId));

  const createNewDocument = useCallback(
    (callback, options) => async () => {
      const result = await createDocumentMutation({
        variables: {
          collectionId,
          input: {
            name: "",
            body: "",
            stageId: defaultStage.id,
            ...(options || {}),
          },
        },
        refetchQueries: [GET_COLLECTION],
        onCompleted: (data) => {
          dispatch(
            addDocument({
              collectionId: data.createDocument.collectionId,
              document: data.createDocument,
            })
          );
        },
      });

      if (result.data) {
        callback(result.data.createDocument.id);
      }
    },
    [collectionId, defaultStage]
  );

  const throttledCreateNewDocument = useCallback(
    throttle(createNewDocument, 1000, { trailing: false }),
    [createNewDocument]
  );

  return { createNewDocument: throttledCreateNewDocument };
}
