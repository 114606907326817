import styled, { css } from "styled-components";

const openInputStyles = css`
  display: flex;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.border.secondary};
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  display: none;
  width: 100%;
  color: ${({ theme }) => theme.input.foregroundColor};
  font-weight: ${(props) => props.theme.typography.bodyMedium.fontWeight};
  font-size: ${(props) => props.theme.typography.bodyMedium.fontSize};
  line-height: ${(props) => props.theme.typography.bodyMedium.lineHeight};

  ::placeholder {
    ${({ theme }) => theme.input.placeholderColor};
  }
  ${({ isOpen }) => isOpen && openInputStyles};
`;

export default function DropdownInput({
  getInputProps,
  isOpen,
  handleInputChange,
  placeholder,
}) {
  return (
    <StyledInput
      {...getInputProps({
        onChange: handleInputChange,
      })}
      isOpen={isOpen}
      placeholder={placeholder}
    />
  );
}
