import { useSelector } from "react-redux";
import { brandVoicesList } from "../../features/brandVoicesSlice";
import Page from "../Page";
import NewBrandVoiceButton from "./NewBrandVoiceButton";
import BrandVoiceItem from "./BrandVoiceItem";

export default function BrandVoices() {
  const brandVoices = useSelector(brandVoicesList);

  return (
    <Page header="Brand Voices">
      <NewBrandVoiceButton />
      {brandVoices.map((brandVoice) => (
        <BrandVoiceItem key={brandVoice.id} brandVoice={brandVoice} />
      ))}
    </Page>
  );
}
