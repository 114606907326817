import { useCallback } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { buttonReset } from "../css-mixins/button";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../lib/cmd-palette-helpers";
import { COLORS, LIGHT_MODE, DARK_MODE, SYSTEM_MODE } from "../theme";
import { Dark12, Light12, Theme12 } from "./Icon";
import { BodyMedium } from "./Typography";
import { themeSelector, setTheme } from "../features/uiSlice";

const themePositions = {
  [LIGHT_MODE]: 0.25,
  [DARK_MODE]: 9.5,
  [SYSTEM_MODE]: 18.5,
};

const SelectedIndicator = styled.div`
  left: ${({ $left }) => $left}rem;
  width: 33.3%;
  height: 8rem;
  border-radius: 6px;
  position: absolute;
  transition: left 100ms ease-out;
  background-color: ${({ theme }) => theme.toggle.selectedColor};
  box-shadow: 0 1px 1px ${COLORS.BLACK100T};
`;

const StyledIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  color: ${({ theme, isSelected }) =>
    isSelected
      ? theme.button.secondary.hoverForegroundColor
      : theme.button.secondary.foregroundColor};
  svg * {
    fill: ${({ theme, isSelected }) =>
      isSelected
        ? theme.button.secondary.hoverForegroundColor
        : theme.button.secondary.foregroundColor};
  }
`;

const hoverStyles = css`
  :hover {
    ${StyledIcon} {
      color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};

      svg * {
        fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
      }
    }
  }
  :active {
    transform: scale(0.95);
  }
`;

const StyledButton = styled.button`
  ${buttonReset}
  padding: 0.75rem 1.5rem;
  border-radius: 1.75rem;
  width: 33%;
  height: 8rem;
  position: relative;
  z-index: 1;
  transition: transform 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isSelected }) => !isSelected && hoverStyles}
`;

const StyledToggle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: ${({ theme }) => theme.toggle.backgroundColor};
  padding: 0.25rem;
  margin-bottom: 0.5rem;
  width: 28rem;
`;

export default function ThemeToggle() {
  const theme = useSelector(themeSelector);
  const dispatch = useDispatch();
  const handleSetTheme = useCallback(
    (theme) => () => dispatch(setTheme(theme)),
    []
  );

  return (
    <StyledToggle className={CMDK_SKIP_TARGET_CLASSNAME}>
      <StyledButton
        aria-label="light theme"
        onClick={handleSetTheme(LIGHT_MODE)}
        isSelected={theme === LIGHT_MODE}
      >
        <StyledIcon isSelected={theme === LIGHT_MODE}>
          <Light12 />
          <BodyMedium>Light</BodyMedium>
        </StyledIcon>
      </StyledButton>

      <StyledButton
        aria-label="dark theme"
        onClick={handleSetTheme(DARK_MODE)}
        isSelected={theme === DARK_MODE}
      >
        <StyledIcon isSelected={theme === DARK_MODE}>
          <Dark12 />
          <BodyMedium>Dark</BodyMedium>
        </StyledIcon>
      </StyledButton>

      <StyledButton
        aria-label="system theme"
        onClick={handleSetTheme(SYSTEM_MODE)}
        isSelected={theme === SYSTEM_MODE}
      >
        <StyledIcon isSelected={theme === SYSTEM_MODE}>
          <Theme12 />
          <BodyMedium>Auto</BodyMedium>
        </StyledIcon>
      </StyledButton>
      <SelectedIndicator $left={themePositions[theme]} />
    </StyledToggle>
  );
}
