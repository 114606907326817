export const DELAY_IN_MS = 300;
export const DELAY_RESET_TIMER_MS = 400;

let timerId = null;
let delay = DELAY_IN_MS;

export default function useGlobalDelayTimer() {
  function removeDelay() {
    // Clear previous resetDelay timer
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      delay = 0;
    }, DELAY_IN_MS);
  }

  function resetDelay() {
    // Clear previous removeDelay timer
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      delay = DELAY_IN_MS;
    }, DELAY_RESET_TIMER_MS);
  }

  return { delay, removeDelay, resetDelay };
}
