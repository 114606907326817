import { gql } from "@apollo/client";

export const GET_COLLECTION = gql`
  query Collection($collectionId: ID!) {
    collection(id: $collectionId) {
      id
      name
      introduction
      systemRole
      userId
      inbox
      showOnSidebar
      order
      documents {
        id
        name
        snapshot
        stage
        stageId
        order
        tags {
          id
          name
        }
        createdAtDate
        updatedAtDate
      }
      collaborators {
        id
        name
        email
        picture
      }
      Grant {
        brandVoiceId
      }
      stages {
        id
        name
        icon
        color
        order
      }
    }
  }
`;

export const GET_INBOX_COLLECTION = gql`
  query InboxCollection {
    inboxCollection {
      id
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query Collections {
    collections(fullFetch: false) {
      id
      name
      introduction
      systemRole
      userId
      inbox
      showOnSidebar
      order
      Grant {
        brandVoiceId
      }
    }
  }
`;

export const GET_FULL_COLLECTIONS = gql`
  query Collections($fullFetch: Boolean) {
    collections(fullFetch: $fullFetch) {
      id
      name
      introduction
      systemRole
      userId
      inbox
      showOnSidebar
      order
      documents {
        id
        name
        snapshot
        stage
        stageId
        order
        tags {
          id
          name
        }
        createdAtDate
        updatedAtDate
      }
      collaborators {
        id
        name
        email
        picture
      }
      Grant {
        brandVoiceId
      }
      stages {
        id
        name
        icon
        color
        order
      }
    }
  }
`;

export const GET_LATEST_CHAT = gql`
  query Chat($collectionId: ID!) {
    chat(collectionId: $collectionId) {
      role
      content
      chatId
    }
  }
`;

export const SEARCH = gql`
  query Search($term: String!) {
    search(term: $term) {
      id
      name
      body
      collectionIds
      collections
      tags
      matchingPositions
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query Subscriptions {
    subscriptions {
      id
      userId
      status
      externalSubscription
    }
  }
`;

export const GET_USERMETADATA = gql`
  query UserMetadata {
    userMetadata {
      subscriptions {
        id
        userId
        name
        status
        externalSubscription
      }
      usage {
        aiMessageCount
        tokens {
          tokenCount
        }
      }
      tokens {
        tokenCount
      }
      allotments {
        id
        entityId
        entityType
        name
        code
        amount
        allotmentType {
          code
          name
          amount
        }
      }
      message
      latestSha
    }
  }
`;

export const GET_CUSTOMER_PORTAL = gql`
  query customerPortal($externalSubscription: String!) {
    customerPortal(externalSubscription: $externalSubscription)
  }
`;

export const GET_BRAND_VOICES = gql`
  query brandVoices {
    brandVoices {
      id
      title
      state
    }
  }
`;
