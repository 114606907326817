import React from "react";
import { useSelector } from "react-redux";
import {
  getWordCount,
  getReadingTime,
  getCharacterCount,
  getSelectedWordCount,
  getSelectedCharacterCount,
} from "../features/uiSlice";
import { BodySmall } from "./Typography";
import styled from "styled-components";

const StyledWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border.secondary};
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem 1.5rem;
  margin-top: 0.5rem;
  gap: 1rem;
  user-select: none;
`;
const StyledBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
`;

const StyledDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.border.secondary};
  width: 100%;
  margin: 1rem 0;
`;

function calculateReadingTimeText(readingTime) {
  const hours = Math.floor(readingTime / 3600);
  const minutes = Math.floor((readingTime % 3600) / 60);
  const seconds = readingTime % 60;

  if (readingTime >= 3600) {
    return `${hours}hr ${minutes}min`;
  } else if (readingTime >= 60) {
    return `${minutes}min ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

function DocumentWordCount() {
  const wordCount = useSelector(getWordCount).toLocaleString();
  const characterCount = useSelector(getCharacterCount).toLocaleString();
  const readingTime = useSelector(getReadingTime);
  const readingTimeText = calculateReadingTimeText(readingTime);
  const selectedWordCount = useSelector(getSelectedWordCount);
  const selectedCharacterCount = useSelector(getSelectedCharacterCount);

  return (
    <StyledWrapper>
      <StyledBlock>
        <StyledBodySmall>Word count:</StyledBodySmall>
        <StyledBodySmall>{wordCount}</StyledBodySmall>
      </StyledBlock>
      <StyledBlock>
        <StyledBodySmall>Character count:</StyledBodySmall>
        <StyledBodySmall>{characterCount}</StyledBodySmall>
      </StyledBlock>
      <StyledBlock>
        <StyledBodySmall>Reading time:</StyledBodySmall>
        <StyledBodySmall>{readingTimeText}</StyledBodySmall>
      </StyledBlock>
      {selectedCharacterCount > 0 && (
        <>
          <StyledDivider />
          <StyledBlock>
            <StyledBodySmall>Selected word count:</StyledBodySmall>
            <StyledBodySmall>
              {selectedWordCount.toLocaleString()}
            </StyledBodySmall>
          </StyledBlock>
          <StyledBlock>
            <StyledBodySmall>Selected character count:</StyledBodySmall>
            <StyledBodySmall>
              {selectedCharacterCount.toLocaleString()}
            </StyledBodySmall>
          </StyledBlock>
        </>
      )}
    </StyledWrapper>
  );
}

export default DocumentWordCount;
