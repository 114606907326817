import { useEditor, EditorContent } from "@tiptap/react";
import StyledEditor from "./StyledEditor";
import defaultExtensions from "./defaultExtensions";

export default function ReadOnlyEditor({ snapshot }) {
  const editor = useEditor({
    editable: false,
    content: snapshot,
    extensions: defaultExtensions(),
  });

  return (
    <StyledEditor>
      <EditorContent editor={editor} />
    </StyledEditor>
  );
}
