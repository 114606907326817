import { createSlice } from "@reduxjs/toolkit";
import { detectTheme } from "../theme";

const initialState = {
  isTyping: false,
  characterCount: 0,
  wordCount: 0,
  readingTime: 0,
  theme: detectTheme(),
  selectedWordCount: 0,
  selectedCharacterCount: 0,
  isSearchOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsTyping: (state, action) => {
      state.isTyping = action.payload;
    },
    setCounts: (state, action) => {
      state.wordCount = action.payload.wordCount;
      state.characterCount = action.payload.characterCount;
      state.readingTime = action.payload.readingTime;
    },
    setSelectedCounts: (state, action) => {
      state.selectedWordCount = action.payload.wordCount;
      state.selectedCharacterCount = action.payload.characterCount;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleSearch: (state) => {
      state.isSearchOpen = !state.isSearchOpen;
    },
  },
});

// actions
export const {
  setIsTyping,
  setTheme,
  setCounts,
  setSelectedCounts,
  toggleSearch,
} = uiSlice.actions;

// selectors
export function getIsTyping(state) {
  return state.ui.isTyping;
}

export function getWordCount(state) {
  return state.ui.wordCount;
}

export function getCharacterCount(state) {
  return state.ui.characterCount;
}

export function getSelectedWordCount(state) {
  return state.ui.selectedWordCount;
}

export function getSelectedCharacterCount(state) {
  return state.ui.selectedCharacterCount;
}

export function getReadingTime(state) {
  return state.ui.readingTime;
}

export function themeSelector(state) {
  return state.ui.theme;
}

export function getIsSearchOpen(state) {
  return state.ui.isSearchOpen;
}

export default uiSlice.reducer;
