import styled from "styled-components";
import { useState, useCallback } from "react";
import { buttonReset } from "../../css-mixins/button";
import { BodyMedium } from "../Typography";

const StyledBodyMedium = styled(BodyMedium)`
  color: ${({ theme }) => theme.button.primary.foregroundColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

const StyledCopyLink = styled.button`
  ${buttonReset}
  padding: 1.125rem;
  border: 1px solid ${({ theme }) => theme.border.primary};
  border-radius: 1.5rem;
  transition: transform 0.1s ease-out;

  :hover {
    border: 1px solid transparent;
    background: ${({ theme }) => theme.button.primary.hoverBackgroundColor};
    ${StyledBodyMedium} {
      color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    }
  }

  :active {
    transform: scale(0.98);
  }
`;

const COPY_LINK_TEXT = "Copy Link";

export default function CopyLinkButton() {
  const [buttonText, setButtonText] = useState(COPY_LINK_TEXT);
  const copyLink = useCallback(() => {
    window.navigator.clipboard.writeText(window.location.href);

    setButtonText("Copied!");
    setTimeout(() => setButtonText(COPY_LINK_TEXT), 1000);
  }, []);

  return (
    <StyledCopyLink onClick={copyLink}>
      <StyledBodyMedium>{buttonText}</StyledBodyMedium>
    </StyledCopyLink>
  );
}
