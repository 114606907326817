import styled from "styled-components";
import React, { useCallback, useContext, useEffect } from "react";
import FrameContext from "../../contexts/FrameContext";
import useGlobalDelayTimer from "../../hooks/useGlobalDelayTimer";
import { device } from "../../css-mixins/media-queries";
import Chat from "../Chat";

const StyledDragHandle = styled.div`
  width: 0.5rem;
  height: 100%;
  border-radius: 1rem;
  z-index: 1;
  ${({ isDragging, theme }) =>
    isDragging && `background-color: ${theme.border.primary}`}
`;

const StyledDragHandleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: -8px;
  width: 1rem;
  cursor: col-resize;
  transition: background-color 100ms ease-out;

  :hover ${StyledDragHandle} {
    transition-delay: ${({ delay }) => `${delay}ms`};
    background-color: ${({ theme }) => theme.border.primary};
  }
`;

const StyledColumnRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.border.primary};
  margin: 1rem 1rem 1rem 1px;
  border-radius: 6px;
  height: calc(100vh - 2rem);
  height: calc(100dvh - 2rem);
  z-index: 2;
  ${({ isDragging, width }) => !isDragging && `width: ${width - 9}px;`}

  ${({ isSidebarRightOpen }) =>
    !isSidebarRightOpen &&
    `
      ${StyledDragHandleWrapper} {
        display: none;
      }
    `}
  
  @media (${device.mobile}) {
    margin: 1rem;
    ${({ shouldShowOnMobile }) => !shouldShowOnMobile && "display: none;"}
  }
`;

export default function SidebarRight({
  shouldShowOnMobile,
  openDocuments,
  collectionId,
}) {
  const { delay, removeDelay, resetDelay } = useGlobalDelayTimer();
  const {
    isSidebarRightOpen,
    isDragging,
    rightColWidth,
    startDrag,
    toggleSidebarRight,
  } = useContext(FrameContext);

  const shortcutSidebar = useCallback(
    (e) => {
      if (e.ctrlKey && e.key === "\\") {
        toggleSidebarRight();
      }
    },
    [toggleSidebarRight]
  );

  useEffect(() => {
    window.addEventListener("keydown", shortcutSidebar);
    return () => {
      window.removeEventListener("keydown", shortcutSidebar);
    };
  });

  return (
    <>
      <StyledColumnRight
        isSidebarRightOpen={isSidebarRightOpen}
        shouldShowOnMobile={shouldShowOnMobile}
        width={rightColWidth}
        isDragging={isDragging}
      >
        {!shouldShowOnMobile && (
          <StyledDragHandleWrapper
            onMouseEnter={removeDelay}
            onMouseLeave={resetDelay}
            onMouseDown={startDrag}
            delay={delay}
          >
            <StyledDragHandle isDragging={isDragging} />
          </StyledDragHandleWrapper>
        )}
        <Chat
          openDocuments={openDocuments}
          collectionId={collectionId}
          key={collectionId}
        />
      </StyledColumnRight>
    </>
  );
}
