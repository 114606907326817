import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_USERMETADATA } from "../graphql/queries";
import { load } from "../features/userMetadataSlice";

const TWENTY_SECONDS = 20 * 1000;

export default function useGetUserMetadata() {
  const dispatch = useDispatch();
  const { data, loading, error } = useQuery(GET_USERMETADATA, {
    onCompleted: (data) => {
      dispatch(load(data.userMetadata));
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    pollInterval: TWENTY_SECONDS,
  });

  return { data, loading, error };
}
