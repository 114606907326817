import {
  DndContext,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
  closestCenter,
  rectIntersection,
  getFirstCollision,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useCallback, useContext, useState, useRef, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import DocumentDragOverlay from "../components/Documents/DocumentDragOverlay";
import useUpdateDocument from "../hooks/api/useUpdateDocument";
import SortableItemsContext from "./SortableItemsContext";
import { positionDocumentInStage } from "../features/collectionDocuments";
import useUpdateCollection from "../hooks/api/useUpdateCollection";

const ACTIVATION_CONSTRAINT_PX = 10;

export default function DndSortableDocumentContext({ children }) {
  const { items, setItems, setSidebarItems } = useContext(SortableItemsContext);
  const [activeId, setActiveId] = useState(null);
  const recentlyMovedToNewContainer = useRef(false);
  const lastOverId = useRef(null);
  const updateDocument = useUpdateDocument();
  const updateCollection = useUpdateCollection();
  const dispatch = useDispatch();
  const match = useMatch("/c/:collectionId/*");
  const collectionId = match?.params?.collectionId;

  const [overlayProps, setOverlayProps] = useState();

  const handleDragStart = useCallback(
    (event) => {
      if (event.active.data.current.type === "sidebar-workspace") {
        setSidebarItems(event.active.data.current.sortable.items);
      }
      setOverlayProps(event.active.data.current);
      setActiveId(event.active.id);
    },
    [setActiveId, setOverlayProps, setSidebarItems]
  );

  const onDragCancel = useCallback(() => {
    setOverlayProps(undefined);
    setActiveId(null);
  }, [setActiveId, setOverlayProps]);

  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const findColumn = useCallback(
    (id) => {
      if (id in items) {
        return id;
      }

      return Object.keys(items).find((key) => items[key].includes(id));
    },
    [items]
  );

  const onDragOver = useCallback(
    ({ active, over }) => {
      // logic for sorting from the sortable multiple containers example
      // https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx#L372
      const overId = over?.id;
      if (overId == null) {
        return;
      }
      const overContainer = findColumn(overId);
      const activeContainer = findColumn(active.id);

      if (!overContainer || !activeContainer) {
        return;
      }

      // moves document to new column in temp items state
      if (activeContainer !== overContainer) {
        setItems(() => {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const overIndex = overItems.indexOf(overId);
          const activeIndex = activeItems.indexOf(active.id);

          let newIndex;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top >
                over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          recentlyMovedToNewContainer.current = true;

          return {
            ...items,
            [activeContainer]: items[activeContainer].filter(
              (item) => item !== active.id
            ),
            [overContainer]: [
              ...items[overContainer].slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...items[overContainer].slice(
                newIndex,
                items[overContainer].length
              ),
            ],
          };
        });
      }
    },
    [setItems, findColumn]
  );

  const handleDragEnd = useCallback(
    async function handleDragEnd(event) {
      setOverlayProps(undefined);
      const { active, over } = event;
      const sortableItems = active.data?.current?.sortable?.items;

      if (sortableItems && active.id.includes("sortable-c")) {
        const newIndex = sortableItems.indexOf(
          `sortable-c-${over.data.current.collectionId}`
        );
        const oldIndex = active.data.current.sortable.index;

        if (newIndex !== -1) {
          setSidebarItems((items) => {
            const newOrder = arrayMove(items, oldIndex, newIndex);
            return newOrder;
          });
          updateCollection({
            id: active.data.current.collectionId,
            input: { sidebarPosition: newIndex },
          });
        } else {
          console.error("DnD newIndex does not exist");
        }
        return;
      } else {
        const overId = over?.id;
        const newStageId = findColumn(overId);

        const documentId = active.data.current.document.id;
        const newCollectionId = over.data.current.collectionId;
        setActiveId(null);

        if (newStageId) {
          const overIndex = items[newStageId].indexOf(overId);
          if (overIndex < 0) {
            console.error("DnD overIndex does not exist");
          }
          const position = Math.max(0, overIndex);
          dispatch(
            positionDocumentInStage({
              documentId,
              stageId: newStageId,
              position,
              collectionId,
            })
          );

          await updateDocument({
            id: documentId,
            input: {
              stageId: newStageId,
              position,
              collectionId,
            },
          });
          setItems(null);
        } else if (newCollectionId) {
          updateDocument({
            id: documentId,
            input: {
              collectionId: newCollectionId,
              previousCollectionId: collectionId,
            },
          });
          setItems(null);
        }
      }
    },
    [updateDocument, setItems, dispatch, items, collectionId, findColumn]
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: ACTIVATION_CONSTRAINT_PX,
      },
    })
  );
  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={onDragOver}
      onDragCancel={onDragCancel}
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
    >
      {children}
      <DocumentDragOverlay {...overlayProps} />
    </DndContext>
  );
}
