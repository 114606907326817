import { useMemo, useState, useEffect } from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  from,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { platform } from "@todesktop/client-core";
import { createClient } from "graphql-ws";
import { useDispatch } from "react-redux";
import { setToken } from "./features/authSlice";
import Sentry from "./integrations/sentry";

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URI,
});

export default function Provider({ children }) {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [token, setLocalToken] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setLocalToken(token);
        dispatch(setToken({ token }));
      } catch (e) {
        // Ignore "Login required" errors
        if (e.message !== "Login required") {
          Sentry.captureException(e);
          loginWithRedirect({
            openUrl: async (url) => {
              if (window.todesktop) {
                return platform.os.openURL(url);
              }
              window.location.href = url;
            },
          });
        }
      }
    };

    getToken();
  }, [getAccessTokenSilently, dispatch]);

  const client = useMemo(() => {
    if (!token) return {};
    const wsLink = new GraphQLWsLink(
      createClient({
        url: import.meta.env.VITE_GRAPHQL_SUBSCRIPTIONS_URI,
        retryAttempts: 1000,
        retryWait: async () => {
          await new Promise((resolve) => setTimeout(resolve, 5000));
        },
        connectionParams: {
          authToken: token,
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );

    const authLink = setContext(async (_, { headers }) => {
      const freshToken = await getAccessTokenSilently();
      if (freshToken !== token) {
        setLocalToken(freshToken);
        dispatch(setToken({ token: freshToken }));
      }

      return {
        headers: {
          ...headers,
          authorization: freshToken ? `Bearer ${freshToken}` : "",
        },
      };
    });

    return new ApolloClient({
      link: from([authLink, splitLink]),
      cache: new InMemoryCache(),
      name: window?.todesktop ? "desktop" : "web",
      version: import.meta.env.VITE_GITHUB_SHA,
    });
  }, [token, getAccessTokenSilently, dispatch]);

  if (!token) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
