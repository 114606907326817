import { css } from "styled-components";

export default css`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1.5rem;
  font-size: 2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.cmdk.foregroundColor};
  padding: 1rem 2rem;
  white-space: pre;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  & p:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &[aria-selected="true"] {
    color: ${({ theme }) => theme.cmdk.hoverForegroundColor};
    background-color: ${({ theme }) => theme.cmdk.hoverBackgroundColor};
  }
`;
