import { useCallback } from "react";
import styled from "styled-components";
import { buttonReset } from "../../css-mixins/button";

const StyledColorButton = styled.button`
  ${buttonReset}
  height: 3.5rem;
  width: 3.5rem;
  position: relative;
  outline: none;
`;

const StyledColorRing = styled.div`
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  border: 2px solid
    ${({ $color, $isSelected, theme }) =>
      $isSelected ? $color : theme.body.secondaryBackgroundColor};
  background-color: ${({ theme }) => theme.body.secondaryBackgroundColor};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    border: 2px solid ${({ $color }) => $color};
  }
`;

const StyledColor = styled.span`
  display: flex;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  background-color: ${({ $color }) => $color};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function ColorButton({
  setSelectedColor,
  color,
  displayColor,
  isSelected,
}) {
  const handleSelectColor = useCallback(
    () => setSelectedColor(color),
    [color, setSelectedColor]
  );
  return (
    <StyledColorButton onClick={handleSelectColor}>
      <StyledColorRing $color={displayColor} $isSelected={isSelected}>
        <StyledColor $color={displayColor} />
      </StyledColorRing>
    </StyledColorButton>
  );
}
