import styled from "styled-components";

const DropdownMenu = styled.ul`
  border-radius: 1.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: auto;
`;

export default DropdownMenu;
