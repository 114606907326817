import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptions: [],
  tokens: {
    tokenCount: 0,
  },
  usage: {
    aiMessageCount: 0,
    tokens: {
      tokenCount: 0,
    },
  },
  message: null,
};

export const userMetadataSlice = createSlice({
  name: "userMetadata",
  initialState,
  reducers: {
    load: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { load } = userMetadataSlice.actions;

//selectors
export function userMetadata(state) {
  return state.userMetadata;
}

export function announcement(state) {
  return state.userMetadata.message;
}

export function subscriptions(state) {
  return state.userMetadata.subscriptions;
}

export const hasActiveSubscription = createSelector(
  subscriptions,
  (subscriptions) =>
    Object.values(subscriptions).some(
      (subscription) => subscription.status === "active"
    )
);

export const getActiveSubscription = createSelector(
  subscriptions,
  (subscriptions) =>
    Object.values(subscriptions).find(
      (subscription) => subscription.status === "active"
    )
);

export const isMessagesOver = createSelector(userMetadata, (userMetadata) => {
  const workspace_allotment = userMetadata.allotments?.find(
    (allotment) => allotment.code === "ai_messages"
  );
  const limit = workspace_allotment?.amount;
  const usage = userMetadata.usage.tokens.tokenCount;
  return usage >= limit;
});

export const updateAvailable = createSelector(userMetadata, (userMetadata) => {
  const appVersion = import.meta.env.VITE_GITHUB_SHA;
  return (
    userMetadata.latestSha &&
    appVersion &&
    userMetadata.latestSha !== appVersion
  );
});

export default userMetadataSlice.reducer;
