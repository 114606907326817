import { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import CommandPaletteContext from "../../../../contexts/CommandPaletteContext";
import { mentionsByCollectionId } from "../../../../features/documentsSlice";
import { capitalize } from "../../../../lib/cmd-palette-helpers";
import { BodyLarge } from "../../../Typography";
import Footer from "../Footer";
import GoBackButton from "../GoBackButton";
import Command from "../cmdk/Command";
import CommandList from "../cmdk/CommandList";
import CommandInput from "../cmdk/CommandInput";
import CommandItem from "../cmdk/CommandItem";
import CommandItemEmpty from "../cmdk/CommandItemEmpty";
import useCurrentCollectionId from "../../../../hooks/useCurrentCollectionId";

export default function SelectExamplePage() {
  const { setTonePage, setAudiencePage, setBrandVoiceId, setTone, setMention } =
    useContext(CommandPaletteContext);

  const { collectionId } = useCurrentCollectionId();
  const items = useSelector(mentionsByCollectionId(collectionId));
  const [exampleValue, setExampleValue] = useState("");

  const handleSelect = useCallback(
    (value) => {
      const mention = items.find(
        (item) => item.name.toLowerCase() === value.toLowerCase()
      );

      setBrandVoiceId(null); // set null to indicate mention is to be used
      setMention(mention);
      setTone(capitalize(value));
      setAudiencePage();
    },
    [setAudiencePage, setTone, setMention, items]
  );

  const filteredMentionOptions = exampleValue
    ? items.filter((item) =>
        item.name.toLowerCase().includes(exampleValue.toLowerCase())
      )
    : items;

  return (
    <Command>
      <CommandList>
        <CommandItemEmpty>No results found.</CommandItemEmpty>
        {filteredMentionOptions.map((item) => (
          <CommandItem key={item.id} onSelect={handleSelect} value={item.name}>
            <BodyLarge>{item.name}</BodyLarge>
          </CommandItem>
        ))}
      </CommandList>
      <Footer>
        <GoBackButton onClick={setTonePage} />
        <CommandInput
          autoFocus
          value={exampleValue}
          onValueChange={setExampleValue}
          placeholder="Pick an example doc"
        />
      </Footer>
    </Command>
  );
}
