import styled from "styled-components";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { BodyMedium, BodySmall } from "./Typography";
import Button from "./Button";
import {
  hasActiveSubscription as hasActiveSubscriptionSelector,
  userMetadata,
} from "../features/userMetadataSlice";
import FrameContext from "../contexts/FrameContext";

const DEFAULT_TOKEN_LIMIT = 5000;

const StyledContent = styled.div`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.border.primary};

  margin-top: 0.5rem;
  padding: 1rem 1.5rem 1.5rem;
  border-radius: 1rem;
  width: 28rem;
  user-select: none;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.body.backgroundColor} 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const StyledHeading = styled(BodySmall)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
`;

const StyledUsage = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.foregroundColor};
`;

const StyledPlan = styled.div`
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  white-space: nowrap;
  margin-top: 0.5rem;
`;

const StyledFill = styled.div`
  position: absolute;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.plan.foregroundColor};
  width: ${({ $width }) => $width * 100}%;
  max-width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
`;

const StyledMeter = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.plan.backgroundColor};
  height: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
`;

const StyledUpgradeButton = styled(Button)`
  margin-top: 1rem;
  width: fit-content;
`;

export default function AccountMenuPlan() {
  const metadata = useSelector(userMetadata);
  const usage = metadata?.usage.aiMessageCount;
  const subscriptions = metadata?.subscriptions;
  const limitAllotment = metadata.allotments?.find(
    (allotment) => allotment.allotmentType.code === "ai_messages"
  );
  const limit = limitAllotment?.allotmentType.amount || DEFAULT_TOKEN_LIMIT;
  const hasActiveSubscription = useSelector(hasActiveSubscriptionSelector);
  const activeSubscription = subscriptions.find(
    (sub) => sub.status === "active"
  );
  const { openBillingModal } = useContext(FrameContext);
  const handleUpgradeButton = function () {
    openBillingModal();
  };

  return (
    <StyledContent>
      <StyledHeading>
        {subscriptions?.length > 0 && hasActiveSubscription
          ? activeSubscription?.name
          : "Free Plan"}
      </StyledHeading>
      <StyledPlan>
        <StyledUsage>
          {usage.toLocaleString()}/{limit.toLocaleString()} Tokens
        </StyledUsage>
        <StyledMeter>
          <StyledFill $width={usage / limit} />
        </StyledMeter>
        <StyledUpgradeButton onClick={handleUpgradeButton}>
          Upgrade
        </StyledUpgradeButton>
      </StyledPlan>
    </StyledContent>
  );
}
