import { ChevronLeft12 } from "../../Icon";
import IconButton from "../../IconButton";

export default function GoBackButton({ onClick }) {
  return (
    <IconButton
      aria-label="go back"
      icon={<ChevronLeft12 />}
      onClick={onClick}
    />
  );
}
