import styled from "styled-components";
import { COLORS } from "../../theme";
import StageIcon from "../StageIcon";
import { BodyMedium } from "../Typography";

const StyledTemplatePreview = styled.div`
  background-color: ${({ theme }) => theme.modal.secondaryBackgroundColor};
  border-left: 1px solid ${({ theme }) => theme.border.secondary};

  padding: 4rem 0 4rem 5rem;
  width: 100%;
`;

const StyledDescription = styled(BodyMedium)`
  color: ${({ theme }) => theme.modal.foregroundColor};
  user-select: none;
  margin-bottom: 1rem;
  width: 363px;
  margin-bottom: 2rem;
`;

const StageName = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  color: ${({ theme }) => theme.modal.foregroundColor};
`;
const StageCount = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
  color: ${({ theme }) => theme.modal.foregroundColor};
`;

const TemplateWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.body.subtleBackgroundColor};
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  box-shadow: 0px 0px 24px 0px ${COLORS.BLACK100T},
    inset 0 0 0 1px ${({ theme }) => theme.border.primary};
  height: 39rem;
`;
const StageRow = styled.div`
  ${({ last, theme }) =>
    !last && `border-bottom: 1px solid ${theme.border.secondary};`}
  padding: 2rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  font-size: 1.75rem;
  line-height: 2.5rem;
  align-items: center;
`;

function TemplateStages({ stages }) {
  return (
    <TemplateWrapper>
      {stages.map((stage, index) => (
        <StageRow key={stage.name} last={stages.length === index + 1}>
          <StageIcon iconId={stage.icon} color={stage.color} />
          <StageName>{stage.name}</StageName>
          <StageCount>0</StageCount>
        </StageRow>
      ))}
    </TemplateWrapper>
  );
}

export default function TemplatePreview({ stages }) {
  return (
    <StyledTemplatePreview>
      <StyledDescription>
        Workspaces let you create and organize your work anyway you want.
      </StyledDescription>
      <TemplateStages stages={stages} />
    </StyledTemplatePreview>
  );
}
