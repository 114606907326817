import styled, { css } from "styled-components";
import { useCallback } from "react";
import { BodyMedium } from "../../Typography";

const hoverAndHighlightedStyled = css`
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  color: ${({ theme }) => theme.body.foregroundColor};

  path {
    ${({ shouldFill, theme }) =>
      shouldFill ? `fill: ${theme.button.primary.foregroundColor};` : ""}
  }
`;

const StyledCommandItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  :hover {
    ${hoverAndHighlightedStyled}
  }
  ${({ isHighlighted }) => isHighlighted && hoverAndHighlightedStyled}
`;

const IconWrapper = styled.span`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  svg * {
    ${({ shouldFill, theme }) =>
      shouldFill
        ? `fill: ${theme.button.primary.hoverForegroundColor};`
        : `fill: ${theme.button.primary.foregroundColor};`}
  }
`;

export default function SlashCommandItem({
  item,
  isHighlighted,
  selectItemByIndex,
  index,
  setSelectedIndex,
}) {
  const selectItem = useCallback(() => {
    selectItemByIndex(index);
  }, [index, selectItemByIndex]);

  const highlightItem = useCallback(() => {
    setSelectedIndex(index);
  }, [setSelectedIndex, index]);

  return (
    <StyledCommandItem
      isHighlighted={isHighlighted}
      onClick={selectItem}
      onMouseEnter={highlightItem}
    >
      {item.icon && (
        <IconWrapper shouldFill={isHighlighted}>
          <item.icon />
        </IconWrapper>
      )}
      <BodyMedium>{item.title}</BodyMedium>
    </StyledCommandItem>
  );
}
