const FULL_BLACK = "rgba(0,0,0,1)";
const FULL_WHITE = "rgba(255,255,255,1)";

const COLORS = {
  BLACK: FULL_BLACK,
  BLACK025T: "rgba(0,0,0,0.025)",
  BLACK050T: "rgba(0,0,0,0.05)",
  BLACK100T: "rgba(0,0,0,0.1)",
  BLACK150T: "rgba(0,0,0,0.15)",
  BLACK200T: "rgba(0,0,0,0.2)",
  BLACK250T: "rgba(0,0,0,0.25)",
  BLACK300T: "rgba(0,0,0,0.3)",
  BLACK350T: "rgba(0,0,0,0.35)",
  BLACK400T: "rgba(0,0,0,0.4)",
  BLACK450T: "rgba(0,0,0,0.45)",
  BLACK500T: "rgba(0,0,0,0.5)",
  BLACK550T: "rgba(0,0,0,0.55)",
  BLACK600T: "rgba(0,0,0,0.6)",
  BLACK650T: "rgba(0,0,0,0.65)",
  BLACK700T: "rgba(0,0,0,0.7)",
  BLACK750T: "rgba(0,0,0,0.75)",
  BLACK800T: "rgba(0,0,0,0.8)",
  BLACK850T: "rgba(0,0,0,0.85)",
  BLACK900T: "rgba(0,0,0,0.9)",
  BLACK950T: "rgba(0,0,0,0.95)",
  BLACK1000T: FULL_BLACK,

  OCEAN500: "#5C6AE4",
  OCEAN600: "#4F5DD7",

  ORANGE500: "#D2461B",

  CHARCOAL100: "#F0F1F2",
  CHARCOAL200: "#DEDFE0",
  CHARCOAL300: "#C1C2C3",
  CHARCOAL400: "#A1A2A4",
  CHARCOAL500: "#7E7F81",
  CHARCOAL600: "#58595A",
  CHARCOAL650: "#48494A",
  CHARCOAL700: "#353637",
  CHARCOAL800: "#272829",
  CHARCOAL850: "#1B1C1C",
  CHARCOAL900: "#131414",
  CHARCOAL1000: "#0F1010",

  CHARCOAL800T: "rgba(50,51,52,0.8)",

  CRIMSON500: "#BC5749",
  FOREST500: "#017264",

  PURPLE500: "#995CBF",
  SAND500: "#F5F4F1",

  SUNRISE300: "#FFD3A1",
  SUNRISE400: "#FFC07F",
  SUNRISE500: "#FFB546",
  SUNRISE600: "#F3A124",

  SUNRISE200T: "rgba(255,181,70,0.2)",

  WHITE: FULL_WHITE,
  WHITE025T: "rgba(255,255,255,0.025)",
  WHITE050T: "rgba(255,255,255,0.05)",
  WHITE100T: "rgba(255,255,255,0.1)",
  WHITE150T: "rgba(255,255,255,0.15)",
  WHITE200T: "rgba(255,255,255,0.2)",
  WHITE250T: "rgba(255,255,255,0.25)",
  WHITE300T: "rgba(255,255,255,0.3)",
  WHITE350T: "rgba(255,255,255,0.35)",
  WHITE400T: "rgba(255,255,255,0.4)",
  WHITE450T: "rgba(255,255,255,0.45)",
  WHITE500T: "rgba(255,255,255,0.5)",
  WHITE550T: "rgba(255,255,255,0.55)",
  WHITE600T: "rgba(255,255,255,0.6)",
  WHITE650T: "rgba(255,255,255,0.65)",
  WHITE700T: "rgba(255,255,255,0.7)",
  WHITE750T: "rgba(255,255,255,0.75)",
  WHITE800T: "rgba(255,255,255,0.8)",
  WHITE850T: "rgba(255,255,255,0.85)",
  WHITE900T: "rgba(255,255,255,0.9)",
  WHITE950T: "rgba(255,255,255,0.95)",
  WHITE1000T: FULL_BLACK,
};

export default COLORS;
