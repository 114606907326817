import styled from "styled-components";
import Button from "../Button";

export const CTAButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  color: ${({ theme }) => theme.button.primary.foregroundColor};

  :hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
  }

  :disabled,
  :disabled:hover {
    background-color: ${({ theme }) => theme.button.primary.backgroundColor};
    opacity: 0.2;
    cursor: default;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export { Button };
