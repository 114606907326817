import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import throttle from "lodash/throttle";
import { marked } from "marked";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import DocumentContext from "../../contexts/DocumentContext";
import { addDocument } from "../../features/documentsSlice";
import { defaultStageByCollectionId } from "../../features/collectionsSlice";
import { CREATE_DOCUMENT, GET_COLLECTION } from "../../graphql";
import { COLORS } from "../../theme";
import Avatar from "../Avatar";
import Button from "../Button";
import { AddTo12, Copy12, Document12 } from "../Icon";
import { BodyLarge } from "../Typography";
import CustomMention from "./MentionExtension";
import suggestion from "./MentionList/suggestion";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const fixedButtonStyles = css`
  position: sticky;
  bottom: 0;
`;

const StyledDocumentButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  overflow-x: auto;
  ${({ $isFixed }) => $isFixed && fixedButtonStyles}
`;

const hideButtons = css`
  :not(:hover) {
    ${StyledDocumentButtons} {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.highlight.backgroundColor};
  color: ${({ theme }) => theme.button.highlight.foregroundColor};
  column-gap: 1rem;
  min-width: max-content;
  :hover {
    background-color: ${({ theme }) =>
      theme.button.highlight.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.highlight.hoverForegroundColor};
    svg path {
      fill: ${({ theme }) => theme.button.highlight.hoverForegroundColor};
    }
  }
  svg path {
    fill: ${({ theme }) => theme.button.highlight.foregroundColor};
  }
`;

const StyledMessage = styled.div`
  margin-bottom: 3rem;
  ${({ $hideButtons }) => $hideButtons && hideButtons}
`;

const StyledMessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  margin-bottom: -1rem;
`;

const StyledBodyLarge = styled(BodyLarge)`
  margin-left: 1rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
`;

const StyledSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -4rem;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  :hover {
    text-decoration: ${({ isClickable }) =>
      isClickable ? "underline" : "none"};
  }
`;

const StyledEditor = styled.div`
  .ProseMirror {
    max-width: 110rem;

    h1 {
      font-size: ${({ theme }) => theme.typography.headerLarge.fontSize};
    }
    h2 {
      font-size: ${({ theme }) => theme.typography.headerMedium.fontSize};
    }
    h3 {
      font-size: ${({ theme }) => theme.typography.headerSmall.fontSize};
    }
    ul p,
    ol p {
      margin: 0.75rem 0;
    }
    ul,
    ol {
      padding-left: 2.7rem;
      li {
        padding-left: 0.8rem;
      }
    }
    hr {
      height: 1px;
      background-color: ${COLORS.BLACK100T};
      margin: 2rem 0;
      border: 0;
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.border.primary};
    border-radius: 6px;

    td,
    th {
      min-width: 1em;
      border-right: 1px solid ${({ theme }) => theme.border.primary};
      border-bottom: 1px solid ${({ theme }) => theme.border.primary};
      padding: 1rem 1.5rem;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin: 0;
      }
    }

    td:last-child,
    th:last-child {
      border-right: 0;
    }

    tr:last-child td,
    tr:last-child th {
      border-bottom: 0;
    }

    th {
      font-weight: bold;
      text-align: left;
      background: ${({ theme }) => theme.body.subtleBackgroundColor};
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: ${({ theme }) => theme.body.subtleBackgroundColor};
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -1px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: ${({ theme }) => theme.border.primary};
      pointer-events: none;
    }
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .mention {
    color: ${COLORS.OCEAN500};
  }
`;

export default function Message({
  content,
  role,
  json,
  id,
  isMostRecent,
  index,
}) {
  const { user } = useAuth0();
  const isUser = role === "human";
  const { collectionId } = useCurrentCollectionId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultStage = useSelector(defaultStageByCollectionId(collectionId));
  const [createDocumentMutation] = useMutation(CREATE_DOCUMENT);
  const { editor: documentEditor, insertContent } = useContext(DocumentContext);

  const editor = useEditor({
    extensions: [
      StarterKit,
      CustomMention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
        renderLabel({ node }) {
          return node.attrs.label;
        },
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
    ],
    editable: false,
    content: json
      ? json
      : marked.parse(content, {
          mangle: false,
          headerIds: false,
          headerPrefix: false,
        }),
  });

  useEffect(() => {
    id &&
      editor?.commands.setContent(
        marked.parse(content, {
          mangle: false,
          headerIds: false,
          headerPrefix: false,
        })
      );
  }, [id, content]);

  const createDocument = useCallback(async () => {
    const result = await createDocumentMutation({
      variables: {
        collectionId,
        input: {
          name: "",
          snapshot: editor.getJSON(),
          stageId: defaultStage.id,
        },
      },
      refetchQueries: [GET_COLLECTION],
      onCompleted: (data) => {
        dispatch(addDocument({ collectionId, document: data.createDocument }));
      },
    });

    if (result.data) {
      navigate(result.data.createDocument.id);
    }
  }, [navigate, editor, defaultStage]);

  const createDocumentThrottled = useCallback(
    throttle(createDocument, 2000, { trailing: false }),
    [createDocument]
  );

  const insertContentIntoDocument = useCallback(() => {
    insertContent(editor.getJSON());
  }, [editor, insertContent]);

  const openModal = useCallback(() => {
    navigate("settings");
  }, [navigate]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(editor.getText());
  }, [editor]);

  const buttonsMarkup = !isUser && index !== 0 && (
    <StyledDocumentButtons $isFixed={isMostRecent}>
      <StyledButton
        onClick={createDocumentThrottled}
        Icon={Document12}
        size="small"
      >
        Create a doc
      </StyledButton>
      {Boolean(documentEditor) && (
        <StyledButton
          onClick={insertContentIntoDocument}
          Icon={AddTo12}
          size="small"
        >
          Add to doc
        </StyledButton>
      )}
      <StyledButton onClick={copyToClipboard} Icon={Copy12} size="small">
        Copy to clipboard
      </StyledButton>
    </StyledDocumentButtons>
  );

  return (
    <StyledMessage $hideButtons={!isMostRecent}>
      <StyledMessageHeader>
        <StyledSectionWrapper
          isClickable={!isUser}
          onClick={!isUser ? openModal : undefined}
        >
          <Avatar size="medium" src={isUser ? user.picture : null} />
          <StyledBodyLarge>{isUser ? "You" : "Strut"}</StyledBodyLarge>
        </StyledSectionWrapper>
        <StyledSectionWrapper>
          {!isMostRecent && buttonsMarkup}
        </StyledSectionWrapper>
      </StyledMessageHeader>
      <StyledEditor>
        <EditorContent editor={editor} />
      </StyledEditor>
      {isMostRecent && buttonsMarkup}
    </StyledMessage>
  );
}
