import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import FrameContext from "../contexts/FrameContext";
import { focusFirstElement } from "../lib/focus-helpers";
import COLORS from "../theme/colors";
import Button from "./Button";
import { BodyLarge, BodyMedium } from "./Typography";

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: auto;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.BLACK500T};
`;

const StyledModal = styled.div`
  background: ${({ theme }) => theme.body.secondaryBackgroundColor};
  padding: 3rem 4rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  max-width: 70rem;
`;

const StyledBodyLarge = styled(BodyLarge)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.body.foregroundColor};
  user-select: none;
`;

const StyledBodyMedium = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  user-select: none;
`;

const StyledButtons = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

export default function DeleteModal({
  description,
  heading,
  onClose,
  onConfirm,
}) {
  const { overlayLayer } = useContext(FrameContext);
  const ref = useRef(null);

  useLayoutEffect(() => {
    focusFirstElement({ element: ref.current });
  }, []);

  useEffect(() => {
    function shortcuts(e) {
      if (e.key === "Escape") {
        onClose();
        e.stopPropagation();
      }
    }
    const modalElement = ref.current;

    modalElement.addEventListener("keydown", shortcuts);
    return () => {
      modalElement.removeEventListener("keydown", shortcuts);
    };
  }, [onClose]);

  return (
    overlayLayer.current &&
    createPortal(
      <StyledModalWrapper ref={ref}>
        <Overlay onClick={onClose} />
        <StyledModal>
          <StyledBodyLarge className="fs-mask">{heading}</StyledBodyLarge>
          <StyledBodyMedium className="fs-mask">{description}</StyledBodyMedium>
          <StyledButtons>
            <StyledButton onClick={onClose}>Cancel</StyledButton>
            {onConfirm && <Button onClick={onConfirm}>Delete</Button>}
          </StyledButtons>
        </StyledModal>
      </StyledModalWrapper>,
      overlayLayer.current
    )
  );
}
