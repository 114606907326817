import styled, { css } from "styled-components";
import { createPortal } from "react-dom";
import {
  useFloating,
  offset,
  autoUpdate,
  flip,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import { useContext, useLayoutEffect } from "react";
import FrameContext from "../contexts/FrameContext";
import { COLORS } from "../theme";

const originMapping = {
  top: "bottom center",
  "top-start": "bottom left",
  "top-end": "bottom right",
  right: "left center",
  "right-start": "left top",
  "right-end": "left bottom",
  bottom: "top center",
  "bottom-start": "top left",
  "bottom-end": "top right",
  left: "right center",
  "left-start": "right top",
  "left-end": "right bottom",
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;
`;

const openStyles = css`
  padding: 0.5rem;
  pointer-events: auto;
  opacity: 1;
  z-index: 10000;
`;

// x and y can contain fractional numbers (decimal), so there will be
// blurring unless we place it evenly on the device’s pixel grid.
// The rounding method below ensures the floating element is
// positioned optimally for the screen.
function roundByDPR(value) {
  const dpr = window.devicePixelRatio || 1;
  return Math.round(value * dpr) / dpr;
}

const StyledPopover = styled.div.attrs(({ x, y }) => ({
  style: {
    transform: `translate(${roundByDPR(x)}px,${roundByDPR(y)}px)`,
  },
}))`
  background-color: ${({ theme }) => theme.menu.backgroundColor};
  box-shadow: 0 4px 8px ${COLORS.BLACK100T}, 0 8px 24px ${COLORS.BLACK100T},
    ${({ theme }) => theme.menu.borderColor};

  backdrop-filter: blur(1rem);
  border-radius: 1.5rem;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: ${({ placement }) => placement && originMapping[placement]};
  opacity: 0;
  pointer-events: none;
  min-width: 24rem;

  ${({ isOpen }) => isOpen && openStyles}
`;

function Popover({
  children,
  placement = "bottom",
  activator,
  isOpen,
  closePopover,
  className,
  id,
  setIsOpen,
  activatorBufferInPx = 4,
}) {
  const { overlayLayer } = useContext(FrameContext);
  const { refs, x, y, context, update } = useFloating({
    placement,
    middleware: [offset(activatorBufferInPx), flip()],
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  useLayoutEffect(() => {
    if (isOpen) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isOpen, update, refs.floating, refs.reference]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {activator}
      </div>
      {overlayLayer.current &&
        createPortal(
          <>
            {isOpen && <Overlay onClick={closePopover} id={id} />}
            <StyledPopover
              isOpen={isOpen}
              placement={placement}
              ref={refs.setFloating}
              x={x}
              y={y}
              className={className}
              {...getFloatingProps()}
            >
              {children}
            </StyledPopover>
          </>,
          overlayLayer.current
        )}
    </>
  );
}

export default styled(Popover)``;
