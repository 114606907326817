import { useMutation } from "@apollo/client";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_BRAND_VOICES, UPDATE_BRAND_VOICE } from "../../graphql";
import Input from "../Input";

export default function BrandVoiceTitle({ title }) {
  const { brandVoiceId } = useParams();
  const [updateBrandVoiceMutation] = useMutation(UPDATE_BRAND_VOICE);
  const [newTitle, setNewTitle] = useState(title);

  const updateBrandVoiceTitle = useCallback(
    async (title) => {
      await updateBrandVoiceMutation({
        variables: { id: brandVoiceId, input: { title } },
        refetchQueries: [GET_BRAND_VOICES],
      });
    },
    [updateBrandVoiceMutation, brandVoiceId]
  );

  const updateBrandVoiceTitleDebounced = useCallback(
    debounce(updateBrandVoiceTitle, 500),
    [updateBrandVoiceTitle]
  );

  const updateTitle = useCallback(
    (e) => {
      setNewTitle(e.target.value);
      updateBrandVoiceTitleDebounced(e.target.value);
    },
    [updateBrandVoiceTitleDebounced]
  );

  return <Input value={newTitle} onChange={updateTitle} />;
}
