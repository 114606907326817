import { useCallback } from "react";

import { ModalForm, Input, Button, CTAButton, ButtonGroup } from "../ModalForm";
import styled from "styled-components";
import { BodySmall } from "../Typography";
import COLORS from "../../theme/colors";

const StyledError = styled(BodySmall)`
  padding: 1rem;
  color: ${COLORS.CRIMSON500};
`;

export default function CreateNewModal({
  onSubmit,
  onClose,
  header,
  description,
  error,
  setTitleValue,
  titleValue,
  placeholder,
}) {
  const setInputValue = useCallback((e) => {
    setTitleValue(e.target.value);
  }, []);

  return (
    <ModalForm
      onClose={onClose}
      header={header}
      description={description}
      onSubmit={onSubmit}
    >
      {error === "Too many Collections" && (
        <StyledError>
          You’ve reached the max number of Workspaces on your plan. Upgrade to
          add more.
        </StyledError>
      )}
      <Input
        name="title-input"
        id="title-input"
        label="Name"
        value={titleValue}
        onChange={setInputValue}
        placeholder={placeholder}
      />
      <ButtonGroup>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
        <CTAButton type="submit" disabled={!titleValue.length}>
          Create
        </CTAButton>
      </ButtonGroup>
    </ModalForm>
  );
}
