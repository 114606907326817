import styled from "styled-components";
import { listReset } from "../../../css-mixins/list";
import withDrag from "../withDrag";
import Document from "./Document";
import DateHeader from "../../DateHeader";

const DraggableDocument = withDrag(Document);

const StyledDocuments = styled.ul`
  ${listReset}
  margin: 0 0 1px 0;
  padding: 0;
  padding-bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LinearGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 1.5rem;
  height: 8rem;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    ${({ theme }) => theme.body.gradient.start} 69.27%
  );
`;

export default function ListView({
  documents,
  showNewDocument,
  handleCreateNewDocument,
  date,
}) {
  return (
    <>
      {date && (
        <DateHeader
          count={documents.length}
          date={date}
          handleCreateNewDocument={showNewDocument && handleCreateNewDocument}
        />
      )}
      <StyledDocuments>
        {documents.map((document) => (
          <DraggableDocument document={document} key={document.id} />
        ))}
        <LinearGradient />
      </StyledDocuments>
    </>
  );
}
