import styled from "styled-components";
import { buttonReset } from "../../css-mixins/button";
import { Plus12 } from "../Icon";
import { BodyMedium } from "../Typography";

const StyledBodyMedium = styled(BodyMedium)`
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
`;

const StyledIcon = styled(Plus12)`
  & path {
    fill: ${({ theme }) => theme.body.secondaryForegroundColor};
  }
`;

const StyledPlaceholder = styled.button`
  ${buttonReset}
  border-radius: 1.5rem;
  border: 1px solid ${({ theme }) => theme.border.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  height: 28rem;
  transition: transform 0.1s ease-out;

  :hover {
    border-color: ${({ theme }) => theme.border.hoverPrimary};
    ${StyledBodyMedium} {
      color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }

    svg path {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }

  :active {
    transform: scale(0.995);
  }
`;

export default function PlaceholderButton({ children, onClick }) {
  return (
    <StyledPlaceholder onClick={onClick}>
      <StyledIcon />
      <StyledBodyMedium>{children}</StyledBodyMedium>
    </StyledPlaceholder>
  );
}
