import React, { useEffect, useState } from "react";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { themes, detectTheme, SYSTEM_MODE } from "../theme";
import { themeSelector } from "../features/uiSlice";

const mediaQueryString = "(prefers-color-scheme: dark)";

export default function ThemeProvider({ children }) {
  const theme = useSelector(themeSelector);
  const [displayTheme, setDisplayTheme] = useState(
    theme === SYSTEM_MODE ? detectTheme() : theme
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(mediaQueryString);
    const changeHandler = () => {
      if (theme === SYSTEM_MODE) {
        setDisplayTheme(detectTheme());
      } else {
        setDisplayTheme(theme);
      }
    };
    changeHandler();

    mediaQuery.addEventListener("change", changeHandler);
    return () => mediaQuery.removeEventListener("change", changeHandler);
  }, [theme, setDisplayTheme]);

  const selectedTheme = themes[displayTheme] || themes.LIGHT;

  return (
    <StyledComponentsThemeProvider theme={selectedTheme}>
      {children}
    </StyledComponentsThemeProvider>
  );
}
