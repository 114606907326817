import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import COLORS from "../theme/colors";
import { useCallback } from "react";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.BLACK500T};
`;

const StyledModal = styled.div`
  padding: 1rem;
  margin: 1rem;
  max-width: 80rem;
  ${({ fitContent }) => !fitContent && "height: 80vh;"}
  flex: 1;
  border-radius: 2rem;
  background: ${({ theme }) => theme.modal.backgroundColor};
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
`;

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; // Check this is above elements on all pages
`;

function Modal({ children, fitContent = false, className, onClose }) {
  const navigate = useNavigate();
  const closeModal = useCallback(() => {
    onClose?.();
    navigate("..");
  }, [navigate, onClose]);

  return (
    <StyledModalWrapper>
      <Overlay onClick={closeModal} />
      <StyledModal fitContent={fitContent} className={className}>
        {children}
      </StyledModal>
    </StyledModalWrapper>
  );
}

export default styled(Modal)``;
