import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  action: null,
  selectedItems: [],
  isCommandPaletteOpen: false,
  shouldUseDocuments: false,
};

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export const multiSelectSlice = createSlice({
  name: "multiSelect",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selectedItems = [...state.selectedItems, action.payload].filter(
        onlyUnique
      );

      return state;
    },
    unselect: (state, action) => {
      state.selectedItems = state.selectedItems.filter(
        (item) => item !== action.payload
      );
      return state;
    },
    setAction: (state, action) => {
      state.action = action.payload.action;
      return state;
    },
    reset: () => {
      return initialState;
    },
    toggleIsOpenState: (state, action) => {
      state.isCommandPaletteOpen = action.payload;
      return state;
    },
    toggleShouldUseDocuments: (state, action) => {
      state.shouldUseDocuments = action.payload;

      if (action.payload === false) {
        state.selectedItems = [];
      }
      return state;
    },
  },
});

// actions
export const {
  setSelected,
  unselect,
  setAction,
  reset,
  toggleIsOpenState,
  toggleShouldUseDocuments,
} = multiSelectSlice.actions;

// selectors
function state(state) {
  return state;
}

export function multiSelect(state) {
  return state.multiSelect;
}

export function getSelectedDocuments(state) {
  return state.multiSelect.selectedItems;
}

export const getFullSelectedDocuments = createSelector(
  state,
  multiSelect,
  (state, multiSelect) =>
    multiSelect.selectedItems.map((id) => state.documents[id])
);

export function getSelectedCount(state) {
  return state.multiSelect.selectedItems.length;
}

export const selectIsCommandPaletteOpen = createSelector(
  multiSelect,
  (multiSelect) => multiSelect.isCommandPaletteOpen
);

export const selectShouldUseDocuments = createSelector(
  multiSelect,
  (multiSelect) => multiSelect.shouldUseDocuments
);

export default multiSelectSlice.reducer;
