import throttle from "lodash/throttle";
import { useCallback, useContext, useEffect } from "react";
import Masonry from "react-masonry-css";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FrameContext from "../../../contexts/FrameContext";
import { SIDEBAR_LEFT_WIDTH_PX } from "../../../css-mixins/main-grid";
import { tagsByCollectionId as tagsByCollectionIdSelector } from "../../../features/documentsSlice";
import { getNumberOfColumns } from "../../../lib/grid-view-helpers";
import withDrag from "../withDrag";
import DocumentPreview from "./DocumentPreview";
import GridButton from "./GridButton";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";
import DateHeader from "../../DateHeader";
import { device } from "../../../css-mixins/media-queries";

const DraggableDocumentPreview = withDrag(DocumentPreview);

const LinearGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8rem;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    ${({ theme }) => theme.body.gradient.start} 69.27%
  );

  @media (${device.mobile}) {
    display: none;
  }
`;

const StyledContent = styled.div`
  padding: 2rem 5rem;
  padding-bottom: 3.5rem;

  @media (${device.mobile}) {
    padding: 2rem;
  }

  .masonry-grid {
    display: flex;
    margin-left: -2rem; /* gutter size offset */
    width: auto;
  }

  .masonry-grid_column {
    padding-left: 2rem; /* gutter size */
    flex: 1;
    max-width: ${({ $numColumns }) =>
      $numColumns && `calc(100% / ${$numColumns})`};
  }
`;

const ContentWrapper = styled.div``;

export default function GridView({
  documents = [],
  stagePosition,
  date,
  showNewDocument,
  handleCreateNewDocument,
}) {
  const { collectionId } = useCurrentCollectionId();
  const tagsByCollectionId = useSelector(
    tagsByCollectionIdSelector(collectionId)
  );

  const {
    isSidebarRightOpen,
    isSidebarLeftOpen,
    numColumns,
    rightColWidth,
    setNumColumns,
  } = useContext(FrameContext);

  const getColumnsOnWindowResize = useCallback(() => {
    const sidebarRightWidth = isSidebarRightOpen ? rightColWidth : 0;
    const sidebarLeftWidth = isSidebarLeftOpen ? SIDEBAR_LEFT_WIDTH_PX : 0;
    const width = window.innerWidth - sidebarRightWidth - sidebarLeftWidth;

    setNumColumns(getNumberOfColumns(width));
  }, [isSidebarRightOpen, isSidebarLeftOpen, rightColWidth, setNumColumns]);

  const throttledHandleResize = useCallback(
    throttle(getColumnsOnWindowResize, 500),
    [getColumnsOnWindowResize]
  );

  useEffect(() => {
    getColumnsOnWindowResize();
  }, [isSidebarRightOpen, isSidebarLeftOpen, rightColWidth]);

  useEffect(() => {
    window.addEventListener("resize", throttledHandleResize);

    return () => {
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, [throttledHandleResize]);

  return (
    <ContentWrapper>
      {date && (
        <DateHeader
          count={documents.length}
          date={date}
          handleCreateNewDocument={showNewDocument && handleCreateNewDocument}
        />
      )}
      <StyledContent $numColumns={numColumns}>
        <Masonry
          breakpointCols={numColumns}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {stagePosition === 0 && <GridButton />}
          {documents.map((document) => (
            <DraggableDocumentPreview
              document={document}
              key={document.id}
              tagsByCollectionId={tagsByCollectionId}
            />
          ))}
        </Masonry>
        <LinearGradient />
      </StyledContent>
    </ContentWrapper>
  );
}
