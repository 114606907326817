import {
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
  pointerWithin,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { useCallback, useState, useContext } from "react";
import useUpdateDocument from "../hooks/api/useUpdateDocument";
import useUpdateCollection from "../hooks/api/useUpdateCollection";
import DocumentDragOverlay from "../components/Documents/DocumentDragOverlay";
import SortableItemsContext from "./SortableItemsContext";

const ACTIVATION_CONSTRAINT_PX = 10;

export default function DndDocumentContext({ children }) {
  const [overlayProps, setOverlayProps] = useState();
  const { setSidebarItems } = useContext(SortableItemsContext);

  const updateDocument = useUpdateDocument();
  const updateCollection = useUpdateCollection();
  const handleDragStart = useCallback(
    (event) => {
      if (event.active.data.current.type === "sidebar-workspace") {
        setSidebarItems(event.active.data.current.sortable.items);
      }
      setOverlayProps(event.active.data.current);
    },
    [setOverlayProps, setSidebarItems]
  );

  const handleDragCancel = useCallback(() => {
    setOverlayProps(undefined);
  }, [setOverlayProps]);

  const handleDragEnd = useCallback(
    (event) => {
      setOverlayProps(undefined);
      if (!event.over) {
        return;
      }
      const { active, over } = event;

      if (active.id.includes("sortable-c-")) {
        const sortableItems = active.data?.current?.sortable?.items;
        const newIndex = sortableItems.indexOf(
          `sortable-c-${over.data.current.collectionId}`
        );
        const oldIndex = active.data.current.sortable.index;
        setSidebarItems((items) => {
          const newOrder = arrayMove(items, oldIndex, newIndex);
          return newOrder;
        });
        return updateCollection({
          id: active.data.current.collectionId,
          input: { sidebarPosition: newIndex },
        });
      }

      const { document, collectionId } = event.active.data.current;
      const { stageId, collectionId: newCollectionId } =
        event.over.data.current;
      const input = {
        previousCollectionId: (!stageId && collectionId) || undefined,
        collectionId: newCollectionId || collectionId,
        stageId,
      };

      updateDocument({
        id: document.id,
        input,
      });
    },
    [updateDocument]
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: ACTIVATION_CONSTRAINT_PX,
      },
    })
  );

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={pointerWithin}
    >
      {children}
      <DocumentDragOverlay {...overlayProps} />
    </DndContext>
  );
}
