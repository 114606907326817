import styled, { css } from "styled-components";

const typographyBase = css`
  margin: 0;
`;

export const HeaderLarge = styled.h1`
  ${typographyBase}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.headerLarge.fontWeight};
  font-size: ${(props) => props.theme.typography.headerLarge.fontSize};
  line-height: ${(props) => props.theme.typography.headerLarge.lineHeight};
`;

export const HeaderMedium = styled.h2`
  ${typographyBase}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.headerMedium.fontWeight};
  font-size: ${(props) => props.theme.typography.headerMedium.fontSize};
  line-height: ${(props) => props.theme.typography.headerMedium.lineHeight};
`;

export const HeaderSmall = styled.h3`
  ${typographyBase}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.headerSmall.fontWeight};
  font-size: ${(props) => props.theme.typography.headerSmall.fontSize};
  line-height: ${(props) => props.theme.typography.headerSmall.lineHeight};
`;

export const BodyLarge = styled.p`
  ${typographyBase}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.bodyLarge.fontWeight};
  font-size: ${(props) => props.theme.typography.bodyLarge.fontSize};
  line-height: ${(props) => props.theme.typography.bodyLarge.lineHeight};
`;

export const BodyMedium = styled.p`
  ${typographyBase}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.bodyMedium.fontWeight};
  font-size: ${(props) => props.theme.typography.bodyMedium.fontSize};
  line-height: ${(props) => props.theme.typography.bodyMedium.lineHeight};
`;

export const BodySmall = styled.p`
  ${typographyBase}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.bodySmall.fontWeight};
  font-size: ${(props) => props.theme.typography.bodySmall.fontSize};
  line-height: ${(props) => props.theme.typography.bodySmall.lineHeight};
`;

export const LabelSmall = styled.p`
  ${typographyBase}
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.labelSmall.fontWeight};
  font-size: ${(props) => props.theme.typography.labelSmall.fontSize};
  line-height: ${(props) => props.theme.typography.labelSmall.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.labelSmall.letterSpacing};
  text-transform: uppercase;
`;
