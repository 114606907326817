import styled from "styled-components";
import { buttonReset } from "../../css-mixins/button";
import { Checkmark12 } from "../Icon";
import { listItemMixin } from "../../css-mixins/menu";
import { BodyMedium } from "../Typography";

const StyledItem = styled.li`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
  ${buttonReset}
  ${listItemMixin}
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  svg * {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }

  &:hover {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};

    svg * {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }
`;

const StyledPlaceholder = styled.span`
  width: 1.5rem;
  display: inline-block;
  margin-right: 2rem;
`;

const StyledIcon = styled(Checkmark12)`
  margin-right: 2rem;
`;

const IconContainer = styled.span`
  margin-right: 1.5rem;
  display: flex;

  svg * {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

export default function DefaultItem({ item, withSelectedIcon, CustomItem }) {
  const ButtonComponent = CustomItem || StyledButton; // Use CustomButton or default

  return (
    <StyledItem key={item.id} onClick={item?.onClick}>
      <ButtonComponent>
        {withSelectedIcon &&
          (item.isSelected ? <StyledIcon /> : <StyledPlaceholder />)}
        {item.icon && <IconContainer>{item.icon}</IconContainer>}
        <BodyMedium className="fs-mask">{item.name}</BodyMedium>
      </ButtonComponent>
    </StyledItem>
  );
}
