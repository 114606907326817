import { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import CommandPaletteContext from "../../../../../contexts/CommandPaletteContext";
import { getSelectedCount } from "../../../../../features/multiSelectSlice";
import {
  customTurnIntoPrompt,
  turnIntoOptions,
  turnIntoOptionsWithCustom,
} from "../../../../../lib/cmd-palette-helpers";
import Footer from "../../Footer";
import Command from "../../cmdk/Command";
import CommandInput from "../../cmdk/CommandInput";
import CommandItemEmpty from "../../cmdk/CommandItemEmpty";
import CommandList from "../../cmdk/CommandList";
import CommandItem from "./Item";

const StyledFooter = styled(Footer)`
  padding-left: 2.875rem;
  justify-content: space-between;
`;

export default function PromptTypePage() {
  const selectedItemsCount = useSelector(getSelectedCount);
  const match = useMatch("/c/:collectionId/:documentId");
  const documentId = match?.params.documentId;

  const placeholder = documentId
    ? "Turn into..."
    : `Turn ${selectedItemsCount} selected into...`;

  const { inputValue, setInputValue } = useContext(CommandPaletteContext);

  const filteredItems = !inputValue
    ? turnIntoOptions
    : turnIntoOptionsWithCustom.filter(
        (item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.id === customTurnIntoPrompt.id
      );

  const ref = useRef(null);
  useEffect(() => {
    // focus input after multi selecting a document
    ref.current.focus();
  }, [selectedItemsCount]);

  return (
    <Command>
      <CommandList>
        <CommandItemEmpty>No results found.</CommandItemEmpty>
        {filteredItems.map(({ id, name }) => (
          <CommandItem id={id} key={id} name={name} />
        ))}
      </CommandList>
      <StyledFooter>
        <CommandInput
          ref={ref}
          value={inputValue}
          onValueChange={setInputValue}
          placeholder={placeholder}
        />
      </StyledFooter>
    </Command>
  );
}
