import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import useCreateDocument from "../../../hooks/useCreateDocument";
import { Plus12 } from "../../Icon";
import { BodyMedium } from "../../Typography";

const hoverStyles = css`
  :hover {
    border: 1px solid ${({ theme }) => theme.border.hoverPrimary};
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    svg * {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }
`;

const StyledGridButtonWrapper = styled.div`
  border-radius: 1.5rem;
  border: 1px solid ${({ theme }) => theme.border.primary};
  cursor: pointer;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  overflow: hidden;
  margin-bottom: 2rem;
  height: 36rem;
  user-select: none;
  display: flex;
  flex-direction: column;
  transition: transform 0.1s ease-out;

  :active {
    transform: scale(0.995);
  }

  ${hoverStyles}
`;

const StyledIcon = styled.span`
  svg {
    flex-shrink: 0;
    * {
      fill: ${({ theme }) => theme.body.secondaryForegroundColor};
    }
  }
`;

const StyledCreateNew = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 1rem;
`;

export default function GridButton() {
  const navigate = useNavigate();
  const { createNewDocument } = useCreateDocument();
  const handleCreateNewDocument = useCallback(
    createNewDocument((documentId) => navigate(documentId)),
    [createNewDocument]
  );

  return (
    <StyledGridButtonWrapper>
      <StyledCreateNew onClick={handleCreateNewDocument}>
        <StyledIcon>
          <Plus12 />
        </StyledIcon>
        <BodyMedium>New Doc</BodyMedium>
      </StyledCreateNew>
    </StyledGridButtonWrapper>
  );
}
