import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function StripePricingTable() {
  const { user } = useAuth0();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": import.meta.env.VITE_STRIPE_PRICE_TABLE,
    "publishable-key": import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    "client-reference-id": user["strutt-uuid"],
    "customer-email": user.email,
  });
}
