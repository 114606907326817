import { useCallback, useContext } from "react";
import styled from "styled-components";
import CommandPaletteContext from "../../../../../contexts/CommandPaletteContext";
import { customTurnIntoPrompt } from "../../../../../lib/cmd-palette-helpers";
import { BodyLarge } from "../../../../Typography";
import CommandItem from "../../cmdk/CommandItem";

const StyledBodyLarge = styled(BodyLarge)`
  color: ${({ theme }) => theme.cmdk.foregroundColor};
`;

export default function Item({ id, name }) {
  const { setAudiencePage, setPromptTypeId, inputValue } = useContext(
    CommandPaletteContext
  );

  const handleSelect = useCallback(() => {
    setAudiencePage();
    setPromptTypeId(id);
  }, [setAudiencePage, setPromptTypeId, id]);

  return (
    <CommandItem key={id} onSelect={handleSelect} value={id}>
      {id === customTurnIntoPrompt.id ? (
        <>
          <StyledBodyLarge>{name}</StyledBodyLarge>
          <BodyLarge>{inputValue}</BodyLarge>
        </>
      ) : (
        name
      )}
    </CommandItem>
  );
}
