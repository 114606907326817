import styled from "styled-components";
import { device } from "../css-mixins/media-queries";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { BodyLarge } from "./Typography";

const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.body.backgroundColor};
  height: 100vh;
  @media (${device.mobile}) {
    justify-content: flex-start;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

const StyledTitle = styled(BodyLarge)`
  font-weight: 500;
`;

const StyledMessage = styled.div`
  width: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30rem;
  flex-grow: 1;
`;

const StyledBody = styled(BodyLarge)`
  color: ${({ theme }) => theme.body.foregroundColor};
  margin: 2rem;
  text-align: center;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const StyledFooter = styled.div`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  margin: 5rem;
  text-align: center;
  p {
    margin: 0;
  }
  a,
  a:visited {
    display: block;
    margin-top: 1rem;
    color: ${({ theme }) => theme.body.secondaryForegroundColor};
    text-decoration: underline;
  }
`;

export default function NoAccess() {
  const { isAuthenticated, user, logout } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("locationState");
  }, []);

  return (
    <PageWrapper>
      <Layout>
        <StyledMessage>
          <StyledTitle>No access</StyledTitle>
          <StyledBody>
            You don&apos;t have access to that content. You can request access
            by messaging the content owner.
          </StyledBody>
          <Button onClick={() => navigate("/inbox")}>Back to app</Button>
        </StyledMessage>
        {isAuthenticated && (
          <StyledFooter>
            <p>You are logged in as </p>
            <p>{user.email}</p>
            <a onClick={logout} href="#">
              Log Out
            </a>
          </StyledFooter>
        )}
      </Layout>
    </PageWrapper>
  );
}
