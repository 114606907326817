import { Extension, getAttributes } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export const LinkMenuState = {
  HIDDEN: "HIDDEN",
  VIEW_LINK_DETAILS: "VIEW_LINK_DETAILS",
  EDIT_LINK: "EDIT_LINK",
};

const LinkBubbleMenuHandler = Extension.create({
  name: "linkBubbleMenuHandler",

  addStorage() {
    return {
      state: LinkMenuState.HIDDEN,
    };
  },

  addCommands() {
    return {
      openLinkBubbleMenu:
        () =>
        ({ editor, chain, dispatch }) => {
          const currentMenuState = this.storage.state;

          let newMenuState;
          if (editor.isActive("link")) {
            if (currentMenuState !== LinkMenuState.VIEW_LINK_DETAILS) {
              chain().extendMarkRange("link").focus().run();
            }

            newMenuState = LinkMenuState.VIEW_LINK_DETAILS;
          } else {
            newMenuState = LinkMenuState.EDIT_LINK;
          }

          if (dispatch) {
            this.storage.state = newMenuState;
          }

          return true;
        },

      editLinkInBubbleMenu:
        () =>
        ({ dispatch }) => {
          const currentMenuState = this.storage.state;
          const newMenuState = LinkMenuState.EDIT_LINK;
          if (currentMenuState === newMenuState) {
            return false;
          }

          if (dispatch) {
            this.storage.state = newMenuState;
          }

          return true;
        },

      closeLinkBubbleMenu:
        () =>
        ({ commands, dispatch }) => {
          const currentMenuState = this.storage.state;
          if (currentMenuState === LinkMenuState.HIDDEN) {
            return false;
          }

          commands.focus();

          if (dispatch) {
            this.storage.state = LinkMenuState.HIDDEN;
          }

          return true;
        },
    };
  },

  onSelectionUpdate() {
    if (this.storage.state === LinkMenuState.EDIT_LINK) {
      this.editor.commands.closeLinkBubbleMenu();
    } else if (
      this.storage.state === LinkMenuState.VIEW_LINK_DETAILS &&
      !this.editor.isActive("link")
    ) {
      this.editor.commands.closeLinkBubbleMenu();
    }
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-u": () => {
        this.editor.commands.openLinkBubbleMenu();
        return true;
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("handleClickLinkForMenu"),
        props: {
          handleClick: (view, pos, event) => {
            const attrs = getAttributes(view.state, "link");
            const link = event.target.closest("a");

            if (
              link &&
              attrs.href &&
              this.storage.state === LinkMenuState.HIDDEN
            ) {
              this.editor.commands.openLinkBubbleMenu();
            } else {
              this.editor.commands.closeLinkBubbleMenu();
            }
            return false;
          },
        },
      }),
    ];
  },
});

export default LinkBubbleMenuHandler;
