import { useContext } from "react";
import styled from "styled-components";
import FrameContext from "../../contexts/FrameContext";
import { device } from "../../css-mixins/media-queries";
import { BodyMedium } from "../Typography";
import PageGrid from "./PageGrid";

const StyledPage = styled.div`
  padding: 3rem 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 3rem;

  @media (${device.desktop}) {
    grid-column: 2 / 4;
  }
`;

const StyledBodyMedium = styled(BodyMedium)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  color: ${({ theme }) => theme.body.primaryForegroundColor};
`;

const StyledHeaderRow = styled.div`
  margin: 0 5rem;
  user-select: none;
  ${({ $isSidebarLeftOpen }) => !$isSidebarLeftOpen && `margin-left: 8rem;`}
`;

export default function Page({ header, children }) {
  const { isSidebarLeftOpen } = useContext(FrameContext);
  return (
    <StyledPage>
      <StyledHeaderRow $isSidebarLeftOpen={isSidebarLeftOpen}>
        <StyledBodyMedium>{header}</StyledBodyMedium>
      </StyledHeaderRow>
      <PageGrid>{children}</PageGrid>
    </StyledPage>
  );
}
