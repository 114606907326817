import React from "react";
import ReactDOM from "react-dom/client";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import App from "./App";
import StruttAuth0Provider from "./providers/StruttAuth0Provider";

const root = ReactDOM.createRoot(document.getElementById("root"));

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LD_KEY,
  });

  root.render(
    <LDProvider>
      <StruttAuth0Provider>
        <App />
      </StruttAuth0Provider>
    </LDProvider>
  );
})();
