import { css } from "styled-components";

export default css`
  .strut-image {
    display: flex;
    margin: auto;
    max-width: 100%;
  }

  [data-youtube-video] {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  [data-youtube-video] iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
