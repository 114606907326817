import { useContext } from "react";
import CommandPaletteContext from "../../../contexts/CommandPaletteContext";
import {
  BRAINSTORM_PAGES,
  COMMAND_LIST_PAGE,
  OUTLINE_PAGES,
  RESEARCH_PAGES,
  TURN_INTO_PAGES,
  DRAFT_PAGES,
} from "../../../lib/cmd-palette-helpers";
import CommandListPage from "./command-list/CommandListPage";
import IdeasPage from "./brainstorm/IdeasPage";
import OutlinePage from "./outline/OutlinePage";
import ResearchPage from "./research/ResearchPage";
import AudiencePage from "./turn-into/AudiencePage";
import PromptTypePage from "./turn-into/PromptTypePage";
import SelectDocumentsPage from "./turn-into/SelectDocumentsPage";
import SelectExamplePage from "./turn-into/SelectExamplePage";
import TonePage from "./turn-into/TonePage";
import CollectionListPage from "./collection-list/CollectionListPage";

export default function Page() {
  const { page } = useContext(CommandPaletteContext);

  switch (page) {
    case BRAINSTORM_PAGES.IDEAS_PAGE:
      return <IdeasPage />;
    case RESEARCH_PAGES.RESEARCH_PAGE:
      return <ResearchPage />;
    case OUTLINE_PAGES.OUTLINE_PAGE:
      return <OutlinePage />;
    case DRAFT_PAGES.SELECT_DOCUMENTS_PAGE:
    case TURN_INTO_PAGES.SELECT_DOCUMENTS_PAGE:
      return <SelectDocumentsPage />;
    case TURN_INTO_PAGES.AUDIENCE_PAGE:
      return <AudiencePage />;
    case TURN_INTO_PAGES.TONE_PAGE:
      return <TonePage />;
    case TURN_INTO_PAGES.SELECT_EXAMPLE_PAGE:
      return <SelectExamplePage />;
    case TURN_INTO_PAGES.PROMPT_TYPE_PAGE:
      return <PromptTypePage />;
    case "COLLECTIONS_LIST_PAGE":
      return <CollectionListPage />;
    default:
    case COMMAND_LIST_PAGE:
      return <CommandListPage />;
  }
}
