import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_COLLECTIONS } from "../../../../graphql/queries";
import CommandPaletteContext from "../../../../contexts/CommandPaletteContext";
import Command from "../cmdk/Command";
import CommandList from "../cmdk/CommandList";
import CommandItem from "../cmdk/CommandItem";
import CommandInput from "../cmdk/CommandInput";
import Footer from "../Footer";
import { Close12 } from "../../../Icon";
import IconButton from "../../../IconButton";

const StyledIconButton = styled(IconButton)`
  svg path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledFooter = styled(Footer)`
  padding-left: 2.875rem;
  justify-content: space-between;
`;

export default function CollectionsPage() {
  const { data } = useQuery(GET_COLLECTIONS);
  const [collections, setCollections] = useState([]);
  const navigate = useNavigate();
  const { closeAndResetMenu } = useContext(CommandPaletteContext);

  const [inputValue, setInputValue] = useState("");
  const ref = useRef(null);

  const filteredCollections = inputValue
    ? collections.filter((collection) =>
        collection.name.toLowerCase().includes(inputValue.toLowerCase())
      )
    : collections;

  useEffect(() => {
    if (data && data.collections) {
      setCollections(data.collections);
    }
  }, [data]);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const handleSelectCollection = (collectionId) => {
    navigate(`/c/${collectionId}`);
  };

  return (
    <Command>
      <CommandList>
        {filteredCollections.map((collection) => (
          <CommandItem
            key={collection.id}
            value={collection.name}
            onSelect={() => handleSelectCollection(collection.id)}
          >
            {collection.name}
          </CommandItem>
        ))}
      </CommandList>
      <StyledFooter>
        <CommandInput
          ref={ref}
          value={inputValue}
          onValueChange={setInputValue}
          placeholder="What workspace would you like open?"
        />
        <StyledIconButton
          aria-label="Close"
          onClick={closeAndResetMenu}
          icon={<Close12 />}
        />
      </StyledFooter>
    </Command>
  );
}
