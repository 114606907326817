import { compute } from "compute-scroll-into-view";

// references downshifts utils function
// https://github.com/downshift-js/downshift/blob/master/src/utils.js#L25
export function scrollIntoView(node, menuNode) {
  if (!node) {
    return;
  }

  const actions = compute(node, {
    boundary: menuNode,
    block: "nearest",
    scrollMode: "if-needed",
  });

  actions.forEach(({ el, top, left }) => {
    el.scrollTop = top;
    el.scrollLeft = left;
  });
}

export function filterItems(inputValue, items) {
  return items.filter((item) => {
    return inputValue
      ? item.name && item.name.toLowerCase().includes(inputValue.toLowerCase())
      : items;
  });
}
