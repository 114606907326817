import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import useDeleteBrandVoice from "../../hooks/useDeleteBrandVoice";
import COLORS from "../../theme/colors";
import DeleteModal from "../DeleteModal";
import { Delete12, More12 } from "../Icon";
import IconButton from "../IconButton";
import Menu from "../Menu";
import Popover from "../Popover";
import Tooltip from "../Tooltip";

const isOpenButtonStyles = css`
  background-color: ${COLORS.BLACK100T};
  svg * {
    fill: ${COLORS.BLACK800T};
  }
`;

const StyledIconButton = styled(IconButton)`
  ${({ $isOpen }) => $isOpen && isOpenButtonStyles}
`;

export default function MoreMenu({
  id,
  title,
  isOpen,
  setIsOpen,
  closeMenu,
  openMenu,
  size = "large",
  popoverPlacement = "bottom-end",
}) {
  const {
    isDeleteModalOpen,
    deleteBrandVoice,
    closeDeleteModal,
    openDeleteModal,
  } = useDeleteBrandVoice({ brandVoiceId: id });

  const handleOpenDeleteModal = useCallback(() => {
    closeMenu();
    openDeleteModal();
  }, [closeMenu, openDeleteModal]);

  const items = useMemo(() => {
    return [
      {
        id: "delete-brand-voice",
        name: "Delete Brand Voice",
        onClick: handleOpenDeleteModal,
        icon: <Delete12 />,
      },
    ];
  }, [handleOpenDeleteModal]);

  return (
    <>
      <Popover
        id={`${id}-more-menu`}
        placement={popoverPlacement}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closePopover={closeMenu}
        activator={
          <Tooltip content="More Options">
            <StyledIconButton
              $isOpen={isOpen}
              size={size}
              aria-label="More menu"
              icon={<More12 />}
              onClick={openMenu}
            />
          </Tooltip>
        }
      >
        <Menu items={items} isOpen={isOpen} />
      </Popover>
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={closeDeleteModal}
          onConfirm={deleteBrandVoice}
          name={title}
          heading={`Are you sure you want to delete the “${
            title || "Untitled"
          }” brand voice?`}
          description="All content inside the brand voice will be deleted."
        />
      )}
    </>
  );
}
