import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { remove } from "../features/brandVoicesSlice";
import { DELETE_BRAND_VOICE, GET_BRAND_VOICES } from "../graphql";

export default function useDeleteBrandVoice({ brandVoiceId }) {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const closeDeleteModal = useCallback(() => setIsDeleteModalOpen(false), []);
  const openDeleteModal = useCallback(() => setIsDeleteModalOpen(true), []);
  const dispatch = useDispatch();

  const [deleteBrandVoiceMutation] = useMutation(DELETE_BRAND_VOICE);
  const deleteBrandVoice = useCallback(async () => {
    closeDeleteModal();
    deleteBrandVoiceMutation({
      variables: { id: brandVoiceId },
      refetchQueries: [GET_BRAND_VOICES],
      onCompleted: (data) => {
        dispatch(remove({ id: data.deleteBrandVoice.id }));
      },
    });

    navigate("/brand");
  }, [
    deleteBrandVoiceMutation,
    brandVoiceId,
    navigate,
    dispatch,
    closeDeleteModal,
  ]);

  return {
    deleteBrandVoice,
    isDeleteModalOpen,
    closeDeleteModal,
    openDeleteModal,
  };
}
