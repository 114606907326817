import { forwardRef, useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLocalStorage } from "@uidotdev/usehooks";
import Tooltip from "../../Tooltip";
import FrameContext from "../../../contexts/FrameContext";
import {
  fullDocumentsByStageId,
  datesByStageId,
} from "../../../features/collectionsSlice";
import useUpdateStage from "../../../hooks/api/useUpdateStage";
import useCreateDocument from "../../../hooks/useCreateDocument";
import { formatStageName } from "../../../lib/stage-helpers";
import { Plus12, ChevronRight8, ChevronDown8 } from "../../Icon";
import IconButton from "../../IconButton";
import IconSelector from "../../IconSelector";
import { BodyMedium } from "../../Typography";
import Grids from "./Grids";
import Lists from "./Lists";
import StageInput from "./StageInput";
import StageMoreMenu from "./StageMoreMenu";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";
import { device } from "../../../css-mixins/media-queries";

const StyledCollapseButton = styled(IconButton)`
  opacity: 0;

  @media (${device.mobile}) {
    display: none;
  }
`;

const StyledStage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.border.secondary};
  padding: 2rem 3rem 2rem 1rem;
  position: sticky;
  margin-bottom: 1px;
  top: 0;
  z-index: 1;
  column-gap: 1rem;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.body.gradient.start} 50%,
    ${({ theme }) => theme.body.gradient.end} 100%
  );
  backdrop-filter: blur(1rem);

  @media (${device.mobile}) {
    padding: 2rem 3rem 2rem 2.5rem;
  }
`;

const StyledCount = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  margin-left: 1rem;
  user-select: none;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

const StyledRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;

  :hover ${StyledCollapseButton} {
    opacity: 1;
  }
`;

const Stage = forwardRef(({ stage, style }, ref) => {
  const stageId = stage.id;
  const navigate = useNavigate();
  const [collapsedStages, saveCollapsedStages] = useLocalStorage(
    `${import.meta.env.VITE_APP_PROTOCOL}.collapsedStages`,
    []
  );
  const { createNewDocument } = useCreateDocument();
  const [resizeCompleted, setResizeCompleted] = useState(false);
  const handleCreateNewDocument = useCallback(
    createNewDocument((documentId) => navigate(documentId), {
      stageId,
    }),
    [createNewDocument, stageId]
  );
  const isCollapsed = collapsedStages.includes(stageId);

  const toggleCollapse = useCallback(() => {
    const newCollapsedStages = [...collapsedStages, stageId].filter(
      (collapsedStage) =>
        isCollapsed ? collapsedStage !== stageId : collapsedStage
    );
    saveCollapsedStages(newCollapsedStages);
  }, [stage.id, isCollapsed, saveCollapsedStages]);

  const { collectionId, isInbox } = useCurrentCollectionId();
  const documentsByStage = useSelector(
    fullDocumentsByStageId(collectionId, stageId)
  );

  const datesByStage = useSelector(datesByStageId(collectionId, stageId));

  const { selectedView } = useContext(FrameContext);
  const handleResizeComplete = useCallback(() => {
    setResizeCompleted(true);
  }, []);
  const documentCount = documentsByStage.length;

  // TO DO: Possibly remove this, but currently the default stages need this to display correctly
  const name = formatStageName(stage.name);

  const updateStage = useUpdateStage();
  const handleUpdateStageIcon = useCallback(
    ({ id, color }) => {
      updateStage({ id: stageId, input: { icon: id, color } });
    },
    [stageId]
  );

  const applyStyle = isInbox ? undefined : style;

  return (
    <StyledWrapper ref={ref} style={applyStyle}>
      {!isInbox && (
        <StyledStage style={style}>
          <StyledLeft>
            <Tooltip content={isCollapsed ? "Expand Stage" : "Collapse Stage"}>
              <StyledCollapseButton
                icon={isCollapsed ? <ChevronRight8 /> : <ChevronDown8 />}
                onClick={toggleCollapse}
              />
            </Tooltip>
            <Tooltip content="Change Icon">
              <IconSelector
                iconId={stage.icon}
                color={stage.color}
                onSelectIcon={handleUpdateStageIcon}
              />
            </Tooltip>
            <StageInput
              defaultValue={name}
              id={stage.id}
              onResizeComplete={handleResizeComplete}
            />
            {resizeCompleted && <StyledCount>{documentCount}</StyledCount>}
          </StyledLeft>
          <StyledRight>
            <Tooltip content="New Doc">
              <IconButton icon={<Plus12 />} onClick={handleCreateNewDocument} />
            </Tooltip>

            <StageMoreMenu
              documentCount={documentsByStage.length}
              name={name}
              stageId={stage.id}
              stagePosition={stage.position}
            />
          </StyledRight>
        </StyledStage>
      )}

      {!isCollapsed && (
        <>
          {documentCount > 0 || stage.position === 0 ? (
            selectedView !== "list" ? (
              <Grids
                documents={documentsByStage}
                dates={isInbox && datesByStage}
                collectionId={collectionId}
                stageId={stageId}
                stagePosition={stage.position}
                handleCreateNewDocument={handleCreateNewDocument}
              />
            ) : (
              <Lists
                documents={documentsByStage}
                dates={isInbox && datesByStage}
                showNewDocument={isInbox}
                collectionId={collectionId}
                stageId={stageId}
                handleCreateNewDocument={handleCreateNewDocument}
              />
            )
          ) : null}
        </>
      )}
    </StyledWrapper>
  );
});

Stage.displayName = "Stage";

export default Stage;
