import { useContext } from "react";
import styled from "styled-components";
import CommandPaletteContext from "../../../../../contexts/CommandPaletteContext";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../../../../lib/cmd-palette-helpers";
import { ChevronRight12, Tone16 } from "../../../../Icon";
import { BodySmall, BodyLarge } from "../../../../Typography";

const StyledToneButton = styled.button`
  appearance: none;
  outline: none;
  border: none;
  width: 100%;
  position: relative;
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.input.backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.5rem;
  text-align: left;
  cursor: pointer;

  :hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.input.borderColor};
  }

  :focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.input.borderColor};
  }
`;

const StyledText = styled(BodyLarge)`
  margin: 0;
  color: ${({ theme }) => theme.cmdk.hoverForegroundColor};
  text-align: left;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.cmdk.foregroundColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
`;

const StyledIconWrapper = styled.span`
  pointer-events: none;
  & svg path {
    fill: ${({ theme }) => theme.cmdk.foregroundColor};
  }
`;

const StyledChevronRight = styled(ChevronRight12)`
  position: absolute;
  right: 1.5rem;
  pointer-events: none;
  & path {
    fill: ${({ theme }) => theme.cmdk.foregroundColor};
  }
`;

const StyledLabel = styled.label`
  pointer-events: none;
`;

export default function SelectTone() {
  const { tone, setTonePage } = useContext(CommandPaletteContext);

  return (
    <StyledToneButton
      id="select-tone"
      className={CMDK_SKIP_TARGET_CLASSNAME}
      onClick={setTonePage}
    >
      <StyledIconWrapper>
        <Tone16 />
      </StyledIconWrapper>
      <StyledRightWrapper>
        <StyledLabel htmlFor="select-tone">
          <StyledBodySmall>Tone</StyledBodySmall>
        </StyledLabel>
        <StyledText>{tone}</StyledText>
      </StyledRightWrapper>
      <StyledChevronRight />
    </StyledToneButton>
  );
}
