import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { GET_COLLECTION, UPDATE_DOCUMENT } from "../../graphql";

export default function useUpdateDocument() {
  const [updateDocumentMutation] = useMutation(UPDATE_DOCUMENT);

  const updateDocument = useCallback(
    ({ id, input }) => {
      updateDocumentMutation({
        variables: {
          id,
          input,
        },
        refetchQueries: [GET_COLLECTION],
      });
    },
    [updateDocumentMutation]
  );

  return updateDocument;
}
