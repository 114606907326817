import CommandPaletteContext from "../contexts/CommandPaletteContext";
import useCommandPalette from "../hooks/command-palette/useCommandPalette";
import useTurnInto from "../hooks/command-palette/useTurnInto";

function CommandPaletteProvider({ children }) {
  const {
    closeAndResetMenu,
    openCommandPalette,
    page,
    selectedCommand,
    setCommandListPage,
    setPage,
    setSelectedCommand,
  } = useCommandPalette();

  const {
    audience,
    brandVoiceId,
    goal,
    handleSubmitTurnInto,
    inputValue,
    mention,
    promptTypeId,
    setAudience,
    setAudiencePage,
    setBrandVoiceId,
    setGoal,
    setInputValue,
    setMention,
    setPromptTypeId,
    setPromptTypePage,
    setSelectExamplePage,
    setSelectedDocumentsPage,
    setTone,
    setTonePage,
    tone,
  } = useTurnInto({ page, setPage });

  return (
    <CommandPaletteContext.Provider
      value={{
        audience,
        brandVoiceId,
        closeAndResetMenu,
        goal,
        handleSubmitTurnInto,
        inputValue,
        mention,
        openCommandPalette,
        page,
        promptTypeId,
        selectedCommand,
        setAudience,
        setAudiencePage,
        setBrandVoiceId,
        setCommandListPage,
        setGoal,
        setInputValue,
        setMention,
        setPage,
        setPromptTypeId,
        setPromptTypePage,
        setSelectedCommand,
        setSelectedDocumentsPage,
        setSelectExamplePage,
        setTone,
        setTonePage,
        tone,
      }}
    >
      {children}
    </CommandPaletteContext.Provider>
  );
}

export default CommandPaletteProvider;
