import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Youtube from "@tiptap/extension-youtube";
import Title from "../../shared/title-extension.mjs";
import Comment from "../../shared/comment-extension.mjs";
import CustomPlaceholder from "./CustomPlaceholder";
import CustomDocument from "./CustomDocument";
import CharacterCount from "@tiptap/extension-character-count";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Focus from "@tiptap/extension-focus";
import { Markdown } from "tiptap-markdown";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { SlashCommands, slashSuggestions } from "../../lib/slash-menu";
import ImageWithUpload from "../Editor/Image/extension";
import LinkBubbleMenuHandler from "../Editor/BubbleLink/extension";

export const basicExtensions = [
  CustomDocument,
  StarterKit.configure({
    history: false,
    document: false,
  }),
  Title,
  Link.configure({
    openOnClick: false,
  }),
  TaskItem.configure({
    nested: true,
  }),
  TaskList,
  Highlight.configure({
    multicolor: true,
  }),
  Youtube,
  Markdown,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
];

export default function defaultExtensions(options = {}, configurations = {}) {
  return [
    ...basicExtensions,
    LinkBubbleMenuHandler,
    CustomPlaceholder.configure({
      placeholder: ({ node }) => {
        if (node.type.name === "title") {
          return "Untitled";
        }

        return "Type something...";
      },
    }),
    CharacterCount,
    Focus.configure({
      className: "focus",
      mode: "all",
    }),
    SlashCommands.configure({ suggestions: slashSuggestions }),
    Comment.configure(configurations.comment),
    options.useImageUpload
      ? ImageWithUpload
      : Image.configure({
          HTMLAttributes: {
            class: "strut-image",
          },
        }),
  ];
}
