import { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import FrameContext from "../../../../contexts/FrameContext";
import CommandPaletteContext from "../../../../contexts/CommandPaletteContext";
import {
  setAction,
  getSelectedCount,
} from "../../../../features/multiSelectSlice";
import {
  researchPromptId,
  researchWithDocumentsPromptId,
} from "../../../../lib/cmd-palette-helpers";
import { Research16 } from "../../../Icon";
import { BodyLarge } from "../../../Typography";
import DocumentsSelector from "../DocumentSelector";
import Footer from "../Footer";
import GoBackButton from "../GoBackButton";
import SubmitButton from "../SubmitButton";
import TextArea from "../TextArea";

const StyledWrapper = styled.div`
  padding: 1rem;
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  column-gap: 1rem;
  user-select: none;
`;

export default function ResearchPage() {
  const match = useMatch("/c/:collectionId/:documentId");
  const documentId = match?.params.documentId;
  const [research, setResearch] = useState("");
  const dispatch = useDispatch();
  const { setCommandListPage } = useContext(CommandPaletteContext);
  const { openSidebarRight } = useContext(FrameContext);
  const selectedDocumentsCount = useSelector(getSelectedCount);

  const handleSubmitResearch = useCallback(() => {
    const includeDocuments = selectedDocumentsCount > 0;
    let prompt = `Research: ${research}${
      includeDocuments ? ". Use the following as reference:" : ""
    }`;

    const promptTemplate = includeDocuments
      ? researchWithDocumentsPromptId
      : researchPromptId;

    openSidebarRight();
    dispatch(
      setAction({
        action: {
          id: promptTemplate,
          prompt,
        },
      })
    );
  }, [dispatch, research, openSidebarRight, selectedDocumentsCount]);

  return (
    <>
      <StyledWrapper>
        <TextArea
          autoFocus
          id="research-topic"
          icon={<Research16 />}
          label="Research"
          onChange={(e) => setResearch(e.target.value)}
          placeholder="What topic would you like to research?"
          value={research}
        />
        {!documentId && <DocumentsSelector />}
      </StyledWrapper>
      <Footer>
        <StyledLeft>
          <GoBackButton onClick={setCommandListPage} />
          <BodyLarge>Research topic</BodyLarge>
        </StyledLeft>
        <SubmitButton disabled={research === ""} onClick={handleSubmitResearch}>
          Research
        </SubmitButton>
      </Footer>
    </>
  );
}
