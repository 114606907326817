import styled, { css } from "styled-components";
import { forwardRef } from "react";
import { buttonReset } from "../css-mixins/button";
import { device } from "../css-mixins/media-queries";

const mobileOnlyStyles = css`
  display: none;
  @media (${device.mobile}) {
    display: flex;
  }
`;

const defaultSizeStyles = css`
  width: 3.5rem;
  height: 3.5rem;
`;

const smallSizeStyles = css`
  width: 2.5rem;
  height: 2.5rem;
`;

const StyledButton = styled.button`
  ${buttonReset}
  ${({ $size }) => ($size === "small" ? smallSizeStyles : defaultSizeStyles)}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 auto;
  outline: none;
  transition: transform 0.1s ease-out;
  backface-visibility: hidden;
  svg * {
    ${({ $shouldPreventFill }) =>
      $shouldPreventFill
        ? ""
        : css`
            fill-opacity: 1;
            fill: ${({ theme }) => theme.button.secondary.foregroundColor};
          `}
  }

  :hover,
  :active,
  :focus-visible {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};

    svg * {
      ${({ $shouldPreventFill, theme }) =>
        $shouldPreventFill
          ? ""
          : `fill: ${theme.button.secondary.hoverForegroundColor};`}
    }
  }

  :active {
    transform: scale(0.95);
  }

  ${({ $isMobileOnly }) => $isMobileOnly && mobileOnlyStyles}
`;

const IconButton = forwardRef(
  (
    { icon, isMobileOnly, size = "large", shouldPreventFill = false, ...rest },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        $isMobileOnly={isMobileOnly}
        $size={size}
        $shouldPreventFill={shouldPreventFill}
        {...rest}
      >
        {icon}
      </StyledButton>
    );
  }
);

IconButton.displayName = "IconButton";

export default styled(IconButton)``;
