import styled, { css } from "styled-components";

const mediaQueries = css`
  @media (min-width: ${({ $isSidebarLeftOpen }) =>
      $isSidebarLeftOpen ? "674px" : "447px"}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ $isSidebarLeftOpen }) =>
      $isSidebarLeftOpen ? "848px" : "621px"}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const PageGrid = styled.div`
  display: grid;
  overflow: auto;
  gap: 2rem;
  padding: 0 5rem;
  padding-top: 1px;
  width: 100%;
  padding-bottom: 3rem;
  grid-template-columns: 1fr;

  ${mediaQueries}
`;

export default PageGrid;
