import { useAuth0 } from "@auth0/auth0-react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import FrameContext from "../contexts/FrameContext";
import { buttonReset } from "../css-mixins/button";
import Menu from "./Menu";
import Popover from "./Popover";
import {
  getActiveSubscription,
  hasActiveSubscription as hasActiveSubscriptionSelector,
} from "../features/userMetadataSlice";
import { GET_CUSTOMER_PORTAL } from "../graphql/queries";
import AccountMenuPlan from "./AccountMenuPlan";
import { SignOut12, Discord12, Download12 } from "./Icon";
import ThemeToggle from "./ThemeToggle";
import { themeSelector } from "../features/uiSlice";
import Tooltip from "./Tooltip";
import Intercom from "@intercom/messenger-js-sdk";

const StyledAccountButton = styled.button`
  ${buttonReset}
  padding: 0.5rem;
  display: flex;
  border-radius: 50%;
  transition: transform 0.1s ease-out;
  -webkit-app-region: no-drag;
  :hover {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
  }

  :active {
    transform: scale(0.95);
  }
`;

const StyledAvatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
`;

export default function AccountButton() {
  const { isCheckoutEnabled } = useFlags();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const { openBillingModal } = useContext(FrameContext);
  const { user, logout } = useAuth0();
  const ldClient = useLDClient();
  const theme = useSelector(themeSelector);
  const hasActiveSubscription = useSelector(hasActiveSubscriptionSelector);
  const activeSubscription = useSelector(getActiveSubscription);
  const [getCustomerPortalUrl] = useLazyQuery(GET_CUSTOMER_PORTAL, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    ldClient.waitUntilReady().then(() => {
      ldClient.identify({
        key: user["strutt-uuid"],
        name: user.name,
        email: user.email,
      });
    });
  });

  useEffect(() => {
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_APP,
      user_id: user["strutt-uuid"],
      name: user.name,
      email: user.email,
      custom_launcher_selector: "#strut_intercom_launcher",
    });
  });

  const openCustomerPortal = useCallback(() => {
    getCustomerPortalUrl({
      variables: {
        externalSubscription: activeSubscription.externalSubscription,
      },
    }).then(({ data }) => {
      window.location.href = data.customerPortal;
    });
  }, [getCustomerPortalUrl, activeSubscription]);

  const items = useMemo(() => {
    const menuItems = [
      {
        id: "DISCORD",
        name: "Join us on Discord",
        icon: <Discord12 />,
        onClick: () => window.open("https://discord.com/invite/cnQYcr5dzE"),
      },
      ...(window.todesktop
        ? []
        : [
            {
              id: "DOWNLOAD_DESKTOP_APP",
              name: "Download Desktop App",
              icon: <Download12 />,
              onClick: () =>
                window.open("https://dl.todesktop.com/2403131cglevsex"),
            },
          ]),
      {
        id: "SIGN_OUT",
        name: "Sign Out",
        icon: <SignOut12 />,
        onClick: () => {
          logout({ logoutParams: { returnTo: window.location.origin } });
        },
      },
    ];

    isCheckoutEnabled &&
      hasActiveSubscription &&
      activeSubscription?.externalSubscription &&
      menuItems.unshift({
        id: "CUSTOMER_PORTAL",
        name: "Customer portal",
        onClick: openCustomerPortal,
      });

    isCheckoutEnabled &&
      !hasActiveSubscription &&
      menuItems.push({
        id: "PLAN",
        name: "Account Plan",
        customItem: AccountMenuPlan,
      });

    menuItems.unshift({
      id: "THEME",
      name: "Theme",
      customItem: ThemeToggle,
    });

    return menuItems;
  }, [logout, isCheckoutEnabled, openBillingModal, activeSubscription, theme]);

  return (
    <Popover
      placement={"bottom-start"}
      isOpen={isAccountMenuOpen}
      setIsOpen={setIsAccountMenuOpen}
      closePopover={() => setIsAccountMenuOpen(false)}
      activator={
        <Tooltip content="Account">
          <StyledAccountButton onClick={() => setIsAccountMenuOpen(true)}>
            {user?.picture && (
              <StyledAvatar referrerPolicy="no-referrer" src={user.picture} />
            )}
          </StyledAccountButton>
        </Tooltip>
      }
    >
      <Menu items={items} isOpen={isAccountMenuOpen} />
    </Popover>
  );
}
