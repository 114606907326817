import { gql } from "@apollo/client";

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($collectionId: ID!, $input: DocumentInput!) {
    createDocument(collectionId: $collectionId, input: $input) {
      name
      id
      body
      snapshot
      tags {
        id
        name
      }
      collectionId
      stage
      stageId
      order
    }
  }
`;

export const IMPORT_DOCUMENT = gql`
  mutation ImportDocument($accessToken: String!, $file: String!) {
    importDocument(accessToken: $accessToken, file: $file) {
      htmlContent
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: ID!, $input: DocumentInput!) {
    updateDocument(id: $id, input: $input) {
      id
      name
      body
      snapshot
      stage
      stageId
      order
      tags {
        id
        name
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`;

export const CREATE_DOCUMENT_TAG = gql`
  mutation CreateDocumentTag($documentId: ID!, $name: String!) {
    createDocumentTag(documentId: $documentId, name: $name) {
      id
    }
  }
`;

export const DELETE_DOCUMENT_TAG = gql`
  mutation DeleteDocumentTag($documentId: ID!, $tagId: ID!) {
    deleteDocumentTag(documentId: $documentId, tagId: $tagId) {
      id
    }
  }
`;
