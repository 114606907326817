import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../css-mixins/media-queries";
import useGetCollection from "../hooks/useGetCollection";
import SidebarRight from "./SidebarRight";
import Documents from "./Documents";
import Header from "./Header";
import useCurrentCollectionId from "../hooks/useCurrentCollectionId";
import useCreateDocument from "../hooks/useCreateDocument";

const StyledContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @media (${device.mobile}) {
    height: 100%;
    ${({ shouldShowOnMobile }) =>
      shouldShowOnMobile ? "display: flex;" : "display: none;"}
  }
`;

export default function Collection({ isSidebarLeftOpen }) {
  const [documentsFetched, setDocumentsFetched] = useState(false);
  const { collectionId, isInbox } = useCurrentCollectionId();
  const { data, loading } = useGetCollection({
    documentsFetched,
    setDocumentsFetched,
  });
  const hasAccess = Boolean(data?.collection);
  const [mobilePage, setMobilePage] = useState("documents");
  const openSidebarRight = useCallback(() => setMobilePage("chat"), []);
  const openDocuments = useCallback(() => setMobilePage("documents"), []);
  const navigate = useNavigate();
  const { createNewDocument } = useCreateDocument();

  const handleCreateNewDocument = useCallback(() => {
    createNewDocument((documentId) => navigate(documentId))();
  }, [createNewDocument]);

  const newDocShortcut = useCallback(
    (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "n") {
        handleCreateNewDocument();
      }
    },
    [handleCreateNewDocument]
  );

  useEffect(() => {
    window.addEventListener("keydown", newDocShortcut);

    return () => {
      window.removeEventListener("keydown", newDocShortcut);
    };
  }, [handleCreateNewDocument]);

  if (!loading && !hasAccess && !isInbox) {
    return <Navigate to="/no-access" />;
  }

  return (
    <>
      <StyledContent shouldShowOnMobile={mobilePage === "documents"}>
        <Header
          isSidebarLeftOpen={isSidebarLeftOpen}
          openChat={openSidebarRight}
        />
        <Documents />
      </StyledContent>
      <SidebarRight
        openDocuments={openDocuments}
        shouldShowOnMobile={mobilePage === "chat"}
        collectionId={collectionId}
        key={collectionId}
      />
    </>
  );
}
