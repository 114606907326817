import { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SortableItemsContext from "../../../contexts/SortableItemsContext";
import { fullDocumentsByStageId } from "../../../features/collectionsSlice";
import useUpdateStage from "../../../hooks/api/useUpdateStage";
import useCreateDocument from "../../../hooks/useCreateDocument";
import { formatStageName } from "../../../lib/stage-helpers";
import { Plus12 } from "../../Icon";
import IconButton from "../../IconButton";
import IconSelector from "../../IconSelector";
import { BodyMedium } from "../../Typography";
import StageMoreMenu from "../Stages/StageMoreMenu";
import StageInput from "./StageInput";
import Tooltip from "../../Tooltip";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const StyledColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid transparent;

  ${({ $showBorder, theme }) =>
    $showBorder && `border-color: ${theme.border.primary};`}
`;

const StyledCount = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  margin-left: 1rem;
  user-select: none;
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function ColumnHeader({ isScrolled, stage }) {
  const { items } = useContext(SortableItemsContext);

  const navigate = useNavigate();
  const { createNewDocument } = useCreateDocument();
  const handleCreateNewDocument = useCallback(
    createNewDocument((documentId) => navigate(documentId), {
      stageId: stage.id,
    }),
    [createNewDocument, stage.id]
  );

  const updateStage = useUpdateStage();
  const handleUpdateStageIcon = useCallback(
    ({ id, color }) => {
      updateStage({ id: stage.id, input: { icon: id, color } });
    },
    [stage.id]
  );

  const { collectionId } = useCurrentCollectionId();
  const documentsByStage = useSelector(
    fullDocumentsByStageId(collectionId, stage.id)
  );

  const name = formatStageName(stage.name);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  const documentCount = items[stage.id]?.length || 0;

  return (
    <StyledColumnHeader $showBorder={isScrolled && documentCount > 0}>
      <StyledSection>
        <Tooltip content="Change Icon">
          <IconSelector
            iconId={stage.icon}
            color={stage.color}
            onSelectIcon={handleUpdateStageIcon}
          />
        </Tooltip>
        <StageInput
          onFocus={() => setInputHasFocus(true)}
          onBlur={() => setInputHasFocus(false)}
          id={stage.id}
          defaultValue={name}
        />
        {!inputHasFocus && <StyledCount>{documentCount}</StyledCount>}
      </StyledSection>
      {!inputHasFocus && (
        <StyledSection>
          <Tooltip content="New Doc">
            <IconButton icon={<Plus12 />} onClick={handleCreateNewDocument} />
          </Tooltip>
          <StageMoreMenu
            documentCount={documentsByStage.length}
            name={name}
            stageId={stage.id}
            stagePosition={stage.position}
          />
        </StyledSection>
      )}
    </StyledColumnHeader>
  );
}
