import { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import SortableItemsContext from "../../../contexts/SortableItemsContext";
import { formattedStages } from "../../../features/collectionsSlice";
import CreateStage from "./CreateStage";
import Documents from "./Documents";
import SortableColumn from "./SortableColumn";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 31.25rem;
  background-color: ${({ theme }) => theme.body.subtleBackgroundColor};
  flex-shrink: 0;
  border-radius: 2rem;
  height: fit-content;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  min-height: 0;
  transition: min-height 0.2s ease-in-out;
  ${({ $isDragging }) => $isDragging && "min-height: 25rem;"}
`;

const StyledKanbanView = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  overflow-x: auto;
  padding: 2.5rem 3.5rem 4rem;
  height: 100%;
`;

export default function KanbanView() {
  const { collectionId } = useCurrentCollectionId();
  const stages = useSelector(formattedStages(collectionId));
  const { items } = useContext(SortableItemsContext);

  return (
    <StyledKanbanView>
      {stages.map((stage) => (
        <SortableColumn key={stage.id} stage={stage} items={items[stage.id]}>
          <Documents stageId={stage.id} />
        </SortableColumn>
      ))}
      <StyledColumn>
        <CreateStage />
      </StyledColumn>
    </StyledKanbanView>
  );
}
