import { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommandPaletteContext from "../../../../contexts/CommandPaletteContext";
import {
  toggleShouldUseDocuments,
  getSelectedCount,
} from "../../../../features/multiSelectSlice";
import { TURN_INTO_PAGES } from "../../../../lib/cmd-palette-helpers";
import CommandItem from "../cmdk/CommandItem";
import { useNavigate } from "react-router-dom";
import { setTheme } from "../../../../features/uiSlice"; // Import the action and constant
import { DARK_MODE, LIGHT_MODE } from "../../../../theme";
import FrameContext from "../../../../contexts/FrameContext";
import useCreateDocument from "../../../../hooks/useCreateDocument";
import useInbox from "../../../../hooks/useInbox";
import useCurrentCollectionId from "../../../../hooks/useCurrentCollectionId";

export default function Item({ id, name }) {
  const { setPage, setSelectedCommand, closeAndResetMenu } = useContext(
    CommandPaletteContext
  );
  const selectedDocumentsCount = useSelector(getSelectedCount);
  const { changeView } = useContext(FrameContext);
  const { createNewDocument } = useCreateDocument();
  const { id: inboxCollectionId } = useInbox();
  const { collectionId } = useCurrentCollectionId();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateAndClose = useCallback(
    (location) => {
      navigate(location);
      closeAndResetMenu();
    },
    [navigate, closeAndResetMenu]
  );

  const changeTheme = useCallback(
    (theme) => {
      dispatch(setTheme(theme));
      closeAndResetMenu();
    },
    [dispatch, closeAndResetMenu]
  );

  const handleChangeView = useCallback(
    (viewType) => {
      changeView(viewType);
      closeAndResetMenu();
    },
    [changeView, closeAndResetMenu]
  );

  const createDocumentHandler = useCallback(
    (path, collectionId) => () => {
      createNewDocument(
        (documentId) => navigate(path.replace(":documentId", documentId)),
        {
          collectionId,
        }
      )();
      closeAndResetMenu();
    },
    [createNewDocument, navigate, closeAndResetMenu]
  );

  const handleCommands = useMemo(
    () => ({
      NAVIGATE_INBOX_COMMAND: () => {
        navigateAndClose("/inbox");
      },
      NAVIGATE_BRAND_VOICE_COMMAND: () => {
        navigateAndClose("/brand");
      },
      NAVIGATE_COLLECTIONS_COMMAND: () => {
        setPage("COLLECTIONS_LIST_PAGE");
      },
      DARK_MODE_THEME_COMMAND: () => changeTheme(DARK_MODE),
      LIGHT_MODE_THEME_COMMAND: () => changeTheme(LIGHT_MODE),
      LIST_VIEW_COMMAND: () => handleChangeView("list"),
      GRID_VIEW_COMMAND: () => handleChangeView("grid"),
      KANBAN_VIEW_COMMAND: () => handleChangeView("kanban"),
      CREATE_INBOX_DOCUMENT_COMMAND: () => {
        createDocumentHandler(`/inbox/:documentId`, inboxCollectionId)();
      },
      CREATE_DOCUMENT_COMMAND: () => {
        createDocumentHandler(`/c/${collectionId}/:documentId`, collectionId)();
      },
      [TURN_INTO_PAGES.SELECT_DOCUMENTS_PAGE]: () => {
        setPage(
          selectedDocumentsCount > 0 ? TURN_INTO_PAGES.PROMPT_TYPE_PAGE : id
        );
        if (selectedDocumentsCount > 0) {
          dispatch(toggleShouldUseDocuments(true));
        }
      },
      default: () => {
        setPage(id);
        if (id === TURN_INTO_PAGES.SELECT_DOCUMENTS_PAGE) {
          dispatch(toggleShouldUseDocuments(true));
        }
      },
    }),
    [
      setPage,
      dispatch,
      selectedDocumentsCount,
      id,
      navigateAndClose,
      changeTheme,
      inboxCollectionId,
      collectionId,
      createDocumentHandler,
    ]
  );

  const handleSelect = useCallback(() => {
    setSelectedCommand(id);
    const handler = handleCommands[id] || handleCommands.default;
    handler();
  }, [dispatch, setPage, selectedDocumentsCount, setSelectedCommand, id]);

  return (
    <CommandItem key={id} onSelect={handleSelect} value={id}>
      {name}
    </CommandItem>
  );
}
