import { gql } from "@apollo/client";

export const CREATE_UPLOAD = gql`
  mutation CreateUpload($fileName: String!) {
    createUpload(fileName: $fileName) {
      uploadUrl
      fileUrl
    }
  }
`;
