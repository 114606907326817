import Mention from "@tiptap/extension-mention";

const CustomMention = Mention.extend({
  addAttributes() {
    return {
      ...this.parent(),
      "data-mention-type": {
        default: "",
      },
    };
  },
});

export default CustomMention;
