import styled from "styled-components";
import { BodySmall } from "../Typography";

const StyledInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  width: 100%;
  color: ${({ theme }) => theme.body.foregroundColor};
  font-size: ${(props) => props.theme.typography.bodyLarge.fontSize};
  line-height: ${(props) => props.theme.typography.bodyLarge.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  user-select: none;
`;

const StyledInputWrapper = styled.div`
  padding: 1rem 2rem;
  border-radius: 2rem;
  background: ${({ theme }) => theme.input.backgroundColor};
`;

export default function Input({
  value,
  onChange,
  placeholder,
  id,
  label,
  className,
}) {
  return (
    <StyledInputWrapper className={className}>
      <label htmlFor={id}>
        <StyledBodySmall>{label}</StyledBodySmall>
      </label>
      <StyledInput
        autoFocus
        type="text"
        value={value}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        className="fs-mask"
      />
    </StyledInputWrapper>
  );
}
