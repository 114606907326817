import {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  Fragment,
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CommandPaletteContext from "../../../../contexts/CommandPaletteContext";
import { getSelectedCount } from "../../../../features/multiSelectSlice";
import {
  brainstorm_command,
  draft_command,
  outline_command,
  turn_into_command,
  navigate_inbox_command,
  navigate_brand_voice_command,
  navigate_collections_command,
  dark_mode_theme_command,
  light_mode_theme_command,
  list_view_command,
  grid_view_command,
  kanban_view_command,
  create_document_command,
  create_inbox_document_command,
} from "../../../../lib/cmd-palette-helpers";
import { Close12 } from "../../../Icon";
import IconButton from "../../../IconButton";
import Footer from "../Footer";
import Command from "../cmdk/Command";
import CommandInput from "../cmdk/CommandInput";
import CommandItemEmpty from "../cmdk/CommandItemEmpty";
import CommandList from "../cmdk/CommandList";
import Item from "./Item";
import { themeSelector } from "../../../../features/uiSlice";
import { DARK_MODE } from "../../../../theme";
import { useMatch } from "react-router-dom";
import useCurrentCollectionId from "../../../../hooks/useCurrentCollectionId";
import FrameContext from "../../../../contexts/FrameContext";

const StyledIconButton = styled(IconButton)`
  svg path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledFooter = styled(Footer)`
  padding-left: 2.875rem;
  justify-content: space-between;
`;

const CommandGroupHeader = styled.div`
  padding: 1rem 2rem;
  color: ${({ theme }) => theme.body.foregroundColor};
  font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  user-select: none;
`;

export default function CommandListPage() {
  const selectedItemsCount = useSelector(getSelectedCount);
  const { selectedView } = useContext(FrameContext);
  const { closeAndResetMenu } = useContext(CommandPaletteContext);
  const theme = useSelector(themeSelector);
  const matchCollectionDocument = useMatch("/c/:collectionId/:documentId");
  const matchInboxDocument = useMatch("/inbox/:documentId");
  const isDocumentOpen = Boolean(
    matchCollectionDocument?.params.documentId ||
      matchInboxDocument?.params.documentId
  );
  const { isInbox } = useCurrentCollectionId();

  const [inputValue, setInputValue] = useState("");
  const ref = useRef(null);

  const inboxViews = useMemo(() => {
    return [
      ...(selectedView !== "list" ? [list_view_command] : []),
      ...(selectedView !== "grid" ? [grid_view_command] : []),
    ];
  }, [selectedView]);

  const workspaceViews = useMemo(() => {
    return [
      ...(selectedView !== "list" ? [list_view_command] : []),
      ...(selectedView !== "grid" ? [grid_view_command] : []),
      ...(selectedView !== "kanban" ? [kanban_view_command] : []),
    ];
  }, [selectedView]);

  const commandGroups = useMemo(
    () => [
      {
        header: "AI Workflows",
        commands: [
          brainstorm_command,
          outline_command,
          draft_command,
          turn_into_command,
        ],
      },
      {
        header: "Create",
        commands: [create_document_command, create_inbox_document_command],
      },
      {
        header: "Navigation",
        commands: [
          navigate_inbox_command,
          navigate_brand_voice_command,
          navigate_collections_command,
        ],
      },
      {
        header: "Views",
        commands: isDocumentOpen ? [] : isInbox ? inboxViews : workspaceViews,
      },
      {
        header: "Theme",
        commands: [
          theme === DARK_MODE
            ? light_mode_theme_command
            : dark_mode_theme_command,
        ],
      },
    ],
    [theme, isDocumentOpen, isInbox, inboxViews, workspaceViews]
  );

  const filteredCommandGroups = commandGroups.map((group) => ({
    ...group,
    commands: group.commands.filter(
      (command) =>
        !inputValue ||
        command.name.toLowerCase().includes(inputValue.toLowerCase())
    ),
  }));

  useEffect(() => {
    // focus input after multi selecting a document
    ref.current.focus();
  }, [selectedItemsCount]);

  return (
    <Command>
      <CommandList>
        <CommandItemEmpty>
          Pick from the list of available commands
        </CommandItemEmpty>
        {filteredCommandGroups.map(
          (group) =>
            group.commands.length > 0 && (
              <Fragment key={group.header}>
                <CommandGroupHeader>{group.header}</CommandGroupHeader>
                {group.commands.map(({ id, name }) => (
                  <Item id={id} key={id} name={name} />
                ))}
              </Fragment>
            )
        )}
      </CommandList>
      <StyledFooter>
        <CommandInput
          ref={ref}
          value={inputValue}
          onValueChange={setInputValue}
          placeholder="What would you like to do?"
        />
        <StyledIconButton
          aria-label="Close"
          onClick={closeAndResetMenu}
          icon={<Close12 />}
        />
      </StyledFooter>
    </Command>
  );
}
