import styled from "styled-components";
import { COLORS } from "../../theme";
import embedStyles from "./embedStyles";
import { device } from "../../css-mixins/media-queries";

export default styled.div`
  flex: 1;
  display: flex;
  position: relative;

  ${embedStyles}

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .document-title {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    font-size: ${({ theme }) => theme.typography.headerLarge.fontSize};
    line-height: ${({ theme }) => theme.typography.headerLarge.lineHeight};
    color: ${({ theme }) => theme.body.foregroundColor};
    margin: 1.75rem 0;
    padding: 0;
  }

  .ProseMirror {
    width: ${({ isScaledUp }) => (isScaledUp ? "80%" : "100%")};
    padding: ${({ $paddingOffset, scale }) =>
      $paddingOffset
        ? `${$paddingOffset / scale - 20}px 10rem ${
            $paddingOffset / scale - 30
          }px`
        : "6rem 10rem 20rem"};
    min-height: 60rem;
    outline: none;
    max-width: 110rem;
    ${({ scale }) => scale && `transform: scale(${scale});`}
    ${({ isScaledUp }) =>
      isScaledUp && "transition: transform 0.2s ease-out, padding 0.2s;"}
    ${({ isScaledUp }) => isScaledUp && "transform-origin: 50% 0;"}

    @media (${device.mobile}) {
      padding: 0rem 1rem 20rem;
    }

    a {
      color: ${({ theme }) => theme.body.foregroundColor};
      cursor: pointer;
    }

    p {
      font-size: ${({ fontSize }) => fontSize};
      line-height: ${({ lineHeight }) => lineHeight};
    }

    *:not(.focus):not(.focus > *):not(.focus > * > *):not(
        .focus > * > * > *
      ):not(.focus > * > * > * > *) {
      // Handles styling when child elements have multiple types of styles(eg. bold, italic, and strikethrough etc.)
      transition: color 0.2s ease-out;
      color: ${({ isTextFocused, theme }) =>
        isTextFocused
          ? theme.document.secondaryForegroundColor
          : theme.document.foregroundColor};
    }

    ul.focus,
    ol.focus {
      transition: color 0.2s ease-out;
      color: ${({ isTextFocused, theme }) =>
        isTextFocused
          ? theme.document.secondaryForegroundColor
          : theme.document.foregroundColor};
    }
  }
  ul li.focus,
  ol li.focus {
    transition: color 0.2s ease-out;
    color: ${({ theme }) => theme.document.foregroundColor};
  }

  a {
    color: ${({ theme }) => theme.document.foregroundColor};
  }

  a.focus {
    transition: color 0.2s ease-out;
    color: ${({ isTextFocused, theme }) =>
      isTextFocused
        ? theme.document.secondaryForegroundColor
        : theme.document.secondaryForegroundColor};
  }

  p {
    font-size: ${({ theme }) => theme.typography.bodyLarge.fontSize};
    line-height: ${({ theme }) => theme.typography.bodyLarge.lineHeight};
  }

  h1 {
    font-size: ${({ theme }) => theme.typography.headerLarge.fontSize};
  }
  h2 {
    font-size: ${({ theme }) => theme.typography.headerMedium.fontSize};
  }
  h3 {
    font-size: ${({ theme }) => theme.typography.headerSmall.fontSize};
  }

  ul p,
  ol p {
    margin: 0.75rem 0;
  }
  ul,
  ol {
    margin: 0;
    padding: 0 0 0 2.7rem;
    li {
      padding-left: 0.8rem;
    }
  }
  hr {
    height: 1px;
    background-color: ${({ theme }) => theme.border.primary};
    margin: 2rem 0;
    border: 0;
  }
  blockquote {
    padding-left: 2.35rem;
    margin-left: 1.15rem;
    position: relative;
    &:before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: ${({ theme }) => theme.body.secondaryForegroundColor};
      width: 3px;
      height: 100%;
      border-radius: 0.5rem;
    }
  }

  pre {
    background: ${({ theme }) => theme.input.backgroundColor};
    border-radius: 1rem;
    font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
    font-family: "SF Mono", SFMono-Regular, ui-monospace, "Segoe UI Mono",
      "Roboto Mono", Menlo, Consolas, Courier, monospace;
    padding: 3rem 3.5rem;

    code {
      background: none;
      padding: 0;
    }
  }

  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    margin: -0.35rem 0 -0.375rem -0.25rem;

    li {
      display: flex;
      align-items: top;
      padding: 0;
      margin: 0;

      p {
        margin: 0.375rem 0;
      }

      > label {
        flex: 0 0 auto;
        margin-right: 0.75rem;
        user-select: none;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.4rem;
      }

      > div {
        flex: 1 1 auto;
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
      width: 16px;
      height: 16px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 2rem;
      border: 2px solid ${({ theme }) => theme.checkbox.borderColor};
      outline: none;
      transition: background-color 0.1s ease;
      position: relative;

      &:hover {
        border: 2px solid ${({ theme }) => theme.checkbox.hoverBorderColor};
      }
    }

    input[type="checkbox"]:checked::after {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      background-image: url('data:image/svg+xml;utf8,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.862 0.862007L2.66667 5.05734L1.138 3.52867C1.01227 3.40723 0.843864 3.34004 0.669066 3.34156C0.494268 3.34308 0.32706 3.41319 0.203454 3.53679C0.0798488 3.6604 0.00973621 3.82761 0.00821726 4.00241C0.00669832 4.1772 0.0738946 4.34561 0.195333 4.47134L2.19533 6.47134C2.32035 6.59632 2.48989 6.66653 2.66667 6.66653C2.84344 6.66653 3.01298 6.59632 3.138 6.47134L7.80467 1.80467C7.92611 1.67894 7.9933 1.51054 7.99178 1.33574C7.99026 1.16094 7.92015 0.993733 7.79655 0.870128C7.67294 0.746522 7.50573 0.67641 7.33093 0.674891C7.15614 0.673372 6.98774 0.740568 6.862 0.862007V0.862007Z" fill="white"/></svg>');
      background-repeat: no-repeat;
      background-position: center;
    }

    input[type="checkbox"]:checked {
      background-color: ${({ theme }) =>
        theme.checkbox.selectedBackgroundColor};
      border: 2px transparent;
    }
  }

  mark {
    background-color: ${({ theme }) => theme.document.highlightColor};
    border-radius: 0.25em;
    box-decoration-break: clone;
    padding: 0 0;
    color: ${({ theme }) => theme.document.foregroundColor};
  }

  .commented {
    background-color: ${({ theme }) => theme.document.highlightColor};
    text-decoration: underline;
    border-radius: 0.25em;
    box-decoration-break: clone;
    padding: 0 0;
    color: ${({ theme }) => theme.document.foregroundColor};
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.border.primary};
    border-radius: 6px;

    td,
    th {
      min-width: 1em;
      border-right: 1px solid ${({ theme }) => theme.border.primary};
      border-bottom: 1px solid ${({ theme }) => theme.border.primary};
      padding: 1rem 1.5rem;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin: 0;
      }
    }

    td:last-child,
    th:last-child {
      border-right: 0;
    }

    tr:last-child td,
    tr:last-child th {
      border-bottom: 0;
    }

    th {
      font-weight: bold;
      text-align: left;
      background: ${({ theme }) => theme.body.subtleBackgroundColor};
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: ${({ theme }) => theme.body.subtleBackgroundColor};
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -1px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: ${({ theme }) => theme.border.primary};
      pointer-events: none;
    }
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  @media (${device.mobile}) {
    padding: 6rem 4rem 20rem;
  }

  .ProseMirror .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: ${({ theme }) => theme.input.placeholderColor};
    pointer-events: none;
    height: 0;
  }

  .collaboration-cursor__caret {
    border-left: 1px solid ${COLORS.CHARCOAL900};
    border-right: 1px solid ${COLORS.CHARCOAL900};
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: ${COLORS.CHARCOAL900};
    font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.3em;
    user-select: none;
    white-space: nowrap;
  }
`;
