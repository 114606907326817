import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { DELETE_STAGE, GET_COLLECTION } from "../../graphql";

export default function useDeleteStage() {
  const [deleteStageMutation] = useMutation(DELETE_STAGE);
  const deleteStage = useCallback(
    async (stageId) => {
      deleteStageMutation({
        variables: { id: stageId },
        refetchQueries: [GET_COLLECTION],
      });
    },
    [deleteStageMutation]
  );

  return {
    deleteStage,
  };
}
