import { useCombobox } from "downshift";
import styled from "styled-components";
import { useState, useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";
import {
  GET_COLLECTION,
  CREATE_DOCUMENT_TAG,
  DELETE_DOCUMENT_TAG,
} from "../../graphql";
import { visuallyHidden } from "../../css-mixins/accessibility";
import { stopPropagation } from "../../lib/event-helpers";
import DropdownInput from "../Dropdown/Input";
import IconButton from "../IconButton";
import { Tag12 } from "../Icon";
import Popover from "../Popover";
import Menu from "./Menu";
import Tooltip from "../Tooltip";

const StyledPopover = styled(Popover)`
  width: 32rem;
  max-height: 32rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

// hidden label styling for accessibility
const HiddenLabel = styled.label`
  ${visuallyHidden}
`;

const StyledIconButton = styled(IconButton)`
  ${({ isOpen }) => isOpen && `opacity: 1 !important;`}
`;

const StyledTagSelector = styled.div`
  position: absolute;
`;

const CREATE_NEW_ITEM = { id: "create-new" };

function onStateChangeWrapper({
  createDocumentTag,
  documentId,
  inputValue,
  resetInput,
  deleteDocumentTag,
  selectedItemsById,
}) {
  return async ({ type, selectedItem }) => {
    switch (type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
      case useCombobox.stateChangeTypes.InputBlur:
        if (selectedItem) {
          const { name, id } = selectedItem;
          const shouldCreateNew = id === CREATE_NEW_ITEM.id;
          const tagName = shouldCreateNew ? inputValue.trim() : name;
          const isSelected = selectedItemsById.includes(id);

          if (!isSelected) {
            createDocumentTag({
              variables: { documentId, name: tagName },
              refetchQueries: [GET_COLLECTION],
            });
          } else {
            deleteDocumentTag({
              variables: { documentId, tagId: id },
              refetchQueries: [GET_COLLECTION],
            });
          }

          resetInput();
        }
        break;
      default:
        break;
    }
  };
}

function filterItems(inputValue, items, selectedItemsById = []) {
  return items.filter((item) => {
    if (inputValue) {
      return (
        item.name &&
        item.name.toLowerCase().includes(inputValue.toLowerCase()) &&
        !selectedItemsById.includes(item.id)
      );
    }
    if (selectedItemsById.length) {
      return item.name && !selectedItemsById.includes(item.id);
    }

    return items;
  });
}

function TagSelector({ tags, documentId, tagsByCollectionId, className }) {
  const [inputValue, setInputValue] = useState("");
  const selectedItemsById = useMemo(() => tags.map((tag) => tag.id), [tags]);
  const items = useMemo(() => {
    return [
      ...filterItems(inputValue, tags),
      ...filterItems(inputValue, tagsByCollectionId, selectedItemsById),
      ...(inputValue && [CREATE_NEW_ITEM]),
    ];
  }, [inputValue, selectedItemsById, tags, tagsByCollectionId]);

  const [createDocumentTag] = useMutation(CREATE_DOCUMENT_TAG);
  const [deleteDocumentTag] = useMutation(DELETE_DOCUMENT_TAG);
  const resetInput = useCallback(() => setInputValue(""), []);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    inputValue,
    items,
    selectedItem: null,
    itemToString: (item) => item?.name || "",
    onStateChange: onStateChangeWrapper({
      createDocumentTag,
      documentId,
      inputValue,
      deleteDocumentTag,
      resetInput,
      selectedItemsById,
    }),
    onIsOpenChange: resetInput,
  });

  const handleInputChange = useCallback(
    (e) => setInputValue(e.target.value),
    []
  );

  return (
    <StyledTagSelector onClick={stopPropagation} className={className}>
      <StyledPopover
        placement="bottom-end"
        isOpen={isOpen}
        closePopover={closeMenu}
        activator={
          <Tooltip content="Add a Tag">
            <StyledIconButton
              isOpen={isOpen}
              icon={<Tag12 />}
              {...getToggleButtonProps({
                onClick: stopPropagation,
                tabIndex: 0,
                "aria-label": "tag selector",
              })}
            />
          </Tooltip>
        }
      >
        <HiddenLabel {...getLabelProps()}>Tag Selector</HiddenLabel>
        <DropdownInput
          getInputProps={getInputProps}
          handleInputChange={handleInputChange}
          isOpen={isOpen}
          placeholder="Add a tag..."
        />
        <Menu
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          inputValue={inputValue}
          isOpen={isOpen}
          items={items}
          selectedItems={selectedItemsById}
        />
      </StyledPopover>
    </StyledTagSelector>
  );
}

export default styled(TagSelector)``;
