export const DEFAULT_NUM_COLUMNS = 3;

export function getNumberOfColumns(elementWidth) {
  if (elementWidth < 446) {
    return 1;
  }

  if (elementWidth < 640) {
    return 2;
  }

  if (elementWidth < 992) {
    return 3;
  }

  if (elementWidth < 1440) {
    return 4;
  }

  if (elementWidth < 1600) {
    return 5;
  }

  if (elementWidth >= 1600) {
    return 6;
  }
}
