import styled from "styled-components";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../../lib/cmd-palette-helpers";

const StyledFooter = styled.div`
  border-radius: 2.5rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 1px solid ${({ theme }) => theme.input.borderColor};
  padding: 1.375rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
`;

function Footer({ className, children }) {
  return (
    <StyledFooter className={`${className} ${CMDK_SKIP_TARGET_CLASSNAME}`}>
      {children}
    </StyledFooter>
  );
}

export default styled(Footer)``;
