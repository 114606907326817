import { Link } from "react-router-dom";
import styled from "styled-components";
import { BodyMedium } from "../Typography";

const StyledIcon = styled.div`
  flex-shrink: 0;
  & path {
    fill: ${({ theme }) => theme.button.primary.foregroundColor};
  }
`;

const StyledGridItem = styled.div`
  background-color: ${({ theme, showOnSidebar }) =>
    !showOnSidebar ? "transparent" : theme.button.primary.backgroundColor};
  box-shadow: ${({ theme, showOnSidebar }) =>
    !showOnSidebar ? `0 0 0 1px ${theme.border.primary}` : "none"};
  height: 28rem;
  border-radius: 1.5rem;
  padding: 1.5rem 2rem;
  user-select: none;
  transition: transform 0.1s ease-out;
  color: ${({ theme }) => theme.button.primary.foregroundColor};
  :hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.border.primary};
    color: ${({ theme }) => theme.button.primary.hoverForegroundColor};

    ${StyledIcon} {
      & path {
        fill: ${({ theme }) => theme.button.primary.hoverForegroundColor};
      }
    }
  }
  :active {
    transform: scale(0.995);
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
`;

const StyledBodyMedium = styled(BodyMedium)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
`;

export default function GridItem({ name, link, onClick, Icon, showOnSidebar }) {
  const displayName = !showOnSidebar ? `${name} (Hidden)` : name;

  return (
    <Link to={link} onClick={onClick}>
      <StyledGridItem showOnSidebar={showOnSidebar}>
        <StyledRow>
          <StyledIcon>
            <Icon />
          </StyledIcon>
          <StyledBodyMedium className="fs-mask">
            {displayName || "Untitled"}
          </StyledBodyMedium>
        </StyledRow>
      </StyledGridItem>
    </Link>
  );
}
