import styled from "styled-components";
import { useSelector } from "react-redux";
import { getSelectedCount } from "../../../../features/multiSelectSlice";
import { BodySmall, BodyLarge } from "../../../Typography";
import { Cursor12 } from "../../../Icon";
import Toggle from "./Toggle";

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.cmdk.foregroundColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  user-select: none;
`;

const StyledDocumentsSelector = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.input.backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.5rem;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledIconWrapper = styled.span`
  padding: 0.25rem;
`;

const StyledCursor = styled(Cursor12)`
  display: flex;
  width: 12px;
  height: 12px;
  & path {
    fill: ${({ theme }) => theme.cmdk.foregroundColor};
  }
`;

const StyledBodyLarge = styled(BodyLarge)`
  color: ${({ $hasSelectedDocuments, theme }) =>
    $hasSelectedDocuments
      ? theme.cmdk.hoverForegroundColor
      : theme.cmdk.foregroundColor};
`;

export default function DocumentsSelector() {
  const selectedDocumentsCount = useSelector(getSelectedCount);

  return (
    <StyledDocumentsSelector>
      <StyledIconWrapper>
        <StyledCursor />
      </StyledIconWrapper>
      <StyledRightWrapper>
        <StyledBodySmall>Inspiration (Optional)</StyledBodySmall>
        <StyledBodyLarge $hasSelectedDocuments={selectedDocumentsCount > 0}>
          {selectedDocumentsCount > 0
            ? `Use ${selectedDocumentsCount} doc${
                selectedDocumentsCount === 1 ? "" : "s"
              } as inspiration`
            : "Pick documents to use as inspiration"}
        </StyledBodyLarge>
      </StyledRightWrapper>
      <Toggle />
    </StyledDocumentsSelector>
  );
}
