import styled from "styled-components";
import { useEffect, useRef } from "react";
import { listItemMixin } from "../../../css-mixins/menu";
import { buttonReset } from "../../../css-mixins/button";
import { scrollIntoView } from "../../../lib/menu-helpers";

const StyledItem = styled.button`
  ${buttonReset}
  ${listItemMixin}
  width: 100%;
  text-align: left;
  &.is-selected {
    background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 1px;
  :last-of-type {
    margin: 0;
  }
`;

export default function Item({
  menuRef,
  children,
  className,
  onClick,
  isHighlighted,
}) {
  const ref = useRef();
  useEffect(() => {
    if (isHighlighted) {
      scrollIntoView(ref.current, menuRef.current);
    }
  }, [isHighlighted, menuRef]);

  return (
    <StyledItem ref={ref} className={`${className} fs-mask`} onClick={onClick}>
      {children}
    </StyledItem>
  );
}
