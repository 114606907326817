import styled from "styled-components";
import React, { useRef, useLayoutEffect, useState, useCallback } from "react";
import throttle from "lodash/throttle";
import Message from "./Message";

const StyledContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ScrollableContent = styled.div`
  overflow: auto;
  padding: 2rem 6rem 2rem 8rem;
`;

const ALLOWED_OFFSET = 20;

export default function ChatContent({ messages }) {
  const ref = useRef(null);
  const [isScrollingPrevented, setIsScrollingPrevented] = useState(false);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (!isScrollingPrevented) {
        ref.current &&
          ref.current.scrollTo({
            top: ref.current.scrollHeight,
          });
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [messages, isScrollingPrevented]);

  const preventAutoScrolling = useCallback(
    throttle((event) => {
      const maxScrollTop =
        ref.current.scrollHeight - ref.current.offsetHeight - ALLOWED_OFFSET;

      setIsScrollingPrevented(event.target.scrollTop < maxScrollTop);
    }, 1000),
    [isScrollingPrevented, messages.length]
  );

  return (
    <StyledContent>
      <ScrollableContent ref={ref} onScroll={preventAutoScrolling}>
        {messages.map(({ role, content, json, runId }, index) => {
          return (
            <Message
              key={runId || index}
              id={runId}
              role={role}
              content={content}
              json={json}
              isMostRecent={index === messages.length - 1}
              index={index}
            />
          );
        })}
      </ScrollableContent>
    </StyledContent>
  );
}
