import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CREATE_STAGE, GET_COLLECTION } from "../../graphql";

export default function useCreateStage() {
  const [createStageMutation] = useMutation(CREATE_STAGE);

  const createStage = useCallback(
    ({ collectionId, input }) => {
      createStageMutation({
        variables: {
          collectionId,
          input,
        },
        refetchQueries: [GET_COLLECTION],
      });
    },
    [createStageMutation]
  );

  return createStage;
}
