import Popover from "../../Popover";
import MenuItem from "./MenuItem";
import { useState, useCallback, useMemo } from "react";
import ToolbarButton from "./ToolbarButton";
import { BodyMedium } from "../../Typography";
import { ChevronDown8, Sparkle16 } from "../../Icon";

export default function InlineEditButton({ editor }) {
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const items = useMemo(() => {
    return [
      { id: "rewrite", name: "Rewrite" },
      {
        id: "adjust-tone",
        name: "Adjust tone",
        submenu: [
          {
            id: "neutral",
            name: "Neutral",
          },
          { id: "friendly", name: "Friendly" },
          {
            id: "professional",
            name: "Professional",
          },
          {
            id: "casual",
            name: "Casual",
          },
        ],
      },
      { id: "spell-check", name: "Fix spelling & grammar" },
      { id: "reduce", name: "Reduce" },
      { id: "extend", name: "Extend" },
    ];
  }, [editor]);

  return (
    <Popover
      placement={"bottom-start"}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closePopover={() => setIsOpen(false)}
      activatorBufferInPx={8}
      activator={
        <ToolbarButton onClick={openMenu}>
          <Sparkle16 />
          <BodyMedium>AI Edit</BodyMedium>
          <ChevronDown8 />
        </ToolbarButton>
      }
    >
      {isOpen &&
        items.map((item) => (
          <MenuItem
            key={item.id}
            item={item}
            editor={editor}
            setIsOpen={setIsOpen}
            onClick={() => setIsOpen(false)}
          />
        ))}
    </Popover>
  );
}
