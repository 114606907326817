import { useDndContext } from "@dnd-kit/core";
import { forwardRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  getSelectedDocuments,
  selectIsCommandPaletteOpen,
  selectShouldUseDocuments,
  setSelected,
  unselect,
} from "../../../features/multiSelectSlice";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../../lib/cmd-palette-helpers";
import { COLORS } from "../../../theme";
import IconButton from "../../IconButton";
import StageSelector from "../../StageSelector/StageSelector";
import Tag from "../../Tag";
import TagSelector from "../../TagSelector";
import Checkbox from "../Checkbox";
import Editor from "./Editor";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const StyledTagSelector = styled(TagSelector)`
  top: 1rem;
  right: 1rem;
`;

const hiddenStyles = css`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

const StyledDocumentPreview = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.document.backgroundColor};
  padding: 0.75rem 1.5rem 1.5rem;
  max-height: 40rem;
  min-height: 10rem;
  user-select: none;
  justify-content: space-between;

  box-shadow: ${({ $isChecked, theme }) =>
    $isChecked
      ? `0 0.25rem 0.5rem 0 ${COLORS.BLACK050T}, 0 0 0 2px ${theme.document.borderSelected}`
      : `0 0.25rem 0.5rem 0 ${COLORS.BLACK050T}, 0 0 0 1px ${theme.document.borderColor}`};

  :hover {
    ${({ $isChecked, theme }) =>
      !$isChecked &&
      `box-shadow: 0 0.25rem 0.5rem 0 ${COLORS.BLACK050T}, 0 0 0 1px ${theme.document.hoverBorderColor};`};
  }

  :not(:hover) {
    ${StyledTagSelector} ${IconButton} {
      :not(:focus-visible) {
        opacity: 0;
      }
    }
  }
`;

const StyledCheckboxWrapper = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  transform: translateY(-50%) translateX(-50%);
`;

const StyledDocumentWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;
  outline: none;
  ${({ $isMeasuring }) => $isMeasuring && hiddenStyles};
  transition: transform 0.1s ease-out;

  :active {
    transform: scale(0.995);
  }

  :not(:hover) {
    ${StyledCheckboxWrapper} {
      :not(:focus-visible) {
        ${({ $isChecked, $showCheckboxes }) =>
          !$showCheckboxes && !$isChecked && "opacity: 0;"}
      }
    }
  }

  ${({ $isDragging }) => $isDragging && "pointer-events: none;"}
`;

const StyledBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const DocumentPreview = forwardRef(
  ({ document, tagsByCollectionId, className, style, ...rest }, ref) => {
    const { active } = useDndContext();
    const { id, tags } = document;
    const { isInbox } = useCurrentCollectionId();

    const dispatch = useDispatch();
    const isCommandPaletteOpen = useSelector(selectIsCommandPaletteOpen);
    const shouldUseDocuments = useSelector(selectShouldUseDocuments);
    const selectedDocumentIds = useSelector(getSelectedDocuments);
    const isChecked = selectedDocumentIds.includes(id);
    const [isMeasuring, setIsMeasuring] = useState(true);
    const navigate = useNavigate();

    const selectOrOpenModal = useCallback(() => {
      if (isCommandPaletteOpen) {
        dispatch(isChecked ? unselect(id) : setSelected(id));
      } else {
        navigate(id);
      }
    }, [
      dispatch,
      id,
      isChecked,
      isCommandPaletteOpen,
      navigate,
      setSelected,
      unselect,
    ]);

    return (
      <StyledDocumentWrapper
        $isDragging={Boolean(active)}
        $isChecked={isChecked}
        $showCheckboxes={shouldUseDocuments}
        $isMeasuring={isMeasuring}
        className={`${className} ${CMDK_SKIP_TARGET_CLASSNAME}`} // used for outside click with cmd palette
        ref={ref}
        style={style}
        {...rest}
      >
        <StyledDocumentPreview
          onClick={selectOrOpenModal}
          $isChecked={isChecked}
        >
          <Editor document={document} setIsMeasuring={setIsMeasuring} />
          <StyledBottom>
            {!isInbox && <StageSelector documentId={id} />}
            {tags.length > 0 &&
              tags.map((tag) => <Tag key={tag.id} name={tag.name} />)}
          </StyledBottom>
          <StyledTagSelector
            tags={tags}
            documentId={id}
            tagsByCollectionId={tagsByCollectionId}
          />
        </StyledDocumentPreview>
        <StyledCheckboxWrapper>
          <Checkbox documentId={id} isChecked={isChecked} />
        </StyledCheckboxWrapper>
      </StyledDocumentWrapper>
    );
  }
);

DocumentPreview.displayName = "DocumentPreview";

export default styled(DocumentPreview)``;
