import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import Placeholder from "@tiptap/extension-placeholder";
import debounce from "lodash/debounce";
import FrameContext from "../../contexts/FrameContext";
import { device } from "../../css-mixins/media-queries";
import { brandVoiceById } from "../../features/brandVoicesSlice";
import { ChevronLeft12, Link12 } from "../Icon";
import IconButton from "../IconButton";
import { BodyMedium, HeaderSmall, BodySmall } from "../Typography";
import BrandVoiceTitle from "./BrandVoiceTitle";
import MoreMenu from "./MoreMenu";
import useUpdateBrandVoice from "../../hooks/useUpdateBrandVoice";
import { update } from "../../features/brandVoicesSlice";
import Button from "../Button";
import GenerateFromWebsiteModal from "./GenerateFromWebsiteModal";
import useBrandVoiceGeneratorStreaming from "../../hooks/useBrandVoiceGeneratorStreaming";
import StopWriting from "../DocumentDetails/StopWriting";
import Tooltip from "../Tooltip";

const StyledPage = styled.div`
  padding: 2.5rem 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${device.desktop}) {
    grid-column: 2 / 4;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  max-width: 25rem;
  column-gap: 1rem;
  svg {
    flex-shrink: 0;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 5rem;
  column-gap: 1rem;
  user-select: none;
  ${({ $isSidebarLeftOpen }) => !$isSidebarLeftOpen && `margin-left: 8rem;`}
`;

const StyledEditor = styled.div`
  width: 100%;
  overflow: auto;

  .ProseMirror {
    width: 100%;
    outline: none;
    padding: 3rem 4rem 20rem;
    p {
      margin: 0;
      color: ${({ theme }) => theme.body.foregroundColor};
    }

    p.is-editor-empty:first-child::before {
      color: ${({ theme }) => theme.body.secondaryForegroundColor};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
`;

const CharCount = styled.div`
  position: absolute;
  right: 3rem;
  bottom: 2rem;
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
`;

const StyledHeaderSmall = styled(HeaderSmall)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
`;

const StyledBodyMedium = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
`;

const StyledContent = styled.div`
  margin-top: 4rem;
  width: 100%;
  align-self: center;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  overflow: hidden;
  margin-bottom: 3rem;
  position: relative;
`;

const StyledCopy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  user-select: none;
`;

const StyledEditorWrapper = styled.div`
  background: ${({ theme }) => theme.input.backgroundColor};
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.border.secondary};
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default function BrandVoice() {
  const { isSidebarLeftOpen } = useContext(FrameContext);
  const { brandVoiceId } = useParams();
  const brandVoice = useSelector(brandVoiceById(brandVoiceId));
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const openMenu = useCallback(() => setIsMoreMenuOpen(true), []);
  const closeMenu = useCallback(() => setIsMoreMenuOpen(false), []);
  const updateBrandVoice = useUpdateBrandVoice();
  const dispatch = useDispatch();
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

  const handleUpdate = useCallback(
    debounce(({ editor }) => {
      updateBrandVoice(
        brandVoiceId,
        {
          state: editor.getJSON(),
        },
        (data) => {
          dispatch(update(data.updateBrandVoice));
        }
      );
    }, 1000),
    [brandVoice, updateBrandVoice]
  );

  const limit = 800;
  const placeholder = "Create your own Brand Voice...";
  const editor = useEditor({
    content: brandVoice.state,
    onUpdate: handleUpdate,
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder,
      }),
      CharacterCount.configure({
        limit,
      }),
    ],
  });

  useEffect(() => {
    if (editor && brandVoice.state) {
      editor.commands.setContent(brandVoice.state);
    }
  }, [brandVoice.id]);

  useEffect(() => {
    if (editor) {
      editor.commands.focus();
    }
  }, [editor]);

  const { abortStreaming, isStreaming } = useBrandVoiceGeneratorStreaming({
    editor,
    brandVoiceId,
  });

  if (!editor || brandVoice.id === undefined) {
    return null;
  }

  return (
    <StyledPage>
      <StyledHeaderRow $isSidebarLeftOpen={isSidebarLeftOpen}>
        <StyledLeft>
          <Tooltip content="Back">
            <Link to="/brand">
              <IconButton icon={<ChevronLeft12 />} />
            </Link>
          </Tooltip>
          <BrandVoiceTitle title={brandVoice.title} />
        </StyledLeft>
        <MoreMenu
          id={brandVoiceId}
          title={brandVoice.title}
          isOpen={isMoreMenuOpen}
          setIsOpen={setIsMoreMenuOpen}
          openMenu={openMenu}
          closeMenu={closeMenu}
        />
      </StyledHeaderRow>
      <StyledContent>
        <StyledCopy>
          <StyledHeaderSmall>Brand Voice</StyledHeaderSmall>
          <StyledBodyMedium>
            Use your brand voice to create on-brand content.
          </StyledBodyMedium>
        </StyledCopy>
        <StyledButton
          Icon={Link12}
          onClick={() => setIsGenerateModalOpen(true)}
        >
          Generate from Website
        </StyledButton>
        {isGenerateModalOpen && (
          <GenerateFromWebsiteModal
            onClose={() => setIsGenerateModalOpen(false)}
            brandVoiceId={brandVoiceId}
          />
        )}
        <StyledEditorWrapper>
          <StyledEditor>
            <EditorContent editor={editor} />
          </StyledEditor>
        </StyledEditorWrapper>
        <CharCount>
          <BodySmall>
            {editor.storage.characterCount.characters()}/{limit} Characters
          </BodySmall>
        </CharCount>
        {isStreaming && (
          <>
            <Overlay />
            <StopWriting abortStreaming={abortStreaming} />
          </>
        )}
      </StyledContent>
    </StyledPage>
  );
}
