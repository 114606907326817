import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  tagsByCollectionId as tagsByCollectionIdSelector,
  documents as documentsSelector,
} from "../../../features/documentsSlice";
import DocumentPreview from "../GridView/DocumentPreview";
import withSort from "./withSort";
import { useContext } from "react";
import SortableItemsContext from "../../../contexts/SortableItemsContext";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const StyledDocumentPreview = styled(DocumentPreview)`
  margin-bottom: 0 !important;

  &:first-of-type {
    margin-top: -1rem;
  }
`;

const SortableDocumentPreview = withSort(StyledDocumentPreview);

export default function Documents({ stageId }) {
  const { collectionId } = useCurrentCollectionId();
  const documents = useSelector(documentsSelector);
  const { items } = useContext(SortableItemsContext);

  const tagsByCollectionId = useSelector(
    tagsByCollectionIdSelector(collectionId)
  );

  return items?.[stageId].map((id, index) => (
    <SortableDocumentPreview
      document={documents[id]}
      key={id}
      index={index}
      tagsByCollectionId={tagsByCollectionId}
    />
  ));
}
