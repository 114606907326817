import { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import { USER_CREATED_SUBSCRIPTION } from "../graphql";
import useGetCollections from "../hooks/useGetCollections";
import useInbox from "../hooks/useInbox";

export default function DemoContentLoader() {
  const { refetch } = useGetCollections();
  const { getInboxCollection, callback, id, refetchInbox } = useInbox();
  useSubscription(USER_CREATED_SUBSCRIPTION, {
    onData: () => {
      refetch();
      getInboxCollection({
        variables: { collectionId: id },
        fetchPolicy: "cache-and-network",
        onCompleted: callback,
      });
    },
    shouldUnsubscribe: true,
  });

  useEffect(() => {
    let timeoutId;
    if (!id) {
      timeoutId = setTimeout(() => {
        refetchInbox();
      }, 500);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [id, getInboxCollection, callback, refetchInbox]);

  return null;
}
