import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UPDATE_STAGE, GET_COLLECTION } from "../../graphql";

export default function useUpdateStage() {
  const [updateStageMutation] = useMutation(UPDATE_STAGE);

  const updateStage = useCallback(
    ({ id, input }) => {
      updateStageMutation({
        variables: {
          id,
          input,
        },
        refetchQueries: [GET_COLLECTION],
      });
    },
    [updateStageMutation]
  );

  return updateStage;
}
