import { useCallback, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import { SIDEBAR_LEFT_WIDTH_PX } from "../css-mixins/main-grid";
import { getNumberOfColumns } from "../lib/grid-view-helpers";

export default function useResizeChat({
  frameRef,
  isSidebarLeftOpen,
  modalRef,
  setNumColumns,
}) {
  const rightColRef = useRef(null);

  const [isSidebarRightOpen, saveIsSidebarRightOpen] = useLocalStorage(
    `${import.meta.env.VITE_APP_PROTOCOL}.isSidebarRightOpen`,
    true
  );

  const toggleSidebarRight = useCallback(() => {
    saveIsSidebarRightOpen((isSidebarRightOpen) => !isSidebarRightOpen);
  }, [isSidebarRightOpen, saveIsSidebarRightOpen]);

  const openSidebarRight = useCallback(() => {
    saveIsSidebarRightOpen(true);
  }, [saveIsSidebarRightOpen]);

  const [rightColWidth, setRightColWidth] = useState(() => {
    return (
      (window.innerWidth - (isSidebarLeftOpen ? SIDEBAR_LEFT_WIDTH_PX : 0)) *
      0.5
    ); // 50% of the screen minus sidebar width
  });

  const [isDragging, setIsDragging] = useState(false);
  const onDrag = useCallback(
    (e) => {
      if (!isDragging) {
        return;
      }
      e.preventDefault();

      // restrict the right col width when sidebar is open
      const xPosition =
        isSidebarLeftOpen && e.clientX < SIDEBAR_LEFT_WIDTH_PX
          ? SIDEBAR_LEFT_WIDTH_PX
          : e.clientX;

      const rightColWidth = frameRef.current.clientWidth - xPosition;
      const sidebarLeftWidth = isSidebarLeftOpen ? SIDEBAR_LEFT_WIDTH_PX : 0;
      const centerWidth = window.innerWidth - rightColWidth - sidebarLeftWidth;

      setNumColumns(getNumberOfColumns(centerWidth));

      frameRef.current.style.gridTemplateColumns = `${sidebarLeftWidth}px 1fr ${rightColWidth}px`;
      if (modalRef.current) {
        modalRef.current.style.gridTemplateColumns = `${sidebarLeftWidth}px 1fr ${rightColWidth}px`;
      }
    },
    [isDragging, isSidebarLeftOpen]
  );

  const startDrag = useCallback(() => {
    setIsDragging(true);
  }, []);

  const endDrag = useCallback(
    (e) => {
      if (isDragging) {
        flushSync(() => {
          const xPosition =
            isSidebarLeftOpen && e.clientX < SIDEBAR_LEFT_WIDTH_PX
              ? SIDEBAR_LEFT_WIDTH_PX
              : e.clientX;

          setRightColWidth(frameRef.current.clientWidth - xPosition);
          frameRef.current.style = {};
          if (modalRef.current) {
            modalRef.current.style = {};
          }
        });
        setIsDragging(false);
      }
    },
    [isDragging, isSidebarLeftOpen]
  );

  return {
    endDrag,
    isSidebarRightOpen,
    isDragging,
    onDrag,
    openSidebarRight,
    rightColRef,
    rightColWidth,
    startDrag,
    toggleSidebarRight,
  };
}
