import { gql } from "@apollo/client";

export const CREATE_CHAT = gql`
  mutation CreateChat(
    $source: SourceInput!
    $question: String!
    $mentions: [Mention]
    $documentPromptType: String
    $brandVoiceId: ID
    $promptTemplate: PromptTemplate
  ) {
    createChat(
      source: $source
      question: $question
      mentions: $mentions
      documentPromptType: $documentPromptType
      brandVoiceId: $brandVoiceId
      promptTemplate: $promptTemplate
    ) {
      chatId
    }
  }
`;

export const UPDATE_CHAT = gql`
  mutation UpdateChat(
    $chatId: ID!
    $source: SourceInput!
    $question: String!
    $mentions: [Mention]
    $documentPromptType: String
    $brandVoiceId: ID
    $promptTemplate: PromptTemplate
  ) {
    updateChat(
      chatId: $chatId
      source: $source
      question: $question
      mentions: $mentions
      documentPromptType: $documentPromptType
      brandVoiceId: $brandVoiceId
      promptTemplate: $promptTemplate
    ) {
      chatId
    }
  }
`;

export const ABORT_CHAT_STREAMING = gql`
  mutation AbortChatStreaming($chatId: ID!, $runId: String!) {
    abortChatStreaming(chatId: $chatId, runId: $runId) {
      status
    }
  }
`;

export const RESET_CHAT = gql`
  mutation ResetChat($collectionId: ID!) {
    resetChat(collectionId: $collectionId) {
      chatId
    }
  }
`;
