import { useCallback } from "react";
import styled from "styled-components";
import { buttonReset } from "../../css-mixins/button";
import { COLORS } from "../../theme";

const StyledIconButton = styled.button`
  ${buttonReset}
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 2rem;

  &:hover {
    background-color: ${COLORS.BLACK050T};
  }
`;

export default function IconButton({
  closeMenu,
  icon,
  id,
  selectedColor,
  onSelectIcon,
}) {
  const handleSelectIcon = useCallback(() => {
    closeMenu();
    onSelectIcon({ id, color: selectedColor });
  }, [closeMenu, selectedColor, id]);
  const Icon = icon;

  return (
    <StyledIconButton onClick={handleSelectIcon}>
      <Icon />
    </StyledIconButton>
  );
}
