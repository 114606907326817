import { css } from "styled-components";

export const listItemMixin = css`
  list-style: none;
  padding: 1rem 1.5rem;
  margin: 0;
  white-space: nowrap;
  border-radius: 1rem;

  :hover {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
`;
