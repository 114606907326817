import { useState, useCallback, useRef, useEffect } from "react";
import { useMutation } from "@apollo/client";
import debounce from "lodash/debounce";
import { GET_COLLECTIONS, UPDATE_COLLECTION } from "../../graphql";
import Input from "../Input";
import styled from "styled-components";
import { BodyMedium } from "../Typography";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const StyledInput = styled(Input)`
  box-sizing: content-box;
  padding: 0.5rem 0 0.5rem 1.5rem;
  min-width: ${({ value }) => (value === "" ? "8.5rem" : "0px")};
  -webkit-app-region: no-drag;

  :not(:focus) {
    ${({ $width }) => $width && `width: ${$width}px;`}
  }
  :focus {
    ${({ $width }) => $width && `width: ${$width}px;`}
  }
`;

const HiddenSpan = styled(BodyMedium)`
  opacity: 0;
  position: absolute;
  pointer-events: none;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  white-space: pre;
  padding: 0.5rem 0 0.5rem 1.5rem;
`;

export default function CollectionName({ name }) {
  const { collectionId, isInbox } = useCurrentCollectionId();
  const [updateCollectionMutation] = useMutation(UPDATE_COLLECTION);
  const [newName, setNewName] = useState(name);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const updateCollectionName = useCallback(
    async (name) => {
      await updateCollectionMutation({
        variables: { id: collectionId, input: { name } },
        refetchQueries: [GET_COLLECTIONS],
      });
    },
    [updateCollectionMutation, collectionId]
  );

  const updateCollectionNameDebounced = useCallback(
    debounce(updateCollectionName, 500),
    [updateCollectionName]
  );

  const updateName = useCallback(
    (e) => {
      setNewName(e.target.value);
      updateCollectionNameDebounced(e.target.value);
      setWidth(Math.ceil(ref.current.getBoundingClientRect().width));
    },
    [updateCollectionNameDebounced, setWidth]
  );
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setWidth(Math.ceil(entries[0].borderBoxSize[0].inlineSize));
      }
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <>
      <HiddenSpan ref={ref}>{newName}</HiddenSpan>
      <StyledInput
        value={newName}
        onChange={updateName}
        $width={width}
        disabled={isInbox}
      />
    </>
  );
}
