import styled from "styled-components";
import { listItemMixin } from "../../css-mixins/menu";
import { formatStageName } from "../../lib/stage-helpers";
import { Checkmark12 } from "../Icon";
import StageIcon from "../StageIcon";
import { BodyMedium } from "../Typography";

const StyledBodyMedium = styled(BodyMedium)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
`;

const StyledItem = styled.li`
  ${listItemMixin}
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `background-color: ${theme.button.secondary.hoverBackgroundColor};
    ${StyledBodyMedium} {
      color: ${theme.button.secondary.hoverForegroundColor};
    }`}

  &:hover {
    ${StyledBodyMedium} {
      color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }
`;
const StyledIconWrapper = styled.span`
  flex-shrink: 0;
  height: 2rem;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1.5rem;
`;

export default function Item({
  item,
  index,
  highlightedIndex,
  isSelected,
  getItemProps,
}) {
  return (
    <StyledItem
      isHighlighted={highlightedIndex === index}
      {...getItemProps({ item, index })}
    >
      <StyledLeft>
        <StyledIconWrapper>
          <StageIcon iconId={item.icon} color={item.color} />
        </StyledIconWrapper>
        <StyledBodyMedium isSelected={isSelected}>
          {formatStageName(item.name)}
        </StyledBodyMedium>
      </StyledLeft>
      {isSelected && (
        <StyledIconWrapper>
          <Checkmark12 />
        </StyledIconWrapper>
      )}
    </StyledItem>
  );
}
