import DropdownMenu from "../../Dropdown/Menu";
import Item from "../../Dropdown/MenuItem";
import EmptyState from "./EmptyState";
import { Plus12 } from "../../Icon";
import { useNavigate } from "react-router-dom";

export default function Menu({
  getItemProps,
  getMenuProps,
  highlightedIndex,
  isOpen,
  items,
  selectedItem,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/brand");
  };

  return (
    <DropdownMenu {...getMenuProps()}>
      {isOpen &&
        (items.length > 1 ? (
          items.map((item, index) => (
            <Item
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              index={index}
              isSelected={selectedItem === item}
              item={item}
              key={item.id}
            />
          ))
        ) : (
          <EmptyState
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            index={items.length}
            item={"EmptyState"}
            emptyIcon={<Plus12 />}
            onClick={handleClick}
          >
            Create a Brand Voice
          </EmptyState>
        ))}
    </DropdownMenu>
  );
}
