import EmptyState from "../Dropdown/EmptyState";
import Item from "../Dropdown/MenuItem";
import DropdownMenu from "../Dropdown/Menu";
import CreateNewItem from "./CreateNewItem";

export default function Menu({
  getItemProps,
  getMenuProps,
  highlightedIndex,
  inputValue,
  isOpen,
  items,
  selectedItems,
}) {
  return (
    <DropdownMenu {...getMenuProps()}>
      {isOpen &&
        items.map((item, index) => {
          if (item.id === "create-new") {
            return (
              <CreateNewItem
                getItemProps={getItemProps}
                index={index}
                inputValue={inputValue}
                highlightedIndex={highlightedIndex}
                item={item}
                key={item.id}
              />
            );
          }

          return (
            <Item
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              index={index}
              isSelected={selectedItems.includes(item.id)}
              item={item}
              key={item.id}
            />
          );
        })}
      {isOpen && !items.length && <EmptyState>No tags yet</EmptyState>}
    </DropdownMenu>
  );
}
