export function stopPrevent(e) {
  e.stopPropagation();
  e.preventDefault();
}

export function stopAll(e) {
  e.nativeEvent.stopImmediatePropagation();
  stopPrevent(e);
}

export function stopPropagation(e) {
  e.stopPropagation();
}

export function preventDefault(e) {
  e.preventDefault();
}
