import { useCallback, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocalStorage } from "@uidotdev/usehooks";
import FrameContext from "../contexts/FrameContext";
import { device } from "../css-mixins/media-queries";
import useResizeChat from "../hooks/useResizeChat";
import { DEFAULT_NUM_COLUMNS } from "../lib/grid-view-helpers";
import useUploader from "../hooks/useUploader";

export default function FrameProvider({ children }) {
  const overlayLayer = useRef(null);
  const frameRef = useRef(null);
  const modalRef = useRef(null);
  const isMobile = useMediaQuery({ query: device.mobile });
  const [isSidebarLeftOpen, saveIsSidebarLeftOpen] = useLocalStorage(
    `${import.meta.env.VITE_APP_PROTOCOL}.isSidebarLeftOpen`,
    !isMobile
  );
  const [numColumns, setNumColumns] = useState(DEFAULT_NUM_COLUMNS);
  const {
    endDrag,
    isSidebarRightOpen,
    isDragging,
    onDrag,
    openSidebarRight,
    rightColRef,
    rightColWidth,
    startDrag,
    toggleSidebarRight,
  } = useResizeChat({
    isSidebarLeftOpen,
    frameRef,
    modalRef,
    setNumColumns,
  });

  const [selectedView, saveSelectedView] = useLocalStorage(
    `${import.meta.env.VITE_APP_PROTOCOL}.selectedView`,
    "grid"
  );

  const toggleSidebarLeft = useCallback(() => {
    saveIsSidebarLeftOpen((isSidebarLeftOpen) => !isSidebarLeftOpen);
  }, [saveIsSidebarLeftOpen]);

  const changeView = useCallback(
    (view) => {
      saveSelectedView(view);
    },
    [saveSelectedView]
  );

  const [isNewWorkspaceModalOpen, setIsNewWorkspaceModalOpen] = useState(false);
  const openNewWorkspaceModal = useCallback(() => {
    setIsNewWorkspaceModalOpen(true);
  }, []);

  const closeNewWorkspaceModal = useCallback(() => {
    setIsNewWorkspaceModalOpen(false);
  }, []);

  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);

  const openBillingModal = useCallback(() => setIsBillingModalOpen(true), []);
  const closeBillingModal = useCallback(() => setIsBillingModalOpen(false), []);

  const [isBrandVoiceModalOpen, setIsBrandVoiceModalOpen] = useState(false);
  const openBrandVoiceModal = useCallback(
    () => setIsBrandVoiceModalOpen(true),
    []
  );
  const closeBrandVoiceModal = useCallback(
    () => setIsBrandVoiceModalOpen(false),
    []
  );

  const [isUploaderModalOpen, setIsUploaderModalOpen] = useState(false);
  const [uploaderOptions, setUploaderOptions] = useState({});
  const triggerUploader = useUploader();

  const closeUploaderModal = useCallback(
    () => setIsUploaderModalOpen(false),
    []
  );
  const openUploaderModal = useCallback((options) => {
    return () => {
      setUploaderOptions(options);
      setIsUploaderModalOpen(true);
    };
  }, []);

  return (
    <FrameContext.Provider
      value={{
        closeBillingModal,
        closeBrandVoiceModal,
        closeNewWorkspaceModal,
        endDrag,
        frameRef,
        isSidebarRightOpen,
        isDragging,
        isBillingModalOpen,
        isBrandVoiceModalOpen,
        isNewWorkspaceModalOpen,
        isUploaderModalOpen,
        closeUploaderModal,
        openUploaderModal,
        uploaderOptions,
        isSidebarLeftOpen,
        modalRef,
        numColumns,
        onDrag,
        openBillingModal,
        openBrandVoiceModal,
        openSidebarRight,
        openNewWorkspaceModal,
        overlayLayer,
        rightColRef,
        rightColWidth,
        selectedView,
        setNumColumns,
        changeView,
        startDrag,
        toggleSidebarRight,
        toggleSidebarLeft,
        triggerUploader,
      }}
    >
      {children}
    </FrameContext.Provider>
  );
}
