import styled from "styled-components";
import DefaultItem from "./DefaultItem";

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
`;

export default function Menu({ items, withSelectedIcon = false, isOpen }) {
  return (
    <StyledList>
      {isOpen &&
        items.map((item) => {
          const Item = item.customItem || DefaultItem;
          return (
            <Item
              key={item.id}
              item={item}
              withSelectedIcon={withSelectedIcon}
              customItem={item.customItem}
            />
          );
        })}
    </StyledList>
  );
}
