import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import Button from "./Button";

const StyledButton = styled(Button)`
  margin-left: 1rem;
  backdrop-filter: blur(1rem);
`;

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <StyledButton
      className="btn btn-primary btn-block"
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
          },
        })
      }
    >
      Sign Up
    </StyledButton>
  );
};

export default LoginButton;
