import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { NodeViewWrapper } from "@tiptap/react";
import { useMutation } from "@apollo/client";
import { CREATE_UPLOAD } from "../../../graphql";

const StyledImage = styled.img`
  display: flex;
  margin: auto;
  max-width: 100%;
`;

export const ImageUpload = ({ node, updateAttributes }) => {
  const { src, doUpload, alt, title } = node.attrs;

  const [createUploadMutation] = useMutation(CREATE_UPLOAD);

  const uploadImage = useCallback(
    async (file) => {
      const response = await createUploadMutation({
        variables: {
          fileName: file.name,
        },
      });
      await fetch(response.data.createUpload.uploadUrl, {
        method: "PUT",
        body: file,
      });

      return response.data.createUpload.fileUrl;
    },
    [createUploadMutation]
  );

  useEffect(() => {
    async function handleUpload(file) {
      const url = await uploadImage(file);
      updateAttributes({ src: url, doUpload: false });
      window.uploadFileStorage = null;
    }
    if (window.uploadFileStorage && !src && doUpload) {
      handleUpload(window.uploadFileStorage);
    }
  }, [uploadImage, src, doUpload]);

  return (
    <NodeViewWrapper>
      <div data-drag-handle>
        <StyledImage src={src || doUpload} alt={alt} title={title} />
      </div>
    </NodeViewWrapper>
  );
};

export default ImageUpload;
