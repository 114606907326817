import styled, { css } from "styled-components";

const hoverStyles = css`
  color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  background-color: ${({ theme }) =>
    theme.button.secondary.hoverBackgroundColor};
  a {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    padding-right: 3rem;
    svg path {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }
`;

const draggingStyles = css`
  background-color: ${({ theme }) => theme.body.secondaryBackgroundColor};
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3.5rem;
  margin-bottom: 1px;
  user-select: none;
  position: relative;
  overflow: hidden;
  transition: transform 0.1s ease-out;
  white-space: nowrap;

  svg path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }

  a {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem 1.5rem;
    color: ${({ theme }) => theme.button.secondary.foregroundColor};
    text-decoration: none;
    font-weight: ${({ theme }) => theme.typography.bodyMedium.fontWeight};
    font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
    line-height: ${({ theme }) => theme.typography.bodyMedium.lineHeight};
    overflow: hidden;
  }

  a.active {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    border-radius: 3.5rem;
    svg path {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
    ${({ $isDragging }) => $isDragging && draggingStyles}
  }

  :hover {
    ${hoverStyles}
  }

  :active {
    transform: scale(0.99);
  }

  ${({ $isMoreMenuOpen }) => $isMoreMenuOpen && hoverStyles}
`;

export default ListItem;
