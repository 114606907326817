import styled from "styled-components";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { GET_COLLECTION } from "../../graphql/queries";
import { collaborators } from "../../features/collectionsSlice";
import { INVITE_USERS } from "../../graphql/mutations";
import { buttonReset } from "../../css-mixins/button";
import { COLORS } from "../../theme";
import { BodyMedium } from "../Typography";
import CopyLinkButton from "./CopyLinkButton";
import User from "./User";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.BLACK500T};
`;

const StyledModal = styled.div`
  padding: 1.5rem;
  max-width: 50rem;
  flex: 1;
  border-radius: 2.5rem;
  background: ${({ theme }) => theme.modal.backgroundColor};
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
`;

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.input.backgroundColor};
`;

const StyledBodyMedium = styled(BodyMedium)`
  color: ${({ theme }) => theme.button.primary.foregroundColor};
`;

const StyledButton = styled.button`
  ${buttonReset}
  padding: 0.375rem 0.875rem;
  border-radius: 1rem;
  margin: 0.875rem;
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  transition: transform 0.1s ease-out;

  &:active {
    transform: scale(0.98);
  }

  :hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hoverBackgroundColor};
    ${StyledBodyMedium} {
      color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    }
  }
`;

const StyledInput = styled.input`
  flex: 1;
  outline: none;
  padding: 1rem 2rem;
  border: none;
  border-radius: 1.5rem;
  font-weight: ${({ theme }) => theme.typography.bodyLarge.fontWeight};
  font-size: ${({ theme }) => theme.typography.bodyLarge.fontSize};
  line-height: ${({ theme }) => theme.typography.bodyLarge.lineHeight};
  background: transparent;
  color: ${({ theme }) => theme.input.foregroundColor};
`;

const StyledDivider = styled.div`
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.border.primary};
  margin: 0 -2rem 1.5rem;
`;

const StyledAccessListWrapper = styled.div`
  padding: 1rem 1rem;
  margin: 0 -1.5rem;
  min-height: 20rem;
  max-height: 50vh;
  overflow-y: scroll;
`;

export default function ShareModal({ onClose }) {
  const { user } = useAuth0();
  const { collectionId } = useCurrentCollectionId();
  const accessList = useSelector(collaborators(collectionId));

  const [inviteUsersMutation] = useMutation(INVITE_USERS);

  const submitInvite = useCallback(
    (e) => {
      e.preventDefault();
      inviteUsersMutation({
        variables: {
          collectionId,
          emails: [e.target.share.value],
        },
        refetchQueries: [GET_COLLECTION],
      });

      e.target.share.value = "";
    },
    [collectionId, inviteUsersMutation]
  );

  return (
    <StyledModalWrapper>
      <Overlay onClick={onClose} id="share-modal-overlay" />
      <StyledModal onClose={onClose}>
        <StyledForm onSubmit={submitInvite}>
          <StyledInput
            name="share"
            type="email"
            placeholder="Enter an email..."
          />
          <StyledButton type="submit">
            <StyledBodyMedium>Send Invite</StyledBodyMedium>
          </StyledButton>
        </StyledForm>
        {user && (
          <>
            <StyledAccessListWrapper>
              {accessList.map((user) => (
                <User key={user.id} user={user} />
              ))}
            </StyledAccessListWrapper>
          </>
        )}
        <StyledDivider />
        <CopyLinkButton />
      </StyledModal>
    </StyledModalWrapper>
  );
}
