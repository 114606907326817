import styled from "styled-components";
import { BodySmall } from "../../Typography";

const StyledInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  width: 100%;
  color: ${({ theme }) => theme.input.foregroundColor};
  font-size: ${(props) => props.theme.typography.bodyLarge.fontSize};
  line-height: ${(props) => props.theme.typography.bodyLarge.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.cmdk.foregroundColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  user-select: none;
`;

const StyledInputWrapper = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.input.backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.5rem;

  :hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.input.borderColor};
  }

  :focus-within {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.input.borderColor};
  }
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledIconWrapper = styled.span`
  display: flex;
  & svg path {
    fill: ${({ theme }) => theme.input.placeholderColor};
  }
`;

export default function Input({
  label,
  id,
  placeholder,
  value,
  onChange,
  icon,
  ...rest
}) {
  return (
    <StyledInputWrapper>
      {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
      <StyledRightWrapper>
        <label htmlFor={id}>
          <StyledBodySmall>{label}</StyledBodySmall>
        </label>
        <StyledInput
          type="text"
          value={value}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
      </StyledRightWrapper>
    </StyledInputWrapper>
  );
}
