import styled from "styled-components";
import { icons } from "../lib/icon-selector-helpers";
import { StatusIdea16 } from "./Icon";

const StyledIcon = styled.span`
  svg * {
    fill: ${({ $color }) => $color};
    fill-opacity: 1;
  }
  height: 2rem;
  width: 2rem;
`;

export default function StageIcon({ iconId, color }) {
  const Icon = icons[iconId]?.icon || StatusIdea16;

  return (
    <StyledIcon $color={color}>
      <Icon />
    </StyledIcon>
  );
}
