import styled from "styled-components";
import { QuestionMarkCircle12 } from "../Icon";
import { BodyMedium } from "../Typography";
import ListItem from "./ListItem";

const StyledIcon = styled(QuestionMarkCircle12)`
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledIntercomButton = styled.div`
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex: 1;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  white-space: nowrap;
  :hover {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    svg path {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }
`;

export default function IntercomButton() {
  return (
    <ListItem>
      <StyledIntercomButton id="strut_intercom_launcher">
        <StyledIcon />
        <BodyMedium>Help & Support</BodyMedium>
      </StyledIntercomButton>
    </ListItem>
  );
}
