import { useSubscription } from "@apollo/client";
import { useCallback } from "react";
import {
  CHAT_REPLY_ENDED,
  CHAT_REPLY_STARTED,
  CHAT_REPLY_STREAM,
} from "../../graphql";
import Sentry from "../../integrations/sentry";

export default function ChatStreaming({ setMessages, setIsTyping, chatId }) {
  const onError = useCallback(
    (error) => {
      Sentry.captureException(error);
      setIsTyping(false);
    },
    [setIsTyping]
  );

  useSubscription(CHAT_REPLY_STARTED, {
    variables: { chatId },
    onError,
    onData: ({ data }) => {
      setIsTyping(false);
      setMessages((messages) => [
        ...messages,
        {
          role: "assistant",
          runId: data.data.chatReplyStarted.runId,
          content: "",
        },
      ]);
    },
  });

  useSubscription(CHAT_REPLY_STREAM, {
    variables: { chatId },
    onError,
    onData: ({ data }) => {
      setIsTyping(true);
      setMessages((messages) => {
        // fallback if CHAT_REPLY_STARTED is not received
        if (
          messages.filter((message) => message.runId !== data.data.runId)
            .length === 0
        ) {
          return [
            ...messages,
            {
              role: "assistant",
              runId: data.data.chatReplyStream.runId,
              content: data.data.chatReplyStream.content,
            },
          ];
        }
        return messages.map((message) => {
          if (message.runId === data.data.chatReplyStream.runId) {
            return {
              ...message,
              content: message.content + data.data.chatReplyStream.content,
            };
          }
          return message;
        });
      });
    },
  });

  useSubscription(CHAT_REPLY_ENDED, {
    variables: { chatId },
    onError,
    onData: () => {
      setIsTyping(false);
    },
  });
}
