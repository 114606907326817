import { useCallback, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import COLORS from "../../theme/colors";
import FrameContext from "../../contexts/FrameContext";

import { BodyLarge, BodyMedium } from "../Typography";

const StyledBodyLarge = styled(BodyLarge)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  user-select: none;
`;

const StyledBodyMedium = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  margin-bottom: 1rem;
  user-select: none;
  a {
    color: ${({ theme }) => theme.body.secondaryForegroundColor};
    text-decoration: underline;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.BLACK500T};
`;

const StyledModal = styled.div`
  padding: 3rem 4rem;
  max-width: 70rem;
  border-radius: 2rem;
  flex: 1;
  background: ${({ theme }) => theme.body.secondaryBackgroundColor};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  gap: 1rem;
`;

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export default function ModalForm({
  onClose,
  header,
  description,
  onSubmit,
  children,
}) {
  const closeModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const shortcuts = useCallback(
    (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", shortcuts);
    return () => {
      window.removeEventListener("keydown", shortcuts);
    };
  }, [shortcuts]);

  const { overlayLayer } = useContext(FrameContext);

  return (
    overlayLayer.current &&
    createPortal(
      <StyledModalWrapper>
        <Overlay onClick={closeModal} />
        <StyledModal>
          <StyledBodyLarge>{header}</StyledBodyLarge>
          <StyledBodyMedium>{description}</StyledBodyMedium>
          <form onSubmit={onSubmit}>{children}</form>
        </StyledModal>
      </StyledModalWrapper>,
      overlayLayer.current
    )
  );
}
