import { useCallback, useState } from "react";
import styled from "styled-components";
import { buttonReset } from "../../../css-mixins/button";
import { Plus12 } from "../../Icon";
import { BodyMedium } from "../../Typography";
import CreateStageInput from "./CreateStageInput";

const StyledCreateStage = styled.button`
  ${buttonReset}
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  flex: 1;
  column-gap: 1rem;

  :hover {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};

    svg * {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }
`;

const StyledIconWrapper = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  svg * {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  width: 100%;
`;

export default function CreateStage() {
  const [showInput, setShowInput] = useState(false);
  const handleShowInput = useCallback(() => setShowInput(true), []);
  const handleHideInput = useCallback(() => setShowInput(false), []);

  return (
    <StyledColumnHeader>
      {showInput ? (
        <CreateStageInput hideInput={handleHideInput} />
      ) : (
        <StyledCreateStage onClick={handleShowInput}>
          <StyledIconWrapper>
            <Plus12 />
          </StyledIconWrapper>
          <BodyMedium>Add a Stage</BodyMedium>
        </StyledCreateStage>
      )}
    </StyledColumnHeader>
  );
}
