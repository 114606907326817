import { useMutation } from "@apollo/client";
import Link from "@tiptap/extension-link";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useCallback, useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { defaultStageByCollectionId } from "../features/collectionsSlice";
import { addDocument } from "../features/documentsSlice";
import { CREATE_DOCUMENT, GET_COLLECTION, IMPORT_DOCUMENT } from "../graphql";

const extensions = [StarterKit, Link];

export default function useUploader() {
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [options, setOptions] = useState(null);
  const match = useMatch("/c/:collectionId/*");
  const collectionId = match?.params?.collectionId;
  const defaultStage = useSelector(defaultStageByCollectionId(collectionId));
  const [importDocumentMutation] = useMutation(IMPORT_DOCUMENT);

  const [createDocumentMutation] = useMutation(CREATE_DOCUMENT);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editor = useEditor({
    extensions,
    editable: false,
  });

  const createDocument = useCallback(
    async (name) => {
      await createDocumentMutation({
        variables: {
          collectionId,
          input: {
            name,
            snapshot: editor.getJSON(),
            stageId: defaultStage.id,
            ...(options || {}),
          },
        },
        refetchQueries: [GET_COLLECTION],
        onCompleted: (data) => {
          dispatch(
            addDocument({ collectionId, document: data.createDocument })
          );
          navigate(`c/${collectionId}/${data.createDocument.id}`);
        },
      });
    },
    [navigate, editor, options, defaultStage]
  );

  const importDocument = useCallback(
    async (file, accessToken, name) => {
      await importDocumentMutation({
        variables: {
          file,
          accessToken,
        },
        onCompleted: async (data) => {
          editor?.commands.setContent(data.importDocument.htmlContent);

          await createDocument(name);
        },
      });
    },
    [importDocumentMutation, editor, createDocument]
  );

  const [openPicker, authResponse] = useDrivePicker();
  const openUploader = useCallback((options) => {
    setOptions(options);
    openPicker({
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      developerKey: import.meta.env.VITE_GOOGLE_DEVELOPER_KEY,
      viewId: "DOCUMENTS",
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: false,
      callbackFunction: (data) => {
        if (data.docs?.length) {
          setSelectedDoc(data.docs[0]);
        } else {
          setSelectedDoc(null);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (selectedDoc && authResponse) {
      importDocument(
        selectedDoc.id,
        authResponse["access_token"],
        selectedDoc.name
      );
      setSelectedDoc(null);
    }
  }, [selectedDoc, authResponse, importDocument]);

  return openUploader;
}
