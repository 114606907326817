import { useDndContext } from "@dnd-kit/core";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SortableItemsContext from "../../../contexts/SortableItemsContext";
import { COLORS } from "../../../theme";
import ColumnHeader from "./ColumnHeader";

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 31.25rem;
  background-color: ${({ theme }) => theme.body.subtleBackgroundColor};
  flex-shrink: 0;
  border-radius: 2rem;
  height: fit-content;
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

const StyledDocuments = styled.div`
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
  ${({ $shouldLockScroll }) => !$shouldLockScroll && `overflow-y: auto;`}
  padding: 1rem;

  ${({ $documentCount }) => $documentCount === 0 && "display: none;"}
`;

const LinearGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    ${COLORS.BLACK100T} 100%
  );
  height: 6.25rem;
  pointer-events: none;
`;

const StyledHiddenSpan = styled.span``;

export default function Column({ stage, style, shouldLockScroll, children }) {
  const { items } = useContext(SortableItemsContext);
  const { active } = useDndContext();
  const scrollRef = useRef(null);
  const spanRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    function onIntersect(entries) {
      entries.forEach((entry) => {
        setIsScrolled(!entry.isIntersecting);
      });
    }

    const observer = new window.IntersectionObserver(onIntersect, {
      root: scrollRef.current,
    });

    spanRef.current && observer.observe(spanRef.current);

    return () => observer.disconnect();
  }, [scrollRef, spanRef]);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    // ref changes size on load
    const observer = new ResizeObserver((entries) => {
      setHasOverflow(
        entries[0].target.scrollHeight > entries[0].target.clientHeight
      );
    });
    scrollElement && observer.observe(scrollRef.current);
    return () => scrollElement && observer.unobserve(scrollElement);
  }, [scrollRef]);

  if (!items[stage.id]) {
    return null;
  }

  const documentCount = items[stage.id]?.length || 0;

  return (
    <StyledColumn style={style}>
      <ColumnHeader isScrolled={isScrolled && !active} stage={stage} />
      <StyledDocuments
        ref={scrollRef}
        $documentCount={documentCount}
        $shouldLockScroll={shouldLockScroll}
      >
        <StyledHiddenSpan ref={spanRef} />
        {children}
      </StyledDocuments>
      {hasOverflow && !active && <LinearGradient />}
    </StyledColumn>
  );
}
