import { useCallback, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { stopPropagation } from "../../lib/event-helpers";
import {
  colors,
  iconsByRow,
  invertIconColor,
} from "../../lib/icon-selector-helpers";
import COLORS from "../../theme/colors";
import IconButton from "../IconButton";
import Popover from "../Popover";
import StageIcon from "../StageIcon";
import ColorButton from "./ColorButton";
import SelectIconButton from "./IconButton";
import { themeSelector } from "../../features/uiSlice";

const StyledIconButton = styled(IconButton)`
  & svg * {
    fill-opacity: 1;
  }
`;

const StyledColors = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 0.5rem;
`;

const StyledIcons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;

  & svg * {
    fill: ${({ $color }) => $color};
    fill-opacity: 1;
  }
`;

const Divider = styled.div`
  margin: 0.5rem -0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.border.secondary};
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export default function IconSelector({ iconId, color, onSelectIcon }) {
  const [selectedColor, setSelectedColor] = useState(color || COLORS.BLACK500T);
  const [isOpen, setIsOpen] = useState(false);
  const openMenu = useCallback((e) => {
    e.preventDefault();
    setIsOpen(true);
  }, []);
  const closeMenu = useCallback(() => setIsOpen(false), []);
  const theme = useSelector(themeSelector);

  const toggleMenu = useCallback(() => {
    setIsOpen((isOpen) => {
      if (isOpen) {
        // reset color
        setSelectedColor(color || COLORS.BLACK500T);
      }
      return !isOpen;
    });
  }, []);

  return (
    <div onClick={stopPropagation}>
      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        setIsOpen={toggleMenu}
        closePopover={() => setIsOpen(false)}
        activator={
          <StyledIconButton
            shouldPreventFill
            $isOpen={isOpen}
            aria-label="Icon menu"
            icon={
              <StageIcon
                iconId={iconId}
                color={invertIconColor(color, theme)}
              />
            }
            onClick={openMenu}
          />
        }
      >
        <StyledColors>
          {colors.map((color) => (
            <ColorButton
              key={color}
              displayColor={invertIconColor(color, theme)}
              color={color}
              isSelected={selectedColor === color}
              setSelectedColor={setSelectedColor}
            />
          ))}
        </StyledColors>
        <Divider />
        <StyledIcons $color={invertIconColor(selectedColor, theme)}>
          {iconsByRow.map((row, index) => (
            <StyledRow key={index}>
              {row.map(({ icon, id }) => (
                <SelectIconButton
                  closeMenu={closeMenu}
                  icon={icon}
                  id={id}
                  key={id}
                  onSelectIcon={onSelectIcon}
                  selectedColor={selectedColor}
                />
              ))}
            </StyledRow>
          ))}
        </StyledIcons>
      </Popover>
    </div>
  );
}
