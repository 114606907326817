import { useDroppable } from "@dnd-kit/core";
import { useContext } from "react";
import { COLORS } from "../../../theme";
import FrameContext from "../../../contexts/FrameContext";

const styles = {
  kanban: COLORS.BLACK100T,
  list: COLORS.BLACK050T,
  grid: COLORS.BLACK050T,
};

export default function withDrop(Component) {
  function DroppableComponent({
    stage,
    workspace,
    children,
    disabled,
    ...rest
  }) {
    const { selectedView } = useContext(FrameContext);
    const id = stage
      ? `droppable-${stage.id}`
      : `droppable-workspace-${workspace.id}`;

    const { isOver, setNodeRef } = useDroppable({
      id,
      data: {
        stageId: stage?.id,
        collectionId: workspace?.id,
      },
    });

    const style = {
      backgroundColor: isOver ? styles[selectedView] : undefined,
    };

    return (
      <div ref={disabled ? undefined : setNodeRef}>
        <Component
          style={style}
          stage={stage}
          workspace={workspace}
          isOver={isOver}
          {...rest}
        >
          {children}
        </Component>
      </div>
    );
  }

  return DroppableComponent;
}
