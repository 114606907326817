import { useParams } from "react-router-dom";
import { useEffect, useContext, useCallback } from "react";
import randomColor from "randomcolor";
import { useEditor, EditorContent } from "@tiptap/react";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import DocumentContext from "../../contexts/DocumentContext";
import StreamingContext from "../../contexts/StreamingContext";
import StyledEditor from "./StyledEditor";
import defaultExtensions from "./defaultExtensions";
import Toolbar from "./Toolbar";
import { removeDocument as removeDocumentAction } from "../../features/documentsSlice";
import {
  setIsTyping,
  setCounts,
  setSelectedCounts,
} from "../../features/uiSlice";
import { GET_COLLECTION, DELETE_DOCUMENT } from "../../graphql";
import { handleImageDrop } from "../../lib/tiptap-helpers";

const calculateCounts = (text) => {
  const wordCount = text.split(/\s+/).filter(Boolean).length;
  const characterCount = text.length;

  return { wordCount, characterCount };
};

export default function Editor({
  provider,
  document,
  isProviderSynced,
  scale,
  isTextFocused,
  isScaledUp,
  paddingOffset,
}) {
  const { documentId } = useParams();
  const { user } = useAuth0();
  const { setEditor } = useContext(DocumentContext);
  const { setEditor: setStreamingEditor } = useContext(StreamingContext);
  const dispatch = useDispatch();

  const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT);

  const editor = useEditor({
    extensions: [
      ...defaultExtensions({ useImageUpload: true }),
      Collaboration.configure({
        document,
      }),
      CollaborationCursor.configure({
        provider,
        user: {
          color: randomColor({
            luminosity: "light",
            alpha: 1,
            format: "hex",
          }),
          name: user.name,
        },
      }),
    ],
    editorProps: {
      handleDrop: handleImageDrop(),
    },
  });

  const removeDocument = useCallback(async (id) => {
    dispatch(removeDocumentAction({ id }));
    await deleteDocumentMutation({
      variables: { id },
      refetchQueries: [GET_COLLECTION],
    });
  }, []);

  useEffect(() => {
    return () => {
      const content = editor?.getJSON();

      if (
        isProviderSynced &&
        content?.content?.length === 1 &&
        !content?.content[0]?.content
      ) {
        removeDocument(documentId);
      }
    };
  }, [documentId, editor, isProviderSynced]);

  useEffect(() => {
    setEditor(editor);
    setStreamingEditor(editor);
    return () => {
      setEditor(null);
      setStreamingEditor(null);
    };
  }, [editor, setEditor, setStreamingEditor]);

  useEffect(() => {
    if (editor && isProviderSynced) {
      setTimeout(() => {
        const content = editor.getJSON();
        const isEmpty =
          content?.content?.length === 1 &&
          content?.content[0]?.content === undefined;
        if (isEmpty) {
          editor.commands.focus();
        }
      }, 100);
    }
  }, [documentId, editor, isProviderSynced]);

  useEffect(() => {
    return () => provider.disconnect();
  }, [provider]);

  const updateCounts = useCallback(
    debounce(() => {
      const text = editor.getText();
      const { wordCount, characterCount } = calculateCounts(text);
      const readingTime = Math.ceil(wordCount / (200 / 60));

      dispatch(setCounts({ wordCount, characterCount, readingTime }));
    }, 500),
    [editor, dispatch]
  );

  const handleSelectionUpdate = debounce(() => {
    if (!editor) return;
    const { selection, doc } = editor.state;
    const selectedText = doc.textBetween(selection.from, selection.to, " ");
    const counts = calculateCounts(selectedText);
    dispatch(setSelectedCounts(counts));
  }, 500);

  useEffect(() => {
    if (!editor) return;

    editor.on("selectionUpdate", handleSelectionUpdate);

    return () => editor?.off("selectionUpdate", handleSelectionUpdate);
  }, [editor, dispatch]);

  useEffect(() => {
    if (editor && isProviderSynced) {
      updateCounts();

      const handler = ({ transaction }) => {
        if (transaction?.updated > 0) {
          dispatch(setIsTyping(true));
          updateCounts();
        }
      };

      editor.on("update", handler);

      return () => {
        editor.off("update", handler);
        updateCounts.cancel();
      };
    }
  }, [editor, dispatch, isProviderSynced, updateCounts]);

  const mouseMoveHandler = useCallback(() => {
    dispatch(setIsTyping(false));
  }, [dispatch, setIsTyping]);

  useEffect(() => {
    window.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      window.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [mouseMoveHandler]);

  return (
    <StyledEditor
      $paddingOffset={paddingOffset}
      scale={scale}
      isTextFocused={isTextFocused}
      isScaledUp={isScaledUp}
    >
      {editor && <Toolbar editor={editor} documentId={documentId} />}
      <EditorContent editor={editor} document={documentId} />
    </StyledEditor>
  );
}
