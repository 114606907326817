import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  documents: [],
  collectionId: null,
};

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    inboxCollectionFetched: (state, action) => {
      state.collectionId = action.payload.id;
      return state;
    },
  },
});

// actions
export const { inboxCollectionFetched } = inboxSlice.actions;

// selectors
export function inbox(state) {
  return state.inbox;
}

export const getInboxCollectionId = createSelector(
  [inbox],
  (inbox) => inbox.collectionId
);

export default inboxSlice.reducer;
