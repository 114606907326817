import { useEffect } from "react";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { documentsByCollectionId } from "../features/documentsSlice";
import { useEditor } from "@tiptap/react";
import { basicExtensions } from "./Tiptap/defaultExtensions";
import ImageWithUpload from "./Editor/Image/extension";

export default function ExportCollection({ collectionId, callback }) {
  const documents = useSelector(documentsByCollectionId(collectionId));
  const editor = useEditor({
    editable: false,
    content: "",
    extensions: [...basicExtensions, ImageWithUpload],
  });
  useEffect(() => {
    if (editor?.storage && documents.length) {
      documents.forEach((doc) => {
        editor.commands.setContent(doc.snapshot);
        const markdownOutput = editor?.storage.markdown.getMarkdown();
        if (markdownOutput) {
          const fileName =
            doc?.name.replace(/[^a-zA-Z0-9]/g, "") || "export-" + doc.id;
          saveAs(
            new Blob([markdownOutput], { type: "text/plain" }),
            `${fileName}.md`
          );
        }
      });
      callback();
    }
  }, [editor]);

  return null;
}
