import { Routes, Route } from "react-router-dom";
import Collection from "./Collection";
import CommandPalette from "./CommandPalette";
import DocumentDetails from "./DocumentDetails";
import Settings from "./Settings/Settings";
import DocumentProvider from "../providers/DocumentProvider";
import StreamingProvider from "../providers/StreamingProvider";

export default function CollectionRoute({ isSidebarLeftOpen }) {
  return (
    <DocumentProvider>
      <Collection isSidebarLeftOpen={isSidebarLeftOpen} />
      <Routes>
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/:documentId"
          element={
            <StreamingProvider>
              <DocumentDetails />
            </StreamingProvider>
          }
        />
      </Routes>
      <CommandPalette />
    </DocumentProvider>
  );
}
