import styled from "styled-components";
import { isToday, format, parseISO } from "date-fns";
import IconButton from "./IconButton";
import Tooltip from "./Tooltip";
import { Plus12, Calendar16 } from "./Icon";
import { device } from "../css-mixins/media-queries";

const StyledGroupHeader = styled.div`
  padding: 2rem 3rem 2rem 5rem;
  color: ${({ theme }) => theme.body.foregroundColor};
  font-size: 14px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.border.secondary};
  flex-direction: row;
  align-items: center;
  position: sticky;
  height: 7.6rem;
  top: 0;
  z-index: 1;
  column-gap: 2.2rem;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.body.gradient.start} 50%,
    ${({ theme }) => theme.body.gradient.end} 100%
  );
  backdrop-filter: blur(1rem);
  user-select: none;

  @media (${device.mobile}) {
    padding: 2rem 3rem 2rem 3rem;
  }
`;

const DateBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  min-width: 2.5rem;
  position: relative;
`;

const DateText = styled.p`
  font-size: 7px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  padding-top: 3px;
`;

const StyledDate = styled.span`
  flex-grow: 4;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
`;

const StyledIcon = styled(Calendar16)`
  position: absolute;
  top: 0px;
  path {
    fill: ${({ theme }) => theme.body.secondaryForegroundColor};
  }
`;

export default function DateHeader({ date, handleCreateNewDocument }) {
  const isoDate = parseISO(date);
  return (
    <StyledGroupHeader>
      <DateBox>
        <DateText>{format(isoDate, "d")}</DateText> <StyledIcon />
      </DateBox>
      <StyledDate>
        {isToday(isoDate) && "Today, "}
        {format(isoDate, "MMM d")}
      </StyledDate>
      {handleCreateNewDocument && (
        <Tooltip content="New Doc">
          <IconButton icon={<Plus12 />} onClick={handleCreateNewDocument} />
        </Tooltip>
      )}
    </StyledGroupHeader>
  );
}
