import styled from "styled-components";
import { useSelector } from "react-redux";
import { collaborators } from "../../features/collectionsSlice";
import { buttonReset } from "../../css-mixins/button";
import { COLORS } from "../../theme";
import { BodyMedium } from "../Typography";
import Avatar from "../Avatar";
import Tooltip from "../Tooltip";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const StyledButton = styled.button`
  ${buttonReset}
  border-radius: 2rem;
  color: ${({ theme }) => theme.button.primary.foregroundColor};
  padding: 0.5rem 1.5rem;
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  transition: transform 0.1s ease-out;

  &:active {
    transform: scale(0.98);
  }
  &:hover {
    background: ${({ theme }) => theme.button.primary.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
  }
`;

const StyledAvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  :not(:last-of-type) {
    mask: radial-gradient(
      circle 13px at 31px 10px,
      transparent 99%,
      ${COLORS.WHITE} 100%
    );
  }
`;

const StyledNumber = styled(BodyMedium)`
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  margin: 0 0.5rem;
`;

const StyledAvatarLayout = styled.button`
  ${buttonReset}
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  border-radius: 1.75rem;
  transition: transform 0.1s ease-out;
  :hover {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
    ${StyledNumber} {
      color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }
  &:active {
    transform: scale(0.98);
  }
`;

export default function Collaborators({ openModal }) {
  const { collectionId } = useCurrentCollectionId();
  const accessList = useSelector(collaborators(collectionId));

  if (accessList.length < 2) {
    return (
      <Tooltip content="Share Workspace">
        <StyledButton onClick={openModal}>
          <BodyMedium>Share</BodyMedium>
        </StyledButton>
      </Tooltip>
    );
  }

  const collaboratorsList =
    accessList.length > 4 ? accessList.slice(0, 3) : accessList;

  const remainder = accessList.length - collaboratorsList.length;

  return (
    <Tooltip content="Share Workspace">
      <StyledAvatarLayout onClick={openModal}>
        {collaboratorsList.map(({ id, picture }) => (
          <StyledAvatarWrapper key={id}>
            <Avatar size="small" src={picture} />
          </StyledAvatarWrapper>
        ))}
        {remainder > 0 && <StyledNumber>+{remainder}</StyledNumber>}
      </StyledAvatarLayout>
    </Tooltip>
  );
}
