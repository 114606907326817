import styled from "styled-components";
import { useCallback, useState, useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CustomMention from "./MentionExtension";
import Placeholder from "@tiptap/extension-placeholder";
import { generateText } from "@tiptap/core";
import suggestion from "./MentionList/suggestion";
import { CREATE_CHAT, UPDATE_CHAT } from "../../graphql";
import COLORS from "../../theme/colors";
import { buttonReset } from "../../css-mixins/button";
import { ArrowUp12 } from "../Icon";
import { collectionById } from "../../features/collectionsSlice";
import { documentById } from "../../features/documentsSlice";
import { getAllMentions } from "../../lib/tiptap-helpers";
import useCmdPaletteWithChat from "../../hooks/useCmdPaletteWithChat";
import ContextSelector from "./ContextSelector";
import ChatShortcuts from "./ChatShortcuts";
import BotTyping from "./BotTyping";
import { BodySmall } from "../Typography";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const StyledError = styled(BodySmall)`
  padding: 1rem;
  color: ${COLORS.CRIMSON500};
`;

const StyledSubmitButton = styled.button`
  ${buttonReset}
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  flex-shrink: 0;
  margin: 0 1.5rem 1.5rem 0;
  transition: transform 0.1s ease-out;
  &:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hoverBackgroundColor};

    path {
      fill: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    }
  }
  &:active {
    transform: scale(0.95);
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.input.backgroundColor};
  border-radius: 3rem;
`;

const StyledFormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`;

const StyledInputWrapper = styled.div`
  margin: 0 4rem 4rem;
`;

const StyledIcon = styled(ArrowUp12)`
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledEditor = styled.div`
  width: 100%;

  .ProseMirror {
    width: 100%;
    outline: none;
    padding: 1.5rem 2.5rem 1.5rem;
    color: ${({ theme }) => theme.input.foregroundColor};

    p {
      margin: 0;
    }
    p.is-editor-empty {
      height: ${({ $placeholderHeight }) =>
        $placeholderHeight ? `${$placeholderHeight}px` : "auto"};
    }
    p.is-editor-empty:first-child::before {
      color: ${({ theme }) => theme.input.placeholderColor};
      content: attr(data-placeholder);
      height: 0;
      pointer-events: none;
      position: ${({ $placeholderHeight }) =>
        $placeholderHeight ? "absolute" : "relative"};
    }
  }

  .mention {
    color: ${COLORS.OCEAN500};
  }
`;

export default function Input({
  setMessages,
  setChatId,
  chatId,
  setIsTyping,
  lastRunId,
  isTyping,
  rightColWidth,
}) {
  const params = useParams();
  const { collectionId } = useCurrentCollectionId();
  const collection = useSelector(collectionById(collectionId));
  const currentDocumentId = params["*"];
  const document = useSelector(documentById(currentDocumentId));
  const [focusedDocumentId, setFocusedDocumentId] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setFocusedDocumentId(currentDocumentId || false);
  }, [currentDocumentId]);

  const isDocumentFocused = document && focusedDocumentId === document?.id;
  const focusedName = isDocumentFocused
    ? document?.name || "Untitled"
    : collection.name || "Untitled";

  const placeholder = `Send a message about ${focusedName}`;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder,
      }),
      ChatShortcuts,
      CustomMention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
        renderLabel({ node }) {
          return node.attrs.label;
        },
      }),
    ],
  });

  // updates placeholder if it changes
  useEffect(() => {
    if (editor !== null && placeholder !== "") {
      editor.extensionManager.extensions.filter(
        (extension) => extension.name === "placeholder"
      )[0].options["placeholder"] = placeholder;
      editor.view.dispatch(editor.state.tr);
    }
  }, [editor, placeholder]);

  const [createChat] = useMutation(CREATE_CHAT);
  const [updateChat] = useMutation(UPDATE_CHAT);

  const currentFocusedDocument = isDocumentFocused && focusedDocumentId;
  const createOrUpdateChat = useCallback(
    (e, options) => {
      e?.preventDefault();
      setIsTyping(true);

      const mentions = getAllMentions(editor.state.doc);
      const source = { collectionId };
      if (currentFocusedDocument) {
        source.documentId = currentFocusedDocument;
      }
      const json = editor.getJSON();
      const question = generateText(json, [StarterKit, CustomMention]);
      setMessages((messages) => [
        ...messages,
        { role: "human", content: question, json },
      ]);
      if (!chatId || options?.resetHistory) {
        createChat({
          variables: {
            source,
            question,
            mentions,
            documentPromptType: options?.documentPromptType,
            brandVoiceId: options?.brandVoiceId,
            promptTemplate: options?.promptTemplate,
          },
          onCompleted: (data) => {
            setChatId(data.createChat.chatId);
          },
          onError: (err) => {
            setIsTyping(false);
            setError(err.message);
            console.error(err);
          },
        });
      } else {
        updateChat({
          variables: {
            chatId,
            source,
            mentions,
            question,
            documentPromptType: options?.documentPromptType,
            brandVoiceId: options?.brandVoiceId,
            promptTemplate: options?.promptTemplate,
          },
          onError: (err) => {
            setIsTyping(false);
            setError(err.message);
            console.error(err);
          },
        });
      }
      editor?.commands.clearContent(true);
    },
    [
      chatId,
      collectionId,
      editor,
      createChat,
      updateChat,
      setMessages,
      setChatId,
      setIsTyping,
      currentFocusedDocument,
    ]
  );

  useCmdPaletteWithChat({ editor, chatId, createOrUpdateChat });
  const [placeholderHeight, setPlaceholderHeight] = useState(0);

  useLayoutEffect(() => {
    setPlaceholderHeight(0);
    setTimeout(() => {
      const height =
        editor?.view.dom.querySelector(".is-editor-empty")?.offsetHeight;
      height && setPlaceholderHeight(height);
    }, 100);
  }, [editor, rightColWidth, focusedDocumentId, placeholder]);

  return (
    <StyledInputWrapper>
      <BotTyping isTyping={isTyping} chatId={chatId} lastRunId={lastRunId} />
      {error && <StyledError>{error}</StyledError>}
      <StyledForm onSubmit={createOrUpdateChat}>
        {currentDocumentId && (
          <ContextSelector
            currentDocumentId={currentDocumentId}
            document={document}
            focusedDocumentId={focusedDocumentId}
            focusedName={focusedName}
            isDocumentFocused={isDocumentFocused}
            setFocusedDocumentId={setFocusedDocumentId}
          />
        )}
        <StyledFormRow>
          <StyledEditor
            className="fs-mask"
            $placeholderHeight={placeholderHeight}
          >
            <EditorContent editor={editor} />
          </StyledEditor>
          <StyledSubmitButton type="submit">
            <StyledIcon />
          </StyledSubmitButton>
        </StyledFormRow>
      </StyledForm>
    </StyledInputWrapper>
  );
}
