import styled from "styled-components";
import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import useInlineEditing from "../../../hooks/useInlineEditing";
import { buttonReset } from "../../../css-mixins/button";
import { listItemMixin } from "../../../css-mixins/menu";
import { ChevronRight8 } from "../../Icon";
import { BodyMedium } from "../../Typography";
import Submenu from "./Submenu";

const StyledItem = styled.li`
  ${listItemMixin}
  padding: 0;

  ${({ isSubmenuOpen }) =>
    isSubmenuOpen &&
    `background-color: ${({ theme }) =>
      theme.button.secondary.backgroundColor};`};
`;

const StyledButton = styled.button`
  ${buttonReset}
  padding: 1rem 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};

  &:hover {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledBodyMedium = styled(BodyMedium)`
  fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  flex: 1;
  text-align: left;
`;

const StyledChevron = styled(ChevronRight8)`
  * {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

export default function MenuItem({ item, editor, setIsOpen }) {
  const { documentId } = useParams();
  const { complete } = useInlineEditing();
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const openSubmenu = useCallback(() => setIsSubmenuOpen(true), []);
  const closeSubmenu = useCallback(() => setIsSubmenuOpen(false), []);
  const { id, name, submenu } = item;

  const getText = useCallback(() => {
    const { from, to, empty } = editor.state.selection;

    if (empty) {
      return null;
    }

    return editor.state.doc.textBetween(from, to, " ");
  }, [editor]);

  const onClick = useCallback(
    (e) => {
      e.preventDefault();

      const text = getText();

      complete({
        text,
        documentId,
        type: id,
      });
      setIsOpen(false);
    },
    [getText, documentId, id, complete, setIsOpen]
  );

  return (
    <StyledWrapper
      onMouseEnter={submenu && openSubmenu}
      onMouseLeave={submenu && closeSubmenu}
    >
      <StyledItem isSubmenuOpen={isSubmenuOpen}>
        <StyledButton onClick={submenu ? openSubmenu : onClick}>
          <StyledBodyMedium>{name}</StyledBodyMedium>
          {submenu && <StyledChevron />}
        </StyledButton>
      </StyledItem>
      {isSubmenuOpen && (
        <Submenu items={submenu} isOpen={isSubmenuOpen} editor={editor} />
      )}
    </StyledWrapper>
  );
}
