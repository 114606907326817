import { css } from "styled-components";

export const buttonReset = css`
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  text-transform: none;
  appearance: button;
  border: none;
  margin: 0;
  padding: 0;
  background-color: unset;
  cursor: pointer;
  user-select: none;
  -webkit-app-region: no-drag;
`;
