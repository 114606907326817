import { gql } from "@apollo/client";

export const CREATE_BRAND_VOICE = gql`
  mutation CreateBrandVoice($input: BrandVoiceInput!) {
    createBrandVoice(input: $input) {
      title
      id
      state
    }
  }
`;

export const UPDATE_BRAND_VOICE = gql`
  mutation UpdateBrandVoice($id: ID!, $input: BrandVoiceInput!) {
    updateBrandVoice(id: $id, input: $input) {
      title
      id
      state
    }
  }
`;

export const DELETE_BRAND_VOICE = gql`
  mutation DeleteBrandVoice($id: ID!) {
    deleteBrandVoice(id: $id) {
      id
    }
  }
`;

export const GENERATE_BRAND_VOICE_FROM_WEBSITE = gql`
  mutation generateBrandVoiceFromWebsite(
    $brandVoiceId: ID!
    $website: String!
  ) {
    generateBrandVoiceFromWebsite(
      brandVoiceId: $brandVoiceId
      website: $website
    ) {
      runId
      brandVoiceId
    }
  }
`;

export const ABORT_BRAND_VOICE_GENERATOR_STREAMING = gql`
  mutation AbortBrandVoiceGeneratorStreaming(
    $runId: String!
    $brandVoiceId: ID!
  ) {
    abortBrandVoiceGeneratorStreaming(
      runId: $runId
      brandVoiceId: $brandVoiceId
    ) {
      status
    }
  }
`;
