import styled from "styled-components";
import Button from "../../Button";

const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.highlight.backgroundColor};
  color: ${({ theme }) => theme.button.highlight.foregroundColor};
  :hover {
    background-color: ${({ theme }) =>
      theme.button.highlight.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.highlight.hoverForegroundColor};
  }
  :disabled,
  :disabled:hover {
    background-color: ${({ theme }) => theme.button.highlight.backgroundColor};
    opacity: 0.2;
    cursor: default;
  }
`;

export default SubmitButton;
