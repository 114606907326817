import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FrameContext from "../../contexts/FrameContext";
import { setAction } from "../../features/multiSelectSlice";
import { brandVoicesSelector } from "../../features/brandVoicesSlice";
import { getCollectionBrandVoice } from "../../features/collectionsSlice";
import {
  selectIsCommandPaletteOpen,
  toggleIsOpenState,
} from "../../features/multiSelectSlice";
import {
  customTurnIntoPrompt,
  formatTurnIntoPrompt,
  turnIntoOptionsWithCustom,
  turnIntoPromptId,
  TURN_INTO_PAGES,
  COMMAND_LIST_PAGE,
} from "../../lib/cmd-palette-helpers";
import useCurrentCollectionId from "../useCurrentCollectionId";

export default function useTurnInto({ page, setPage }) {
  const dispatch = useDispatch();
  const { collectionId } = useCurrentCollectionId();
  const { openSidebarRight } = useContext(FrameContext);
  const isCommandPaletteOpen = useSelector(selectIsCommandPaletteOpen);
  const workspaceBrandVoiceId = useSelector(
    getCollectionBrandVoice(collectionId)
  );
  const brandVoices = useSelector(brandVoicesSelector);
  const workspaceBrandVoice = brandVoices.find(
    (brandVoice) => brandVoice.id === workspaceBrandVoiceId
  )?.name;

  const [audience, setAudience] = useState("");
  const [brandVoiceId, setBrandVoiceId] = useState();
  const [goal, setGoal] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [mention, setMention] = useState(null);
  const [promptTypeId, setPromptTypeId] = useState(null);
  const [tone, setTone] = useState(null);
  const defaultTone = workspaceBrandVoice || "Default";

  const setSelectedDocumentsPage = useCallback(() => {
    setPage(TURN_INTO_PAGES.SELECT_DOCUMENTS_PAGE);
  }, []);

  const setAudiencePage = useCallback(() => {
    setPage(TURN_INTO_PAGES.AUDIENCE_PAGE);
  }, []);

  const setPromptTypePage = useCallback(() => {
    setPage(TURN_INTO_PAGES.PROMPT_TYPE_PAGE);
  }, []);

  const setTonePage = useCallback(() => {
    setPage(TURN_INTO_PAGES.TONE_PAGE);
  }, []);

  const setSelectExamplePage = useCallback(() => {
    setPage(TURN_INTO_PAGES.SELECT_EXAMPLE_PAGE);
  }, []);

  const resetState = useCallback(() => {
    setPage(COMMAND_LIST_PAGE);
    setAudience("");
    setGoal("");
    setTone(null);
    setInputValue("");
    setMention(null);
    setBrandVoiceId(); // set undefined to use (default) workspace brand voice
  }, []);

  useEffect(() => {
    if (!isCommandPaletteOpen) {
      resetState();
    }
  }, [isCommandPaletteOpen]);

  const handleSubmitTurnInto = useCallback(() => {
    let prompt = turnIntoOptionsWithCustom.find(
      ({ id }) => id === promptTypeId
    ).prompt;
    prompt +=
      promptTypeId === customTurnIntoPrompt.id ? `${inputValue} using` : "";
    const refinePrompt = formatTurnIntoPrompt({
      audience,
      goal,
      tone,
      mention,
      brandVoiceId,
    });

    openSidebarRight();
    dispatch(
      setAction({
        action: {
          id: turnIntoPromptId,
          documentPromptType: promptTypeId,
          prompt,
          refine: {
            prompt: refinePrompt,
            mention,
            brandVoiceId,
          },
        },
      })
    );

    resetState();
    dispatch(toggleIsOpenState(false)); // close cmd palette, but do not reset reducer state
  }, [
    dispatch,
    inputValue,
    promptTypeId,
    audience,
    brandVoiceId,
    goal,
    mention,
    openSidebarRight,
    resetState,
    tone,
  ]);

  return {
    audience,
    brandVoiceId,
    goal,
    handleSubmitTurnInto,
    inputValue,
    mention,
    page,
    promptTypeId,
    resetState,
    setAudience,
    setAudiencePage,
    setBrandVoiceId,
    setGoal,
    setInputValue,
    setMention,
    setPromptTypeId,
    setPromptTypePage,
    setSelectedDocumentsPage,
    setSelectExamplePage,
    setTone,
    setTonePage,
    tone: tone || defaultTone,
  };
}
