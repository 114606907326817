import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_BRAND_VOICES } from "../graphql/queries";
import { load } from "../features/brandVoicesSlice";

export default function useGetBrandVoices() {
  const dispatch = useDispatch();
  const { data, loading, error } = useQuery(GET_BRAND_VOICES, {
    onCompleted: (data) => {
      dispatch(load(data.brandVoices));
    },
    fetchPolicy: "cache-and-network",
  });

  return { data, loading, error };
}
