import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useCreateStage from "../../../hooks/api/useCreateStage";
import { COLORS } from "../../../theme";
import Button from "../../Button";
import IconSelector from "../../IconSelector";
import Input from "../../Input";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const StyledInputForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
  row-gap: 1rem;
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0;
  width: 100%;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-self: flex-end;
`;

const StyledIdeasWrapper = styled.span`
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
`;

const CTAButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  color: ${({ theme }) => theme.button.primary.foregroundColor};

  :hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
  }
`;

export default function CreateStageInput({ hideInput }) {
  const createStage = useCreateStage();
  const { collectionId } = useCurrentCollectionId();
  const ref = useRef(null);
  const [stageInput, setStageInput] = useState("");
  const [color, setColor] = useState(COLORS.BLACK500T);
  const [iconId, setIconId] = useState("StatusIdea16");

  const handleSelectIcon = useCallback(({ id, color }) => {
    setIconId(id);
    setColor(color);
  }, []);

  const handleCreateStage = useCallback(
    (e) => {
      e.preventDefault();
      createStage({
        collectionId,
        input: {
          name: ref.current.value.trim() || "Untitled",
          color,
          icon: iconId,
        },
      });
      setStageInput("");
      hideInput();
    },
    [collectionId, createStage, hideInput, color, iconId]
  );

  const cancelCreateStage = useCallback(
    (e) => {
      e.preventDefault();
      setStageInput("");
      hideInput();
    },
    [hideInput]
  );

  const shortcuts = useCallback(
    (e) => {
      if (e.key === "Escape") {
        e.stopPropagation();
        cancelCreateStage(e);
        return;
      }
    },
    [cancelCreateStage]
  );

  useEffect(() => {
    window.addEventListener("keydown", shortcuts);
    return () => {
      window.removeEventListener("keydown", shortcuts);
    };
  }, [shortcuts]);

  return (
    <StyledInputForm onSubmit={handleCreateStage}>
      <StyledSection>
        <StyledIdeasWrapper>
          <IconSelector
            iconId={iconId}
            color={color}
            onSelectIcon={handleSelectIcon}
          />
        </StyledIdeasWrapper>
        <Input
          ref={ref}
          autoFocus
          value={stageInput}
          onChange={(e) => setStageInput(e.target.value)}
        />
      </StyledSection>
      <StyledButtons>
        <CTAButton type="submit">Create</CTAButton>
        <Button onClick={cancelCreateStage}>Cancel</Button>
      </StyledButtons>
    </StyledInputForm>
  );
}
