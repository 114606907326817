import { useMutation } from "@apollo/client";
import { useCombobox } from "downshift";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { visuallyHidden } from "../../../css-mixins/accessibility";
import { brandVoicesSelector } from "../../../features/brandVoicesSlice";
import { getCollectionBrandVoice } from "../../../features/collectionsSlice";
import { GET_COLLECTION, SET_COLLECTION_BRAND_VOICE } from "../../../graphql";
import { stopPropagation } from "../../../lib/event-helpers";
import { filterItems } from "../../../lib/menu-helpers";
import COLORS from "../../../theme/colors";
import Button from "../../Button";
import DropdownInput from "../../Dropdown/Input";
import { Tone12 } from "../../Icon";
import Menu from "./Menu";
import Tooltip from "../../Tooltip";
import { device } from "../../../css-mixins/media-queries";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

// hidden label styling for accessibility
const HiddenLabel = styled.label`
  ${visuallyHidden}
`;

const StyledMenuWrapper = styled.div`
  width: 32rem;
  max-height: 32rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-shadow: 0 4px 8px ${COLORS.BLACK100T}, 0 8px 24px ${COLORS.BLACK100T},
    ${({ theme }) => theme.menu.borderColor};
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.menu.backgroundColor};
  border-radius: 1.5rem;
  margin-top: 0.5rem;
  z-index: 2;

  ${({ $isOpen }) => !$isOpen && "display: none"}
`;

const StyledButton = styled(Button)`
  ${({ isOpen }) => isOpen && `opacity: 1 !important;`}
  white-space: nowrap;
  max-width: 15.5rem;
  column-gap: 1rem;
  height: 3.5rem;

  svg {
    flex-shrink: 0;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    @media (${device.mobile}) {
      display: none;
    }
  }
`;

const StyledBrandVoiceSelector = styled.div`
  position: relative;
`;

const defaultBrandVoice = {
  id: null,
  name: "No Brand Voice",
};

export default function BrandVoiceSelector() {
  const [inputValue, setInputValue] = useState("");
  const resetInput = useCallback(() => setInputValue(""), []);
  const handleInputChange = useCallback(
    (e) => setInputValue(e.target.value),
    []
  );

  const { collectionId } = useCurrentCollectionId();
  const brandVoices = useSelector(brandVoicesSelector);
  const workspaceBrandVoiceId = useSelector(
    getCollectionBrandVoice(collectionId)
  );
  const items = [defaultBrandVoice, ...brandVoices];
  const filteredItems = filterItems(inputValue, items);

  const selectedItem =
    brandVoices.find((brandVoice) => brandVoice.id === workspaceBrandVoiceId) ||
    defaultBrandVoice;

  const [setBrandVoiceMutation] = useMutation(SET_COLLECTION_BRAND_VOICE);

  const handleSelectItem = useCallback(
    async ({ selectedItem: newSelectedItem }) => {
      await setBrandVoiceMutation({
        variables: {
          collectionId,
          brandVoiceId: newSelectedItem.id,
        },
        refetchQueries: [GET_COLLECTION],
      });
    },
    [collectionId, setBrandVoiceMutation]
  );

  const {
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    items: filteredItems,
    selectedItem,
    itemToString: () => "",
    onSelectedItemChange: handleSelectItem,
    onIsOpenChange: resetInput,
  });

  return (
    <StyledBrandVoiceSelector onClick={stopPropagation}>
      <Tooltip content="Brand Voice">
        <StyledButton isOpen={isOpen} Icon={Tone12} {...getToggleButtonProps()}>
          {selectedItem.id === null ? "Brand Voice" : selectedItem.name}
        </StyledButton>
      </Tooltip>
      <StyledMenuWrapper $isOpen={isOpen}>
        <HiddenLabel {...getLabelProps()}>Brand voice selector</HiddenLabel>
        <DropdownInput
          getInputProps={getInputProps}
          handleInputChange={handleInputChange}
          isOpen={isOpen}
          placeholder="Pick a brand voice"
        />
        <Menu
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          isOpen={isOpen}
          items={filteredItems}
          selectedItem={selectedItem}
        />
      </StyledMenuWrapper>
    </StyledBrandVoiceSelector>
  );
}
