import React, { useEffect, useRef } from "react";
import tippy from "tippy.js";
import styled from "styled-components";
import "tippy.js/animations/scale-subtle.css";

const TooltipWrapper = styled.div``;

const Tooltip = ({
  children,
  content,
  shortcuts = [],
  offset,
  placement,
  moveTransition,
  ...props
}) => {
  const tooltipRef = useRef();

  const tippyOptions = {
    content: `${content} ${shortcuts
      .map((shortcut) => `<span class='shortcut'>${shortcut}</span>`)
      .join("")}`,
    allowHTML: true,
    theme: "customdark",
    animation: "scale-subtle",
    offset: offset || [0, 4],
    delay: [400, 100],
    placement: placement || "bottom",
    duration: [100, 100],
    moveTransition: moveTransition ? "transform 0.2s ease-out" : "",
    ...props,
  };

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = tippy(tooltipRef.current, tippyOptions);
      return () => {
        tooltip.destroy();
      };
    }
  }, [content, offset, placement, shortcuts]);

  return <TooltipWrapper ref={tooltipRef}>{children}</TooltipWrapper>;
};

export default Tooltip;
