import { createGlobalStyle } from "styled-components";
import "../assets/fonts/fonts.web.css";
import { COLORS } from "../theme";

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    color: ${({ theme }) => theme.body.foregroundColor};
    font-size: 8px;
    -webkit-font-smoothing: antialiased;
    background-color: ${({ theme }) => theme.body.backgroundColor};
    color-scheme: ${({ theme }) => theme.colorScheme};
  }

  body {
    font-size: 2rem;
    overflow: hidden;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

  p {
    font-size: 2rem;
    line-height: 3rem;
  }

  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  #root {
    height: 100%;
    overflow: hidden;
  }

  .tippy-box[data-theme~='customdark'] {
    background: ${({ theme }) => theme.tooltip.backgroundColor};
    backdrop-filter: blur(2rem);
    color: white;
    border-radius: 1rem;
    font-size: ${({ theme }) => theme.typography.bodySmall.fontSize};

    .tippy-content{
      padding: 0.5rem 1rem;
      align-items: center;
      display: flex;
    }

    .shortcut {
      background: ${COLORS.WHITE200T};
      color: ${COLORS.WHITE};
      padding: 0.25rem 0.5rem;
      margin-left: 0.25rem;
      font-size: ${({ theme }) => theme.typography.labelSmall.fontSize};
      font-weight: 500;
      border-radius: 0.5rem;

      &:first-of-type {
        margin-left: 1rem;
      }

      &:last-of-type {
        margin-right: -0.5rem;
      }
    }
  }
`;
