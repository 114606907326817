import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFullSelectedDocuments, reset } from "../features/multiSelectSlice";
import { composeTiptapJsonFromMultiSelect } from "../lib/multi-select-helpers";

export default function useCmdPaletteWithChat({
  editor,
  chatId,
  createOrUpdateChat,
}) {
  const multiSelectedDocuments = useSelector(getFullSelectedDocuments);
  const dispatch = useDispatch();
  const multiSelectionAction = useSelector((state) => state.multiSelect.action);
  const promptTemplate = multiSelectionAction?.id;
  const documentPromptType = multiSelectionAction?.documentPromptType;
  const brandVoiceId = multiSelectionAction?.refine?.brandVoiceId;

  useEffect(() => {
    if (multiSelectionAction !== null) {
      const jsonContent = composeTiptapJsonFromMultiSelect(
        multiSelectionAction,
        multiSelectedDocuments
      );
      const prevContent = editor.getJSON();

      editor?.chain().focus().setContent(jsonContent).run();

      createOrUpdateChat(null, {
        resetHistory: true,
        documentPromptType,
        brandVoiceId,
        promptTemplate,
      });
      dispatch(reset());
      editor?.chain().focus().setContent(prevContent).run();
    }
  }, [multiSelectionAction, multiSelectedDocuments, editor, chatId]);

  return null;
}
