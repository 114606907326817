import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_BRAND_VOICE } from "../graphql";

export default function useUpdateBrandVoice() {
  const [updateBrandVoiceMutation] = useMutation(UPDATE_BRAND_VOICE);

  const updateBrandVoice = useCallback(
    (id, input, callback) => {
      updateBrandVoiceMutation({
        variables: {
          id,
          input,
        },
        onCompleted: callback,
      });
    },
    [updateBrandVoiceMutation]
  );

  return updateBrandVoice;
}
