import { useCallback, useContext } from "react";
import styled from "styled-components";
import CommandPaletteContext from "../../../../../contexts/CommandPaletteContext";
import {
  turnIntoOptionsWithCustom,
  customTurnIntoPrompt,
  TURN_INTO_PAGES,
} from "../../../../../lib/cmd-palette-helpers";
import { BodyLarge } from "../../../../Typography";
import { Goal16, Profile16 } from "../../../../Icon";
import Footer from "../../Footer";
import GoBackButton from "../../GoBackButton";
import Input from "../../Input";
import SubmitButton from "../../SubmitButton";
import SelectTone from "./SelectTone";

const StyledWrapper = styled.div`
  padding: 1rem;
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  column-gap: 1rem;
  user-select: none;
`;

export default function AudiencePage() {
  const {
    promptTypeId,
    audience,
    setAudience,
    goal,
    inputValue,
    setGoal,
    setPromptTypePage,
    handleSubmitTurnInto,
    selectedCommand,
  } = useContext(CommandPaletteContext);

  const onSubmit = useCallback(() => {
    handleSubmitTurnInto({
      type:
        selectedCommand === TURN_INTO_PAGES.SELECT_DOCUMENTS_PAGE
          ? "TURN_INTO"
          : "DRAFT",
    });
  }, [handleSubmitTurnInto]);

  return (
    <>
      <StyledWrapper>
        <Input
          autoFocus
          icon={<Profile16 />}
          id="audience"
          label="Audience"
          onChange={(e) => setAudience(e.target.value)}
          placeholder="Describe your audience"
          value={audience}
        />
        <Input
          id="goal"
          icon={<Goal16 />}
          label="Goal"
          onChange={(e) => setGoal(e.target.value)}
          placeholder="Describe your goal (eg. lead generation, brand awareness)"
          value={goal}
        />
        <SelectTone />
      </StyledWrapper>
      <Footer>
        <StyledLeft>
          <GoBackButton onClick={setPromptTypePage} />
          <BodyLarge>
            {
              turnIntoOptionsWithCustom.find(({ id }) => id === promptTypeId)
                .name
            }
            {promptTypeId === customTurnIntoPrompt.id && inputValue}
          </BodyLarge>
        </StyledLeft>
        <SubmitButton onClick={onSubmit}>Create</SubmitButton>
      </Footer>
    </>
  );
}
