import { useCallback, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import COLORS from "../../theme/colors";
import FrameContext from "../../contexts/FrameContext";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.BLACK500T};
`;

const StyledModal = styled.div`
  padding: 3rem 4rem;
  max-width: 100rem;
  border-radius: 2rem;
  flex: 1;
  background: ${({ theme }) => theme.modal.backgroundColor};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  gap: 1rem;
`;

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export default function Modal({ onClose, children, style }) {
  const closeModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const shortcuts = useCallback(
    (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", shortcuts);
    return () => {
      window.removeEventListener("keydown", shortcuts);
    };
  }, [shortcuts]);

  const { overlayLayer } = useContext(FrameContext);

  return (
    overlayLayer.current &&
    createPortal(
      <StyledModalWrapper>
        <Overlay onClick={closeModal} />
        <StyledModal style={style}>{children}</StyledModal>
      </StyledModalWrapper>,
      overlayLayer.current
    )
  );
}
