import styled from "styled-components";
import COLORS from "../../theme/colors";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/image/Strut-Logo.svg";
import LoginButton from "../LoginButton";
import SignupButton from "../SignupButton";

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 150rem;
  margin: 0 auto;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${COLORS.BLACK050T};
  align-items: center;
`;

const NavigationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 0 2rem;
  backdrop-filter: blur(1rem);
`;

const NavLeft = styled.div``;
const NavRight = styled.div``;
const StruttLogo = styled.div``;

export default function MarketingNavigation() {
  return (
    <NavigationWrapper>
      <Navigation>
        <NavLeft>
          <NavLink to="/">
            <StruttLogo>
              <img src={Logo} alt="Strut Logo" />
            </StruttLogo>
          </NavLink>
        </NavLeft>
        <NavRight>
          <LoginButton />
          <SignupButton />
        </NavRight>
      </Navigation>
    </NavigationWrapper>
  );
}
