import {
  Bag16,
  Calendar16,
  Clock16,
  Coin16,
  Document16,
  Face16,
  Heart16,
  Laptop16,
  Stack16,
  Star16,
  StatusDone16,
  StatusDraft16,
  StatusIdea16,
  StatusOutline16,
  StatusProfile16,
  StatusResearch16,
  StatusReview16,
  ToDo16,
} from "../components/Icon";
import { LIGHT_MODE, COLORS, SYSTEM_MODE, detectTheme } from "../theme";

export const colors = [
  COLORS.BLACK500T,
  COLORS.PURPLE500,
  COLORS.CRIMSON500,
  COLORS.SUNRISE500,
  COLORS.OCEAN500,
  COLORS.FOREST500,
];

export const icons = {
  StatusIdea16: {
    icon: StatusIdea16,
    id: "StatusIdea16",
  },
  StatusResearch16: {
    icon: StatusResearch16,
    id: "StatusResearch16",
  },
  StatusOutline16: {
    icon: StatusOutline16,
    id: "StatusOutline16",
  },
  StatusDraft16: {
    icon: StatusDraft16,
    id: "StatusDraft16",
  },
  StatusReview16: {
    icon: StatusReview16,
    id: "StatusReview16",
  },
  StatusDone16: {
    icon: StatusDone16,
    id: "StatusDone16",
  },
  Coin16: {
    icon: Coin16,
    id: "Coin16",
  },
  Document16: {
    icon: Document16,
    id: "Document16",
  },
  Face16: {
    icon: Face16,
    id: "Face16",
  },
  StatusProfile16: {
    icon: StatusProfile16,
    id: "StatusProfile16",
  },
  Laptop16: {
    icon: Laptop16,
    id: "Laptop16",
  },
  Bag16: {
    icon: Bag16,
    id: "Bag16",
  },
  Stack16: {
    icon: Stack16,
    id: "Stack16",
  },
  Calendar16: {
    icon: Calendar16,
    id: "Calendar16",
  },
  Heart16: {
    icon: Heart16,
    id: "Heart16",
  },
  Clock16: {
    icon: Clock16,
    id: "Clock16",
  },
  ToDo16: {
    icon: ToDo16,
    id: "ToDo16",
  },
  Star16: {
    icon: Star16,
    id: "Star16",
  },
};

export const iconsByRow = [
  [
    icons.StatusIdea16,
    icons.StatusResearch16,
    icons.StatusOutline16,
    icons.StatusDraft16,
    icons.StatusReview16,
    icons.StatusDone16,
  ],
  [
    icons.Coin16,
    icons.Document16,
    icons.Face16,
    icons.StatusProfile16,
    icons.Laptop16,
    icons.Bag16,
  ],
  [
    icons.Stack16,
    icons.Calendar16,
    icons.Heart16,
    icons.Clock16,
    icons.ToDo16,
    icons.Star16,
  ],
];

function getCurrentTheme(theme) {
  if (theme === SYSTEM_MODE) {
    return detectTheme();
  }
  return theme;
}

export function invertIconColor(color, theme) {
  const currentTheme = getCurrentTheme(theme);
  if (currentTheme === LIGHT_MODE) {
    return color;
  }
  if (color === COLORS.BLACK500T) {
    return COLORS.WHITE500T;
  }
  return color;
}
