import { css } from "styled-components";
// hidden styling for accessibility
// referenced from https://github.com/Shopify/polaris-react/blob/master/src/styles/shared/_accessibility.scss
export const visuallyHidden = css`
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 transparent !important;
`;
