import { createSelector, createSlice } from "@reduxjs/toolkit";
import { format, parseISO } from "date-fns";
import uniq from "lodash/uniq";
import COLORS from "../theme/colors";
import {
  addDocument,
  updateDocument,
  positionDocumentInStage,
} from "./collectionDocuments";

const initialState = {};

function setCollectionOrder(id, collections, position) {
  const STEP = 8;
  const sequences = Object.values(collections)
    .filter((c) => c.order || c.id === id)
    .sort((a, b) => a["order"] - b["order"]);

  const sidebarPosition = Number.isInteger(position)
    ? position
    : sequences.length - 1;
  const filteredSequences = sequences.filter((col) => col.id !== id);
  const maxOrder = sequences.slice(-1)[0].order + STEP;
  const next = filteredSequences[sidebarPosition]?.order || maxOrder;
  const prev = filteredSequences[sidebarPosition - 1]?.order || 0.0;
  return (next + prev) / 2;
}

export const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    load: (state, action) => {
      action.payload.forEach((collection) => {
        const { documents, stages, ...collectionWithoutDocuments } = collection;
        state[collection.id] = {
          ...(state[collection.id] || {}),
          ...collectionWithoutDocuments,
          documents:
            documents?.map((document) => document.id) ||
            state[collection.id]?.documents ||
            [],
          stages:
            stages?.reduce((accumulator, stage) => {
              accumulator[stage.id] = {
                ...stage,
                documents: documents?.reduce((acc, document) => {
                  if (document.stageId === stage.id) {
                    acc.push(document.id);
                  }
                  return acc;
                }, []),
              };
              return accumulator;
            }, {}) ||
            state[collection.id]?.stages ||
            {},
        };
      });
      return state;
    },
    remove: (state, action) => {
      delete state[action.payload.id];
      return state;
    },
    update: (state, action) => {
      const { id, input } = action.payload;
      const { sidebarPosition, showOnSidebar, ...updates } = input;
      if (sidebarPosition !== undefined || showOnSidebar === true) {
        updates.order = setCollectionOrder(id, state, sidebarPosition);
      } else if (showOnSidebar === false) {
        updates.order = undefined;
      }
      state[id] = {
        ...state[id],
        ...updates,
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addDocument, (state, action) => {
      const { document, collectionId } = action.payload;
      state[collectionId] = {
        ...state[collectionId],
        documents: [...state[collectionId].documents, document.id],
        stages: {
          ...state[collectionId].stages,
          [document.stageId]: {
            ...(state[collectionId].stages[document.stageId] || {}),
            documents: [
              ...(state[collectionId].stages[document.stageId].documents || []),
              document.id,
            ],
          },
        },
      };

      return state;
    });
    builder.addCase(updateDocument, (state, action) => {
      const { document } = action.payload;
      const collectionId = document.collectionId;
      if (state[collectionId].stages[document.stageId]) {
        state[collectionId] = {
          ...state[collectionId],
          documents: uniq([...state[collectionId].documents, document.id]),
          stages: {
            ...state[collectionId].stages,
            [document.stageId]: {
              ...(state[collectionId].stages[document.stageId] || {}),
              documents: uniq([
                ...(state[collectionId].stages[document.stageId].documents ||
                  []),
                document.id,
              ]),
            },
          },
        };
      }

      return state;
    });
    builder.addCase(positionDocumentInStage, (state, action) => {
      const { documentId, stageId, collectionId } = action.payload;
      const documents = state[collectionId].stages[stageId].documents;
      const index = documents.indexOf(documentId);
      if (index === -1) {
        state[collectionId].stages[stageId].documents.push(documentId);
        Object.keys(state[collectionId].stages).forEach((stage) => {
          if (stage !== stageId) {
            const stageDocuments = state[collectionId].stages[stage].documents;
            const index = stageDocuments.indexOf(documentId);
            if (index !== -1) {
              stageDocuments.splice(index, 1);
            }
          }
        });
      }

      return state;
    });
  },
});

// actions
export const { load, remove, update } = collectionsSlice.actions;

// selectors
function state(state) {
  return state;
}

function collections(state) {
  return state.collections;
}

export const collectionsList = createSelector(collections, (collections) =>
  Object.values(collections)
);

export const collectionsSidebar = createSelector(collections, (collections) =>
  Object.values(collections)
    .filter((collection) => collection.showOnSidebar)
    .filter((collection) => !collection.inbox)
    .sort((a, b) => a["order"] - b["order"])
);

export const inboxCollections = createSelector(collections, (collections) =>
  Object.values(collections).find((collection) => collection.inbox)
);

export function collectionById(collectionId) {
  return createSelector(collections, (collections) => {
    return collections?.[collectionId] || {};
  });
}

function addCollaboratorType(collection) {
  return function (collaborator) {
    return {
      ...collaborator,
      type: collaborator.id === collection.userId ? "Owner" : "Can edit",
    };
  };
}

export function collaborators(collectionId) {
  return createSelector(collectionById(collectionId), (collection) => {
    return (
      collection?.collaborators?.map(addCollaboratorType(collection)) || []
    );
  });
}

export function getCollectionBrandVoice(collectionId) {
  return createSelector(collectionById(collectionId), (collection) => {
    return collection?.Grant?.brandVoiceId;
  });
}

export function numberOfDocuments(collectionId) {
  return createSelector(
    collectionById(collectionId),
    (collection) => collection.documents.length
  );
}

export function numberOfStagesByCollectionId(collectionId) {
  return createSelector(collectionById(collectionId), (collection) =>
    collection?.stages ? Object.keys(collection.stages).length : 0
  );
}

export function stagesByCollectionId(collectionId) {
  return createSelector(collectionById(collectionId), (collection) => {
    const sortedArray = (
      collection.stages ? Object.values(collection.stages) : []
    ).sort((a, b) => a.order - b.order);

    return sortedArray;
  });
}

export function orderedDocumentsByStage(collectionId) {
  return createSelector(
    state,
    stagesByCollectionId(collectionId),
    (state, stages) => {
      const orderedDocumentsByStage = stages.reduce((accumulator, stage) => {
        const documents = stage.documents
          ? Object.values(
              stage.documents.filter((stage) => state.documents[stage])
            )
          : [];
        const sortedArray = documents.sort(
          (a, b) => state.documents[a]?.order - state.documents[b]?.order
        );

        accumulator[stage.id] = sortedArray;
        return accumulator;
      }, {});

      return orderedDocumentsByStage;
    }
  );
}

export function defaultStageByCollectionId(collectionId) {
  return createSelector(stagesByCollectionId(collectionId), (stages) => {
    return stages[0] || {};
  });
}

export function formattedStages(collectionId) {
  return createSelector(stagesByCollectionId(collectionId), (stages) =>
    stages.map((stage, index) => ({
      id: stage.id,
      name: stage.name,
      position: index,
      icon: stage.icon || "StatusIdea16",
      color: stage.color || COLORS.BLACK500T,
    }))
  );
}

export function fullDocumentsByStageId(collectionId, stageId) {
  return createSelector(
    state,
    orderedDocumentsByStage(collectionId),
    (state, orderedDocuments) => {
      const orderedDocumentsByStageId = orderedDocuments[stageId] || [];
      const fullDocuments = orderedDocumentsByStageId
        .map((id) => state.documents[id])
        .filter((doc) => doc);

      return fullDocuments || [];
    }
  );
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function sortByDate(a, b) {
  return new Date(b) - new Date(a);
}

export function datesByStageId(collectionId, stageId) {
  return createSelector(
    fullDocumentsByStageId(collectionId, stageId),
    (documents) => {
      const today = format(new Date(), "yyyy-MM-dd");
      return [...documents, { createdAtDate: today }]
        .map((doc) =>
          format(
            doc.createdAtDate ? parseISO(doc.createdAtDate) : new Date(),
            "yyyy-MM-dd"
          )
        )
        .filter((doc) => doc)
        .filter(onlyUnique)
        .sort(sortByDate);
    }
  );
}

export function documentsByStageDate(collectionId, stageId) {
  return createSelector(
    fullDocumentsByStageId(collectionId, stageId),
    (documents) => {
      return documents.reduce((acc, doc) => {
        const createdAtDate = doc.createdAtDate
          ? format(parseISO(doc.createdAtDate), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd");
        acc[createdAtDate] = acc[createdAtDate] || [];
        acc[createdAtDate].push(doc);
        return acc;
      }, {});
    }
  );
}

export default collectionsSlice.reducer;
