import { BRAINSTORM_PAGES } from "./brainstorm-helpers";
import { TURN_INTO_PAGES } from "./turn-into-helpers";
import { OUTLINE_PAGES } from "./outline-helpers";

export const CMDK_SKIP_TARGET_CLASSNAME = "cmdk-skip-target";

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const COMMAND_LIST_PAGE = "COMMAND_LIST_PAGE";

// prompt template ids
export const turnIntoPromptId = "DEFAULT";
export const brainstormPromptId = "BRAINSTORM";
export const brainstormWithDocumentsPromptId = "BRAINSTORM_WITH_DOCUMENTS";
export const researchPromptId = "RESEARCH";
export const researchWithDocumentsPromptId = "RESEARCH_WITH_DOCUMENTS";
export const outlinePromptId = "OUTLINE";
export const outlineWithDocumentsPromptId = "OUTLINE_WITH_DOCUMENTS";

// commands
export const brainstorm_command = {
  id: BRAINSTORM_PAGES.IDEAS_PAGE,
  name: "Brainstorm ideas",
};

export const turn_into_command = {
  id: TURN_INTO_PAGES.SELECT_DOCUMENTS_PAGE,
  name: "Re-purpose content",
};

export const outline_command = {
  id: OUTLINE_PAGES.OUTLINE_PAGE,
  name: "Generate outline",
};

export const DRAFT_PAGES = {
  ...TURN_INTO_PAGES,
  SELECT_DOCUMENTS_PAGE: "SELECT_DOCUMENTS_PAGE_DRAFT",
};

export const draft_command = {
  id: DRAFT_PAGES.SELECT_DOCUMENTS_PAGE,
  name: "Create a draft",
};

export const navigate_inbox_command = {
  id: "NAVIGATE_INBOX_COMMAND",
  name: "Go to Inbox",
};

export const navigate_brand_voice_command = {
  id: "NAVIGATE_BRAND_VOICE_COMMAND",
  name: "Go to Brand Voice",
};

export const navigate_collections_command = {
  id: "NAVIGATE_COLLECTIONS_COMMAND",
  name: "Go to a Workspace...",
};

export const dark_mode_theme_command = {
  id: "DARK_MODE_THEME_COMMAND",
  name: "Change theme to Dark",
};

export const light_mode_theme_command = {
  id: "LIGHT_MODE_THEME_COMMAND",
  name: "Change theme to Light",
};

export const list_view_command = {
  id: "LIST_VIEW_COMMAND",
  name: "Change to List view",
};

export const grid_view_command = {
  id: "GRID_VIEW_COMMAND",
  name: "Change to Grid view",
};

export const kanban_view_command = {
  id: "KANBAN_VIEW_COMMAND",
  name: "Change to Kanban view",
};

export const create_document_command = {
  id: "CREATE_DOCUMENT_COMMAND",
  name: "Create new doc",
};

export const create_inbox_document_command = {
  id: "CREATE_INBOX_DOCUMENT_COMMAND",
  name: "Create new doc in Inbox",
};
