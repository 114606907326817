export function getAllMentions(doc) {
  const result = [];

  doc.descendants((node) => {
    if (node.type.name === "mention") {
      result.push({
        id: node.attrs.id,
        name: node.attrs.label,
        type: node.attrs["data-mention-type"],
      });
    }
  });

  return result;
}

const maxFileSize = 10; //MB

export function handleImageDrop(
  allowedFiletypes = ["image/jpeg", "image/png", "image/gif"]
) {
  return function (view, event, slice, moved) {
    if (!moved && event.dataTransfer?.files?.length) {
      const file = event.dataTransfer.files[0];
      const filesize = (file.size / 1024 / 1024).toFixed(4);
      if (allowedFiletypes.includes(file.type) && filesize < maxFileSize) {
        const img = new Image();
        const src = URL.createObjectURL(file);
        img.src = src;
        img.onload = function () {
          if (this.width > 5000 || this.height > 5000) {
            window.alert(
              "Your images need to be less than 5000 pixels in height and width."
            );
          } else {
            window.uploadFileStorage = file;
            const { schema } = view.state;
            const coordinates = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });
            const node = schema.nodes.image.create({
              doUpload: src,
            });
            const transaction = view.state.tr.insert(coordinates.pos, node);

            view.dispatch(transaction);
          }
        };

        return true;
      }
    }
    return false;
  };
}
