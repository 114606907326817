import { HocuspocusProvider } from "@hocuspocus/provider";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Y from "yjs";
import FrameContext from "../../contexts/FrameContext";
import StreamingContext from "../../contexts/StreamingContext";
import { getToken } from "../../features/authSlice";
import { getIsTyping } from "../../features/uiSlice";
import { documentById } from "../../features/documentsSlice";
import { selectIsCommandPaletteOpen } from "../../features/multiSelectSlice";
import DocumentModal from "../DocumentModal";
import DocumentMoreMenu from "../DocumentMoreMenu";
import { Close12, FocusDeep12, FocusRegular12, FocusZoom12 } from "../Icon";
import IconButton from "../IconButton";
import Editor from "../Tiptap/Editor";
import ReadOnlyEditor from "../Tiptap/ReadOnlyEditor";
import AdminTools from "./AdminTools";
import StopWriting from "./StopWriting";
import Tooltip from "../Tooltip";
import { device } from "../../css-mixins/media-queries";
import { setIsTyping } from "../../features/uiSlice";
import { COLORS } from "../../theme";

const StyledModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  background-color: ${({ theme }) => theme.body.secondaryBackgroundColor};
  border-radius: 6px;
  box-shadow: 0px 4px 32px ${COLORS.BLACK150T},
    0 0 0 1px ${({ theme }) => theme.document.modalBorderColor};
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 1;
  column-gap: 1rem;
  ${({ isSidebarRightOpen }) => !isSidebarRightOpen && `margin-right: 5rem;`}
  opacity: ${({ isEditorTyping }) => (isEditorTyping ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
  @media (${device.mobile}) {
    margin-right: 0rem;
  }
`;

const ScrollableContent = styled.div`
  overflow: auto;
`;

const ReadOnlyEditorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: ${({ isProviderSynced }) => (isProviderSynced ? "none" : "block")};
  background-color: ${({ theme }) => theme.document.backgroundColor};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledFocusButton = styled(IconButton)`
  @media (${device.mobile}) {
    display: none;
  }
`;

export default function DocumentDetails() {
  const dispatch = useDispatch();
  const { isSidebarRightOpen } = useContext(FrameContext);
  const { documentId } = useParams();
  const token = useSelector(getToken);
  const [isProviderSynced, setIsProviderSynced] = useState(false);
  const { isStreaming, abortStreaming } = useContext(StreamingContext);
  const document = useSelector(documentById(documentId));
  const navigate = useNavigate();
  const isCommandPaletteOpen = useSelector(selectIsCommandPaletteOpen);
  const isEditorTyping = useSelector(getIsTyping);
  const scrollRef = useRef(null);
  const [paddingOffset, setPaddingOffset] = useState(0);
  const [isTextFocused, setIsTextFocused] = useState(false);
  const [scale, setScale] = useState(1);
  const [isScaledUp, setIsScaledUp] = useState(false);

  const closeDocument = useCallback(() => {
    dispatch(setIsTyping(false));
    navigate("..");
  }, [dispatch, navigate]);

  const yjsDocument = useMemo(() => new Y.Doc(), [documentId]);

  const provider = useMemo(
    () =>
      new HocuspocusProvider({
        url: import.meta.env.VITE_HOCUSPOCUS_URL,
        name: documentId,
        document: yjsDocument,
        token,
      }),
    [documentId, token]
  );

  useEffect(() => {
    provider.on("synced", ({ state }) => {
      setIsProviderSynced(state);
    });

    if (provider.status === "disconnected") {
      provider.connect();
    }

    return () => provider.disconnect();
  }, [provider]);

  const setWrapperStyle = useCallback(
    (active) => {
      const height = scrollRef?.current?.parentNode.clientHeight;
      if (height) {
        let padding = 0;
        if (active) {
          padding = height / 2;
        }
        setPaddingOffset(padding);
      }
    },
    [scrollRef, setPaddingOffset]
  );

  const trackCursor = useCallback(() => {
    requestAnimationFrame(() => {
      let y = 0;

      const sel = window.getSelection();

      if (sel.rangeCount) {
        const range = sel.getRangeAt(0).cloneRange();
        if (range.getClientRects) {
          if (range.getClientRects().length > 0) {
            range.collapse(true);
            const rect = range.getClientRects()[0];
            if (rect) {
              y = rect.top;
            }
          } else {
            if (range.commonAncestorContainer.getBoundingClientRect) {
              const containerRect =
                range.commonAncestorContainer.getBoundingClientRect();
              y = containerRect.top;
            }
          }
        }
      }
      if (y) {
        const height = scrollRef?.current.parentNode.clientHeight;
        const scrollTop = scrollRef?.current.scrollTop;
        const middle = height / 2;
        const correction = y - middle;

        if (Math.abs(correction) > 5) {
          const newScrollTop = scrollTop + correction;
          window.dispatchEvent(new KeyboardEvent("keydown", { key: "Shift" }));
          scrollRef.current.scrollTo({
            top: newScrollTop,
            behahvior: "smooth",
          });
        }
      }
    });
  }, [scrollRef]);

  const scaleTracker = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === "=") {
        if (isScaledUp) {
          setIsTextFocused(true);
          setWrapperStyle(true);
        } else {
          setScale(1.25);
          setIsScaledUp(true);
        }
        trackCursor();
      } else if (event.ctrlKey && event.key === "-") {
        setWrapperStyle(false);
        if (isScaledUp && isTextFocused) {
          setIsTextFocused(false);
        } else if (!isScaledUp && isTextFocused) {
          setIsTextFocused(false);
        } else if (isScaledUp && !isTextFocused) {
          setScale(1);
          setIsScaledUp(false);
        }
      }
    },
    [
      setIsTextFocused,
      isScaledUp,
      isTextFocused,
      setScale,
      setIsScaledUp,
      setWrapperStyle,
      trackCursor,
    ]
  );

  const handleScale = useCallback(
    (event) => {
      event.preventDefault();
      if (!isScaledUp) {
        setScale(1.25);
        setIsScaledUp(true);
      } else if (isScaledUp && !isTextFocused) {
        setIsTextFocused(true);
        setWrapperStyle(true);
      } else {
        setScale(1);
        setIsScaledUp(false);
        setIsTextFocused(false);
        setWrapperStyle(false);
      }
      trackCursor();
    },
    [
      isScaledUp,
      isTextFocused,
      setScale,
      setIsScaledUp,
      setIsTextFocused,
      setWrapperStyle,
      trackCursor,
    ]
  );

  const getScaleIcon = () => {
    if (isScaledUp && !isTextFocused) {
      return <FocusDeep12 />;
    } else if (isScaledUp && isTextFocused) {
      return <FocusRegular12 />;
    } else {
      return <FocusZoom12 />;
    }
  };

  const getScaleTooltip = () => {
    if (isScaledUp && !isTextFocused) {
      return "Deep Focus Mode";
    } else if (isScaledUp && isTextFocused) {
      return "Regular Mode";
    } else {
      return "Zoom Mode";
    }
  };

  const getScaleShortcuts = () => {
    if (isScaledUp && !isTextFocused) {
      return ["CTRL", "+"];
    } else if (isScaledUp && isTextFocused) {
      return ["CTRL", "-"];
    } else {
      return ["CTRL", "+"];
    }
  };

  const keyDownHandler = useCallback(
    (e) => {
      if (!isCommandPaletteOpen && e.key === "Escape") {
        return closeDocument();
      }
      scaleTracker(e);
      isTextFocused && trackCursor();
    },
    [
      closeDocument,
      isCommandPaletteOpen,
      scaleTracker,
      trackCursor,
      isTextFocused,
    ]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);

  if (!document) {
    return null;
  }

  return (
    <DocumentModal key={documentId}>
      <StyledModalContent>
        <StyledButtons
          isSidebarRightOpen={isSidebarRightOpen}
          isEditorTyping={isEditorTyping}
        >
          <Tooltip content={getScaleTooltip()} shortcuts={getScaleShortcuts()}>
            <StyledFocusButton
              aria-label="Focus Mode"
              onMouseDown={(event) => handleScale(event)} // Mousedown prevents cursor from leaving editor
              icon={getScaleIcon()}
            />
          </Tooltip>
          <DocumentMoreMenu documentId={documentId} onDelete={closeDocument} />
          <Tooltip content="Close">
            <IconButton
              aria-label="Close"
              onClick={closeDocument}
              icon={<Close12 />}
            />
          </Tooltip>
        </StyledButtons>
        <ScrollableContent ref={scrollRef}>
          <Editor
            paddingOffset={paddingOffset}
            document={yjsDocument}
            provider={provider}
            isProviderSynced={isProviderSynced}
            scale={scale}
            setIsTextFocused={setIsTextFocused}
            isTextFocused={isTextFocused}
            isScaledUp={isScaledUp}
          />
          <ReadOnlyEditorWrapper isProviderSynced={isProviderSynced}>
            <ReadOnlyEditor snapshot={document.snapshot} />
          </ReadOnlyEditorWrapper>
        </ScrollableContent>
        {isStreaming && (
          <>
            <Overlay />
            <StopWriting abortStreaming={abortStreaming} />
          </>
        )}
      </StyledModalContent>
      <AdminTools />
    </DocumentModal>
  );
}
