import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CommandPaletteContext from "../../../../contexts/CommandPaletteContext";
import { getSelectedCount } from "../../../../features/multiSelectSlice";
import {
  CMDK_SKIP_TARGET_CLASSNAME,
  TURN_INTO_PAGES,
} from "../../../../lib/cmd-palette-helpers";
import { Cursor12 } from "../../../Icon";
import { BodyLarge } from "../../../Typography";

const StyledSelectDocumentsPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  flex: 1;
  padding: 1.5rem;
  color: ${({ theme }) => theme.cmdk.foregroundColor};
  overflow: hidden;
  user-select: none;
`;

const StyledBodyLarge = styled(BodyLarge)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
`;

const StyledCursorIcon = styled(Cursor12)`
  flex-shrink: 0;
  margin: 1rem;
  path {
    fill: ${({ theme }) => theme.cmdk.foregroundColor};
  }
`;

export default function SelectDocumentsPage() {
  const { setPromptTypePage, selectedCommand } = useContext(
    CommandPaletteContext
  );
  const selectedDocumentsCount = useSelector(getSelectedCount);

  useEffect(() => {
    if (selectedDocumentsCount > 0) {
      setPromptTypePage();
    }
  }, [selectedDocumentsCount]);

  return (
    <StyledSelectDocumentsPage className={CMDK_SKIP_TARGET_CLASSNAME}>
      <StyledCursorIcon />
      <StyledBodyLarge>
        {selectedCommand === TURN_INTO_PAGES.SELECT_DOCUMENTS_PAGE
          ? "Pick documents you would like to turn into something new"
          : "Pick documents you would like to turn into a draft"}
      </StyledBodyLarge>
    </StyledSelectDocumentsPage>
  );
}
