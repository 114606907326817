import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import CommandPaletteContext from "../../../../../contexts/CommandPaletteContext";
import { commandPaletteBrandVoices } from "../../../../../features/brandVoicesSlice";
import {
  customTonePrompt,
  toneOptions,
} from "../../../../../lib/cmd-palette-helpers";
import Footer from "../../Footer";
import GoBackButton from "../../GoBackButton";
import Command from "../../cmdk/Command";
import CommandInput from "../../cmdk/CommandInput";
import CommandList from "../../cmdk/CommandList";
import ToneOption from "./ToneOption";

function useGetToneOptions({ mention, toneValue }) {
  const brandVoices = useSelector(commandPaletteBrandVoices);

  const baseToneOptions = [
    ...(mention ? [mention] : []),
    ...brandVoices,
    ...toneOptions,
  ];

  const toneOptionsWithCustom = [...baseToneOptions, customTonePrompt];

  return toneValue
    ? toneOptionsWithCustom.filter(
        (item) =>
          item.name.toLowerCase().includes(toneValue.toLowerCase()) ||
          item.id === "custom-tone"
      )
    : baseToneOptions;
}

export default function TonePage() {
  const { setAudiencePage, mention } = useContext(CommandPaletteContext);
  const [toneValue, setToneValue] = useState("");
  const toneOptions = useGetToneOptions({ mention, toneValue });

  return (
    <Command>
      <CommandList>
        {toneOptions.map((toneOption) => (
          <ToneOption
            key={toneOption.id}
            id={toneOption.id}
            value={
              toneOption.id === "custom-tone" ? toneValue : toneOption.name
            }
            type={toneOption.type}
          />
        ))}
      </CommandList>
      <Footer>
        <GoBackButton onClick={setAudiencePage} />
        <CommandInput
          autoFocus
          value={toneValue}
          onValueChange={setToneValue}
          placeholder="Pick a tone"
        />
      </Footer>
    </Command>
  );
}
