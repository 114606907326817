import styled from "styled-components";
import COLORS from "../../theme/colors";
import Button from "../Button";
import { Stop12 } from "../Icon";
import { BodyMedium } from "../Typography";
import Ellipsis from "../Ellipsis";

const StyledButton = styled(Button)`
  background-color: ${COLORS.WHITE};
  p {
    font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  }
  :hover {
    background-color: ${COLORS.BLACK050T};
  }
`;

const StyledIcon = styled(Stop12)`
  margin-right: 1rem;
`;

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 10rem;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledStopWriting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 8px 24px 0 ${COLORS.BLACK100T};
  border: 1px solid ${COLORS.BLACK100T};
  background-color: ${COLORS.WHITE};
  width: 38.75rem;
  padding: 1rem;
  border-radius: 12.5rem;
`;

const StyledStrutIsTyping = styled.div`
  padding: 0.5rem 1.5rem;
  color: ${COLORS.BLACK500T};
`;

export default function StopWriting({ abortStreaming }) {
  return (
    <StyledWrapper>
      <StyledStopWriting>
        <StyledStrutIsTyping>
          <BodyMedium>
            Strut is typing
            <Ellipsis />
          </BodyMedium>
        </StyledStrutIsTyping>
        <StyledButton Icon={StyledIcon} onClick={abortStreaming}>
          Stop Writing
        </StyledButton>
      </StyledStopWriting>
    </StyledWrapper>
  );
}
