import styled from "styled-components";
import { device } from "../css-mixins/media-queries";
import COLORS from "../theme/colors";
import Button from "./Button";
import { useAuth0 } from "@auth0/auth0-react";
import { BodyLarge } from "./Typography";

const PageWrapper = styled.div`
  background-color: ${COLORS.SAND500};
  height: 100vh;
  @media (${device.mobile}) {
    justify-content: flex-start;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

const StyledTitle = styled(BodyLarge)`
  font-weight: 500;
`;

const StyledMessage = styled.div`
  width: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30rem;
  flex-grow: 1;
`;

const StyledBody = styled(BodyLarge)`
  color: ${COLORS.BLACK500T};
  margin: 2rem;
  text-align: center;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export default function AuthError() {
  const { loginWithRedirect } = useAuth0();

  return (
    <PageWrapper>
      <Layout>
        <StyledMessage>
          <StyledTitle>Please verify your email</StyledTitle>
          <StyledBody>
            Please verify your email before continuing. You can login when you
            have completed this step.
          </StyledBody>
          <Button onClick={loginWithRedirect}>Login</Button>
        </StyledMessage>
      </Layout>
    </PageWrapper>
  );
}
