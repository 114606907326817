import { gql } from "@apollo/client";

export const CREATE_STAGE = gql`
  mutation CreateStage($collectionId: ID!, $input: CollectionStageInput!) {
    createStage(collectionId: $collectionId, input: $input) {
      id
      name
      color
      icon
    }
  }
`;

export const DELETE_STAGE = gql`
  mutation DeleteStage($id: ID!) {
    deleteStage(id: $id) {
      id
    }
  }
`;

export const UPDATE_STAGE = gql`
  mutation UpdateStage($id: ID!, $input: CollectionStageInput!) {
    updateStage(id: $id, input: $input) {
      id
      name
      color
      icon
      order
    }
  }
`;
