import DropdownMenu from "../Dropdown/Menu";
import EmptyState from "../Dropdown/EmptyState";
import Item from "./Item";

export default function Menu({
  getItemProps,
  getMenuProps,
  highlightedIndex,
  isOpen,
  items,
  selectedItem,
}) {
  return (
    <DropdownMenu {...getMenuProps()}>
      {isOpen &&
        items.map((item, index) => {
          return (
            <Item
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              index={index}
              isSelected={selectedItem === item}
              item={item}
              key={item.id}
            />
          );
        })}
      {isOpen && !items.length && <EmptyState>No results</EmptyState>}
    </DropdownMenu>
  );
}
