import { useCallback, useState } from "react";
import styled from "styled-components";
import { Folder12 } from "../Icon";
import IconButton from "../IconButton";
import GridItem from "../Page/GridItem";
import WorkspaceMoreMenu from "../WorkspaceMoreMenu";

const StyledIcon = styled(Folder12)`
  flex-shrink: 0;
  & path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledPopoverWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const StyledWrapper = styled.div`
  position: relative;
  a {
    text-decoration: none;
  }

  :not(:hover) {
    ${StyledPopoverWrapper} ${IconButton} {
      :not(:focus-within) {
        opacity: 0;
      }
    }
  }
`;

export default function Workspace({ workspace, showOnSidebar }) {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const closeMoreMenu = useCallback(() => setIsMoreMenuOpen(false), []);
  const openMoreMenu = useCallback(() => setIsMoreMenuOpen(true), []);

  return (
    <StyledWrapper>
      <GridItem
        link={`/c/${workspace.id}`}
        Icon={StyledIcon}
        name={workspace.name}
        showOnSidebar={showOnSidebar}
      />
      <StyledPopoverWrapper>
        <WorkspaceMoreMenu
          closeMenu={closeMoreMenu}
          id={workspace.id}
          isOpen={isMoreMenuOpen}
          setIsOpen={setIsMoreMenuOpen}
          name={workspace.name}
          openMenu={openMoreMenu}
        />
      </StyledPopoverWrapper>
    </StyledWrapper>
  );
}
