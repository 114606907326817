import { useMutation } from "@apollo/client";
import { saveAs } from "file-saver";
import { useCallback, useMemo, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeDocument } from "../features/documentsSlice";
import Menu from "./Menu";
import { GET_COLLECTION, DELETE_DOCUMENT } from "../graphql";
import Popover from "./Popover";
import { Delete12, Link12, More12, Download12 } from "./Icon";
import IconButton from "./IconButton";
import Tooltip from "./Tooltip";
import DocumentWordCount from "./DocumentWordCount";
import { collaborators } from "../features/collectionsSlice";
import useCurrentCollectionId from "../hooks/useCurrentCollectionId";
import DocumentContext from "../contexts/DocumentContext";
import { documentById } from "../features/documentsSlice";

const COPY_LINK_TEXT = "Copy Link";

export default function DocumentMoreMenu({ documentId, onDelete }) {
  const dispatch = useDispatch();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT);
  const [buttonText, setButtonText] = useState(COPY_LINK_TEXT);
  const { collectionId } = useCurrentCollectionId();
  const accessList = useSelector(collaborators(collectionId));
  const { editor } = useContext(DocumentContext);
  const document = useSelector(documentById(documentId));

  const deleteDocument = useCallback(async () => {
    const result = await deleteDocumentMutation({
      variables: { id: documentId },
      refetchQueries: [GET_COLLECTION],
      onCompleted: () => {
        dispatch(removeDocument({ id: documentId }));
      },
    });
    if (result.data) {
      onDelete?.();
    }
  }, [deleteDocumentMutation, documentId, dispatch, onDelete]);

  const copyLink = useCallback(() => {
    window.navigator.clipboard.writeText(window.location.href);

    setButtonText("Copied!");
    setTimeout(() => setButtonText(COPY_LINK_TEXT), 1000);
  }, []);

  const exportMarkdown = useCallback(() => {
    const markdownOutput = editor?.storage.markdown.getMarkdown();
    if (markdownOutput) {
      const fileName =
        document?.name.replace(/[^a-zA-Z0-9]/g, "") || "export-" + documentId;
      saveAs(
        new Blob([markdownOutput], { type: "text/plain" }),
        `${fileName}.md`
      );
    }
    setIsMoreMenuOpen(false);
  }, [editor, setIsMoreMenuOpen, documentId]);

  const items = useMemo(() => {
    return [
      {
        id: "delete-document",
        name: "Delete Document",
        onClick: deleteDocument,
        icon: <Delete12 />,
      },
      ...(accessList.length > 1
        ? [
            {
              id: "copy-link",
              name: buttonText,
              onClick: copyLink,
              icon: <Link12 />,
            },
          ]
        : []),
      {
        id: "export-markdown",
        name: "Export Markdown",
        onClick: exportMarkdown,
        icon: <Download12 />,
      },
      {
        id: "word-count",
        name: "word count",
        customItem: DocumentWordCount,
      },
    ];
  }, [deleteDocument, buttonText, accessList.length, exportMarkdown]);

  return (
    <Popover
      placement={"bottom-end"}
      isOpen={isMoreMenuOpen}
      setIsOpen={setIsMoreMenuOpen}
      closePopover={() => setIsMoreMenuOpen(false)}
      activator={
        <Tooltip content="More Options">
          <IconButton
            icon={<More12 />}
            onClick={() => setIsMoreMenuOpen(true)}
          />
        </Tooltip>
      }
    >
      <Menu items={items} isOpen={isMoreMenuOpen} />
    </Popover>
  );
}
