import { useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getIsTyping } from "../../features/uiSlice";
import CommandPaletteContext from "../../contexts/CommandPaletteContext";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../lib/cmd-palette-helpers";
import COLORS from "../../theme/colors";
import { Sparkle16 } from "../Icon";
import { BodySmall } from "../Typography";
import cmdPaletteContainerStyles from "./cmd-palette-container";
import { device } from "../../css-mixins/media-queries";

const StyledCmdK = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
`;

const StyledKey = styled.span`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.cmdk.borderColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.cmdk.foregroundColor};
`;

const StyledCommandPaletteLauncher = styled.div`
  ${cmdPaletteContainerStyles}
  background: ${({ theme }) => theme.cmdk.backgroundColor};
  bottom: 4rem;
  box-shadow: 0 1rem 2rem 0 ${COLORS.BLACK050T};
  border: 1px solid ${({ theme }) => theme.cmdk.borderColor};
  backdrop-filter: blur(1rem);
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  min-width: 20rem;
  padding: 1rem;
  padding-left: 1.5rem;
  cursor: pointer;
  user-select: none;
  opacity: ${({ isEditorTyping }) => (isEditorTyping ? 0 : 1)};
  transition: min-width 0.1s ease-in-out, opacity 0.5s ease-in-out;

  :hover {
    min-width: 21rem;
  }

  @media (${device.mobile}) {
    display: none;
  }
`;

const StyledSparkleIcon = styled(Sparkle16)`
  flex-shrink: 0;
  path {
    transition: fill 0.1s ease-in-out;
    fill: ${({ theme }) => theme.cmdk.foregroundColor};
  }
  ${StyledCommandPaletteLauncher}:hover & {
    path {
      fill: ${({ theme }) => theme.cmdk.hoverForegroundColor};
    }
  }
`;

export default function CommandPaletteLauncher() {
  const { openCommandPalette } = useContext(CommandPaletteContext);
  const isEditorTyping = useSelector(getIsTyping);

  return (
    <StyledCommandPaletteLauncher
      onClick={openCommandPalette}
      className={CMDK_SKIP_TARGET_CLASSNAME}
      isEditorTyping={isEditorTyping}
    >
      <StyledSparkleIcon />
      <StyledCmdK>
        <StyledKey>
          <BodySmall>⌘</BodySmall>
        </StyledKey>
        <StyledKey>
          <BodySmall>K</BodySmall>
        </StyledKey>
      </StyledCmdK>
    </StyledCommandPaletteLauncher>
  );
}
