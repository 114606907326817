import styled from "styled-components";
import COLORS from "../../theme/colors";
import { Checkmark12 } from "../Icon";

const SelectGroup = styled.div`
  padding: 2px;
  border-radius: 14px;
  background: ${({ theme }) => theme.toggle.backgroundColor};
  cursor: pointer;
`;

const CheckIcon = styled(Checkmark12)`
  path {
    fill: ${({ theme }) => theme.toggle.selectedForegroundColor};
  }
`;
const StyledOption = styled.div`
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: center;
  border-radius: 12px;
  background-color: ${COLORS.WHITE};
  padding: 1rem 2rem;
  font-size: 14px;
  color: ${({ active, theme }) =>
    active
      ? theme.toggle.selectedForegroundColor
      : theme.toggle.foregroundColor};
  background-color: ${({ active, theme }) =>
    !active ? "transparent" : theme.toggle.selectedColor};
  ${({ active }) =>
    active && `box-shadow: 0px 1px 1px 0px ${COLORS.BLACK100T};`}
`;

function Option({ active, children, ...props }) {
  return (
    <StyledOption active={active} {...props}>
      {children}
      {active && <CheckIcon />}
    </StyledOption>
  );
}
export default function TemplateSelector({
  templates,
  setTemplate,
  activeTemplate,
}) {
  return (
    <SelectGroup>
      {Object.keys(templates).map((template) => (
        <Option
          key={template}
          onClick={setTemplate(template)}
          active={activeTemplate === template}
        >
          {templates[template].name}
        </Option>
      ))}
    </SelectGroup>
  );
}
