import { useContext } from "react";
import FrameContext from "../../contexts/FrameContext";
import PlaceholderButton from "../Page/PlaceholderButton";

export default function NewBrandVoiceButton() {
  const { openBrandVoiceModal } = useContext(FrameContext);

  return (
    <PlaceholderButton onClick={openBrandVoiceModal}>
      New Brand Voice
    </PlaceholderButton>
  );
}
