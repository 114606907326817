import styled from "styled-components";
import { BodySmall } from "../../Typography";

const StyledTextArea = styled.textarea`
  border: none;
  background: transparent;
  outline: none;
  padding: 0 1rem 1rem 0;
  width: 100%;
  color: ${({ theme }) => theme.cmdk.hoverForegroundColor};
  font-size: ${(props) => props.theme.typography.bodyLarge.fontSize};
  line-height: ${(props) => props.theme.typography.bodyLarge.lineHeight};
  font-family: ${(props) => props.theme.typography.fontFamily};
  resize: none;
  height: 9rem;
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${({ theme }) => theme.cmdk.foregroundColor};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  user-select: none;
`;

const StyledTextAreaWrapper = styled.div`
  padding-top: 1rem;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.input.backgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.5rem;
  position: relative;
  overflow: hidden;

  :hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.input.borderColor};
  }

  :focus-within {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.input.borderColor};
  }
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 5rem;
`;

const StyledIconWrapper = styled.span`
  display: flex;
  position: absolute;
  left: 1.5rem;
  top: 2.5rem;

  & svg path {
    fill: ${({ theme }) => theme.cmdk.foregroundColor};
  }
`;

export default function TextArea({
  label,
  id,
  placeholder,
  value,
  onChange,
  icon,
  ...rest
}) {
  return (
    <label htmlFor={id}>
      <StyledTextAreaWrapper>
        {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
        <StyledRightWrapper>
          <StyledBodySmall>{label}</StyledBodySmall>
          <StyledTextArea
            value={value}
            id={id}
            placeholder={placeholder}
            onChange={onChange}
            {...rest}
          />
        </StyledRightWrapper>
      </StyledTextAreaWrapper>
    </label>
  );
}
