import { useSelector } from "react-redux";
import { useCallback, useRef } from "react";
import { collectionById } from "../../features/collectionsSlice";
import Modal from "../Modal";
import Form from "./Form";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

export default function Settings() {
  const { collectionId } = useCurrentCollectionId();
  const collection = useSelector(collectionById(collectionId));
  const saveButtonRef = useRef(null);

  const saveChanges = useCallback(() => {
    saveButtonRef.current?.click();
  }, []);

  return (
    <Modal fitContent onClose={saveChanges}>
      {collection.systemRole !== undefined && (
        <Form
          saveButtonRef={saveButtonRef}
          defaultSystemRole={collection.systemRole}
          collectionId={collectionId}
        />
      )}
    </Modal>
  );
}
