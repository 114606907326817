import tippy, { sticky } from "tippy.js";
import { ReactRenderer } from "@tiptap/react";
import { stopPrevent } from "../mouse-event-helpers";

export default function suggestions(Component, options = {}) {
  return {
    render: () => {
      let component = null;
      let localProps = null;
      let popup = null;

      // clipping boundary
      const [boundaryElement] = document.getElementsByClassName(
        "tippy-boundary-element"
      );
      const [appendToElement] =
        document.getElementsByClassName("tippy-append-layer");
      const scrollableElements = document.getElementsByClassName(
        "tippy-scrollable-content"
      );

      const scollableElementsList = Array.from(scrollableElements);

      function preventScroll() {
        scollableElementsList.forEach((element) =>
          element.classList.add("hide-overflow")
        );
      }

      function enableScroll() {
        scollableElementsList.forEach((element) =>
          element.classList.remove("hide-overflow")
        );
      }

      function destroyAll() {
        // destroy popover and menu component after hiding
        if (popup?.[0] && !popup[0].state.isVisible) {
          popup[0].destroy();
          popup = null;
          component.destroy();
          component = null;
        }
      }

      return {
        onStart(props) {
          localProps = { ...props, event: "" };
          component = new ReactRenderer(Component, {
            props: localProps,
            editor: localProps.editor,
          });

          popup = tippy("body", {
            getReferenceClientRect: localProps.clientRect,
            appendTo: appendToElement,
            content: component.element,
            showOnCreate: localProps.editor.isFocused || options.showOnCreate,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            duration: 0,
            animation: "fade-scale",
            sticky: "reference",
            plugins: [sticky],
            onMount: preventScroll,
            onShow: preventScroll,
            onHide: enableScroll,
            onHidden: options.closeOnHidden && props.onClose,
            popperOptions: {
              modifiers: [
                {
                  name: "flip",
                  options: {
                    fallbackPlacements: ["top-start"],
                    boundary: boundaryElement,
                  },
                },
              ],
            },
          });

          popup[0].popper.addEventListener("transitionend", destroyAll);
        },

        onUpdate(props) {
          localProps = { ...props, event: "" };
          component && component.updateProps(localProps);
          popup?.[0].setProps({
            getReferenceClientRect: localProps.clientRect,
          });
        },

        onExit() {
          // hide before destroying to show closing animation properly
          popup?.[0].hide();
        },

        onKeyDown(props) {
          component &&
            component.updateProps({ ...localProps, event: props.event });
          if (
            component &&
            (props.event.key === "ArrowUp" || props.event.key === "ArrowDown")
          ) {
            stopPrevent(props.event);

            return true;
          }
          if (props.event.key === "Escape") {
            stopPrevent(props.event);
            popup?.[0].hide();

            return true;
          } else if (props.event.key === "Enter" && popup?.[0]) {
            stopPrevent(props.event);

            return true;
          }

          return component && component.ref?.onKeyDown(props);
        },
      };
    },
  };
}
