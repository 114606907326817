import { useState } from "react";
import { useParams } from "react-router-dom";
import StreamingContext from "../contexts/StreamingContext";
import useInlineEditStreaming from "../hooks/useInlineEditStreaming";

function StreamingProvider({ children }) {
  const { documentId } = useParams();
  const [editor, setEditor] = useState(null);
  const { abortStreaming, isStreaming } = useInlineEditStreaming({
    documentId,
    editor,
  });

  return (
    <StreamingContext.Provider
      value={{ editor, setEditor, isStreaming, abortStreaming }}
    >
      {children}
    </StreamingContext.Provider>
  );
}

export default StreamingProvider;
