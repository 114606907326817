import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { Folder12, Inbox12 } from "../Icon";
import { BodyMedium } from "../Typography";
import WorkspaceMoreMenu from "../WorkspaceMoreMenu";
import ListItem from "./ListItem";
import IconButton from "../IconButton";
import { COLORS } from "../../theme";

const StyledIcon = styled(Folder12)`
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;
const StyledInboxIcon = styled(Inbox12)`
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledMenuWrapper = styled.div`
  position: absolute;
  right: 0.5rem;
`;

const StyledBodyMedium = styled(BodyMedium)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const isOverStyle = css`
  background-color: ${({ theme }) =>
    theme.button.secondary.hoverBackgroundColor};
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.border.highlight};

  ${StyledBodyMedium} {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
  ${StyledIcon} path, ${StyledInboxIcon} path {
    fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
`;

const isDraggingStyle = css`
  background-color: ${({ theme }) => theme.body.secondaryBackgroundColor};
  box-shadow: 0 2px 4px 0 ${COLORS.BLACK100T},
    0 0 0 1px ${({ theme }) => theme.border.hoverPrimary};

  ${StyledBodyMedium} {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
  ${StyledIcon} path, ${StyledInboxIcon} path {
    fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
`;

const StyledListItem = styled(ListItem)`
  ${({ $isOver }) => $isOver && isOverStyle}
  ${({ $isDragging }) => $isDragging && isDraggingStyle}
  :not(:hover) {
    ${StyledMenuWrapper} ${IconButton} {
      :not(:focus-within) {
        opacity: 0;
      }
    }
  }
`;

export default function WorkspaceItem({ workspace, isOver, isDragging }) {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const closeMoreMenu = useCallback(() => setIsMoreMenuOpen(false), []);
  const openMoreMenu = useCallback(() => setIsMoreMenuOpen(true), []);
  const link = workspace.inbox ? `/inbox` : `/c/${workspace.id}`;

  return (
    <StyledListItem
      $isMoreMenuOpen={isMoreMenuOpen}
      $isOver={isOver}
      $isDragging={isDragging}
      className="fs-mask"
    >
      <NavLink
        to={link}
        className={({ isActive }) => "workspace" + (isActive ? " active" : "")}
      >
        {workspace.inbox ? <StyledInboxIcon /> : <StyledIcon />}
        <StyledBodyMedium>
          {workspace.name === "" ? "Untitled" : workspace.name}
        </StyledBodyMedium>
      </NavLink>
      <StyledMenuWrapper>
        {!workspace.inbox && (
          <WorkspaceMoreMenu
            closeMenu={closeMoreMenu}
            id={workspace.id}
            isOpen={isMoreMenuOpen}
            setIsOpen={setIsMoreMenuOpen}
            tooltipPlacement={"right"}
            name={workspace.name}
            openMenu={openMoreMenu}
            size="small"
            popoverPlacement="right-start"
          />
        )}
      </StyledMenuWrapper>
    </StyledListItem>
  );
}
