import { useContext } from "react";
import styled from "styled-components";
import FrameContext from "../../contexts/FrameContext";
import COLORS from "../../theme/colors";
import Modal from "./Modal";
import StripePricingTable from "./PricingTable";

const StyledBillingModal = styled.div`
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background-color: ${COLORS.WHITE};
  z-index: 1;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 81rem;
`;

export default function BillingModal() {
  const { closeBillingModal } = useContext(FrameContext);

  return (
    <Modal onClose={closeBillingModal}>
      <StyledBillingModal>
        <StyledModalContent>
          <StyledSection>
            <StripePricingTable />
          </StyledSection>
        </StyledModalContent>
      </StyledBillingModal>
    </Modal>
  );
}
