import { useState, useCallback } from "react";
import DocumentContext from "../contexts/DocumentContext";

function DocumentProvider({ children }) {
  const [editor, setEditor] = useState(null);

  const insertContent = useCallback(
    (json) => {
      if (json.content?.[0]?.type !== "title") {
        // insert title node to match document schema
        json.content.unshift({ type: "title" });
      }
      editor?.chain().focus().insertContent(json).run();
    },
    [editor]
  );

  return (
    <DocumentContext.Provider value={{ editor, setEditor, insertContent }}>
      {children}
    </DocumentContext.Provider>
  );
}

export default DocumentProvider;
