import { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import FrameContext from "../../contexts/FrameContext";
import { focusFirstElement } from "../../lib/focus-helpers";
import { COLORS } from "../../theme";

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLORS.BLACK500T};
`;

export default function Modal({ children, onClose }) {
  const { overlayLayer } = useContext(FrameContext);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    focusFirstElement({ element: ref.current });
  }, [ref]);

  useEffect(() => {
    function shortcuts(e) {
      if (e.key === "Escape") {
        onClose();
        e.stopPropagation();
      }
    }
    const modalElement = ref.current;

    modalElement.addEventListener("keydown", shortcuts);
    return () => {
      modalElement.removeEventListener("keydown", shortcuts);
    };
  }, [onClose]);

  return (
    overlayLayer.current &&
    createPortal(
      <StyledModalWrapper ref={ref}>
        <Overlay tabIndex={-1} onClick={onClose} />
        {children}
      </StyledModalWrapper>,
      overlayLayer.current
    )
  );
}
