import styled, { css } from "styled-components";
import COLORS from "../theme/colors";

const avatarStyles = css`
  width: ${({ size }) => (size === "medium" ? "3rem" : "2.5rem")};
  height: ${({ size }) => (size === "medium" ? "3rem" : "2.5rem")};
  border-radius: 50%;
  user-select: none;
`;

const StyledAvatar = styled.img`
  ${avatarStyles}
`;

const StyledStruttAvatar = styled.div`
  ${avatarStyles}
  background-color: ${COLORS.SUNRISE500};
`;

export default function Avatar({ src, size = "medium" }) {
  if (!src) {
    return <StyledStruttAvatar size={size} />;
  }
  return <StyledAvatar size={size} referrerPolicy="no-referrer" src={src} />;
}
