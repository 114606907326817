import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  GET_COLLECTIONS,
  DUPLICATE_COLLECTION,
  LEAVE_COLLECTION,
} from "../graphql";
import { Copy12, Delete12, Hide12, Leave12, More12, Download12 } from "./Icon";
import { collectionById, remove } from "../features/collectionsSlice";
import Menu from "./Menu";
import useDeleteCollection from "../hooks/useDeleteCollection";
import useUpdateCollection from "../hooks/api/useUpdateCollection";
import DeleteModal from "./DeleteModal";
import IconButton from "./IconButton";
import Popover from "./Popover";
import Tooltip from "./Tooltip";
import ExportCollection from "./ExportCollection";

const isOpenButtonStyles = css`
  background-color: ${({ theme }) =>
    theme.button.secondary.hoverBackgroundColor};

  svg * {
    fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
`;

const StyledIconButton = styled(IconButton)`
  ${({ $isOpen }) => $isOpen && isOpenButtonStyles}
`;

export default function WorkspaceMoreMenu({
  id,
  name,
  isOpen,
  closeMenu,
  openMenu,
  size = "large",
  popoverPlacement = "bottom-end",
  tooltipPlacement,
  setIsOpen,
  isInbox,
}) {
  const [duplicateCollectionMutation] = useMutation(DUPLICATE_COLLECTION);
  const [leaveCollectionMutation] = useMutation(LEAVE_COLLECTION);
  const [isExportEnabled, setIsExportEnabled] = useState(false);
  const updateCollection = useUpdateCollection();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const collection = useSelector(collectionById(id));

  const duplicateCollection = useCallback(async () => {
    closeMenu();
    await duplicateCollectionMutation({
      variables: { id },
      refetchQueries: [GET_COLLECTIONS],
      onCompleted: (data) => {
        navigate("/c/" + data.duplicateCollection.id);
      },
    });
  }, [id, navigate, duplicateCollectionMutation, closeMenu]);

  const hasMultipleCollaborators = collection.collaborators?.length > 1;
  const isHidden = !collection.showOnSidebar;

  const leaveCollection = useCallback(async () => {
    closeMenu();
    await leaveCollectionMutation({
      variables: { id },
      refetchQueries: [GET_COLLECTIONS],
      onCompleted: (data) => {
        dispatch(remove({ id: data.leaveCollection.id }));
        navigate("/new");
      },
    });
  }, [id, navigate, leaveCollectionMutation, closeMenu, dispatch]);

  const toggleCollection = useCallback(async () => {
    closeMenu();
    const input = {
      showOnSidebar: isHidden,
    };
    updateCollection({ id, input });
  });

  const {
    isDeleteModalOpen,
    deleteCollection,
    closeDeleteModal,
    openDeleteModal,
  } = useDeleteCollection({ collectionId: id });

  const handleOpenDeleteModal = useCallback(() => {
    closeMenu();
    openDeleteModal();
  }, [closeMenu, openDeleteModal]);

  const items = useMemo(() => {
    if (isInbox) {
      return [
        {
          id: "export-collection",
          name: "Export to Markdown",
          onClick: () => setIsExportEnabled(true),
          icon: <Download12 />,
        },
      ];
    }
    return [
      {
        id: "export-collection",
        name: "Export to Markdown",
        onClick: () => setIsExportEnabled(true),
        icon: <Download12 />,
      },
      {
        id: "toggle-collection",
        name: isHidden ? "Show on Sidebar" : "Hide from Sidebar",
        onClick: toggleCollection,
        icon: <Hide12 />,
      },
      ...(hasMultipleCollaborators
        ? [
            {
              id: "leave-collection",
              name: "Leave Workspace",
              onClick: leaveCollection,
              icon: <Leave12 />,
            },
          ]
        : []),
      {
        id: "delete-collection",
        name: "Delete Workspace",
        onClick: handleOpenDeleteModal,
        icon: <Delete12 />,
      },
      {
        id: "duplicate-collection",
        name: "Duplicate Workspace",
        onClick: duplicateCollection,
        icon: <Copy12 />,
      },
    ];
  }, [
    handleOpenDeleteModal,
    duplicateCollection,
    leaveCollection,
    hasMultipleCollaborators,
    isHidden,
    isInbox,
  ]);

  return (
    <>
      {isExportEnabled && (
        <ExportCollection
          collectionId={id}
          callback={() => setIsExportEnabled(false)}
        />
      )}
      <Popover
        id={`${id}-more-menu`}
        placement={popoverPlacement}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closePopover={closeMenu}
        activator={
          <Tooltip content="More Options" placement={tooltipPlacement}>
            <StyledIconButton
              $isOpen={isOpen}
              size={size}
              aria-label="More menu"
              icon={<More12 />}
              onClick={openMenu}
            />
          </Tooltip>
        }
      >
        <Menu items={items} isOpen={isOpen} />
      </Popover>
      {isDeleteModalOpen && (
        <DeleteModal
          onClose={closeDeleteModal}
          onConfirm={deleteCollection}
          name={name}
          heading={`Are you sure you want to delete the “${
            name || "Untitled"
          }” workspace?`}
          description="All content inside the workspace will also be deleted."
        />
      )}
    </>
  );
}
