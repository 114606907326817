import { useCallback, useState } from "react";
import styled from "styled-components";
import { buttonReset } from "../../../css-mixins/button";
import { Plus12 } from "../../Icon";
import { BodyMedium } from "../../Typography";
import CreateStageInput from "./CreateStageInput";
import { device } from "../../../css-mixins/media-queries";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCreateStage = styled.button`
  ${buttonReset}
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.button.primary.foregroundColor};
  flex: 1;
  padding: 2rem 5rem;
  column-gap: 2rem;

  :hover {
    color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    svg * {
      fill: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    }
  }

  @media (${device.mobile}) {
    padding: 2rem 3rem;
  }
`;

const StyledIconWrapper = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  svg * {
    fill: ${({ theme }) => theme.button.primary.foregroundColor};
  }
`;

export default function CreateStage() {
  const [showInput, setShowInput] = useState(false);
  const handleShowInput = useCallback(() => setShowInput(true), []);
  const handleHideInput = useCallback(() => setShowInput(false), []);

  return (
    <StyledWrapper>
      {showInput ? (
        <CreateStageInput hideInput={handleHideInput} />
      ) : (
        <StyledCreateStage onClick={handleShowInput}>
          <StyledIconWrapper>
            <Plus12 />
          </StyledIconWrapper>
          <BodyMedium>Add a Stage</BodyMedium>
        </StyledCreateStage>
      )}
    </StyledWrapper>
  );
}
