import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import Button from "../Button";
import { ABORT_CHAT_STREAMING } from "../../graphql";
import Ellipsis from "../Ellipsis";

const StyledBotTyping = styled.div`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.button.secondary.ForegroundColor};
  margin: 1rem 2.5rem;
  height: 2rem;
  position: relative;
  :empty {
    display: none;
  }
`;

const StopWritingButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.secondary.BackgroundColor};
`;

const StopWritingButtonWrapper = styled.div`
  top: -1rem;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default function BotTyping({ isTyping, lastRunId, chatId }) {
  const [abortStreamingMutation] = useMutation(ABORT_CHAT_STREAMING);

  const abortStreaming = useCallback(async () => {
    if (lastRunId) {
      await abortStreamingMutation({
        variables: { runId: lastRunId, chatId },
      });
    }
  }, [abortStreamingMutation, lastRunId, chatId]);
  return (
    <StyledBotTyping>
      {isTyping && (
        <>
          Strut is typing
          <Ellipsis />
        </>
      )}
      {isTyping && lastRunId && (
        <StopWritingButtonWrapper>
          <StopWritingButton size="small" onClick={abortStreaming}>
            Stop writing
          </StopWritingButton>
        </StopWritingButtonWrapper>
      )}
    </StyledBotTyping>
  );
}
