import {
  SortableContext,
  defaultAnimateLayoutChanges,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import COLORS from "../../../theme/colors";
import Column from "./Column";

const animateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

export default function SortableColumn({ items, stage, children, ...rest }) {
  const { over, setNodeRef } = useSortable({
    id: stage.id,
    data: {
      stageId: stage.id,
      children: items,
    },
    animateLayoutChanges,
  });
  const isOver = over ? stage.id === over.id || items.includes(over.id) : false;

  const style = {
    backgroundColor: isOver ? COLORS.BLACK100T : undefined,
  };

  return (
    <div ref={setNodeRef}>
      <Column
        style={style}
        stage={stage}
        shouldLockScroll={over?.id && !isOver}
        {...rest}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {children}
        </SortableContext>
      </Column>
    </div>
  );
}
