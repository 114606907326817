import { useFlags } from "launchdarkly-react-client-sdk";
import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collectionsSidebar,
  inboxCollections,
} from "../../features/collectionsSlice";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDndContext } from "@dnd-kit/core";
import COLORS from "../../theme/colors";
import { buttonReset } from "../../css-mixins/button";
import FrameContext from "../../contexts/FrameContext";
import { Plus8, Tone12, BrowseAll12 } from "../Icon";
import { BodyMedium } from "../Typography";
import IntercomButton from "./IntercomButton";
import ListItem from "./ListItem";
import WorkspaceItem from "./WorkspaceItem";
import Plan from "./Plan";
import withDrop from "../Documents/Stages/withDrop";
import RefreshToUpdate from "./RefreshToUpdate";
import SearchButton from "./SearchButton";
import SortableItem from "./SortableItem";
import SortableItemsContext from "../../contexts/SortableItemsContext";

const DroppableWorkspace = withDrop(WorkspaceItem);

const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  margin-top: 8rem;
  padding: 0 2rem 10rem;
  ${({ $isScrolled }) =>
    $isScrolled && `border-top: 1px solid ${COLORS.BLACK050T};`};
  ${({ $hasOverflow }) =>
    $hasOverflow && `border-bottom: 1px solid ${COLORS.BLACK050T}`}
`;

const StyledGroupHeader = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  margin: 0.5rem 1rem;
`;

const StyledIcon = styled.span`
  flex-shrink: 0;
  display: flex;
  svg path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledListWrapper = styled.ul`
  padding: 0;
  margin: 0;
`;

const StyledSidebarGroup = styled.div`
  margin: 0 0 2.5rem 0;
  user-select: none;
`;

const StyledNewWorkspaceButton = styled.button`
  ${buttonReset}
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  text-align: left;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  border-radius: 3.5rem;
  transition: transform 0.1s ease-out;

  :hover {
    color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
    svg path {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }

  :active {
    transform: scale(0.99);
  }
`;

const StyledPlus = styled(Plus8)`
  margin: 0 1.25rem 0 0.25rem;
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin: 2rem 2rem 2.5rem;
`;

export default function WorkspaceList() {
  const { isTokenUsageEnabled } = useFlags();
  const { active } = useDndContext();
  const { openNewWorkspaceModal } = useContext(FrameContext);
  const { sidebarItems } = useContext(SortableItemsContext);

  const scrollRef = useRef(null);
  const collectionsRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const collections = useSelector(collectionsSidebar);
  const inboxWorkspace = useSelector(inboxCollections);
  const hasOverflow =
    scrollRef.current?.scrollHeight > scrollRef.current?.clientHeight;

  useEffect(() => {
    function onIntersect(entries) {
      entries.forEach((entry) => {
        setIsScrolled(!entry.isIntersecting);
      });
    }

    const observer = new window.IntersectionObserver(onIntersect, {
      root: scrollRef.current,
      threshold: 1,
      rootMargin: "-4px",
    });

    observer.observe(collectionsRef.current);

    return () => observer.disconnect();
  }, []);

  const sortableActive = Boolean(active?.id?.startsWith("sortable-c"));

  return (
    <>
      <ScrollWrapper
        ref={scrollRef}
        $isScrolled={isScrolled}
        $hasOverflow={hasOverflow}
      >
        <StyledSidebarGroup>
          <StyledListWrapper>
            <SearchButton />
            {inboxWorkspace && (
              <DroppableWorkspace
                key={inboxWorkspace.id}
                disabled={sortableActive}
                workspace={inboxWorkspace}
              />
            )}
          </StyledListWrapper>
        </StyledSidebarGroup>
        <StyledSidebarGroup>
          <StyledGroupHeader ref={collectionsRef}>Workspaces</StyledGroupHeader>
          <StyledListWrapper>
            {collections.length > 0 && (
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={sidebarItems}
              >
                {collections.map((workspace) => {
                  return (
                    <SortableItem
                      id={`sortable-c-${workspace.id}`}
                      collectionId={workspace.id}
                      disabled={!sortableActive}
                      key={workspace.id}
                    >
                      <DroppableWorkspace
                        disabled={sortableActive}
                        workspace={workspace}
                      />
                    </SortableItem>
                  );
                })}
              </SortableContext>
            )}
            <ListItem>
              <NavLink
                to={`/new`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <StyledIcon>
                  <BrowseAll12 />
                </StyledIcon>
                <BodyMedium>Browse all</BodyMedium>
              </NavLink>
            </ListItem>
            <StyledNewWorkspaceButton
              onClick={() => openNewWorkspaceModal("left-side-bar")}
            >
              <StyledPlus />
              <BodyMedium>Add a workspace</BodyMedium>
            </StyledNewWorkspaceButton>
          </StyledListWrapper>
        </StyledSidebarGroup>
      </ScrollWrapper>
      <RefreshToUpdate />
      <StyledBottomWrapper>
        <ListItem>
          <NavLink
            to={`/brand`}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <StyledIcon>
              <Tone12 />
            </StyledIcon>
            <BodyMedium>Brand</BodyMedium>
          </NavLink>
        </ListItem>
        <IntercomButton />
        {isTokenUsageEnabled && <Plan />}
      </StyledBottomWrapper>
    </>
  );
}
