import { useCallback, useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShouldUseDocuments,
  toggleShouldUseDocuments,
  getSelectedCount,
} from "../../../../features/multiSelectSlice";

const StyledToggleWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  user-select: none;
`;

const StyledLabel = styled.label`
  cursor: pointer;
`;

const StyledHiddenInput = styled.input`
  display: none;
`;

const StyledCircle = styled.span`
  display: flex;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  background-color: ${COLORS.WHITE};
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  transition: transform 0.1s ease-in;
  ${({ $isChecked }) => $isChecked && "transform: translateX(1.5rem);"}
`;

const StyledToggle = styled.div`
  border-radius: 1.25rem;
  width: 4rem;
  height: 2.5rem;
  background-color: ${({ $isChecked }) =>
    $isChecked ? COLORS.SUNRISE500 : COLORS.BLACK500T};

  transition: background-color 0.1s ease-in;
  :hover {
    background-color: ${({ $isChecked }) =>
      $isChecked ? COLORS.SUNRISE600 : COLORS.BLACK600T};
  }
`;

export default function Toggle() {
  const isChecked = useSelector(selectShouldUseDocuments);
  const selectedDocumentsCount = useSelector(getSelectedCount);

  const dispatch = useDispatch();
  const handleToggleChecked = useCallback(
    (e) => {
      dispatch(toggleShouldUseDocuments(e.target.checked));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedDocumentsCount > 0) {
      dispatch(toggleShouldUseDocuments(true));
    }
  }, [dispatch, selectedDocumentsCount]);

  return (
    <StyledToggleWrapper>
      <StyledLabel htmlFor="toggle-switch">
        <StyledHiddenInput
          type="checkbox"
          name="name"
          id="toggle-switch"
          checked={isChecked}
          onChange={handleToggleChecked}
        />
        <StyledToggle $isChecked={isChecked} />
        <StyledCircle $isChecked={isChecked} />
      </StyledLabel>
    </StyledToggleWrapper>
  );
}
