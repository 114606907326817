import { useSubscription } from "@apollo/client";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import FrameContext from "../../contexts/FrameContext";
import { updateDocument } from "../../features/documentsSlice";
import { DOCUMENT_SUBSCRIPTION } from "../../graphql";
import KanbanView from "./KanbanView";
import Stages from "./Stages";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

export default function Documents() {
  const { selectedView } = useContext(FrameContext);
  const dispatch = useDispatch();
  const { isInbox } = useCurrentCollectionId();

  useSubscription(DOCUMENT_SUBSCRIPTION, {
    onData: ({ data }) => {
      dispatch(updateDocument({ document: data.data.documentUpdated }));
    },
  });

  if (selectedView === "kanban" && !isInbox) {
    return <KanbanView />;
  }
  return <Stages isInbox={isInbox} />;
}
