import { useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { getInboxCollectionId } from "../features/inboxSlice";

export default function useCurrentCollectionId() {
  const match = useMatch("/c/:collectionId/*");
  const inboxCollectionId = useSelector(getInboxCollectionId);
  return {
    collectionId: match?.params?.collectionId || inboxCollectionId,
    isInbox: !match,
  };
}
