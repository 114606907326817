import { useState, useMemo } from "react";
import Fuse from "fuse.js";
import {
  BulletedList16,
  Code16,
  Divider16,
  Heading1_16,
  Heading2_16,
  Heading3_16,
  NumberedList16,
  Quote12,
  TodoList16,
} from "../components/Icon";

const defaultSlashMenuItems = [
  {
    id: "heading-1",
    title: "Heading 1",
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 1 })
        .run();
    },
    icon: Heading1_16,
  },
  {
    id: "heading-2",
    title: "Heading 2",
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 2 })
        .run();
    },
    icon: Heading2_16,
  },
  {
    id: "heading-3",
    title: "Heading 3",
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode("heading", { level: 3 })
        .run();
    },
    icon: Heading3_16,
  },
  {
    id: "numbered-list",
    title: "Numbered List",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run();
    },
    icon: NumberedList16,
  },
  {
    id: "bulleted-list",
    title: "Bulleted List",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run();
    },
    icon: BulletedList16,
  },
  {
    id: "todo-list",
    title: "Todo List",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleTaskList().run();
    },
    icon: TodoList16,
  },
  {
    id: "divider",
    title: "Divider",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setHorizontalRule().run();
    },
    icon: Divider16,
  },
  {
    id: "code-block",
    title: "Code Block",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setCodeBlock().run();
    },
    icon: Code16,
  },
  {
    id: "quote-block",
    title: "Quote Block",
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setBlockquote().run();
    },
    icon: Quote12,
  },
];

const fuseOptions = {
  location: 0,
  threshold: 0.2,
  distance: 100,
  keys: ["title"],
};

export default function useSlashCommandItems(query) {
  const [slashMenuItems] = useState(() => {
    const slashMenuItems = [...defaultSlashMenuItems];

    return slashMenuItems;
  });

  const [fuse] = useState(
    () =>
      new Fuse(
        slashMenuItems.filter((item) => !item.sectionHeader),
        fuseOptions
      )
  );

  const results = useMemo(
    () =>
      query ? fuse.search(query).map((result) => result.item) : slashMenuItems,
    [query, slashMenuItems, fuse]
  );

  return results;
}
