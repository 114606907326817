import { gql } from "@apollo/client";

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($input: CollectionInput!) {
    createCollection(input: $input) {
      name
      id
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  mutation UpdateCollection($id: ID!, $input: CollectionInput!) {
    updateCollection(id: $id, input: $input) {
      name
      id
      order
      showOnSidebar
      introduction
      systemRole
      showOnSidebar
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation DeleteCollection($id: ID!) {
    deleteCollection(id: $id) {
      id
    }
  }
`;

export const LEAVE_COLLECTION = gql`
  mutation LeaveCollection($id: ID!) {
    leaveCollection(id: $id) {
      id
    }
  }
`;

export const INVITE_USERS = gql`
  mutation InviteUsers($collectionId: ID!, $emails: [String]!) {
    inviteUsers(collectionId: $collectionId, emails: $emails)
  }
`;

export const REMOVE_USERS_FROM_COLLECTION = gql`
  mutation RemoveUsersFromCollection($collectionId: ID!, $userIds: [ID!]!) {
    removeUsersFromCollection(collectionId: $collectionId, userIds: $userIds)
  }
`;

export const DUPLICATE_COLLECTION = gql`
  mutation DuplicateCollection($id: ID!) {
    duplicateCollection(id: $id) {
      id
    }
  }
`;

export const SET_COLLECTION_BRAND_VOICE = gql`
  mutation SetCollectionBrandVoice($collectionId: ID!, $brandVoiceId: ID) {
    setCollectionBrandVoice(
      collectionId: $collectionId
      brandVoiceId: $brandVoiceId
    ) {
      brandVoiceId
    }
  }
`;
