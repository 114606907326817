import CommandPaletteProvider from "../../providers/CommandPaletteProvider";
import CommandPalette from "./CommandPalette";

export default function CommandPaletteWithProvider() {
  return (
    <CommandPaletteProvider>
      <CommandPalette />
    </CommandPaletteProvider>
  );
}
