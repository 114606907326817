import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import brandVoicesReducer from "../features/brandVoicesSlice";
import collectionsReducer from "../features/collectionsSlice";
import documentsReducer from "../features/documentsSlice";
import authReducer from "../features/authSlice";
import multiSelectSlice from "../features/multiSelectSlice";
import userMetadataReducer from "../features/userMetadataSlice";
import uiReducer from "../features/uiSlice";
import inboxReducer from "../features/inboxSlice";

function sanitizeDocument(doc) {
  return {
    ...doc,
    snapshot: "<<LONG_JSON>>",
  };
}

function actionSanitizer(action) {
  if (action.payload?.documents) {
    action.payload.documents = action.payload.documents.map(sanitizeDocument);
  } else if (action.type === "collections/load") {
    action.payload = action.payload.map((collection) => ({
      ...collection,
      documents: collection.documents?.map(sanitizeDocument),
    }));
  }
  return action;
}

function stateSanitizer(state) {
  return {
    ...state,
    documents: Object.keys(state.documents).reduce((acc, docId) => {
      acc[docId] = sanitizeDocument(state.documents[docId]);
      return acc;
    }, {}),
  };
}

const persistConfig = {
  key: "strut",
  storage,
  whitelist: ["ui"],
};

const rootReducer = combineReducers({
  brandVoices: brandVoicesReducer,
  collections: collectionsReducer,
  documents: documentsReducer,
  auth: authReducer,
  multiSelect: multiSelectSlice,
  userMetadata: userMetadataReducer,
  ui: uiReducer,
  inbox: inboxReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: {
    stateSanitizer,
    actionSanitizer,
  },
});

export const persistor = persistStore(store);
