import { useSubscription, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  ABORT_BRAND_VOICE_GENERATOR_STREAMING,
  BRAND_VOICE_GENERATOR_ENDED,
  BRAND_VOICE_GENERATOR_STARTED,
  BRAND_VOICE_GENERATOR_STREAM,
} from "../graphql";
import Sentry from "../integrations/sentry";

export default function useBrandVoiceGeneratorStreaming({
  brandVoiceId,
  editor,
}) {
  const [abortStreamingMutation] = useMutation(
    ABORT_BRAND_VOICE_GENERATOR_STREAMING
  );
  const [lastRunId, setLastRunId] = useState(null);

  const abortStreaming = useCallback(async () => {
    if (lastRunId) {
      await abortStreamingMutation({
        variables: { runId: lastRunId, brandVoiceId },
      });
      setLastRunId(null);
    }
  }, [abortStreamingMutation, lastRunId, brandVoiceId]);

  const onError = useCallback((error) => {
    Sentry.captureException(error);
  }, []);

  useSubscription(BRAND_VOICE_GENERATOR_STARTED, {
    variables: { brandVoiceId },
    onError,
    onData: ({ data }) => {
      editor.commands.setContent("");
      editor.setEditable(false);
      setLastRunId(data.data.brandVoiceGeneratorStarted.runId);
    },
  });

  useSubscription(BRAND_VOICE_GENERATOR_STREAM, {
    variables: { brandVoiceId },
    onError,
    onData: ({ data }) => {
      editor.setEditable(false);
      const text = data.data?.brandVoiceGeneratorStream?.content;

      if (text) {
        editor.commands.insertContentAt(editor.state.selection.to, text);
      }
    },
  });

  useSubscription(BRAND_VOICE_GENERATOR_ENDED, {
    variables: { brandVoiceId },
    onError,
    onData: () => {
      editor.setEditable(true);
      setLastRunId(null);
    },
  });

  return { abortStreaming, isStreaming: !!lastRunId };
}
