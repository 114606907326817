import { useCallback, useContext } from "react";
import styled from "styled-components";
import CommandPaletteContext from "../../../../../contexts/CommandPaletteContext";
import {
  capitalize,
  customTonePrompt,
  pickExampleDocument,
} from "../../../../../lib/cmd-palette-helpers";
import COLORS from "../../../../../theme/colors";
import { BodyLarge } from "../../../../Typography";
import CommandItem from "../../cmdk/CommandItem";

const StyledBodyLarge = styled(BodyLarge)`
  color: ${COLORS.BLACK500T};
`;

export default function ToneOption({ id, value, type }) {
  const {
    setBrandVoiceId,
    setTone,
    setAudiencePage,
    setSelectExamplePage,
    setMention,
  } = useContext(CommandPaletteContext);

  const handleSelect = useCallback(
    (value) => {
      if (id === pickExampleDocument.id) {
        setSelectExamplePage();
        return;
      }
      if (type !== "document" && type !== "tag") {
        setMention(null);
      }

      // set null to indicate a preset tone is to be used
      setBrandVoiceId(() => (type === "brandVoice" ? id : null));
      setTone(capitalize(value));
      setAudiencePage();
    },
    [
      setAudiencePage,
      setBrandVoiceId,
      setTone,
      setMention,
      setSelectExamplePage,
      type,
      id,
    ]
  );

  return (
    <CommandItem key={value} onSelect={handleSelect} value={value}>
      {id === "custom-tone" && (
        <StyledBodyLarge>{customTonePrompt.name}</StyledBodyLarge>
      )}
      <BodyLarge>{value}</BodyLarge>
    </CommandItem>
  );
}
