import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { listReset } from "../../css-mixins/list";

const SortableListItem = styled.li`
  ${listReset}
`;

export default function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
    data: {
      collectionId: props.collectionId,
      type: "sidebar-workspace",
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <SortableListItem
      isDragging={isDragging}
      ref={props.disabled ? undefined : setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {React.cloneElement(props.children, { isDragging })}
    </SortableListItem>
  );
}
