import { useContext, useCallback, useState } from "react";
import { BubbleMenu } from "@tiptap/react";
import styled from "styled-components";
import { useFlags } from "launchdarkly-react-client-sdk";
import FrameContext from "../../../contexts/FrameContext";
import COLORS from "../../../theme/colors";
import {
  Bold16,
  BulletedList16,
  Italic16,
  NumberedList16,
  Strike16,
  Marker16,
  TodoList16,
  Checkmark12,
  Delete12,
  Link12,
  Edit12,
  Comment12,
} from "../../Icon";
import ToolbarButton from "./ToolbarButton";
import Divider from "./Divider";
import InlineEditButton from "./InlineEditButton";
import Tooltip from "../../Tooltip";
import EditorStyleButton from "./EditorStyleButton";
import { LinkMenuState } from "../../Editor/BubbleLink/extension";
import { BodyMedium } from "../../Typography";

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.body.secondaryBackgroundColor};
  color: ${COLORS.BLACK500T};
  column-gap: 0.5rem;
  width: max-content;
  box-shadow: 0 4px 8px ${COLORS.BLACK100T}, 0 8px 24px ${COLORS.BLACK100T},
    0 0 0 1px ${({ theme }) => theme.border.primary};
`;

const LinkInput = styled.input`
  border: 0;
  padding: 0 1rem;
  outline: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
`;

const DisplayLink = styled.a`
  padding: 0.5rem 1rem;
  min-width: 150px;
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: underline;

    color: ${({ theme }) => theme.body.foregroundColor};
  }
`;

// in order not to re-position menu set same width as parent menu using padding
const PositionWrapper = styled.div`
  position: relative;
  width: 450px;
  align-items: center;
  padding: 0 110px;
`;

export default function Toolbar({ editor }) {
  const { isCommentingEnabled } = useFlags();
  const { overlayLayer } = useContext(FrameContext);
  const [link, setLink] = useState("");

  const updateLink = useCallback(
    (e) => {
      setLink(e.target.value);
    },
    [setLink]
  );

  const resetToolbar = useCallback(() => {
    setLink("");
  }, [setLink]);

  const defaultAction = useCallback(
    (callback) => {
      return (e) => {
        e.preventDefault();
        callback();
        resetToolbar();
      };
    },
    [resetToolbar]
  );

  const toggleItalic = useCallback(
    defaultAction(() => editor.chain().focus().toggleItalic().run()),
    [editor, defaultAction]
  );

  const toggleBold = useCallback(
    defaultAction(() => editor.chain().focus().toggleBold().run()),
    [editor, defaultAction]
  );

  const toggleStrike = useCallback(
    defaultAction(() => editor.chain().focus().toggleStrike().run()),
    [editor, defaultAction]
  );

  const addLink = useCallback(() => {
    const href = link.includes("://") ? link : `https://${link}`;
    editor.chain().focus().setLink({ href }).run();
    editor.commands.closeLinkBubbleMenu();
  }, [editor, defaultAction, link]);

  const saveOnEnter = useCallback(
    (event) => {
      if (event.key === "Enter") {
        addLink();
      }
    },
    [addLink]
  );

  const removeLink = useCallback(
    defaultAction(() => {
      editor.chain().focus().unsetLink().run();
      editor.commands.closeLinkBubbleMenu();
    }),
    [editor, defaultAction]
  );

  const linkBubbleMenuState = editor.storage.linkBubbleMenuHandler.state;

  const toggleBulletList = useCallback(
    defaultAction(() => editor.chain().focus().toggleBulletList().run()),
    [editor, defaultAction]
  );

  const toggleOrderedList = useCallback(
    defaultAction(() => editor.chain().focus().toggleOrderedList().run()),
    [editor, defaultAction]
  );

  const toggleHighlight = useCallback(
    defaultAction(() => editor.chain().focus().toggleHighlight().run()),
    [editor, defaultAction]
  );

  const toggleTodoList = useCallback(
    defaultAction(() => editor.chain().focus().toggleTaskList().run()),
    [editor, defaultAction]
  );

  const currentLink = editor.getAttributes("link")?.href;

  const activateLinkMenu = useCallback(() => {
    editor.commands.editLinkInBubbleMenu();
    setLink(currentLink || "");
  }, [editor, defaultAction, setLink, currentLink]);

  const addComment = useCallback(() => {
    const { from, to } = editor.view.state.selection;
    const text = editor.state.doc.textBetween(from, to, "");
    const fakeId = Math.random().toString(36).substring(7);
    console.log(
      "we can now create new comment and wait from backend for threadId",
      { fakeId, text }
    );
    editor.commands.setComment(fakeId);
  }, [editor]);

  const displayLinkMenu = [
    LinkMenuState.VIEW_LINK_DETAILS,
    LinkMenuState.EDIT_LINK,
  ].includes(linkBubbleMenuState);

  const activeEditLink = useCallback(() => {
    setLink(currentLink || "");
    editor.commands.editLinkInBubbleMenu();
  }, [editor, currentLink]);

  const isLinkEditMode = linkBubbleMenuState === LinkMenuState.EDIT_LINK;

  const fullToolbar = (
    <StyledButtonGroup>
      <EditorStyleButton editor={editor} />
      <Divider />
      <Tooltip
        content="Bold"
        offset={[0, 8]}
        placement="top"
        moveTransition="true"
      >
        <ToolbarButton
          aria-label="bold"
          onClick={toggleBold}
          isSelected={editor.isActive("bold")}
        >
          <Bold16 />
        </ToolbarButton>
      </Tooltip>
      <Tooltip
        content="Italic"
        offset={[0, 8]}
        placement="top"
        moveTransition="true"
      >
        <ToolbarButton
          aria-label="italic"
          onClick={toggleItalic}
          isSelected={editor.isActive("italic")}
        >
          <Italic16 />
        </ToolbarButton>
      </Tooltip>
      <Tooltip
        content="Strikethrough"
        offset={[0, 8]}
        placement="top"
        moveTransition="true"
      >
        <ToolbarButton
          aria-label="strike"
          onClick={toggleStrike}
          isSelected={editor.isActive("strike")}
        >
          <Strike16 />
        </ToolbarButton>
      </Tooltip>
      <Divider />
      <Tooltip
        content="Bulleted List"
        offset={[0, 8]}
        placement="top"
        moveTransition="true"
      >
        <ToolbarButton
          aria-label="bulleted list"
          onClick={toggleBulletList}
          isSelected={editor.isActive("bulletList")}
        >
          <BulletedList16 />
        </ToolbarButton>
      </Tooltip>

      <Tooltip
        content="Ordered List"
        offset={[0, 8]}
        placement="top"
        moveTransition="true"
      >
        <ToolbarButton
          aria-label="ordered list"
          onClick={toggleOrderedList}
          isSelected={editor.isActive("orderedList")}
        >
          <NumberedList16 />
        </ToolbarButton>
      </Tooltip>
      <Tooltip
        content="Todo List"
        offset={[0, 8]}
        placement="top"
        moveTransition="true"
      >
        <ToolbarButton
          aria-label="todo list"
          onClick={toggleTodoList}
          isSelected={editor.isActive("todoList")}
        >
          <TodoList16 />
        </ToolbarButton>
      </Tooltip>
      <Divider />
      <Tooltip
        content="Link"
        placement="top"
        moveTransition="true"
        offset={[0, 8]}
      >
        <ToolbarButton
          aria-label="link"
          isSelected={editor.isActive("link")}
          onClick={activateLinkMenu}
        >
          <Link12 />
        </ToolbarButton>
      </Tooltip>
      {isCommentingEnabled && (
        <>
          <Divider />
          <Tooltip
            content="Comment"
            placement="top"
            moveTransition="true"
            isSelected={editor.isActive("comment")}
            offset={[0, 8]}
          >
            <ToolbarButton aria-label="comment" onClick={addComment}>
              <Comment12 />
              <BodyMedium>Comment</BodyMedium>
            </ToolbarButton>
          </Tooltip>
        </>
      )}
      <Divider />
      <Tooltip
        content="Highlight"
        offset={[0, 8]}
        placement="top"
        moveTransition="true"
      >
        <ToolbarButton
          aria-label="highlight"
          onClick={toggleHighlight}
          isSelected={editor.isActive("highlight")}
        >
          <Marker16 />
        </ToolbarButton>
      </Tooltip>
      <Divider />
      <InlineEditButton editor={editor} />
    </StyledButtonGroup>
  );

  const linkToolbar = (
    <PositionWrapper>
      <StyledButtonGroup>
        {isLinkEditMode && (
          <LinkInput
            type="text"
            autoFocus
            placeholder="Enter a url..."
            value={link}
            onKeyDown={saveOnEnter}
            onChange={updateLink}
          />
        )}
        {!isLinkEditMode && (
          <DisplayLink href={currentLink} target="_blank" rel="noreferrer">
            {currentLink}
          </DisplayLink>
        )}
        <Divider />
        <Tooltip content="Remove Link">
          <ToolbarButton onClick={removeLink}>
            <Delete12 />
          </ToolbarButton>
        </Tooltip>
        <Tooltip content={isLinkEditMode ? "Save" : "Edit Link"}>
          <ToolbarButton onClick={isLinkEditMode ? addLink : activeEditLink}>
            {isLinkEditMode ? <Checkmark12 /> : <Edit12 />}
          </ToolbarButton>
        </Tooltip>
      </StyledButtonGroup>
    </PositionWrapper>
  );

  return (
    <BubbleMenu
      editor={editor}
      updateDelay={200}
      tippyOptions={{
        duration: 200,
        maxWidth: 460,
        appendTo: () => overlayLayer.current,
        onHide: resetToolbar,
      }}
    >
      {displayLinkMenu ? linkToolbar : fullToolbar}
    </BubbleMenu>
  );
}
