import { css } from "styled-components";

export const SIDEBAR_LEFT_WIDTH_PX = 224;
const SIDEBAR_WIDTH_REM = SIDEBAR_LEFT_WIDTH_PX / 8;

function gridTemplateColumns({
  $isSidebarLeftOpen,
  $rightColWidth,
  $isSidebarRightOpen,
}) {
  const sidebarLeftWidth = $isSidebarLeftOpen ? `${SIDEBAR_WIDTH_REM}rem` : 0;
  const sidebarRightWidth = $isSidebarRightOpen ? `${$rightColWidth}px` : 0;

  return `${sidebarLeftWidth} 1fr ${sidebarRightWidth}`;
}

const draggingStyles = css`
  transition: none;
  cursor: col-resize;
`;

export const gridMixin = css`
  display: grid;
  z-index: 1; // Effects document elevation
  transition: all 150ms ease-out, color 0s, background-color 0s;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
  grid-template-columns: ${({
    $isSidebarLeftOpen,
    $rightColWidth,
    $isSidebarRightOpen,
  }) =>
    gridTemplateColumns({
      $isSidebarLeftOpen,
      $rightColWidth,
      $isSidebarRightOpen,
    })};
  ${({ $isDragging }) => $isDragging && draggingStyles}
`;
