import { useContext } from "react";
import { useSelector } from "react-redux";
import {
  hasActiveSubscription as hasActiveSubscriptionSelector,
  userMetadata,
} from "../../features/userMetadataSlice";
import styled from "styled-components";
import { BodySmall, BodyMedium } from "../Typography";
import Button from "../Button";
import FrameContext from "../../contexts/FrameContext";

const DEFAULT_MESSAGE_LIMIT = 25;

const StyledPlan = styled.div`
  padding: 2rem 1.5rem 0;
  user-select: none;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  white-space: nowrap;
`;

const StyledSubscriptionType = styled(BodySmall)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
  flex-shrink: 0;
`;

const StyledUsage = styled(BodyMedium)`
  color: ${({ theme }) => theme.body.foregroundColor};
`;

const StyledFill = styled.div`
  position: absolute;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.plan.foregroundColor};
  width: ${({ $width }) => $width * 100}%;
  max-width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
`;

const StyledMeter = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.plan.backgroundColor};
  height: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
`;

const StyledUpgradeButton = styled(Button)`
  margin-top: 1rem;
  width: fit-content;
`;

export default function Plan() {
  const metadata = useSelector(userMetadata);
  const usage = metadata?.usage.aiMessageCount;
  const subscriptions = metadata?.subscriptions;
  const limitAllotment = metadata.allotments?.find(
    (allotment) => allotment.code === "ai_messages"
  );
  const limit = limitAllotment?.amount || DEFAULT_MESSAGE_LIMIT;
  const hasActiveSubscription = useSelector(hasActiveSubscriptionSelector);
  const activeSubscription = subscriptions.find(
    (sub) => sub.status === "active"
  );
  const usagePercentage = usage / limit;
  const { openBillingModal } = useContext(FrameContext);
  const handleUpgradeButton = function () {
    openBillingModal();
  };

  return (
    <StyledPlan>
      <StyledSubscriptionType>
        {subscriptions?.length > 0 && hasActiveSubscription
          ? activeSubscription?.name
          : "Free Plan"}
      </StyledSubscriptionType>
      <StyledUsage>
        {usage.toLocaleString()}/{limit.toLocaleString()} Messages
      </StyledUsage>
      <StyledMeter>
        <StyledFill $width={usagePercentage} />
      </StyledMeter>
      {activeSubscription?.name !== "Pro Plan" && usagePercentage >= 0.7 && (
        <StyledUpgradeButton
          onClick={handleUpgradeButton}
          usagePercentage={usagePercentage}
        >
          Upgrade
        </StyledUpgradeButton>
      )}
    </StyledPlan>
  );
}
