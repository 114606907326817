import { useMutation } from "@apollo/client";
import { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import FrameContext from "../../contexts/FrameContext";
import { CREATE_COLLECTION, GET_COLLECTIONS } from "../../graphql";
import Sentry from "../../integrations/sentry";
import Modal from "./Modal";
import { BodyLarge, BodyMedium, BodySmall } from "../Typography";
import { Input, Button, CTAButton, ButtonGroup } from "../ModalForm";
import COLORS from "../../theme/colors";
import TemplateSelector from "./TemplateSelector";
import TemplatePreview from "./TemplatePreview";

const templates = {
  default: {
    name: "Complete",
    stages: [
      { name: "Ideas", icon: "StatusIdea16", color: "#00000080" },
      {
        name: "Research",
        icon: "StatusResearch16",
        color: "#995CBF",
      },
      {
        name: "Outline",
        icon: "StatusOutline16",
        color: "#D2461B",
      },
      { name: "Drafts", icon: "StatusDraft16", color: "#FFB546" },
      {
        name: "In Review",
        icon: "StatusReview16",
        color: "#5C6AE4",
      },
      { name: "Done", icon: "StatusDone16", color: "#017264" },
    ],
  },
  basic: {
    name: "Basic",
    stages: [
      { name: "Notes", icon: "StatusIdea16", color: "#00000080" },
      { name: "Drafts", icon: "StatusDraft16", color: "#FFB546" },
      { name: "Done", icon: "StatusDone16", color: "#017264" },
    ],
  },
  notes: {
    name: "Notes",
    stages: [{ name: "Notes", icon: "StatusIdea16", color: "#00000080" }],
  },
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const resetPadding = { padding: 0 };

const StyledBodyLarge = styled(BodyLarge)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  user-select: none;
`;
const BodyTitle = styled(BodyMedium)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  user-select: none;
`;
const StyledDescription = styled(BodyMedium)`
  color: ${({ theme }) => theme.modal.foregroundColor};
  user-select: none;
  margin-bottom: 1rem;
`;

const ModalForm = styled.form`
  padding: 3rem 4rem;
  display: grid;
`;
const StyledInput = styled(Input)`
  width: 293px;
`;

const StyledError = styled(BodySmall)`
  padding: 1rem;
  color: ${COLORS.CRIMSON500};
`;

export default function NewWorkspaceModal() {
  const [createCollection] = useMutation(CREATE_COLLECTION);
  const { closeNewWorkspaceModal } = useContext(FrameContext);
  const [activeTemplate, setActiveTemplate] = useState("default");
  const navigate = useNavigate();
  const [titleValue, setTitleValue] = useState("");
  const [error, setError] = useState(null);

  const setInputValue = useCallback((e) => {
    setTitleValue(e.target.value);
  }, []);

  const handleCreateCollection = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const result = await createCollection({
          variables: {
            input: {
              name: titleValue.trim(),
              template: activeTemplate,
            },
          },
          refetchQueries: [GET_COLLECTIONS],
        });
        if (result.errors) {
          console.log(result.errors);
          Sentry.captureException(result.error);
        } else if (result.data) {
          navigate(`/c/${result.data.createCollection.id}`);
          closeNewWorkspaceModal();
        }
      } catch (err) {
        setError(err.message);
      }
    },
    [
      closeNewWorkspaceModal,
      navigate,
      createCollection,
      titleValue,
      activeTemplate,
    ]
  );

  const setTemplate = useCallback(
    (template) => () => {
      setActiveTemplate(template);
    },
    []
  );

  return (
    <Modal onClose={closeNewWorkspaceModal} style={resetPadding}>
      <ModalContainer>
        <ModalForm onSubmit={handleCreateCollection}>
          {error === "Too many Collections" && (
            <StyledError>
              You’ve reached the max number of Workspaces on your plan. Upgrade
              to add more.
            </StyledError>
          )}
          <StyledBodyLarge>Create a workspace</StyledBodyLarge>
          <div>
            <StyledInput
              name="title-input"
              id="title-input"
              label="Name"
              value={titleValue}
              onChange={setInputValue}
              placeholder="Workspace name..."
            />
          </div>
          <div>
            <BodyTitle>Pick your template</BodyTitle>
            <StyledDescription>
              You can customize after creating
            </StyledDescription>

            <TemplateSelector
              templates={templates}
              setTemplate={setTemplate}
              activeTemplate={activeTemplate}
            />
          </div>
          <ButtonGroup>
            <Button type="button" onClick={closeNewWorkspaceModal}>
              Cancel
            </Button>
            <CTAButton type="submit" disabled={!titleValue.length}>
              Create
            </CTAButton>
          </ButtonGroup>
        </ModalForm>
        <TemplatePreview stages={templates[activeTemplate].stages} />
      </ModalContainer>
    </Modal>
  );
}
