import { useSubscription, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import {
  ABORT_INLINE_EDIT_STREAMING,
  INLINE_EDIT_ENDED,
  INLINE_EDIT_STARTED,
  INLINE_EDIT_STREAM,
} from "../graphql";
import Sentry from "../integrations/sentry";

export default function useInlineEditStreaming({ documentId, editor }) {
  const [content, setContent] = useState("");
  const [abortStreamingMutation] = useMutation(ABORT_INLINE_EDIT_STREAMING);
  const [lastRunId, setLastRunId] = useState(null);

  const abortStreaming = useCallback(async () => {
    if (lastRunId) {
      await abortStreamingMutation({
        variables: { runId: lastRunId, documentId, type: "completion" },
      });
    }
  }, [abortStreamingMutation, lastRunId, documentId]);

  const onError = useCallback((error) => {
    Sentry.captureException(error);
  }, []);

  useSubscription(INLINE_EDIT_STARTED, {
    variables: { documentId },
    onError,
    onData: ({ data }) => {
      editor.setEditable(false);
      setLastRunId(data.data.inlineEditStarted.runId);
      const type = data.data?.inlineEditStarted?.type;

      if (type !== "extend") {
        editor.commands.deleteSelection();
      }
    },
  });

  useSubscription(INLINE_EDIT_STREAM, {
    variables: { documentId },
    onError,
    onData: ({ data }) => {
      editor.setEditable(false);
      const text = data.data?.inlineEditStream?.content;

      if (text) {
        setContent((content) => content + text);
        editor.commands.insertContentAt(editor.state.selection.to, text);
      }
      editor.setEditable(true);
    },
  });

  useSubscription(INLINE_EDIT_ENDED, {
    variables: { documentId },
    onError,
    onData: () => {
      editor.setEditable(true);
      editor
        ?.chain()
        .focus()
        .setTextSelection({
          from: editor.state.selection.to - content.length,
          to: editor.state.selection.to,
        })
        .run();
      setContent("");
      setLastRunId(null);
    },
  });

  return { abortStreaming, isStreaming: !!lastRunId };
}
