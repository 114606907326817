import { useDraggable } from "@dnd-kit/core";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

export default function withDrag(Component) {
  function DraggableComponent({ document, ...rest }) {
    const { collectionId } = useCurrentCollectionId();
    const { attributes, listeners, setNodeRef } = useDraggable({
      id: `draggable-${document.id}`,
      data: {
        collectionId,
        document,
      },
    });

    return (
      <Component
        document={document}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        {...rest}
      />
    );
  }

  return DraggableComponent;
}
