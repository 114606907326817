import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { stopPrevent } from "../../../lib/mouse-event-helpers";
import { BodyMedium } from "../../Typography";
import { COLORS } from "../../../theme";
import useSlashCommandItems from "../../../hooks/useSlashCommandItems";
import SlashCommandItem from "./SlashCommandItem";

const StyledMenu = styled.ul`
  margin: 0;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.menu.backgroundColor};
  box-shadow: 0 4px 8px ${COLORS.BLACK100T}, 0 8px 24px ${COLORS.BLACK100T},
    ${({ theme }) => theme.menu.borderColor};
  color: ${({ theme }) => theme.body.foregroundColor};
  overflow-y: overlay;
  outline: none;
  border-radius: 1.5rem;
  min-width: 23.5rem;
`;

const StyledBodyMedium = styled(BodyMedium)`
  padding: 0.75rem 1.5rem;
`;

export default function SlashCommandList(props) {
  const { command, event, query } = props;
  const items = useSlashCommandItems(query);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => setSelectedIndex(0), [items]);

  const selectItem = useCallback(
    (index) => {
      const item = items[index];

      if (item) {
        setTimeout(() => command(item));
      }
    },
    [command, items]
  );

  useEffect(() => {
    if (event.key === "ArrowUp") {
      stopPrevent(event);
      setSelectedIndex((index) => (index + items.length - 1) % items.length);
    }

    if (event.key === "ArrowDown") {
      stopPrevent(event);
      setSelectedIndex((index) => (index + 1) % items.length);
    }
  }, [event, items]);

  useEffect(() => {
    if (event.key === "Enter") {
      stopPrevent(event);
      selectItem(selectedIndex);
    }
  }, [event, selectItem, selectedIndex]);

  return (
    <StyledMenu {...props}>
      {items.length ? (
        items.map((item, index) => {
          return (
            <SlashCommandItem
              isHighlighted={index === selectedIndex}
              item={item}
              key={item.id}
              selectItemByIndex={selectItem}
              setSelectedIndex={setSelectedIndex}
              index={index}
            />
          );
        })
      ) : (
        <StyledBodyMedium>No results</StyledBodyMedium>
      )}
    </StyledMenu>
  );
}
