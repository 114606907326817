import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token;
      return state;
    },
  },
});

// actions
export const { setToken } = authSlice.actions;

// selectors
export function getToken(state) {
  return state.auth.token;
}

export default authSlice.reducer;
