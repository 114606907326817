import styled from "styled-components";
import { useSelector } from "react-redux";
import { MoveLayerDown16 } from "../Icon";
import { updateAvailable } from "../../features/userMetadataSlice";

const Box = styled.div`
  background: ${({ theme }) => theme.body.secondaryBackgroundColor};
  border: 1px solid ${({ theme }) => theme.border.primary};
  box-shadow: 0 0.5rem 1rem 0 ${({ theme }) => theme.border.secondary};
  border-radius: 1rem;
  margin: 1rem 1rem 0 1rem;
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const StyledText = styled.div`
  b {
    display: block;
    font-weight: 600;
    color: ${({ theme }) => theme.body.foregroundColor};
  }
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
`;

const StyledIcon = styled(MoveLayerDown16)`
  path {
    fill: ${({ theme }) => theme.body.foregroundColor};
  }
`;

export default function RefreshToUpdate() {
  const newVersionAvailable = useSelector(updateAvailable);
  if (!newVersionAvailable) return null;
  return (
    <Box onClick={() => window.location.reload()}>
      <StyledText>
        <b>New version available</b>
        Click to reload
      </StyledText>
      <StyledIcon />
    </Box>
  );
}
