import { useCallback, useContext, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FrameContext from "../../contexts/FrameContext";
import { collectionById } from "../../features/collectionsSlice";
import COLORS from "../../theme/colors";
import { Chat16 } from "../Icon";
import IconButton from "../IconButton";
import ShareModal from "../ShareModal";
import WorkspaceMoreMenu from "../WorkspaceMoreMenu";
import Collaborators from "./Collaborators";
import CollectionName from "./CollectionName";
import DocumentViewToggle from "./DocumentViewToggle";
import BrandVoiceSelector from "./BrandVoiceSelector";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const StyledHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.BLACK500T};
  padding: 2.5rem 3rem 0 3.5rem;
  ${({ isSidebarLeftOpen }) =>
    !isSidebarLeftOpen && `padding-left: 0; margin-left: 6.5rem;`}
  ${({ isSidebarRightOpen }) => !isSidebarRightOpen && `padding-right: 7.5rem;`}
  transition: padding 0.15s ease-in-out, margin 0.15s ease-in-out;
  column-gap: 1rem;
  -webkit-app-region: drag;

  margin-left: ${({ isSidebarLeftOpen }) =>
    isSidebarLeftOpen ? "0" : window.todesktop ? "15rem" : "6.5rem"};
`;

const StyledHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;
const StyledHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
`;

export default function Header({ openSidebarRight, isSidebarLeftOpen }) {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const openMoreMenu = useCallback(() => setIsMoreMenuOpen(true), []);
  const closeMoreMenu = useCallback(() => setIsMoreMenuOpen(false), []);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsShareModalOpen(false), []);
  const openModal = useCallback(() => setIsShareModalOpen(true), []);
  const { collectionId } = useCurrentCollectionId();
  const collection = useSelector(collectionById(collectionId));
  const { isSidebarRightOpen } = useContext(FrameContext);

  return (
    <>
      {isShareModalOpen && <ShareModal onClose={closeModal} />}
      <StyledHeaderRow
        isSidebarLeftOpen={isSidebarLeftOpen}
        isSidebarRightOpen={isSidebarRightOpen}
      >
        <StyledHeaderLeft>
          <CollectionName
            name={collection.name}
            key={collection.id}
            editable={!collection.inbox}
          />
        </StyledHeaderLeft>
        <StyledHeaderRight>
          {!collection.inbox && <Collaborators openModal={openModal} />}
          {!collection.inbox && (
            <BrandVoiceSelector key={`brand-voice-${collection.id}`} />
          )}
          <DocumentViewToggle isInbox={collection.inbox} />
          <WorkspaceMoreMenu
            closeMenu={closeMoreMenu}
            isInbox={collection.inbox}
            id={collection?.id}
            isOpen={isMoreMenuOpen}
            setIsOpen={setIsMoreMenuOpen}
            name={collection.name}
            openMenu={openMoreMenu}
          />
          <IconButton
            icon={<Chat16 />}
            isMobileOnly
            onClick={openSidebarRight}
          />
        </StyledHeaderRight>
      </StyledHeaderRow>
    </>
  );
}
