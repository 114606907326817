import { createSlice, createSelector } from "@reduxjs/toolkit";
import { collectionById } from "./collectionsSlice";
import {
  addDocument,
  updateDocument,
  removeDocument,
  positionDocumentInStage,
} from "./collectionDocuments";

const initialState = {};

const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    load: (state, action) => {
      const { documents } = action.payload;
      documents.forEach((document) => {
        state[document.id] = document;
      });
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addDocument, (state, action) => {
      const { document } = action.payload;

      state[document.id] = document;

      return state;
    });
    builder.addCase(updateDocument, (state, action) => {
      const { document } = action.payload;

      state[document.id] = {
        ...state[document.id],
        ...document,
      };

      return state;
    });
    builder.addCase(removeDocument, (state, action) => {
      const { id } = action.payload;

      delete state[id];
      return state;
    });
    builder.addCase(positionDocumentInStage, (state, action) => {
      // temporarily sets document position until we refetch documents - used as optimistic update
      const { documentId, stageId, position } = action.payload;
      const documents = Object.values(state);

      const othersOrdered = documents
        .filter((doc) => doc.stageId === stageId && doc.id !== documentId)
        .sort((a, b) => a.order - b.order)
        .map((doc) => doc.id);
      const ordered = insert(othersOrdered, position, documentId);

      ordered.forEach((id, index) => {
        state[id].order = index + 1;
      });

      return state;
    });
  },
});

export const { load } = documentsSlice.actions;
export { addDocument, updateDocument, removeDocument };

// selectors
export function documents(state) {
  return state.documents;
}

export function documentById(id) {
  return createSelector(documents, (documents) => documents[id]);
}

export function documentsByCollectionId(collectionId) {
  return createSelector(
    collectionById(collectionId),
    documents,
    (collection, documents) => {
      return (
        collection.documents
          ?.map((documentId) => documents[documentId])
          .filter((doc) => doc) || []
      );
    }
  );
}

export function documentNames(collectionId) {
  return createSelector(documentsByCollectionId(collectionId), (documents) =>
    Object.values(documents)
      .map(({ id, name }) => ({
        name,
        id,
        type: "document",
      }))
      .filter((d) => d.name)
  );
}

export function tagsByCollectionId(collectionId) {
  return createSelector(documentsByCollectionId(collectionId), (documents) => {
    const tags = documents.reduce((accumulator, document) => {
      document.tags?.forEach(({ id, name }) => {
        accumulator[id] = {
          id,
          name,
          type: "tag",
        };
      });
      return accumulator;
    }, {});

    return Object.values(tags);
  });
}

export function mentionsByCollectionId(collectionId) {
  return createSelector(
    documentNames(collectionId),
    tagsByCollectionId(collectionId),
    (documents, tags) => {
      return [...documents, ...tags];
    }
  );
}

export default documentsSlice.reducer;
