import { Node, ReactNodeViewRenderer, nodeInputRule } from "@tiptap/react";
import ImageComponent from "./Image";

const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const Image = Node.create({
  name: "image",

  isolating: true,

  defining: true,

  group: "block",

  draggable: true,

  selectable: true,

  inline: false,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML() {
    return ["div", { "data-type": this.name }];
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      doUpload: {
        default: null,
      },
    };
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageComponent);
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;
          return { src, alt, title };
        },
      }),
    ];
  },
  addStorage() {
    return {
      markdown: {
        serialize: (state, node) => {
          state.write(
            "![" +
              state.esc(node.attrs.alt || "") +
              "](" +
              node.attrs?.src?.replace(/[\(\)]/g, "\\$&") + // eslint-disable-line
              (node.attrs?.title
                ? ' "' + node.attrs.title?.replace(/"/g, '\\"') + '"'
                : "") +
              ")"
          );
        },
      },
    };
  },
});

export default Image;
