import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { SEARCH } from "../../graphql";

export default function useSearch() {
  const [searchQuery] = useLazyQuery(SEARCH);

  const search = useCallback(
    ({ term }) => {
      return searchQuery({
        fetchPolicy: "cache-and-network",
        variables: {
          term,
        },
      });
    },
    [searchQuery]
  );

  return search;
}
