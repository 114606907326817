import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function DesktopRedirect() {
  const location = useLocation();

  useEffect(() => {
    window.location.href = `${import.meta.env.VITE_APP_PROTOCOL}://${
      location.search
    }`;
  }, []);

  return (
    <div>
      <p>The Strut desktop app will open shortly...</p>
    </div>
  );
}
