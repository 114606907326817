import { useSortable } from "@dnd-kit/sortable";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

export default function withSort(Component) {
  function SortableComponent({ document, index, ...rest }) {
    const { collectionId } = useCurrentCollectionId();

    const {
      setNodeRef,
      listeners,
      attributes,
      isDragging,
      transition,
      transform,
    } = useSortable({
      id: document.id,
      data: {
        collectionId,
        document,
        index,
      },
    });

    return (
      <Component
        document={document}
        ref={setNodeRef}
        style={{
          opacity: isDragging ? 0.5 : 1,
          transition,
          transform: transform
            ? `translate(${Math.round(transform.x)}px, ${Math.round(
                transform.y
              )}px)`
            : undefined,
        }}
        {...attributes}
        {...listeners}
        {...rest}
      />
    );
  }

  return SortableComponent;
}
