import styled from "styled-components";
import { listItemMixin } from "../../css-mixins/menu";
import { BodyMedium } from "../Typography";

const StyledCreateNewItem = styled.li`
  ${listItemMixin}
  cursor: pointer;
  margin: 0.5rem;
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `background-color: ${theme.button.secondary.hoverBackgroundColor};`}
`;

export default function CreateNewItem({
  getItemProps,
  index,
  highlightedIndex,
  item,
  inputValue,
}) {
  return (
    <StyledCreateNewItem
      isHighlighted={highlightedIndex === index}
      {...getItemProps({ item, index })}
    >
      <BodyMedium>{`Create "${inputValue}"`}</BodyMedium>
    </StyledCreateNewItem>
  );
}
