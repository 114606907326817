import { mergeAttributes, Node } from "@tiptap/core";

const Title = Node.create({
  name: "title",
  addOptions() {
    return {
      level: 1,
      HTMLAttributes: { class: "document-title" },
    };
  },
  content: "text*",
  marks: "",
  defining: true,
  renderHTML({ HTMLAttributes }) {
    const level = this.options.level;

    return [
      `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addStorage() {
    return {
      markdown: {
        serialize(state, node) {
          state.write("# ");
          state.renderInline(node, false);
          state.closeBlock(node);
        },
      },
    };
  },
});

export default Title;
