import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {};

export const brandVoicesSlice = createSlice({
  name: "brandVoices",
  initialState,
  reducers: {
    load: (state, action) => {
      const brandVoices = action.payload;

      brandVoices.forEach((brandVoice) => {
        state[brandVoice.id] = brandVoice;
      });
      return state;
    },
    remove: (state, action) => {
      delete state[action.payload.id];
      return state;
    },
    update: (state, action) => {
      const brandVoice = action.payload;
      state[brandVoice.id] = brandVoice;
      return state;
    },
  },
});

export const { load, remove, update } = brandVoicesSlice.actions;

// selectors
export function brandVoices(state) {
  return state.brandVoices;
}

export const brandVoicesList = createSelector(brandVoices, (brandVoices) =>
  Object.values(brandVoices)
);

export const commandPaletteBrandVoices = createSelector(
  brandVoicesList,
  (brandVoicesList) =>
    brandVoicesList.map(({ title, id }) => ({
      id,
      name: title,
      type: "brandVoice",
    }))
);

export const brandVoicesSelector = createSelector(
  brandVoicesList,
  (brandVoicesList) =>
    brandVoicesList.map(({ title, id }) => ({
      id,
      name: title,
    }))
);

export function brandVoiceById(id) {
  return createSelector(brandVoices, (brandVoices) => brandVoices[id] || {});
}

export default brandVoicesSlice.reducer;
