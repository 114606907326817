import COLORS from "./colors";

export const LIGHT = {
  name: "Light",
  colorScheme: "light",
  mac: {
    trafficLight: COLORS.BLACK200T,
  },
  body: {
    backgroundColor: COLORS.SAND500,
    secondaryBackgroundColor: COLORS.WHITE,
    subtleBackgroundColor: COLORS.BLACK050T,
    foregroundColor: COLORS.BLACK800T,
    secondaryForegroundColor: COLORS.BLACK500T,
    subtleForegroundColor: COLORS.BLACK300T,
    highlightColor: COLORS.SUNRISE500,
    gradient: {
      start: COLORS.SAND500,
      end: "rgba(245,244,241,0.5)",
    },
  },
  border: {
    primary: COLORS.BLACK100T,
    secondary: COLORS.BLACK050T,
    hoverPrimary: COLORS.BLACK200T,
    hoverSecondary: COLORS.BLACK150T,
    highlight: COLORS.BLACK500T,
  },
  input: {
    backgroundColor: COLORS.BLACK050T,
    foregroundColor: COLORS.BLACK800T,
    borderColor: COLORS.BLACK100T,
    placeholderColor: COLORS.BLACK500T,
  },
  typography: {
    bodyFontSize: "2rem",
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    fontFamily: "Inter, Helvetica, Arial, sans-serif",
    headerLarge: {
      fontWeight: 500,
      fontSize: "3.5rem",
      lineHeight: "4.5rem",
    },
    headerMedium: {
      fontWeight: 500,
      fontSize: "3rem",
      lineHeight: "4rem",
    },
    headerSmall: {
      fontWeight: 500,
      fontSize: "2.5rem",
      lineHeight: "3.5rem",
    },
    headerExtraSmall: {
      fontWeight: 500,
      fontSize: "2.25rem",
      lineHeight: "3.25rem",
    },
    bodyLarge: {
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "3rem",
    },
    bodyMedium: {
      fontWeight: 400,
      fontSize: "1.75rem",
      lineHeight: "2.5rem",
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    labelSmall: {
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.2rem",
    },
  },
  button: {
    primary: {
      backgroundColor: COLORS.BLACK050T,
      foregroundColor: COLORS.BLACK500T,
      hoverBackgroundColor: COLORS.BLACK100T,
      hoverForegroundColor: COLORS.BLACK800T,
    },
    secondary: {
      backgroundColor: "transparent",
      foregroundColor: COLORS.BLACK500T,
      hoverBackgroundColor: COLORS.BLACK050T,
      hoverForegroundColor: COLORS.BLACK800T,
    },
    highlight: {
      backgroundColor: COLORS.SUNRISE500,
      foregroundColor: COLORS.BLACK700T,
      hoverBackgroundColor: COLORS.SUNRISE600,
      hoverForegroundColor: COLORS.BLACK800T,
    },
  },
  toggle: {
    backgroundColor: COLORS.BLACK050T,
    foregroundColor: COLORS.BLACK500T,
    selectedColor: COLORS.WHITE,
    selectedForegroundColor: COLORS.BLACK800T,
  },
  document: {
    backgroundColor: COLORS.WHITE,
    foregroundColor: COLORS.BLACK800T,
    secondaryForegroundColor: COLORS.BLACK300T,
    borderColor: COLORS.BLACK100T,
    hoverBorderColor: COLORS.BLACK200T,
    borderSelected: COLORS.BLACK500T,
    modalBorderColor: COLORS.BLACK100T,
    highlightColor: COLORS.SUNRISE300,
  },
  checkbox: {
    backgroundColor: COLORS.WHITE,
    selectedBackgroundColor: COLORS.BLACK800T,
    foregroundColor: COLORS.BLACK250T,
    hoverForegroundColor: COLORS.BLACK800T,
    selectedForegroundColor: COLORS.WHITE,
    borderColor: COLORS.BLACK250T,
    hoverBorderColor: COLORS.BLACK500T,
  },
  tag: {
    backgroundColor: COLORS.BLACK050T,
    foregroundColor: COLORS.BLACK500T,
  },
  cmdk: {
    backgroundColor: COLORS.WHITE800T,
    hoverBackgroundColor: COLORS.BLACK050T,
    foregroundColor: COLORS.BLACK500T,
    hoverForegroundColor: COLORS.BLACK800T,
    borderColor: COLORS.BLACK100T,
  },
  tooltip: {
    backgroundColor: COLORS.BLACK600T,
    foregroundColor: COLORS.WHITE500T,
  },
  modal: {
    backgroundColor: COLORS.WHITE,
    secondaryBackgroundColor: COLORS.BLACK050T,
    foregroundColor: COLORS.BLACK800T,
  },
  menu: {
    borderColor: `0 0 0 1px ${COLORS.BLACK050T}`,
    backgroundColor: COLORS.WHITE,
  },
  plan: {
    backgroundColor: COLORS.BLACK050T,
    foregroundColor: COLORS.BLACK500T,
  },
};

export const DARK = {
  name: "Dark",
  colorScheme: "dark",
  mac: {
    trafficLight: COLORS.WHITE200T,
  },
  body: {
    backgroundColor: COLORS.CHARCOAL850,
    secondaryBackgroundColor: COLORS.CHARCOAL800,
    subtleBackgroundColor: COLORS.WHITE025T,
    foregroundColor: COLORS.WHITE800T,
    secondaryForegroundColor: COLORS.WHITE500T,
    subtleForegroundColor: COLORS.WHITE300T,
    highlightColor: COLORS.SUNRISE500,
    gradient: {
      start: COLORS.CHARCOAL850,
      end: "rgba(27,28,28,0.5)",
    },
  },
  border: {
    primary: COLORS.WHITE050T,
    hoverPrimary: COLORS.WHITE100T,
    secondary: COLORS.WHITE025T,
    hoverSecondary: COLORS.WHITE050T,
    highlight: COLORS.WHITE500T,
  },
  input: {
    borderColor: COLORS.WHITE050T,
    backgroundColor: COLORS.WHITE050T,
    foregroundColor: COLORS.WHITE800T,
    placeholderColor: COLORS.WHITE500T,
  },
  typography: {
    bodyFontSize: "2rem",
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    fontFamily: "Inter, Helvetica, Arial, sans-serif",
    headerLarge: {
      fontWeight: 500,
      fontSize: "3.5rem",
      lineHeight: "4.5rem",
    },
    headerMedium: {
      fontWeight: 500,
      fontSize: "3rem",
      lineHeight: "4rem",
    },
    headerSmall: {
      fontWeight: 500,
      fontSize: "2.5rem",
      lineHeight: "3.5rem",
    },
    headerExtraSmall: {
      fontWeight: 500,
      fontSize: "2.25rem",
      lineHeight: "3.25rem",
    },
    bodyLarge: {
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: "3rem",
    },
    bodyMedium: {
      fontWeight: 400,
      fontSize: "1.75rem",
      lineHeight: "2.5rem",
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    labelSmall: {
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.2rem",
    },
  },
  button: {
    primary: {
      backgroundColor: COLORS.WHITE050T,
      foregroundColor: COLORS.WHITE500T,
      hoverBackgroundColor: COLORS.WHITE100T,
      hoverForegroundColor: COLORS.WHITE800T,
    },
    secondary: {
      backgroundColor: "transparent",
      foregroundColor: COLORS.WHITE500T,
      hoverBackgroundColor: COLORS.WHITE050T,
      hoverForegroundColor: COLORS.WHITE800T,
    },
    highlight: {
      backgroundColor: COLORS.SUNRISE500,
      foregroundColor: COLORS.BLACK700T,
      hoverBackgroundColor: COLORS.SUNRISE600,
      hoverForegroundColor: COLORS.BLACK800T,
    },
  },
  toggle: {
    backgroundColor: COLORS.WHITE050T,
    foregroundColor: COLORS.WHITE500T,
    selectedColor: COLORS.WHITE100T,
    selectedForegroundColor: COLORS.WHITE800T,
  },
  document: {
    backgroundColor: COLORS.CHARCOAL800,
    foregroundColor: COLORS.WHITE800T,
    secondaryForegroundColor: COLORS.WHITE300T,
    borderColor: COLORS.WHITE100T,
    hoverBorderColor: COLORS.WHITE150T,
    borderSelected: COLORS.WHITE200T,
    modalBorderColor: COLORS.WHITE150T,
    highlightColor: COLORS.SUNRISE200T,
  },
  checkbox: {
    backgroundColor: COLORS.CHARCOAL800,
    selectedBackgroundColor: COLORS.WHITE500T,
    foregroundColor: COLORS.WHITE250T,
    hoverForegroundColor: COLORS.WHITE800T,
    selectedForegroundColor: COLORS.WHITE,
    borderColor: COLORS.WHITE200T,
    hoverBorderColor: COLORS.WHITE500T,
  },
  tag: {
    backgroundColor: COLORS.WHITE050T,
    foregroundColor: COLORS.WHITE500T,
  },
  cmdk: {
    backgroundColor: COLORS.CHARCOAL800T,
    hoverBackgroundColor: COLORS.WHITE100T,
    foregroundColor: COLORS.WHITE500T,
    hoverForegroundColor: COLORS.WHITE800T,
    borderColor: COLORS.WHITE050T,
  },
  tooltip: {
    backgroundColor: COLORS.WHITE200T,
    foregroundColor: COLORS.WHITE500T,
  },
  modal: {
    backgroundColor: COLORS.CHARCOAL800,
    secondaryBackgroundColor: COLORS.WHITE025T,
    foregroundColor: COLORS.WHITE500T,
  },
  menu: {
    borderColor: `inset 0 0 0 1px ${COLORS.WHITE050T}`,
    backgroundColor: COLORS.CHARCOAL800,
  },
  plan: {
    backgroundColor: COLORS.WHITE100T,
    foregroundColor: COLORS.WHITE300T,
  },
};

export const LIGHT_MODE = "LIGHT";
export const DARK_MODE = "DARK";
export const SYSTEM_MODE = "SYSTEM";
export const DARK_MODE_MEDIA_QUERY = "(prefers-color-scheme: dark)";

export function detectTheme() {
  if (typeof window !== "undefined") {
    const isDarkMode = window.matchMedia(DARK_MODE_MEDIA_QUERY).matches;

    return isDarkMode ? DARK_MODE : LIGHT_MODE;
  }

  return LIGHT_MODE;
}

export { COLORS };

export const themes = {
  [LIGHT_MODE]: LIGHT,
  [DARK_MODE]: DARK,
};
