import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import FrameContext from "../contexts/FrameContext";
import useCreateBrandVoice from "../hooks/useCreateBrandVoice";
import CreateNewModal from "./CreateNewModal";

export default function NewBrandVoiceModal() {
  const { closeBrandVoiceModal } = useContext(FrameContext);
  const navigate = useNavigate();
  const [titleValue, setTitleValue] = useState("");
  const createBrandVoice = useCreateBrandVoice();

  const handleCreateBrandVoice = useCallback(
    async (e) => {
      e.preventDefault();
      createBrandVoice(titleValue.trim());
      closeBrandVoiceModal();
    },
    [closeBrandVoiceModal, navigate, titleValue]
  );

  return (
    <CreateNewModal
      onClose={closeBrandVoiceModal}
      header="New Brand Voice"
      description="Train the AI on your unique brand voice"
      onSubmit={handleCreateBrandVoice}
      titleValue={titleValue}
      setTitleValue={setTitleValue}
      placeholder="Brand Voice name..."
    />
  );
}
