import styled from "styled-components";
import { Tone12 } from "../Icon";
import GridItem from "../Page/GridItem";

const StyledWrapper = styled.div`
  position: relative;
  a {
    text-decoration: none;
  }
`;

const StyledIcon = styled(Tone12)`
  flex-shrink: 0;
  & path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

export default function BrandVoice({ brandVoice }) {
  return (
    <StyledWrapper>
      <GridItem
        link={`/brand/${brandVoice.id}`}
        Icon={StyledIcon}
        name={brandVoice.title}
        showOnSidebar={true} //Prevents the Grid Item from applying the hidden workspaces formatting
      />
    </StyledWrapper>
  );
}
