import { useCombobox } from "downshift";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { visuallyHidden } from "../../css-mixins/accessibility";
import { formattedStages } from "../../features/collectionsSlice";
import { documentById } from "../../features/documentsSlice";
import useUpdateDocument from "../../hooks/api/useUpdateDocument";
import { stopPropagation } from "../../lib/event-helpers";
import { filterItems } from "../../lib/menu-helpers";
import DropdownInput from "../Dropdown/Input";
import IconButton from "../IconButton";
import Popover from "../Popover";
import StageIcon from "../StageIcon";
import Menu from "./Menu";
import Tooltip from "../Tooltip";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";
import { themeSelector } from "../../features/uiSlice";
import { invertIconColor } from "../../lib/icon-selector-helpers";

const StyledPopover = styled(Popover)`
  padding: 0;
`;

// hidden label styling for accessibility
const HiddenLabel = styled.label`
  ${visuallyHidden}
`;

export default function StageSelector({ documentId }) {
  const { collectionId } = useCurrentCollectionId();
  const [inputValue, setInputValue] = useState("");
  const resetInput = useCallback(() => setInputValue(""), []);
  const handleInputChange = useCallback(
    (e) => setInputValue(e.target.value),
    []
  );
  const document = useSelector(documentById(documentId));
  const stages = useSelector(formattedStages(collectionId));
  const theme = useSelector(themeSelector);

  const initialSelectedItem = stages.find(
    (item) => item.id === document.stageId
  );
  const items = filterItems(inputValue, stages);
  const updateDocument = useUpdateDocument();
  const handleSelectItem = useCallback(
    (item) => {
      updateDocument({
        id: documentId,
        input: {
          collectionId,
          stageId: item.selectedItem.id,
        },
      });
    },
    [updateDocument, collectionId, documentId]
  );

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    selectedItem,
  } = useCombobox({
    initialSelectedItem,
    defaultHighlightedIndex: 0,
    inputValue,
    items,
    onIsOpenChange: resetInput,
    onSelectedItemChange: handleSelectItem,
  });

  return (
    <div onClick={stopPropagation}>
      <StyledPopover
        placement="bottom-end"
        isOpen={isOpen}
        closePopover={closeMenu}
        activator={
          <Tooltip content="Change Stage">
            <IconButton
              shouldPreventFill
              isOpen={isOpen}
              icon={
                initialSelectedItem && (
                  <StageIcon
                    iconId={initialSelectedItem.icon}
                    color={invertIconColor(initialSelectedItem.color, theme)}
                  />
                )
              }
              {...getToggleButtonProps({
                onClick: stopPropagation,
                tabIndex: 0,
                "aria-label": "stage selector",
              })}
            />
          </Tooltip>
        }
      >
        <HiddenLabel {...getLabelProps()}>Stage Selector</HiddenLabel>
        <DropdownInput
          getInputProps={getInputProps}
          handleInputChange={handleInputChange}
          isOpen={isOpen}
          placeholder="Change status"
        />
        <Menu
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          inputValue={inputValue}
          isOpen={isOpen}
          items={items}
          selectedItem={selectedItem}
        />
      </StyledPopover>
    </div>
  );
}
