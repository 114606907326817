import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useUpdateStage from "../../../hooks/api/useUpdateStage";
import debounce from "lodash/debounce";
import Input from "../../Input";
import { BodyMedium } from "../../Typography";

const StyledInput = styled(Input)`
  box-sizing: content-box;
  padding: 0.5rem 0 0.5rem 1.5rem;
  min-width: ${({ value }) => (value === "" ? "8.5rem" : "0px")};

  :not(:focus) {
    ${({ $width }) => $width && `width: ${$width}px;`}
  }
  :focus {
    ${({ $width }) => $width && `width: ${$width}px;`}
  }
`;

const HiddenSpan = styled(BodyMedium)`
  opacity: 0;
  position: absolute;
  pointer-events: none;
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  white-space: pre;
  padding: 0.5rem 0 0.5rem 1.5rem;
`;

export default function StageInput({
  defaultValue,
  className,
  id,
  onResizeComplete,
}) {
  const updateStage = useUpdateStage();
  const [value, setValue] = useState(defaultValue);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const updateStageName = useCallback(
    debounce((name) => {
      updateStage({ id, input: { name: name.trim() || "Untitled" } });
    }, 500),
    [updateStage, id]
  );

  const updateName = useCallback(
    (e) => {
      setValue(e.target.value);
      updateStageName(e.target.value);
      setWidth(Math.ceil(ref.current.getBoundingClientRect().width));
    },
    [updateStageName, setWidth]
  );

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        setWidth(Math.ceil(entries[0].borderBoxSize[0].inlineSize));
        onResizeComplete();
      }
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [onResizeComplete]);

  return (
    <>
      <HiddenSpan ref={ref}>{value}</HiddenSpan>
      <StyledInput
        value={value}
        className={className}
        onChange={updateName}
        $width={width}
      />
    </>
  );
}
