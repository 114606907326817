import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { CREATE_INLINE_EDITING } from "../graphql";
import useCurrentCollectionId from "./useCurrentCollectionId";

export default function useInlineEditing() {
  const { collectionId } = useCurrentCollectionId();
  const [createInlineEditing] = useMutation(CREATE_INLINE_EDITING);

  const complete = useCallback(
    async ({ text, documentId, type }) => {
      createInlineEditing({
        variables: { collectionId, text, documentId, type },
      });
    },
    [createInlineEditing, collectionId]
  );

  return { complete };
}
