import { useDndContext } from "@dnd-kit/core";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { formattedStages } from "../../../features/collectionsSlice";
import CreateStage from "./CreateStage";
import Stage from "./Stage";
import withDrop from "./withDrop";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const DroppableStage = withDrop(Stage);

const StyledStages = styled.div`
  overflow: auto;
  padding-bottom: 16rem;
  ${({ $isDragging }) => $isDragging && `cursor: grabbing;`}
`;

export default function Stages() {
  const { active } = useDndContext();
  const { collectionId, isInbox } = useCurrentCollectionId();
  const stages = useSelector(formattedStages(collectionId));

  return (
    <StyledStages $isDragging={Boolean(active)}>
      {stages.map((stage) => (
        <DroppableStage key={stage.id} stage={stage} />
      ))}
      {!isInbox && <CreateStage />}
    </StyledStages>
  );
}
