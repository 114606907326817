import { useState, useMemo, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import SortableItemsContext from "../contexts/SortableItemsContext";
import {
  orderedDocumentsByStage,
  collectionsSidebar,
} from "../features/collectionsSlice";

function SortableItemsProvider({ children }) {
  const match = useMatch("/c/:collectionId/*");
  const collectionId = match?.params?.collectionId;
  const defaultItems = useSelector(orderedDocumentsByStage(collectionId));
  const [items, setItems] = useState(null);
  const collections = useSelector(collectionsSidebar);
  const defaultSidebarItems = useMemo(
    () => (collections ? collections.map((c) => `sortable-c-${c.id}`) : []),
    [collections]
  );
  const numOfCollections = collections.length;
  useEffect(() => {
    setSidebarItems(defaultSidebarItems);
  }, [numOfCollections]);
  const [sidebarItems, setSidebarItems] = useState(null);

  return (
    <SortableItemsContext.Provider
      value={{
        items: items || defaultItems,
        setItems,
        sidebarItems: sidebarItems,
        setSidebarItems,
      }}
    >
      {children}
    </SortableItemsContext.Provider>
  );
}

export default SortableItemsProvider;
