import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { GET_BRAND_VOICES, CREATE_BRAND_VOICE } from "../graphql";

export default function useCreateBrandVoice() {
  const [createBrandVoiceMutation] = useMutation(CREATE_BRAND_VOICE);

  const createBrandVoice = useCallback(
    (title) => {
      createBrandVoiceMutation({
        variables: {
          input: {
            title,
          },
        },
        refetchQueries: [GET_BRAND_VOICES],
        onCompleted: () => {
          // TO DO: add to redux store
        },
      });
    },
    [createBrandVoiceMutation]
  );

  return createBrandVoice;
}
