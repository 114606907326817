import styled from "styled-components";
import { getIsTyping } from "../features/uiSlice";
import { useSelector } from "react-redux";
import { useContext } from "react";
import FrameContext from "../contexts/FrameContext";

const ButtonWrapper = styled.div`
  width: 8rem;
  margin-left: 0;
  position: relative;
  z-index: 2;
  opacity: ${({ isSidebarLeftOpen, isEditorTyping }) =>
    !isSidebarLeftOpen && isEditorTyping ? 0 : 1};
  transition: opacity 0.5s ease-in-out;

  html.todesktop-platform-win32 &,
  html.todesktop-platform-linux & {
    display: none;
  }
`;

const TrafficButton = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.mac.trafficLight};
  margin-right: 8px;
  display: inline-block;
`;

const MacOSButtons = () => {
  const isEditorTyping = useSelector(getIsTyping);
  const { isSidebarLeftOpen } = useContext(FrameContext);

  return window.todesktop ? (
    <ButtonWrapper
      isEditorTyping={isEditorTyping}
      isSidebarLeftOpen={isSidebarLeftOpen}
    >
      <TrafficButton />
      <TrafficButton />
      <TrafficButton />
    </ButtonWrapper>
  ) : null;
};

export default MacOSButtons;
