import { useEffect } from "react";
import { RouterProvider, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { platform } from "@todesktop/client-core";
import { PersistGate } from "redux-persist/integration/react";
import { sentryCreateBrowserRouter } from "./integrations/sentry";
import GlobalStyle from "./components/GlobalStyle";
import Frame from "./components/Frame";
import ApolloProvider from "./apollo";
import LandingPage from "./components/LandingPage";
import ThemeProvider from "./components/ThemeProvider";
import { store, persistor } from "./app/store";
import FrameProvider from "./providers/FrameProvider";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import NoAccess from "./components/NoAccess";
import AuthError from "./components/AuthError";
import DesktopRedirect from "./components/DesktopRedirect";

function RequireAuth({ children }) {
  const { error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated === false && location.pathname !== "/inbox") {
      window.localStorage.setItem("locationState", location.pathname);
    }
    if (error?.error !== "access_denied" && !isAuthenticated && !isLoading) {
      loginWithRedirect({
        openUrl: async (url) => {
          if (window.todesktop) {
            return platform.os.openURL(url);
          }
          window.location.href = url;
        },
      });
    }
  }, [location, isAuthenticated, isLoading, loginWithRedirect, error]);

  if (error && error?.error === "access_denied") {
    return <AuthError />;
  }
  if (isLoading || !isAuthenticated) {
    return null;
  }

  return children;
}

const router = sentryCreateBrowserRouter([
  {
    path: "/desktop",
    element: <DesktopRedirect />,
  },
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/flowless",
    element: <LandingPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
  },
  {
    path: "/no-access",
    element: <NoAccess />,
  },
  {
    path: "/*",
    element: (
      <RequireAuth>
        <ApolloProvider>
          <FrameProvider>
            <Frame />
          </FrameProvider>
        </ApolloProvider>
      </RequireAuth>
    ),
  },
]);

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <GlobalStyle />
          <RouterProvider router={router} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
