import { useMemo, useState, useCallback } from "react";
import Menu from "../../Menu";
import Popover from "../../Popover";
import {
  ChevronDown8,
  Heading1_16,
  Heading2_16,
  Heading3_16,
  Heading4_16,
  Text16,
} from "../../Icon";
import Tooltip from "../../Tooltip";
import ToolbarButton from "./ToolbarButton";
import { BodyMedium } from "../../Typography";

export default function EditorStyleButton({ editor }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleHeading1 = useCallback(
    () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    [editor]
  );
  const toggleHeading2 = useCallback(
    () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    [editor]
  );
  const toggleHeading3 = useCallback(
    () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    [editor]
  );
  const toggleHeading4 = useCallback(
    () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
    [editor]
  );
  const toggleParagraph = useCallback(
    () => editor.commands.setParagraph(),
    [editor]
  );
  const getSelectedTextStyle = () => {
    const { selection } = editor.state;

    if (!selection || selection.empty) {
      return "Text";
    }

    const parentNode = selection.$from.parent;

    if (parentNode.type.name !== "heading") {
      return "Text";
    }

    return `Heading ${parentNode.attrs.level}`;
  };

  const items = useMemo(() => {
    return [
      {
        id: "text",
        name: "Text",
        icon: <Text16 />,
        onClick: () => {
          toggleParagraph();
          setIsOpen(false);
        },
      },
      {
        id: "heading-1",
        name: "Heading 1",
        icon: <Heading1_16 />,
        onClick: () => {
          toggleHeading1();
          setIsOpen(false);
        },
      },
      {
        id: "heading-2",
        name: "Heading 2",
        icon: <Heading2_16 />,
        onClick: () => {
          toggleHeading2();
          setIsOpen(false);
        },
      },
      {
        id: "heading-3",
        name: "Heading 3",
        icon: <Heading3_16 />,
        onClick: () => {
          toggleHeading3();
          setIsOpen(false);
        },
      },
      {
        id: "heading-4",
        name: "Heading 4",
        icon: <Heading4_16 />,
        onClick: () => {
          toggleHeading4();
          setIsOpen(false);
        },
      },
    ];
  }, [
    toggleHeading1,
    toggleHeading2,
    toggleHeading3,
    toggleHeading4,
    toggleParagraph,
  ]);

  return (
    <Popover
      placement={"bottom-start"}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closePopover={() => setIsOpen(false)}
      activatorBufferInPx={8}
      activator={
        <Tooltip
          content="Style Options"
          offset={[0, 8]}
          placement="top"
          moveTransition="true"
        >
          <ToolbarButton
            aria-label="style options"
            onClick={() => setIsOpen(!isOpen)}
          >
            <BodyMedium>{getSelectedTextStyle()}</BodyMedium>
            <ChevronDown8 />
          </ToolbarButton>
        </Tooltip>
      }
    >
      <Menu items={items} isOpen={isOpen} />
    </Popover>
  );
}
