import styled from "styled-components";
import { listItemMixin } from "../../css-mixins/menu";
import { BodyMedium } from "./../Typography";
import { Checkmark12 } from "./../Icon";

const StyledBodyMedium = styled(BodyMedium)`
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  padding-left: ${({ isSelected }) => (isSelected ? "1rem" : "2.5rem")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledCheckmark = styled(Checkmark12)`
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledItem = styled.li`
  ${listItemMixin}
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverBackgroundColor};
    ${StyledBodyMedium} {
      color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
    ${StyledCheckmark} {
      path {
        fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
      }
    }
  }
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `background-color: ${theme.button.secondary.hoverBackgroundColor};
    ${StyledBodyMedium} {
      color: ${theme.button.secondary.hoverForegroundColor};
    }
    ${StyledCheckmark} {
      path {
        fill: ${theme.button.secondary.hoverForegroundColor};
      }
    }
    `}
`;

export default function Item({
  item,
  index,
  highlightedIndex,
  isSelected,
  getItemProps,
}) {
  return (
    <StyledItem
      isHighlighted={highlightedIndex === index}
      {...getItemProps({ item, index })}
    >
      {isSelected && <StyledCheckmark />}
      <StyledBodyMedium isSelected={isSelected}>{item.name}</StyledBodyMedium>
    </StyledItem>
  );
}
