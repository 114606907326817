import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_COLLECTIONS, UPDATE_COLLECTION } from "../../graphql";
import { update } from "../../features/collectionsSlice";

export default function useUpdateDocument() {
  const [updateCollectionMutation] = useMutation(UPDATE_COLLECTION);
  const dispatch = useDispatch();

  const updateCollection = useCallback(
    ({ id, input }) => {
      dispatch(update({ id, input }));
      return updateCollectionMutation({
        variables: {
          id,
          input,
        },
        fetchPolicy: "no-cache",
        refetchQueries: [GET_COLLECTIONS],
      });
    },
    [updateCollectionMutation]
  );

  return updateCollection;
}
