import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Youtube from "@tiptap/extension-youtube";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Title from "../../../shared/title-extension.mjs";
import CustomDocument from "../../Tiptap/CustomDocument";
import embedStyles from "../../Tiptap/embedStyles";
import Comment from "../../../shared/comment-extension.mjs";

const SCALE_FACTOR = 2;
const MAX_PREVIEW_HEIGHT_PX = 417;
const MAX_PREVIEW_HEIGHT_RM = MAX_PREVIEW_HEIGHT_PX / 8;

const StyledWrapper = styled.div`
  position: relative;
  padding: 0.25rem 0.75rem;
  max-height: ${MAX_PREVIEW_HEIGHT_RM}rem;
  overflow: hidden;
  ${({ $height }) => $height && `height: ${$height}px;`}
  pointer-events: none;
`;

const StyledEditor = styled.div`
  width: calc(100% * ${SCALE_FACTOR});
  transform: scale(0.5) translateX(-50%) translateY(-50%);

  .ProseMirror {
    overflow: hidden;
  }

  ${embedStyles}

  .ProseMirror {
    a {
      color: ${({ theme }) => theme.body.foregroundColor};
      cursor: pointer;
    }

    h1 {
      font-size: ${({ theme }) => theme.typography.headerMedium.fontSize};
    }
    h2 {
      font-size: ${({ theme }) => theme.typography.headerMedium.fontSize};
    }
    h3 {
      font-size: ${({ theme }) => theme.typography.headerSmall.fontSize};
    }

    .document-title {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
      font-size: ${({ theme }) => theme.typography.headerLarge.fontSize};
      line-height: ${({ theme }) => theme.typography.headerLarge.lineHeight};
      color: ${({ theme }) => theme.body.foregroundColor};
      padding: 1.5rem 0 1rem;
      margin: 0;
      padding-right: calc(2rem * ${SCALE_FACTOR});
    }

    ul p,
    ol p {
      margin: 0.75rem 0;
    }
    ul,
    ol {
      padding-left: 2.7rem;
      li {
        padding-left: 0.8rem;
      }
    }
    hr {
      height: 1px;
      background-color: ${({ theme }) => theme.border.primary};
      margin: 2rem 0;
      border: 0;
    }

    ul[data-type="taskList"] {
      list-style: none;
      padding: 0;
      margin: -0.35rem 0 -0.375rem -0.25rem;

      li {
        display: flex;
        align-items: top;
        padding: 0;
        margin: 0;

        p {
          margin: 0.375rem 0;
        }

        > label {
          flex: 0 0 auto;
          margin-right: 0.75rem;
          user-select: none;
          height: 24px;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.4rem;
        }

        > div {
          flex: 1 1 auto;
        }
      }

      input[type="checkbox"] {
        cursor: pointer;
        width: 16px;
        height: 16px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 2rem;
        border: 2px solid ${({ theme }) => theme.checkbox.borderColor};
        outline: none;
        transition: background-color 0.1s ease;
        position: relative;

        &:hover {
          border: 2px solid ${({ theme }) => theme.checkbox.hoverBorderColor};
        }
      }

      input[type="checkbox"]:checked::after {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        content: "";
        background-image: url('data:image/svg+xml;utf8,<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.862 0.862007L2.66667 5.05734L1.138 3.52867C1.01227 3.40723 0.843864 3.34004 0.669066 3.34156C0.494268 3.34308 0.32706 3.41319 0.203454 3.53679C0.0798488 3.6604 0.00973621 3.82761 0.00821726 4.00241C0.00669832 4.1772 0.0738946 4.34561 0.195333 4.47134L2.19533 6.47134C2.32035 6.59632 2.48989 6.66653 2.66667 6.66653C2.84344 6.66653 3.01298 6.59632 3.138 6.47134L7.80467 1.80467C7.92611 1.67894 7.9933 1.51054 7.99178 1.33574C7.99026 1.16094 7.92015 0.993733 7.79655 0.870128C7.67294 0.746522 7.50573 0.67641 7.33093 0.674891C7.15614 0.673372 6.98774 0.740568 6.862 0.862007V0.862007Z" fill="white"/></svg>');
        background-repeat: no-repeat;
        background-position: center;
      }

      input[type="checkbox"]:checked {
        background-color: ${({ theme }) =>
          theme.checkbox.selectedBackgroundColor};
        border: 2px transparent;
      }
    }
    mark {
      background-color: ${({ theme }) => theme.document.highlightColor};
      border-radius: 0.25em;
      box-decoration-break: clone;
      padding: 0.125em 0;
      color: ${({ theme }) => theme.document.foregroundColor};
    }
    table {
      border-collapse: collapse;
      table-layout: fixed;
      margin: 0;
      overflow: hidden;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.border.primary};
      border-radius: 6px;
      td,
      th {
        min-width: 1em;
        border-right: 1px solid ${({ theme }) => theme.border.primary};
        border-bottom: 1px solid ${({ theme }) => theme.border.primary};
        padding: 1rem 1.5rem;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin: 0;
        }
      }

      td:last-child,
      th:last-child {
        border-right: 0;
      }

      tr:last-child td,
      tr:last-child th {
        border-bottom: 0;
      }

      th {
        font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
        text-align: left;
        background: ${({ theme }) => theme.body.subtleBackgroundColor};
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: ${({ theme }) => theme.body.subtleBackgroundColor};
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -1px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: ${({ theme }) => theme.border.primary};
        pointer-events: none;
      }
    }

    .tableWrapper {
      overflow-x: auto;
    }
  }
`;

const LinearGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    ${({ theme }) => theme.body.secondaryBackgroundColor} 69.27%
  );
`;

export default function Editor({ document, setIsMeasuring }) {
  const { snapshot } = document;
  const [height, setHeight] = useState(undefined);
  const [showGradient, setShowGradient] = useState(false);
  const editorRef = useRef(null);
  const editor = useEditor({
    extensions: [
      CustomDocument,
      Image.configure({
        HTMLAttributes: {
          class: "strut-image",
        },
      }),
      StarterKit.configure({
        document: false,
      }),
      Title,
      Youtube,
      Link,
      TaskItem,
      TaskList,
      Highlight.configure({
        multicolor: true,
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Comment,
    ],
    editable: false,
    content: snapshot,
  });

  useEffect(() => {
    editor && editor.commands.setContent(snapshot);
  }, [editor, snapshot]);

  useEffect(() => {
    const editorElement = editorRef.current;
    const observer = new ResizeObserver((entries) => {
      const clientHeight = entries[0].target.clientHeight;
      if (clientHeight === 0) {
        return;
      }
      if (clientHeight / SCALE_FACTOR < MAX_PREVIEW_HEIGHT_PX) {
        setHeight(clientHeight / SCALE_FACTOR);
      } else {
        setHeight(MAX_PREVIEW_HEIGHT_PX);
      }

      if (clientHeight > MAX_PREVIEW_HEIGHT_PX) {
        setShowGradient(clientHeight > MAX_PREVIEW_HEIGHT_PX);
      }

      setIsMeasuring(false);
    });
    observer.observe(editorRef.current);
    return () => editorElement && observer.unobserve(editorElement);
  }, []);

  return (
    <StyledWrapper $height={height}>
      <StyledEditor ref={editorRef}>
        <EditorContent editor={editor} />
      </StyledEditor>
      {showGradient && <LinearGradient />}
    </StyledWrapper>
  );
}
