import styled from "styled-components";
import { useCallback, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useGetCollections from "../../hooks/useGetCollections";
import useGetBrandVoices from "../../hooks/useGetBrandVoices";
import useGetUserMetadata from "../../hooks/useGetUserMetadata";
import { collectionsSidebar } from "../../features/collectionsSlice";
import COLORS from "../../theme/colors";
import { device } from "../../css-mixins/media-queries";
import FrameContext from "../../contexts/FrameContext";
import { getIsSearchOpen, toggleSearch } from "../../features/uiSlice";

import useInbox from "../../hooks/useInbox";

import DemoContentLoader from "../DemoContentLoader";
import Search from "./Search";
import WorkspaceList from "./WorkspaceList";

const Overlay = styled.div`
  @media (${device.desktop}) {
    display: none;
  }

  @media (${device.mobile}) {
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${COLORS.BLACK500T};
  }
`;

const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (${device.desktop}) {
    border-right: 1px solid ${({ theme }) => theme.border.secondary};
    max-width: 28rem;
    width: 100%;
  }

  @media (${device.mobile}) {
    position: fixed;
    background-color: ${({ theme }) => theme.body.backgroundColor};
    z-index: 3;
    height: 100%;
    top: 0;
    left: 0;
    right: 8rem;
    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0);" : "translateX(-100%);"};
    transition: transform 0.2s ease-in-out;
  }
`;

export default function SidebarLeft({ isOpen }) {
  const { toggleSidebarLeft } = useContext(FrameContext);
  useGetCollections();
  useGetBrandVoices();
  useGetUserMetadata();
  useInbox();

  const isSearchOpened = useSelector(getIsSearchOpen);
  const dispatch = useDispatch();

  const collections = useSelector(collectionsSidebar);

  const toggleSearchDisplay = useCallback(() => {
    return dispatch(toggleSearch());
  }, [dispatch]);

  const shortcutSidebar = useCallback(
    (e) => {
      if (e.ctrlKey && e.key === "`") {
        toggleSidebarLeft();
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "/") {
        toggleSearchDisplay();
        if (!isOpen) {
          toggleSidebarLeft();
        }
      }
    },
    [toggleSidebarLeft, toggleSearchDisplay, isOpen]
  );

  useEffect(() => {
    window.addEventListener("keydown", shortcutSidebar);
    return () => {
      window.removeEventListener("keydown", shortcutSidebar);
    };
  }, [shortcutSidebar]);

  return (
    <>
      {isOpen && <Overlay onClick={toggleSidebarLeft} />}
      <StyledSidebar isOpen={isOpen}>
        {isSearchOpened && <Search toggleSearch={toggleSearchDisplay} />}
        {!isSearchOpened && <WorkspaceList />}
        {collections.length === 0 && <DemoContentLoader />}
      </StyledSidebar>
    </>
  );
}
