import { gql } from "@apollo/client";

export const CREATE_INLINE_EDITING = gql`
  mutation CreateInlineEditing(
    $collectionId: ID
    $text: String!
    $documentId: ID!
    $type: String!
  ) {
    createInlineEditing(
      collectionId: $collectionId
      text: $text
      documentId: $documentId
      type: $type
    ) {
      documentId
    }
  }
`;

export const ABORT_INLINE_EDIT_STREAMING = gql`
  mutation AbortInlineEditStreaming(
    $runId: String!
    $documentId: ID!
    $type: String!
  ) {
    abortInlineEditStreaming(
      runId: $runId
      documentId: $documentId
      type: $type
    ) {
      status
    }
  }
`;
