import { useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import FrameContext from "../../contexts/FrameContext";
import { buttonReset } from "../../css-mixins/button";
import { device } from "../../css-mixins/media-queries";
import { CMDK_SKIP_TARGET_CLASSNAME } from "../../lib/cmd-palette-helpers";
import { COLORS } from "../../theme";
import { Grid12, Kanban12, List12 } from "../Icon";
import Tooltip from "../Tooltip";

const LIST_TOGGLE_LEFT_REM = 0.25;
const GRID_TOGGLE_LEFT_REM = 4.75;
const KANBAN_TOGGLE_LEFT_REM = 9.25;

const SelectedIndicator = styled.div`
  left: ${({ $left }) => $left}rem;
  width: 4.5rem;
  height: 3rem;
  border-radius: 2rem;
  position: absolute;
  transition: left 100ms ease-out;
  background-color: ${({ theme }) => theme.toggle.selectedColor};
  box-shadow: 0 1px 1px ${COLORS.BLACK100T};
`;

const StyledIcon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg * {
    fill: ${({ theme, isSelected }) =>
      isSelected
        ? theme.button.secondary.foregroundColor
        : theme.button.secondary.foregroundColor};
  }
`;

const hoverStyles = css`
  :hover {
    ${StyledIcon} {
      svg * {
        fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
      }
    }
  }
  :active {
    transform: scale(0.95);
  }
`;

const StyledButton = styled.button`
  ${buttonReset}
  padding: 0.75rem 1.5rem;
  border-radius: 1.75rem;
  width: 4.5rem;
  position: relative;
  z-index: 1;
  transition: transform 0.1s ease-out;

  ${({ isSelected }) => !isSelected && hoverStyles}
`;

const StyledToggle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 1.75rem;
  background: ${({ theme }) => theme.toggle.backgroundColor};
  padding: 0.25rem;
  @media (${device.mobile}) {
    ${({ shouldShowOnMobile }) => !shouldShowOnMobile && "display: none;"}
  }
`;

export default function DocumentViewToggle({ isInbox }) {
  const { selectedView, changeView } = useContext(FrameContext);
  const setGridView = useCallback(() => changeView("grid"), [changeView]);
  const setListView = useCallback(() => changeView("list"), [changeView]);
  const setKanbanView = useCallback(() => changeView("kanban"), [changeView]);

  const viewPositions = {
    list: LIST_TOGGLE_LEFT_REM,
    grid: GRID_TOGGLE_LEFT_REM,
    kanban: isInbox ? GRID_TOGGLE_LEFT_REM : KANBAN_TOGGLE_LEFT_REM,
  };

  return (
    <StyledToggle className={CMDK_SKIP_TARGET_CLASSNAME}>
      <Tooltip content="List View" offset={[0, 6]}>
        <StyledButton
          aria-label="list view"
          onClick={setListView}
          isSelected={selectedView === "list"}
        >
          <StyledIcon isSelected={selectedView === "list"}>
            <List12 />
          </StyledIcon>
        </StyledButton>
      </Tooltip>

      <Tooltip content="Grid View" offset={[0, 6]}>
        <StyledButton
          aria-label="grid view"
          onClick={setGridView}
          isSelected={selectedView === "grid"}
        >
          <StyledIcon isSelected={selectedView === "grid"}>
            <Grid12 />
          </StyledIcon>
        </StyledButton>
      </Tooltip>

      {!isInbox && (
        <Tooltip content="Kanban View" offset={[0, 6]}>
          <StyledButton
            aria-label="kanban view"
            onClick={setKanbanView}
            isSelected={selectedView === "kanban"}
          >
            <StyledIcon isSelected={selectedView === "kanban"}>
              <Kanban12 />
            </StyledIcon>
          </StyledButton>
        </Tooltip>
      )}

      <SelectedIndicator $left={viewPositions[selectedView] || 0} />
    </StyledToggle>
  );
}
