import { useCallback, useState } from "react";
import useUpdateStage from "../../../hooks/api/useUpdateStage";
import Input from "../../Input";

export default function StageInput({
  defaultValue,
  className,
  id,
  onBlur,
  ...rest
}) {
  const updateStage = useUpdateStage();
  const [value, setValue] = useState(defaultValue);

  const handleUpdateStage = useCallback(() => {
    updateStage({ id, input: { name: value.trim() || "Untitled" } });
    onBlur?.();
  }, [updateStage, value, id]);

  return (
    <Input
      className={className}
      onBlur={handleUpdateStage}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      {...rest}
    />
  );
}
