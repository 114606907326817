import { capitalize } from "./cmd-palette-helpers";

const SELECT_DOCUMENTS_PAGE = "SELECT_DOCUMENTS_PAGE_TURN_INTO";
const PROMPT_TYPE_PAGE = "PROMPT_TYPE_PAGE";
const AUDIENCE_PAGE = "AUDIENCE_PAGE";
const SELECT_EXAMPLE_PAGE = "SELECT_EXAMPLE_PAGE";
const TONE_PAGE = "TONE_PAGE";

export const TURN_INTO_PAGES = {
  SELECT_DOCUMENTS_PAGE,
  PROMPT_TYPE_PAGE,
  AUDIENCE_PAGE,
  SELECT_EXAMPLE_PAGE,
  TONE_PAGE,
};

export const pickExampleDocument = {
  id: "pick-example-document",
  name: "Pick an example doc",
};

export const toneOptions = [
  pickExampleDocument,
  {
    id: "default",
    name: "Default",
  },
  {
    id: "informative",
    name: "Informative",
  },
  {
    id: "exciting",
    name: "Exciting",
  },
  {
    id: "persuasive",
    name: "Persuasive",
  },
  {
    id: "casual",
    name: "Casual",
  },
  {
    id: "formal",
    name: "Formal",
  },
  {
    id: "humorous",
    name: "Humorous",
  },
  {
    id: "inspirational",
    name: "Inspirational",
  },
];

export const turnIntoOptions = [
  {
    id: "BLOG",
    name: "Blog Post",
    prompt: "Write a blog post using",
  },
  {
    id: "EMAIL",
    name: "Email",
    prompt: "Write an email using",
  },
  {
    id: "PITCH",
    name: "Pitch",
    prompt: "Write a sales pitch using",
  },
  {
    id: "LANDING_PAGE",
    name: "Landing Page",
    prompt: "Write high-conversion landing page copy using",
  },
  {
    id: "PRESENTATION",
    name: "Presentation",
    prompt: "Write a slide presentation using",
  },
  {
    id: "PRESS_RELEASE",
    name: "Press Release",
    prompt: "Write a press release using",
  },
  {
    id: "LINKEDIN",
    name: "LinkedIn Post",
    prompt: "Write a LinkedIn post using",
  },
  {
    id: "TWITTER",
    name: "Twitter/X Post",
    prompt: "Write a Twitter post using",
  },
  {
    id: "YOUTUBE",
    name: "YouTube Script",
    prompt: "Write a YouTube script using",
  },
  {
    id: "NEWSLETTER",
    name: "Newsletter",
    prompt: "Write a newsletter using",
  },
  {
    id: "JOB_POST",
    name: "Job Post",
    prompt: "Write a job post using",
  },
  {
    id: "ANNOUNCEMENT",
    name: "Announcement",
    prompt: "Write an announcement using",
  },
  {
    id: "INTERVIEW",
    name: "Interview",
    prompt: "Write interview questions using",
  },
];

export function formatTurnIntoPrompt({
  audience,
  goal,
  tone,
  mention,
  brandVoiceId,
}) {
  let prompts = [];
  goal && prompts.push(`the goal is ${goal}`);

  if (tone && !brandVoiceId) {
    let tonePrompt = `the tone is ${tone}`;
    if (mention) {
      // example doc selected
      tonePrompt = `write the tone in the same style as `;
    }

    prompts.push(tonePrompt);
  }

  audience &&
    prompts.unshift(
      `The audience is ${audience}${prompts.length > 1 ? "," : ""}`
    );

  prompts.length > 1 && prompts.splice(prompts.length - 1, 0, "and");
  let refinePrompt = prompts.join(" ");

  return capitalize(refinePrompt);
}

const CUSTOM_PROMPT = "CUSTOM_TURN_INTO_PROMPT";
export const customTurnIntoPrompt = {
  id: CUSTOM_PROMPT,
  name: "Turn into ",
  prompt: "Turn into ",
};

export const turnIntoOptionsWithCustom = [
  ...turnIntoOptions,
  customTurnIntoPrompt,
];

export const customTonePrompt = {
  id: "custom-tone",
  name: "Set tone to ",
  prompt: "Set tone to ",
};

export const toneOptionsWithCustom = [...toneOptions, customTonePrompt];
