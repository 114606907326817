import styled from "styled-components";
import { listItemMixin } from "../../../css-mixins/menu";
import { BodyMedium } from "../../Typography";

const StyledEmptyState = styled.li`
  ${listItemMixin}
  margin: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  svg * {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }

  &:hover {
    svg * {
      fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
    }
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
      background-color: ${({ theme }) =>
        theme.button.secondary.hoverbackgroundColor};
      color: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
      svg * {
          fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
      }
    `}
`;

export default function EmptyState({
  children,
  emptyIcon,
  getItemProps,
  highlightedIndex,
  index,
  item,
  onClick,
}) {
  const itemProps = getItemProps({
    index: index,
    item: item,
    onClick: onClick,
  });

  return (
    <StyledEmptyState {...itemProps} isHighlighted={highlightedIndex === index}>
      {emptyIcon}
      <BodyMedium>{children}</BodyMedium>
    </StyledEmptyState>
  );
}
