import { gql } from "@apollo/client";

export const CHAT_SUBSCRIPTION = gql`
  subscription OnChatUpdated($chatId: ID!) {
    chatUpdated(chatId: $chatId) {
      source {
        collectionId
        documentId
      }
      chatId
      content
    }
  }
`;

export const CHAT_REPLY_STARTED = gql`
  subscription OnChatReplyStarted($chatId: ID!) {
    chatReplyStarted(chatId: $chatId) {
      chatId
      runId
    }
  }
`;

export const CHAT_REPLY_ENDED = gql`
  subscription OnChatReplyEnded($chatId: ID!) {
    chatReplyEnded(chatId: $chatId) {
      chatId
      runId
      content
    }
  }
`;

export const CHAT_REPLY_STREAM = gql`
  subscription OnChatReplyStream($chatId: ID!) {
    chatReplyStream(chatId: $chatId) {
      chatId
      runId
      content
    }
  }
`;

export const DOCUMENT_SUBSCRIPTION = gql`
  subscription OnDocumentUpdated {
    documentUpdated {
      id
      name
      collectionId
      snapshot
      createdAtDate
      stageId
      tags {
        id
        name
      }
    }
  }
`;

export const INLINE_EDIT_STARTED = gql`
  subscription OnInlineEditStarted($documentId: ID!) {
    inlineEditStarted(documentId: $documentId) {
      documentId
      type
      runId
    }
  }
`;

export const INLINE_EDIT_ENDED = gql`
  subscription OnInlineEditEnded($documentId: ID!) {
    inlineEditEnded(documentId: $documentId) {
      documentId
      runId
      type
      content
    }
  }
`;

export const INLINE_EDIT_STREAM = gql`
  subscription OnInlineEditStream($documentId: ID!) {
    inlineEditStream(documentId: $documentId) {
      documentId
      runId
      type
      content
    }
  }
`;

export const BRAND_VOICE_GENERATOR_STARTED = gql`
  subscription BrandVoiceGeneratorStarted($brandVoiceId: ID!) {
    brandVoiceGeneratorStarted(brandVoiceId: $brandVoiceId) {
      brandVoiceId
      runId
    }
  }
`;

export const BRAND_VOICE_GENERATOR_ENDED = gql`
  subscription BrandVoiceGeneratorEnded($brandVoiceId: ID!) {
    brandVoiceGeneratorEnded(brandVoiceId: $brandVoiceId) {
      brandVoiceId
      runId
      content
    }
  }
`;

export const BRAND_VOICE_GENERATOR_STREAM = gql`
  subscription BrandVoiceGeneratorStream($brandVoiceId: ID!) {
    brandVoiceGeneratorStream(brandVoiceId: $brandVoiceId) {
      brandVoiceId
      runId
      content
    }
  }
`;

export const USER_CREATED_SUBSCRIPTION = gql`
  subscription OnUserCreated {
    userCreated {
      id
    }
  }
`;
