import { useContext } from "react";
import DndDocumentContext from "../contexts/DndDocumentsContext";
import DndSortableDocumentContext from "../contexts/DndSortableDocumentsContext";
import FrameContext from "../contexts/FrameContext";
import SortableItemsProvider from "../providers/SortableItemsProvider";

export default function DndContext({ children }) {
  const { selectedView } = useContext(FrameContext);
  const Context =
    selectedView === "kanban" ? DndSortableDocumentContext : DndDocumentContext;
  return (
    <SortableItemsProvider>
      <Context>{children}</Context>
    </SortableItemsProvider>
  );
}
