import styled, { css } from "styled-components";
import { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { BodyLarge, BodyMedium } from "../Typography";
import { GET_COLLECTION, UPDATE_COLLECTION } from "../../graphql";
import Button from "../Button";

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 2rem 1.5rem 2rem;
`;

const StyledForm = styled.form``;

const textAreaStyles = css`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: ${({ theme }) => theme.typography.bodyMedium.fontWeight};
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  line-height: ${({ theme }) => theme.typography.bodyMedium.lineHeight};
  background-color: ${({ theme }) => theme.input.backgroundColor};
  color: ${({ theme }) => theme.input.foregroundColor};
  resize: none;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.input.borderColor};
  padding: 2rem 2.5rem;
  outline: none;
  border-radius: 1rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

const StyledSystemRole = styled.textarea`
  ${textAreaStyles}
  height: 30rem;
`;

export default function Form({
  saveButtonRef,
  defaultSystemRole,
  collectionId,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [updateCollectionMutation] = useMutation(UPDATE_COLLECTION);
  const [systemRoleValue, setSystemRoleValue] = useState(defaultSystemRole);

  const saveSettings = useCallback(
    async (e) => {
      e.preventDefault();

      const result = await updateCollectionMutation({
        variables: {
          id: collectionId,
          input: {
            systemRole: systemRoleValue,
          },
        },
        refetchQueries: [GET_COLLECTION],
      });

      if (result.data) {
        setIsEditMode(false);
      }
    },
    [collectionId, systemRoleValue, updateCollectionMutation]
  );

  return (
    <StyledForm onSubmit={saveSettings}>
      <StyledRow>
        <BodyLarge>Settings</BodyLarge>
        {isEditMode ? (
          <Button ref={saveButtonRef} type="submit">
            <BodyMedium>Save</BodyMedium>
          </Button>
        ) : (
          <Button onClick={() => setIsEditMode(true)}>
            <BodyMedium>Edit</BodyMedium>
          </Button>
        )}
      </StyledRow>
      <Label onClick={() => setIsEditMode(true)}>
        <StyledSystemRole
          value={systemRoleValue}
          onChange={(e) => setSystemRoleValue(e.target.value)}
          readOnly={!isEditMode}
          placeholder={
            "Add instruction in here to give the AI extra context on what you would like to do..."
          }
        />
      </Label>
    </StyledForm>
  );
}
