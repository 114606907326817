import { useSelector } from "react-redux";
import GridView from "../GridView";
import { documentsByStageDate } from "../../../features/collectionsSlice";

export default function Grids({
  documents = [],
  stagePosition,
  dates,
  stageId,
  collectionId,
  handleCreateNewDocument,
}) {
  const documentsByDate = useSelector(
    documentsByStageDate(collectionId, stageId)
  );

  if (!dates || !dates.length) {
    return <GridView documents={documents} stagePosition={stagePosition} />;
  }
  return (
    <>
      {dates.map((date, i) => (
        <GridView
          key={date}
          stagePosition={i}
          showNewDocument={i === 0}
          documents={documentsByDate[date] || []}
          date={date}
          handleCreateNewDocument={handleCreateNewDocument}
        />
      ))}
    </>
  );
}
