import { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import FrameContext from "../../../contexts/FrameContext";
import { numberOfStagesByCollectionId } from "../../../features/collectionsSlice";
import useDeleteStage from "../../../hooks/api/useDeleteStage";
import useUpdateStage from "../../../hooks/api/useUpdateStage";
import DeleteModal from "../../DeleteModal";
import {
  ArrowDown12,
  ArrowRight12,
  ArrowLeft12,
  ArrowUp12,
  Delete12,
  More12,
} from "../../Icon";
import IconButton from "../../IconButton";
import Menu from "../../Menu";
import Popover from "../../Popover";
import Tooltip from "../../Tooltip";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

export default function StageMoreMenu({
  documentCount,
  name,
  stageId,
  stagePosition,
}) {
  const { selectedView } = useContext(FrameContext);
  const { collectionId } = useCurrentCollectionId();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { deleteStage } = useDeleteStage();
  const numberOfStages = useSelector(
    numberOfStagesByCollectionId(collectionId)
  );
  const updateStage = useUpdateStage();

  let description =
    documentCount > 0
      ? `To delete the "${name}" stage, you need to either move all documents to another stage, or delete them.`
      : `Are you sure you want to delete the “${name}” stage? This action cannot be undone.`;
  if (numberOfStages === 1) {
    description =
      "You must have at least one Stage in your workspace. To delete this Stage, you need to create another Stage.";
  }

  const handleDeleteStage = useCallback(() => {
    setIsModalOpen(true);
    setIsMoreMenuOpen(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    deleteStage(stageId);
    setIsModalOpen(false);
  }, [deleteStage, stageId]);

  const handleMoveStageUp = useCallback(() => {
    updateStage({ id: stageId, input: { position: stagePosition - 1 } });
    setIsMoreMenuOpen(false);
  }, [stageId, stagePosition]);

  const handleMoveStageDown = useCallback(() => {
    updateStage({ id: stageId, input: { position: stagePosition + 1 } });
    setIsMoreMenuOpen(false);
  }, [stageId, stagePosition]);

  const items = useMemo(() => {
    const menuItems = [
      {
        id: "delete-stage",
        name: "Delete Stage",
        onClick: handleDeleteStage,
        icon: <Delete12 />,
      },
    ];

    stagePosition !== numberOfStages - 1 &&
      menuItems.unshift({
        id: "move-stage-down",
        name: `Move Stage ${selectedView === "kanban" ? "Right" : "Down"}`,
        onClick: handleMoveStageDown,
        icon: selectedView === "kanban" ? <ArrowRight12 /> : <ArrowDown12 />,
      });

    stagePosition !== 0 &&
      menuItems.unshift({
        id: "move-stage-up",
        name: `Move Stage ${selectedView === "kanban" ? "Left" : "Up"}`,
        onClick: handleMoveStageUp,
        icon: selectedView === "kanban" ? <ArrowLeft12 /> : <ArrowUp12 />,
      });

    return menuItems;
  }, [handleDeleteStage, stagePosition, numberOfStages, selectedView]);

  const onConfirm = useMemo(() => {
    if (documentCount > 0) {
      return null;
    }
    if (numberOfStages === 1) {
      return null;
    }
    return handleConfirmDelete;
  }, [documentCount, handleConfirmDelete, numberOfStages]);

  const heading =
    numberOfStages === 1
      ? "You need at least one Stage"
      : `Delete “${name}” Stage`;

  return (
    <>
      <Popover
        placement={"bottom-end"}
        isOpen={isMoreMenuOpen}
        setIsOpen={setIsMoreMenuOpen}
        closePopover={() => setIsMoreMenuOpen(false)}
        activator={
          <Tooltip content="More Options">
            <IconButton
              icon={<More12 />}
              onClick={() => setIsMoreMenuOpen(true)}
            />
          </Tooltip>
        }
      >
        <Menu items={items} isOpen={isMoreMenuOpen} />
      </Popover>
      {isModalOpen && (
        <DeleteModal
          description={description}
          heading={heading}
          onClose={() => setIsModalOpen(false)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
}
