import styled from "styled-components";
import { useCallback } from "react";
import { Search12 } from "../Icon";
import { useDispatch } from "react-redux";
import { toggleSearch } from "../../features/uiSlice";
import ListItem from "./ListItem";
import { BodyMedium } from "../Typography";
import Tooltip from "../Tooltip";

const StyledIcon = styled(Search12)`
  flex-shrink: 0;
`;

const StyledListItem = styled(ListItem)`
  padding: 0.5rem 1.5rem;
  display: flex;
  gap: 1rem;
  margin-bottom: 1px;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  cursor: pointer;

  :hover ${StyledIcon} path {
    fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }
`;

const SearchButton = () => {
  const dispatch = useDispatch();

  const toggle = useCallback(() => {
    return dispatch(toggleSearch());
  }, [dispatch]);

  return (
    <Tooltip content="Search" placement={"right"} shortcuts={["CTRL", "/"]}>
      <StyledListItem onClick={toggle}>
        <StyledIcon />
        <BodyMedium>Search</BodyMedium>
      </StyledListItem>
    </Tooltip>
  );
};

export default SearchButton;
