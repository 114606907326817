import { css } from "styled-components";

export default css`
  align-items: center;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: row;
  grid-column: 2 / 2;
  position: absolute;
  z-index: 1;
`;
