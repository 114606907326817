import { useCallback } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useDndContext } from "@dnd-kit/core";
import {
  setSelected,
  unselect,
  selectIsCommandPaletteOpen,
  toggleIsOpenState,
} from "../../features/multiSelectSlice";
import { Checkmark8 } from "../Icon";

const StyledIcon = styled(Checkmark8)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  pointer-events: none;
  path {
    fill: ${({ theme }) => theme.checkbox.foregroundColor};
  }
`;

const selectedStyles = css`
  border-color: transparent;
  background-color: ${({ theme }) => theme.checkbox.selectedBackgroundColor};
  backdrop-filter: blur(1rem);
  & + ${StyledIcon} {
    display: block;
    path {
      fill: ${({ theme }) => theme.checkbox.selectedForegroundColor};
    }
  }
`;

const hoverStyles = css`
  border-color: ${({ theme }) => theme.checkbox.hoverBorderColor};
  background-color: ${({ theme }) => theme.checkbox.backgroundColor};

  & + ${StyledIcon} {
    display: block;
    path {
      fill: ${({ theme }) => theme.checkbox.hoverForegroundColor};
    }
  }
`;

const defaultListViewStyles = css`
  border: 1px solid ${({ theme }) => theme.border.primary};
  background: transparent;

  :hover {
    background: transparent;
  }

  & + ${StyledIcon} {
    display: none;
  }
`;

const defaultGridViewStyles = css`
  border: 1px solid ${({ theme }) => theme.checkbox.borderColor};
  background: ${({ theme }) => theme.checkbox.backgroundColor};
`;

const StyledInput = styled.input`
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  appearance: none;
  margin: 0;
  border-radius: 50%;
  ${({ $viewType }) =>
    $viewType === "grid" ? defaultGridViewStyles : defaultListViewStyles}

  :hover {
    ${({ value }) => !value && hoverStyles}
  }

  ${({ value }) => value && selectedStyles}
  ${({ $isDragging }) => $isDragging && "pointer-events: none;"}
`;

export const documentHoverStyles = css`
  ${StyledIcon} {
    display: block;
    path {
      fill: ${({ theme }) => theme.checkbox.foregroundColor};
    }
  }

  ${StyledInput} {
    border-color: ${({ theme }) => theme.checkbox.borderColor};
    background-color: transparent;
    :hover {
      ${({ $isChecked }) => !$isChecked && hoverStyles}
    }
    ${({ $isChecked }) => $isChecked && selectedStyles}
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

function Checkbox({
  className,
  documentId,
  isChecked = false,
  viewType = "grid",
}) {
  const { active } = useDndContext();
  const dispatch = useDispatch();
  const isCommandPaletteOpen = useSelector(selectIsCommandPaletteOpen);
  const onChange = useCallback(() => {
    if (!isCommandPaletteOpen) {
      dispatch(toggleIsOpenState(true));
    }
    dispatch(isChecked ? unselect(documentId) : setSelected(documentId));
  }, [dispatch, isChecked, documentId, isCommandPaletteOpen]);

  return (
    <StyledWrapper className={className}>
      <StyledInput
        $viewType={viewType}
        $isDragging={Boolean(active)}
        id={documentId}
        value={isChecked}
        onChange={onChange}
        type="checkbox"
      />
      <StyledIcon />
    </StyledWrapper>
  );
}

export default styled(Checkbox)``;
