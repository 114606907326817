import { useQuery, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { load } from "../features/collectionsSlice";
import { load as documentLoad } from "../features/documentsSlice";
import { GET_COLLECTION, GET_FULL_COLLECTIONS } from "../graphql";
import useCurrentCollectionId from "./useCurrentCollectionId";

export default function useGetCollection({
  documentsFetched,
  setDocumentsFetched,
}) {
  const { collectionId } = useCurrentCollectionId();
  const [getAllCollections] = useLazyQuery(GET_FULL_COLLECTIONS);
  const dispatch = useDispatch();
  const { loading, data } = useQuery(GET_COLLECTION, {
    variables: { collectionId },
    fetchPolicy: "cache-and-network",
    skip: !collectionId,
    onCompleted: (data) => {
      if (data.collection === null) return;
      const { documents, ...collection } = data.collection;
      dispatch(load([data.collection]));
      dispatch(documentLoad({ documents, collectionId: data.collection.id }));
      document.title = "Strut - " + collection?.name;
      if (documentsFetched) return;
      getAllCollections({
        variables: { fullFetch: true },
        fetchPolicy: "cache-and-network",
        onCompleted: (collectionsData) => {
          setDocumentsFetched(true);
          collectionsData.collections.forEach((collection) => {
            const { documents } = collection;
            if (documents?.length > 0) {
              dispatch(load([collection]));
              dispatch(
                documentLoad({ documents, collectionId: collection.id })
              );
            }
          });
        },
      });
    },
  });

  return { loading, data };
}
