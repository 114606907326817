import styled, { css } from "styled-components";
import { forwardRef } from "react";
import { buttonReset } from "../css-mixins/button";
import { BodyMedium, BodySmall } from "./Typography";

const smallStyles = css`
  padding: 0.5rem 1rem;
  border-radius: 3rem;
`;

const StyledButton = styled.button`
  ${buttonReset}
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  color: ${({ theme }) => theme.button.primary.foregroundColor};
  border-radius: 2rem;
  padding: 0.5rem 1.5rem;
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.1s ease-out;

  path {
    fill: ${({ theme }) => theme.button.primary.foregroundColor};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    path {
      fill: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    }
  }

  &:active {
    transform: scale(0.99, 0.98);
  }

  ${({ size }) => size === "small" && smallStyles}
`;

const Button = forwardRef(
  ({ size = "medium", children, onClick, className, Icon, ...rest }, ref) => {
    const TypographyComponent = size === "small" ? BodySmall : BodyMedium;
    return (
      <StyledButton
        size={size}
        ref={ref}
        onClick={onClick}
        className={className}
        {...rest}
      >
        {Icon && <Icon />}
        <TypographyComponent>{children}</TypographyComponent>
      </StyledButton>
    );
  }
);

Button.displayName = "Button";

export default styled(Button)``;
