import { useSelector } from "react-redux";
import styled from "styled-components";
import { tagsByCollectionId as tagsByCollectionIdSelector } from "../../../features/documentsSlice";
import { stopPropagation } from "../../../lib/event-helpers";
import DocumentMoreMenu from "../../DocumentMoreMenu";
import StageSelector from "../../StageSelector/StageSelector";
import TagSelector from "../../TagSelector";
import useCurrentCollectionId from "../../../hooks/useCurrentCollectionId";

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  margin-right: 3rem;
`;

const StyledTagSelector = styled(TagSelector)`
  position: unset;
`;

export default function DocumentButtons({ document }) {
  const { id, tags = [] } = document;
  const { collectionId, isInbox } = useCurrentCollectionId();
  const tagsByCollectionId = useSelector(
    tagsByCollectionIdSelector(collectionId)
  );

  return (
    <StyledButtonWrapper onClick={stopPropagation}>
      <StyledTagSelector
        tags={tags}
        documentId={id}
        tagsByCollectionId={tagsByCollectionId}
      />
      {!isInbox && <StageSelector documentId={id} />}
      <DocumentMoreMenu documentId={id} />
    </StyledButtonWrapper>
  );
}
