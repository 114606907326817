import { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import useSearch from "../../hooks/api/useSearch";
import { useSelector, useDispatch } from "react-redux";
import { getInboxCollectionId } from "../../features/inboxSlice";
import { Folder12, Search12, Close8 } from "../Icon";
import { toggleSearch } from "../../features/uiSlice";
import { device } from "../../css-mixins/media-queries";

const StyledSearch = styled.div`
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  margin-top: 8rem;
  padding: 0 2rem 10rem;
`;

const Header = styled.header`
  position: sticky;
  top: 0;
`;

const SearchIcon = styled(Search12)`
  display: flex;
  flex-shrink: 0;
  position: absolute;
  left: 1.5rem;
  pointer-events: none;
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const SearchInputWrapper = styled.div`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.border.hoverPrimary};
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 24rem;
  background-color: ${({ theme }) => theme.body.backgroundColor};
  position: relative;
  z-index: 1;
  backdrop-filter: blur(0.5rem);

  &:hover ${SearchIcon} path {
    fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }

  &:focus-within ${SearchIcon} path {
    fill: ${({ theme }) => theme.button.secondary.hoverForegroundColor};
  }

  @media (${device.mobile}) {
    width: 100%;
  }
`;

const SearchInput = styled.input`
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  display: flex;
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  width: 100%;
  padding: 0.7rem 1rem 0.7rem 4rem;

  @media (${device.mobile}) {
    font-size: ${({ theme }) => theme.typography.bodyLarge.fontSize};
  }
`;

const Circle = styled.div`
  background-color: ${({ theme }) => theme.body.secondaryForegroundColor};
  border-radius: 1.25rem;
  padding: 0.5rem;
  width: 16px;
  height: 16px;
  display: flex;
  path {
    fill: ${({ theme }) => theme.body.secondaryBackgroundColor};
  }

  :hover {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverForegroundColor};
  }
`;

const StyledCloseWrapper = styled.div`
  display: flex;
  padding: 0.75rem;
  cursor: pointer;

  :hover ${Circle} {
    background-color: ${({ theme }) =>
      theme.button.secondary.hoverForegroundColor};
  }

  @media (${device.mobile}) {
    padding: 1rem;
  }
`;

const Results = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 1rem;
`;

const StyledBody = styled.p`
  font-size: 12px;
  max-height: 32px;
  overflow: hidden;
  line-height: 16px;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  .highlight {
    font-weight: 600;
  }
`;

const StyledCollection = styled.p`
  padding: 0.5rem 0 0;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.button.secondary.foregroundColor};
  path {
    fill: ${({ theme }) => theme.button.secondary.foregroundColor};
  }
`;

const StyledResult = styled(Link)`
  border-top: 1px solid ${({ theme }) => theme.border.primary};
  color: ${({ theme }) => theme.body.foregroundColor};
  text-decoration: none;
  padding: 1.5rem 2rem;
  gap: 0.5rem;
  display: flex;
  flex-flow: column;
  width: 23.875rem;
  outline: none;

  @media (${device.mobile}) {
    width: 100%;
  }

  .highlight {
    font-weight: 600;
  }

  :hover,
  :focus {
    border-top: 1px solid transparent;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.body.subtleBackgroundColor};
  }

  &:hover + &,
  &:focus + & {
    border-top: 1px solid transparent;
  }

  &:hover ${StyledBody}, &:hover ${StyledCollection} {
    color: ${({ theme }) => theme.body.foregroundColor};
    path {
      fill: ${({ theme }) => theme.body.foregroundColor};
    }
  }

  &:focus ${StyledBody}, &:focus ${StyledCollection} {
    color: ${({ theme }) => theme.body.foregroundColor};
    path {
      fill: ${({ theme }) => theme.body.foregroundColor};
    }
  }

  :first-child {
    border-top: 1px solid transparent;
  }
`;

const StyledTitle = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  line-height: ${({ theme }) => theme.typography.bodyMedium.lineHeight};
  padding: 0;
  margin: 0;

  .highlight {
    font-weight: 700;
  }
`;

const StyledGradient = styled.div`
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    ${({ theme }) => theme.body.backgroundColor} 100%
  );
  height: 7rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

function CloseSearch(props) {
  return (
    <StyledCloseWrapper {...props}>
      <Circle>
        <Close8 />
      </Circle>
    </StyledCloseWrapper>
  );
}

export default function Search() {
  const [term, setTerm] = useState("");
  const inputRef = useRef();
  const resultsRef = useRef();
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState({
    results: [],
    loading: false,
  });

  const search = useSearch();
  const inboxCollectionId = useSelector(getInboxCollectionId);

  const doSearch = useCallback(
    debounce(async (term) => {
      setSearchResults({ results: [], loading: true });
      if (term) {
        const response = await search({ term });
        setSearchResults({ results: response.data.search, loading: false });
      } else {
        setSearchResults({ results: [], loading: false });
      }
    }, 500),
    [search]
  );

  const updateTerm = useCallback(
    (e) => {
      setTerm(e.target.value);
      doSearch(e.target.value);
    },
    [doSearch, setTerm]
  );

  const clearSearch = useCallback(() => {
    setTerm("");
    setSearchResults({ loading: false, results: [] });
    dispatch(toggleSearch());
  }, [setTerm, setSearchResults, dispatch]);

  useEffect(() => {
    if (term === "") {
      inputRef.current.focus();
    }
  }, [term]);

  const makeLink = useCallback(
    (result) => {
      if (result.collectionIds.includes(inboxCollectionId)) {
        return `/inbox/${result.id}`;
      }
      return `/c/${result.collectionIds[0]}/${result.id}`;
    },
    [inboxCollectionId]
  );

  const handleKeyDown = useCallback((e) => {
    if (e.key === "ArrowUp" && e.target.previousSibling) {
      e.target.previousSibling.focus();
    } else if (e.key === "ArrowDown" && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  }, []);

  const handleInputKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowDown") {
        const firstResult = resultsRef.current.querySelector("a");
        firstResult?.focus();
      } else if (e.key === "Escape") {
        e.stopPropagation();
        clearSearch();
      }
    },
    [resultsRef, clearSearch]
  );

  return (
    <StyledSearch>
      <Header>
        <SearchInputWrapper>
          <SearchIcon />
          <SearchInput
            ref={inputRef}
            type="text"
            className="fs-mask"
            onKeyDown={handleInputKeyDown}
            value={term}
            autoFocus
            onChange={updateTerm}
            placeholder="Search"
          />
          <CloseSearch onClick={clearSearch} $show={term} />
        </SearchInputWrapper>
        <StyledGradient />
      </Header>
      <Results onKeyDown={handleKeyDown} ref={resultsRef}>
        {searchResults.results.map((result) => (
          <StyledResult key={result.id} to={makeLink(result)}>
            <StyledTitle dangerouslySetInnerHTML={{ __html: result.name }} />
            <StyledBody
              dangerouslySetInnerHTML={{
                __html: result.body,
              }}
            />
            <StyledCollection>
              <Folder12 />
              {result.collections[0]}
            </StyledCollection>
          </StyledResult>
        ))}
      </Results>
    </StyledSearch>
  );
}
