import { DragOverlay, useDndContext } from "@dnd-kit/core";
import { useContext, useEffect, useRef, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FrameContext from "../../contexts/FrameContext";
import { tagsByCollectionId as tagsByCollectionIdSelector } from "../../features/documentsSlice";
import { COLORS } from "../../theme";
import { BodyMedium } from "../Typography";
import Checkbox from "./Checkbox";
import DocumentPreview from "./GridView/DocumentPreview";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const StyledListDocument = styled.div`
  cursor: grabbing;
  padding: 2rem;
  border-radius: 1.5rem;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.border.primary},
    0 1rem 3rem 0 ${COLORS.BLACK250T};
  background: ${({ theme }) => theme.body.secondaryBackgroundColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
  transition: transform 0.15s ease;
  ${({ $isOverWorkspace }) =>
    $isOverWorkspace && `transform: scale(0.3); max-width: 300px;`}
`;

const StyledWrapper = styled.div`
  cursor: grabbing;
  transition: transform 0.15s ease;
  box-shadow: 0 1rem 3rem 0 ${COLORS.BLACK250T};
  border-radius: 1.5rem;
  ${({ $isOverWorkspace }) => $isOverWorkspace && `transform: scale(0.3);`}
`;

export default function DocumentDragOverlay({ document }) {
  const { selectedView } = useContext(FrameContext);
  const { active, over } = useDndContext();
  const isActive = active && active.id.indexOf(document?.id) !== -1;
  const isOverWorkspace =
    isActive && over?.id && over.id.indexOf("workspace") !== -1;
  const { collectionId } = useCurrentCollectionId();
  const tagsByCollectionId = useSelector(
    tagsByCollectionIdSelector(collectionId)
  );

  const wrapperRef = useRef(null);
  const [isFirstMoveOver, setIsFirstMoveOver] = useState(true);

  const handleMouseMove = useCallback(
    (event) => {
      if (isOverWorkspace && wrapperRef.current) {
        if (isFirstMoveOver) {
          const rect = wrapperRef.current.getBoundingClientRect();
          const x = ((event.clientX - rect.left) / rect.width) * 100;
          const y = ((event.clientY - rect.top) / rect.height) * 100;
          wrapperRef.current.style.transformOrigin = `${x}% ${y}%`;
          setIsFirstMoveOver(false);
        }
      } else {
        setIsFirstMoveOver(true);
      }
    },
    [wrapperRef, isOverWorkspace, isFirstMoveOver]
  );

  useEffect(() => {
    if (document) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseMove, document]);

  return (
    <DragOverlay dropAnimation={null}>
      {Boolean(document) &&
        (selectedView === "list" ? (
          <StyledListDocument
            ref={wrapperRef}
            $isOverWorkspace={isOverWorkspace}
          >
            <Checkbox viewType="list" />
            <BodyMedium>{document?.name || "Untitled"}</BodyMedium>
          </StyledListDocument>
        ) : (
          <StyledWrapper ref={wrapperRef} $isOverWorkspace={isOverWorkspace}>
            <DocumentPreview
              document={document}
              tagsByCollectionId={tagsByCollectionId}
            />
          </StyledWrapper>
        ))}
    </DragOverlay>
  );
}
