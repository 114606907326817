import { useSelector } from "react-redux";
import { collectionsList } from "../../features/collectionsSlice";
import Page from "../Page";
import NewWorkspaceButton from "./NewWorkspaceButton";
import Workspace from "./Workspace";

export default function Home() {
  const workspaces = useSelector(collectionsList);

  return (
    <Page header="Workspaces">
      <NewWorkspaceButton />
      {workspaces.length > 0 &&
        workspaces
          .filter((workspace) => !workspace.inbox)
          .sort((a, b) => a["order"] - b["order"])
          .map((workspace) => (
            <Workspace
              key={workspace.id}
              workspace={workspace}
              showOnSidebar={workspace.showOnSidebar}
            />
          ))}
    </Page>
  );
}
