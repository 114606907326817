function getMentionNode(mention) {
  return mention
    ? [
        {
          type: "mention",
          attrs: {
            id: mention.id,
            label: mention.name || "Untitled",
            "data-mention-type": mention.type,
          },
        },
      ]
    : [];
}

function getSelectedDocumentsNode(selectedDocuments) {
  return selectedDocuments.length > 0
    ? [
        {
          type: "text",
          text: " ",
        },
        ...selectedDocuments.map((document, index) => {
          return {
            type: "mention",
            attrs: {
              id: document.id,
              label: (index > 0 ? ", " : "") + (document.name || "Untitled"),
              "data-mention-type": "document",
            },
          };
        }),
      ]
    : [];
}

export function composeTiptapJsonFromMultiSelect(
  multiSelectionAction,
  multiSelectedDocuments
) {
  const { prompt, refine } = multiSelectionAction;

  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: prompt,
          },
          ...getSelectedDocumentsNode(multiSelectedDocuments),
          {
            type: "text",
            text: ".",
          },
          ...(refine && refine.prompt
            ? [
                {
                  type: "text",
                  text: " " + refine.prompt,
                },
                ...getMentionNode(refine.mention),
                {
                  type: "text",
                  text: ".",
                },
              ]
            : []),
        ],
      },
    ],
  };
}
