import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_COLLECTIONS } from "../graphql/queries";
import { load } from "../features/collectionsSlice";

export default function useGetCollections() {
  const dispatch = useDispatch();
  const { data, loading, error, refetch } = useQuery(GET_COLLECTIONS, {
    onCompleted: (data) => {
      dispatch(load(data.collections));
    },
    fetchPolicy: "cache-and-network",
  });

  return { data, loading, error, refetch };
}
