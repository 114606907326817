import { useQuery, useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";

import { inboxCollectionFetched } from "../features/inboxSlice";
import { load } from "../features/collectionsSlice";
import { load as documentLoad } from "../features/documentsSlice";
import { GET_INBOX_COLLECTION, GET_COLLECTION } from "../graphql";
import { useCallback } from "react";

export default function useInbox() {
  const dispatch = useDispatch();
  const [getInboxCollection] = useLazyQuery(GET_COLLECTION);
  const callback = useCallback(
    (data) => {
      dispatch(load([data.collection]));
      dispatch(
        documentLoad({
          documents: data.collection.documents,
          collectionId: data.collection.id,
        })
      );
    },
    [dispatch]
  );

  const { loading, data, refetch } = useQuery(GET_INBOX_COLLECTION, {
    onCompleted: (data) => {
      if (data.inboxCollection === null) return;
      dispatch(inboxCollectionFetched(data.inboxCollection));
      getInboxCollection({
        variables: { collectionId: data.inboxCollection.id },
        fetchPolicy: "cache-and-network",
        onCompleted: callback,
      });
    },
  });

  return {
    loading,
    id: data?.inboxCollection?.id,
    callback,
    getInboxCollection,
    refetchInbox: refetch,
  };
}
