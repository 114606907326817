import styled from "styled-components";
import COLORS from "../../theme/colors";
import { NavLink } from "react-router-dom";
import Button from "../Button";
import { useAuth0 } from "@auth0/auth0-react";

const Footer = styled.ul`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 0;
  border-top: 1px solid ${COLORS.BLACK100T};
`;

const FooterItem = styled.li`
  display: inline;
  a {
    font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
    color: ${COLORS.BLACK500T};
    text-decoration: none;
    :hover {
      color: ${COLORS.BLACK800T};
    }
  }
  :not(:last-child) {
    margin-right: 2rem;
  }
`;

const FooterCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 72rem;
  margin: 8rem auto 16rem;
  padding: 0 2rem;
`;

const SubHeading = styled.h2`
  font-size: 6rem;
  margin: 1rem 0 2rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  max-width: 80rem;
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.OCEAN500};
  color: ${COLORS.WHITE};
  margin-top: 3rem;
  :hover {
    background-color: ${COLORS.OCEAN600};
    color: ${COLORS.WHITE};
  }
`;

export default function MarketingFooter() {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <FooterCTA>
        <SubHeading>
          Get started and for a limited time, Strut is completely free
        </SubHeading>
        <StyledButton
          className="btn btn-primary btn-block"
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                screen_hint: "signup",
              },
            })
          }
        >
          Get Started with Strut
        </StyledButton>
      </FooterCTA>
      <Footer>
        <FooterItem>
          <NavLink to={`/`}>Strut</NavLink>
        </FooterItem>
        <FooterItem>
          <NavLink to={`/privacy-policy`}>Privacy Policy</NavLink>
        </FooterItem>
        <FooterItem>
          <NavLink to={`/terms-of-service`}>Terms of Service</NavLink>
        </FooterItem>
      </Footer>
    </>
  );
}
