import { forwardRef } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  display: flex;
  border: none;
  flex: 1;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.bodyMedium.fontSize};
  line-height: ${({ theme }) => theme.typography.bodyMedium.lineHeight};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  color: ${({ theme }) => theme.input.foregroundColor};
  outline: none;
  background-color: transparent;
  width: 100%;
  transition: background-color 0.1s ease-in-out;
  text-overflow: ellipsis;

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholderColor};
  }
  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.input.backgroundColor};
  }
  :focus {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.input.borderColor};
  }
`;

const Input = forwardRef(({ value, onChange, className, ...rest }, ref) => {
  return (
    <StyledInput
      ref={ref}
      className={`${className} fs-mask`}
      type="text"
      placeholder="Untitled"
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
});

Input.displayName = "Input";

export default Input;
