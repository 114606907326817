import styled from "styled-components";
import { useCallback, useState, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { listReset } from "../../css-mixins/list";
import { REMOVE_USERS_FROM_COLLECTION, GET_COLLECTION } from "../../graphql";
import { COLORS } from "../../theme";
import { BodyMedium } from "../Typography";
import { buttonReset } from "../../css-mixins/button";
import { ChevronDown8 } from "../Icon";
import Popover from "../Popover";
import Menu from "../Menu";
import useCurrentCollectionId from "../../hooks/useCurrentCollectionId";

const StyledAvatar = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: ${COLORS.BLACK050T};
  user-select: none;
`;

const StyledDefaultAvatar = styled.span`
  width: 3rem;
  height: 3rem;
  display: inline-block;
  border-radius: 50%;
  background: ${COLORS.BLACK050T};
`;

const StyledUser = styled.li`
  ${listReset}
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.body.secondaryForegroundColor};
`;

const StyledName = styled(BodyMedium)`
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledButton = styled.button`
  ${buttonReset}
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.button.primary.foregroundColor};
  svg path {
    fill: ${({ theme }) => theme.button.primary.foregroundColor};
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.button.primary.hoverBackgroundColor};
    color: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    svg path {
      fill: ${({ theme }) => theme.button.primary.hoverForegroundColor};
    }
  }
`;

const StyledIcon = styled(ChevronDown8)`
  margin-left: 1rem;
`;

const StyledOwner = styled.div`
  padding: 0.5rem 1.5rem;
`;

export default function User({ user }) {
  const { user: authUser } = useAuth0();
  const { collectionId } = useCurrentCollectionId();
  const [isAccessMenuOpen, setIsAccessMenuOpen] = useState(false);
  const closeAccessMenu = useCallback(() => {
    setIsAccessMenuOpen(false);
  }, []);

  const openAccessMenu = useCallback(() => {
    setIsAccessMenuOpen(true);
  }, []);

  const [removeUserMutation] = useMutation(REMOVE_USERS_FROM_COLLECTION);

  const removeUser = useCallback(() => {
    removeUserMutation({
      variables: {
        collectionId,
        userIds: [user.id],
      },
      refetchQueries: [GET_COLLECTION],
    });

    closeAccessMenu();
  }, [collectionId, user, removeUserMutation, closeAccessMenu]);

  const items = useMemo(() => {
    return [
      {
        id: "edit",
        name: "Can edit",
        isSelected: true,
      },
      {
        id: "remove",
        name: "Remove access",
        isSelected: false,
        onClick: removeUser,
      },
    ];
  }, [removeUser]);

  return (
    <StyledUser>
      {user.picture ? (
        <StyledAvatar referrerPolicy="no-referrer" src={user.picture} />
      ) : (
        <StyledDefaultAvatar />
      )}
      <StyledName>{user.name}</StyledName>
      {user.id === authUser["strutt-uuid"] || user.type === "Owner" ? (
        <StyledOwner>
          <BodyMedium>{user.type}</BodyMedium>
        </StyledOwner>
      ) : (
        <Popover
          activator={
            <StyledButton onClick={openAccessMenu}>
              <BodyMedium>{user.type}</BodyMedium>
              <StyledIcon />
            </StyledButton>
          }
          placement={"bottom-end"}
          isOpen={isAccessMenuOpen}
          setIsOpen={setIsAccessMenuOpen}
          closePopover={closeAccessMenu}
        >
          <Menu withSelectedIcon items={items} isOpen={isAccessMenuOpen} />
        </Popover>
      )}
    </StyledUser>
  );
}
