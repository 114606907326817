import styled from "styled-components";
import { useContext } from "react";
import FrameContext from "../../contexts/FrameContext";
import { gridMixin } from "../../css-mixins/main-grid";
import { device } from "../../css-mixins/media-queries";

const StyledModal = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.body.backgroundColor};
  display: flex;
  flex-direction: column;
  z-index: 3;
  height: 100vh;
  height: 100dvh;
  pointer-events: auto;
  transition: padding 0.3s ease-in-out;
  min-width: 0%;

  @media (${device.desktop}) {
    grid-column: 2 / 3;
  }

  @media (${device.mobile}) {
    max-width: 100%;
    height: 100%;
  }
`;

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  @media (${device.desktop}) {
    ${gridMixin}
  }

  @media (${device.mobile}) {
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

function DocumentModal({ children, className }) {
  const {
    modalRef,
    isSidebarRightOpen,
    isSidebarLeftOpen,
    rightColWidth,
    isDragging,
  } = useContext(FrameContext);

  return (
    <StyledModalWrapper
      $isSidebarRightOpen={isSidebarRightOpen}
      $isDragging={isDragging}
      $isSidebarLeftOpen={isSidebarLeftOpen}
      $rightColWidth={rightColWidth}
      ref={modalRef}
    >
      <StyledModal className={className}>{children}</StyledModal>
    </StyledModalWrapper>
  );
}
export default styled(DocumentModal)``;
