import { Command } from "cmdk";
import styled from "styled-components";

const CommandInput = styled(Command.Input)`
  width: 100%;
  border: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 3.5rem;
  color: ${({ theme }) => theme.cmdk.hoverForegroundColor};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: ${(props) => props.theme.typography.bodyLarge.fontWeight};
  font-size: ${(props) => props.theme.typography.bodyLarge.fontSize};
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  ::placeholder {
    color: ${({ theme }) => theme.cmdk.placeholderColor};
  }
`;

export default CommandInput;
