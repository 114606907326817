import { useAuth0 } from "@auth0/auth0-react";
import Button from "./Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  background-color: transparent;
  backdrop-filter: blur(1rem);
`;

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <StyledButton
      className="btn btn-primary btn-block"
      onClick={() => loginWithRedirect()}
    >
      Log In
    </StyledButton>
  );
};

export default LoginButton;
