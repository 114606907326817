import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { device } from "../css-mixins/media-queries";
import COLORS from "../theme/colors";
import { BodyLarge, BodyMedium } from "./Typography";
import Button from "./Button";
import Workspace from "../assets/image/Workspace.png";
import HeroLeft from "../assets/image/Hero-Left.svg";
import HeroRight from "../assets/image/Hero-Right.svg";
import CaseStudy from "../assets/image/Case-Study.png";
import Screenshot from "../assets/image/Screenshot.png";
import Editor from "../assets/image/Editor.png";
import FooterLeft from "../assets/image/Footer-Left.svg";
import FooterRight from "../assets/image/Footer-Right.svg";
import MarketingNavigation from "./Marketing/MarketingNavigation";
import MarketingFooter from "./Marketing/MarketingFooter";
import Share from "../assets/image/Share.png";

const PageWrapper = styled.div`
  background-color: ${COLORS.SAND500};
  background-repeat: repeat;
  overflow: scroll;
  height: 100vh;
  @media (${device.mobile}) {
    justify-content: flex-start;
  }
`;

const StickyWrapper = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${COLORS.SAND500};
`;

const BottomWrapper = styled.div`
  background: #fff;
  padding: 8rem 0 0;
  position: sticky;
  bottom: 0;
`;

const HeroLeftWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 40rem;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const HeroRightWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 10rem;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const HeroHeading = styled.h1`
  font-size: 10rem;
  line-height: 10rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  margin: 0 0 2rem;

  @media only screen and (max-width: 800px) {
    font-size: 7rem;
    line-height: 7rem;
  }
`;

const HeroTitleWrapper = styled.div`
  padding: 24rem 2rem 12rem 2rem;
  text-align: center;
  max-width: 72rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const HeroImage = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  img {
    width: 100%;
  }
`;

const HeroWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    ${COLORS.SAND500} 100%
  );
`;

const YourVoiceSection = styled.div`
  background: ${COLORS.SAND500};
  padding: 0 2rem 16rem;
  @media only screen and (max-width: 800px) {
    padding-bottom: 4rem;
  }
`;

const CollaborativeSection = styled.div`
  background: #fff;
  padding: 8rem 2rem 16rem;
  @media only screen and (max-width: 800px) {
    padding-bottom: 4rem;
  }
`;

const AutomateSection = styled.div`
  background: ${COLORS.SAND500};
  padding: 8rem 2rem 20rem;
`;

const TeamGrid = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 1200px;
  margin: 0 auto;

  @media only screen and (max-width: 800px) {
    display: block;

    > div {
      margin-bottom: 2rem;
    }
  }

  > div {
    background-color: ${COLORS.SAND500};
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const GridItem = styled.div`
  :first-of-type {
    grid-area: span 2 / span 1 / span 2 / span 1;
  }
`;

const GridDescription = styled.div`
  margin: 3rem 4rem;
`;

const CaseStudyWrapper = styled.div`
  background-color: #fff;
  margin: 1rem 1rem 0;
  padding: 6rem 2rem 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
  flex-grow: 1;
`;

const EditorWrapper = styled.div`
  margin: 1rem;
  padding: 2rem;

  img {
    margin: 0 auto;
    display: block;
    max-width: 290px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.OCEAN500};
  color: ${COLORS.WHITE};
  margin-top: 3rem;
  :hover {
    background-color: ${COLORS.OCEAN600};
    color: ${COLORS.WHITE};
  }
`;

const SubHeading = styled.h2`
  font-size: 6rem;
  margin: 1rem 0 2rem;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  max-width: 80rem;
`;

const FeatureLabel = styled(BodyMedium)`
  color: ${({ color }) => color || COLORS.OCEAN500};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`;

const FeatureTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 72rem;
  margin: 8rem auto 6rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  max-width: 1200px;
  margin: 6rem auto 0;
  place-items: center;

  @media only screen and (max-width: 800px) {
    display: block;
    padding: 2rem;

    > div {
      margin-bottom: 4rem;
    }
  }
`;

const FeatureGridItem = styled.div`
  > * {
    display: inline;
  }
`;

const StyledBodyLarge = styled(BodyLarge)`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
`;

const StyledCaseStudy = styled.img`
  max-width: 80%;
  margin: 0 auto;
  display: block;
`;

const WordCloud = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 90rem;
  flex-wrap: wrap;
  justify-content: center;
`;

const CloudItem = styled.div`
  white-space: nowrap;
  margin: 1rem 2rem;
  font-size: 3rem;
  font-weight: 700;
`;

const FooterLeftWrapper = styled.div`
  position: absolute;
  bottom: -48px;
  left: 10rem;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;
const FooterRightWrapper = styled.div`
  position: absolute;
  bottom: -48px;
  right: 10rem;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export default function LandingPage() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/inbox" />;
  }

  return (
    <PageWrapper>
      <MarketingNavigation />
      <StickyWrapper>
        <HeroWrapper>
          <HeroTitleWrapper>
            <HeroHeading>Turn notes into finished work with AI</HeroHeading>
            <BodyLarge>
              Strut is a collaborative notebook that uses your notes and AI to
              create professional content that sounds like you.
            </BodyLarge>
            <StyledButton
              className="btn btn-primary btn-block"
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: "signup",
                  },
                })
              }
            >
              Get Started with Strut
            </StyledButton>
          </HeroTitleWrapper>
          <HeroImage>
            <img src={Screenshot} alt="Strut Screenshot" />
          </HeroImage>
        </HeroWrapper>
        <HeroLeftWrapper>
          <img src={HeroLeft} alt="Shapes" />
        </HeroLeftWrapper>
        <HeroRightWrapper>
          <img src={HeroRight} alt="Shapes" />
        </HeroRightWrapper>
        <YourVoiceSection>
          <FeatureTextWrapper>
            <FeatureLabel color="#017264">Personal</FeatureLabel>
            <SubHeading>Your notes, your voice</SubHeading>
            <BodyLarge>
              Strut uses your notes and examples of your work to create polished
              content that sounds like you.
            </BodyLarge>
          </FeatureTextWrapper>
          <HeroImage>
            <img src={Workspace} alt="Working on documents with AI" />
          </HeroImage>
          <FeatureGrid>
            <FeatureGridItem>
              <StyledBodyLarge>Sound Natural &nbsp;</StyledBodyLarge>
              <BodyLarge>
                Train the AI with examples of your work to create with your own
                voice and tone.
              </BodyLarge>
            </FeatureGridItem>
            <FeatureGridItem>
              <StyledBodyLarge>@Mention &nbsp;</StyledBodyLarge>
              <BodyLarge>
                Quickly reference documents when chatting with AI using
                @mentions.
              </BodyLarge>
            </FeatureGridItem>
            <FeatureGridItem>
              <StyledBodyLarge>Tagging &nbsp;</StyledBodyLarge>
              <BodyLarge>
                Tag collections of content to easily reference multiple
                documents with the AI.
              </BodyLarge>
            </FeatureGridItem>
          </FeatureGrid>
        </YourVoiceSection>
        <CollaborativeSection>
          <FeatureTextWrapper>
            <FeatureLabel color="#5C6AE4">Collaborative</FeatureLabel>
            <SubHeading>Your team + AI</SubHeading>
            <BodyLarge>
              Collaborate in real-time with your team and AI. Capture notes
              together, chat with AI, and share final versions.
            </BodyLarge>
          </FeatureTextWrapper>
          <TeamGrid>
            <GridItem>
              <CaseStudyWrapper>
                <StyledCaseStudy src={CaseStudy} alt="Collaboration" />
              </CaseStudyWrapper>
              <GridDescription>
                <StyledBodyLarge>Fully Collaborative &nbsp;</StyledBodyLarge>
                <BodyLarge>
                  Invite your team to join you in multiplayer workspaces
                </BodyLarge>
              </GridDescription>
            </GridItem>
            <GridItem>
              <EditorWrapper>
                <img src={Editor} alt="Hero" />
              </EditorWrapper>
              <GridDescription>
                <StyledBodyLarge>Easy to use editor &nbsp;</StyledBodyLarge>
                <BodyLarge>
                  Use markdown or formatting tools to create polished docs
                </BodyLarge>
              </GridDescription>
            </GridItem>
            <GridItem>
              <EditorWrapper>
                <img src={Share} alt="Hero" />
              </EditorWrapper>
              <GridDescription>
                <StyledBodyLarge>Shared workspaces &nbsp;</StyledBodyLarge>
                <BodyLarge>
                  Create and invite your team to different workspaces
                </BodyLarge>
              </GridDescription>
            </GridItem>
          </TeamGrid>
        </CollaborativeSection>
        <AutomateSection>
          <FeatureTextWrapper>
            <FeatureLabel color="#FF6550">Fast</FeatureLabel>
            <SubHeading>Automate Repetitive Writing Tasks</SubHeading>
            <BodyLarge>
              Use templates, past examples, and rough notes to automatically
              create complete documents.
            </BodyLarge>
          </FeatureTextWrapper>
          <WordCloud>
            <CloudItem>Press Releases</CloudItem>
            <CloudItem>Blog Posts</CloudItem>
            <CloudItem>Job Postings</CloudItem>
            <CloudItem>Ad Copy</CloudItem>
            <CloudItem>Newsletters</CloudItem>
            <CloudItem>Landing Pages</CloudItem>
            <CloudItem>Reports</CloudItem>
            <CloudItem>Sales Pitches</CloudItem>
            <CloudItem>Presentations</CloudItem>
            <CloudItem>Interviews</CloudItem>
            <CloudItem>Video & Podcast Scripts</CloudItem>
            <CloudItem>And so much more</CloudItem>
          </WordCloud>
        </AutomateSection>
        <FooterLeftWrapper>
          <img src={FooterLeft} />
        </FooterLeftWrapper>
        <FooterRightWrapper>
          <img src={FooterRight} />
        </FooterRightWrapper>
      </StickyWrapper>
      <BottomWrapper>
        <MarketingFooter />
      </BottomWrapper>
    </PageWrapper>
  );
}
