import { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import FrameContext from "../../../../contexts/FrameContext";
import CommandPaletteContext from "../../../../contexts/CommandPaletteContext";
import {
  setAction,
  getSelectedCount,
} from "../../../../features/multiSelectSlice";
import {
  brainstormPromptId,
  brainstormWithDocumentsPromptId,
} from "../../../../lib/cmd-palette-helpers";
import { Idea16 } from "../../../Icon";
import { BodyLarge } from "../../../Typography";
import DocumentsSelector from "../DocumentSelector";
import Footer from "../Footer";
import GoBackButton from "../GoBackButton";
import SubmitButton from "../SubmitButton";
import TextArea from "../TextArea";

const StyledWrapper = styled.div`
  padding: 1rem;
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  column-gap: 1rem;
  user-select: none;
`;

export default function IdeasPage() {
  const match = useMatch("/c/:collectionId/:documentId");
  const documentId = match?.params.documentId;
  const [ideas, setIdeas] = useState("");
  const dispatch = useDispatch();
  const { setCommandListPage } = useContext(CommandPaletteContext);
  const { openSidebarRight } = useContext(FrameContext);
  const selectedDocumentsCount = useSelector(getSelectedCount);

  const handleSubmitBrainstorm = useCallback(() => {
    const includeDocuments = selectedDocumentsCount > 0;
    let prompt = `Brainstorm: ${ideas}${
      includeDocuments ? ". Use the following as reference:" : ""
    }`;

    const promptTemplate = includeDocuments
      ? brainstormWithDocumentsPromptId
      : brainstormPromptId;

    openSidebarRight();
    dispatch(
      setAction({
        action: {
          id: promptTemplate,
          prompt,
        },
      })
    );
  }, [dispatch, ideas, openSidebarRight, selectedDocumentsCount]);

  return (
    <>
      <StyledWrapper>
        <TextArea
          autoFocus
          id="brainstorm-ideas"
          icon={<Idea16 />}
          label="Brainstorm"
          onChange={(e) => setIdeas(e.target.value)}
          placeholder="What would you like to brainstorm ideas about?"
          value={ideas}
        />
        {!documentId && <DocumentsSelector />}
      </StyledWrapper>
      <Footer>
        <StyledLeft>
          <GoBackButton onClick={setCommandListPage} />
          <BodyLarge>Brainstorm ideas</BodyLarge>
        </StyledLeft>
        <SubmitButton disabled={ideas === ""} onClick={handleSubmitBrainstorm}>
          Brainstorm
        </SubmitButton>
      </Footer>
    </>
  );
}
