import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import FrameContext from "../../contexts/FrameContext";
import { device } from "../../css-mixins/media-queries";
import { GET_LATEST_CHAT, RESET_CHAT } from "../../graphql";
import { List16, SidebarRight16, Settings12 } from "../Icon";
import IconButton from "../IconButton";
import ChatContent from "./ChatContent";
import Input from "./Input";
import ChatStreaming from "./ChatStreaming";
import { isMessagesOver } from "../../features/userMetadataSlice";
import UpgradeChat from "./UpgradeChat";
import Tooltip from "../Tooltip";
import { getIsTyping } from "../../features/uiSlice";
import Button from "../Button";

const StyledChatHeader = styled.div`
  padding: 1.5rem 6rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  -webkit-app-region: drag;

  @media (${device.mobile}) {
    padding-right: 1.75rem;
  }
`;

const StyledChat = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  grid-template-areas:
    "header"
    "content"
    "input";
`;

const StyledIconWrapper = styled.div`
  position: fixed;
  right: 3rem;
  top: 2.5rem;
  z-index: 3; // Needs to be above Document Modal
  opacity: ${({ isSidebarRightOpen, isEditorTyping }) =>
    !isSidebarRightOpen && isEditorTyping ? 0 : 1};
  transition: opacity 0.5s ease-in-out;
  -webkit-app-region: no-drag;
`;

const StyledIconButton = styled(IconButton)`
  @media (${device.mobile}) {
    ${({ shouldShowOnMobile }) => !shouldShowOnMobile && "display: none;"}
  }
`;

export default function Chat({ openDocuments, collectionId }) {
  const [resetChatMutation] = useMutation(RESET_CHAT);
  const [chatId, setChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const isEditorTyping = useSelector(getIsTyping);
  const [isNewChat, setIsNewChat] = useState(false);
  const overMessagesLimit = useSelector(isMessagesOver);

  const navigate = useNavigate();
  const { rightColRef, toggleSidebarRight, isSidebarRightOpen, rightColWidth } =
    useContext(FrameContext);

  const openModal = useCallback(() => {
    navigate("settings");
  }, [navigate]);

  const resetChat = useCallback(() => {
    setIsNewChat(true);
    setChatId(null);
    setMessages([]);
    resetChatMutation({
      variables: { collectionId },
      onCompleted: ({ resetChat }) => {
        if (resetChat?.chatId) {
          setChatId(resetChat.chatId);
        }
      },
    });
  }, []);

  const shortcutSidebarRight = useCallback(
    (e) => {
      if (e.ctrlKey && e.key === "\\") {
        toggleSidebarRight();
      }
    },
    [toggleSidebarRight]
  );

  useEffect(() => {
    window.addEventListener("keydown", shortcutSidebarRight);
    return () => {
      window.removeEventListener("keydown", shortcutSidebarRight);
    };
  }, [shortcutSidebarRight]);

  useQuery(GET_LATEST_CHAT, {
    variables: { collectionId },
    skip: !!isNewChat || !collectionId,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data.chat.length) {
        setChatId(data.chat[0].chatId);
        setMessages(data.chat);
      }
    },
  });

  const lastRunId =
    messages.length > 0 ? messages[messages.length - 1].runId : null;

  return (
    <>
      <StyledChat ref={rightColRef}>
        {chatId && (
          <ChatStreaming
            setIsTyping={setIsTyping}
            setMessages={setMessages}
            chatId={chatId}
          />
        )}
        <StyledChatHeader>
          <StyledIconWrapper
            isSidebarRightOpen={isSidebarRightOpen}
            isEditorTyping={isEditorTyping}
          >
            <Tooltip
              content={isSidebarRightOpen ? "Close Sidebar" : "Open Sidebar"}
              shortcuts={["CTRL", "\\"]}
            >
              <StyledIconButton
                onClick={toggleSidebarRight}
                icon={<SidebarRight16 />}
              />
            </Tooltip>
          </StyledIconWrapper>
          {messages.length > 0 && (
            <Button onClick={resetChat}>Clear Chat</Button>
          )}
          <Tooltip content="AI Settings">
            <IconButton
              onClick={openModal}
              icon={<Settings12 />}
              aria-label="Chat Settings"
            />
          </Tooltip>

          <IconButton icon={<List16 />} isMobileOnly onClick={openDocuments} />
        </StyledChatHeader>
        <ChatContent messages={messages} />
        {overMessagesLimit ? (
          <UpgradeChat />
        ) : (
          <Input
            setMessages={setMessages}
            chatId={chatId}
            setChatId={setChatId}
            setIsTyping={setIsTyping}
            isTyping={isTyping}
            lastRunId={lastRunId}
            rightColWidth={rightColWidth}
          />
        )}
      </StyledChat>
    </>
  );
}
